import { ReadTransferFreightCost } from "Features/Survey";
import { Container } from "semantic-ui-react";

function ViewTransferFreightCost () {

    return (
        <Container className="page">

            <ReadTransferFreightCost />

        </Container>
    );
}

export default ViewTransferFreightCost;