import { useState, useEffect, useContext } from "react";
import useGetCompany from "Features/Company/ReadCompany/useGetCompany";
import useGetCompanyUnits from "../ListCompanyUnits/useGetCompanyUnits";
import useUpdateCompanyUnits from "./useUpdateCompanyUnits";
import { Form, Table, Header, Input, Segment }  from 'semantic-ui-react';
import { useTranslation } from "react-i18next";
import { ButtonContainer, SaveBtn, CancelBtn } from "Components/Buttons";
import { useNavigate, useParams } from "react-router-dom";
import { MessageContext } from "Features/Messages";
import useInputValidation from "Components/FormElements/InputWithUnit/useInputValidation";

function UpdateCompany () {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { companyId } = useParams();
    const [ isLoading, setIsLoading ] = useState(true);
    const { addMessage, setMessages } = useContext(MessageContext);
    
    const { updateCompanyUnits } = useUpdateCompanyUnits();
    const { company, getCompanyById } = useGetCompany();
    const { companyUnits, getCompanyUnits } = useGetCompanyUnits();
    const [ categories, setCategories ] = useState<any>([]);
    const { isValidMonth, isValidYear } = useInputValidation();

    // Fetch company and company units
    useEffect(() => {
        (async () => {
            if (companyId) {
                await getCompanyById(companyId);
                await getCompanyUnits(companyId);
                setIsLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // set initial values for company
    useEffect(() => {
        if (companyUnits.length>0) {
            const cats:any[] = [];
            setCategories([]);
            companyUnits.map(unit => {
                if(!cats.find(cat => cat.name === unit.category.name)){
                    cats.push({id: unit.category.id, name: unit.category.name, units: []})
                }
                cats.find(cat => cat.name === unit.category.name).units.push(unit);
                return unit;
            });
            setCategories(cats);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[companyUnits]);

    const setUnitField = (catId:string,unitId:string,fieldname:string,value?:string) => {
        const cats = [...categories];
        const cat = cats.find(c => c.id === catId);
        const unit = cat.units.find((u:any) => u.unitId === unitId);
        const numericValue = value==="" ? null : parseInt(value || "");
        switch(fieldname){
            case "isCompanyUnit":
                unit.isCompanyUnit=!unit.isCompanyUnit;
                break;
            case "usageStartMonth":
                unit.usageStartMonth=numericValue;
                break;
            case "usageStartYear":
                unit.usageStartYear=numericValue;
                break;
            case "usageEndMonth":
                unit.usageEndMonth=numericValue;
                break;
            case "usageEndYear":
                unit.usageEndYear=numericValue;
                break;
            }
        setCategories(cats);
    }

    const onSubmit = async () => {

        let errors = false;
        const data:any = [];
        categories.map((cat:any) => {
            cat.units.map((unit:any) =>{
                if(!isValidMonth(unit.usageStartMonth) || !isValidMonth(unit.usageEndMonth) || 
                    !isValidYear(unit.usageStartYear,2000,new Date().getFullYear()+2) || !isValidYear(unit.usageEndYear,2000,new Date().getFullYear()+2)){
                    errors=true
                }
                if(unit.isCompanyUnit){
                    data.push(unit);
                }
                return unit;
            });
            return cat;
        });

        setMessages([]);
        if(errors){
            addMessage({header: t("features.survey.inputErrorHeader"), type: "error", body: t("features.survey.inputErrorDescription")});
            window.scrollTo(0,0);
        } else {

            setIsLoading(true);

            let result = await updateCompanyUnits(companyId,data);

            if (result && result.status === 204) return navigate(-1);
            
            setIsLoading(false);
        }
    }

    return (
        
        <Form loading={isLoading} onSubmit={onSubmit}>
            {categories.length>0 ?
                <>
                 {companyId &&
                    <Segment className="invisible">
                    <Header sub>{t("features.companyUnit.company")}</Header>
                    <div>{company && company.name}</div>
                    </Segment>
                }
                {categories.map((category:any) => 
                    <div key={"cat" + category.id}>
                        <Header as="h2">{category.name}</Header>
                        <div className="table-wrapper">
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>{t("features.companyUnit.unit")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("features.companyUnit.active")}</Table.HeaderCell>
                                        <Table.HeaderCell colSpan="2">{t("features.companyUnit.usageStart")}</Table.HeaderCell>
                                        <Table.HeaderCell colSpan="2">{t("features.companyUnit.usageEnd")}</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {category.units.length>0 && category.units.map((unit:any) =>
                                        <Table.Row key={"unit" + unit.unitId}>
                                            <Table.Cell>
                                                {unit.unitName}
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Form.Checkbox
                                                    id={"active" + unit.unitId}
                                                    onChange={(e,{value}) => setUnitField(category.id,unit.unitId,"isCompanyUnit")}
                                                    checked={unit.isCompanyUnit}
                                                />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Input
                                                    id={"usageStartMonth" + unit.unitId}
                                                    value={unit.usageStartMonth || ""}
                                                    type="number"
                                                    onChange={(e) => setUnitField(category.id,unit.unitId,"usageStartMonth",e.target.value)}
                                                    error={!isValidMonth(unit.usageStartMonth)}
                                                    placeholder={t("features.companyUnit.month")}
                                                />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Input
                                                    id={"usageStartYear" + unit.unitId}
                                                    value={unit.usageStartYear || ""}
                                                    type="number"
                                                    onChange={(e) => setUnitField(category.id,unit.unitId,"usageStartYear",e.target.value)}
                                                    error={!isValidYear(unit.usageStartYear,2000,new Date().getFullYear()+2)}
                                                    placeholder={t("features.companyUnit.year")}
                                                />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Input
                                                    id={"usageEndMonth" + unit.unitId}
                                                    value={unit.usageEndMonth || ""}
                                                    type="number"
                                                    onChange={(e) => setUnitField(category.id,unit.unitId,"usageEndMonth",e.target.value)}
                                                    error={!isValidMonth(unit.usageEndMonth)}
                                                    placeholder={t("features.companyUnit.month")}
                                                />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Input
                                                    id={"usageEndYear" + unit.unitId}
                                                    value={unit.usageEndYear || ""}
                                                    type="number"
                                                    onChange={(e) => setUnitField(category.id,unit.unitId,"usageEndYear",e.target.value)}
                                                    error={!isValidYear(unit.usageEndYear,2000,new Date().getFullYear()+2)}
                                                    placeholder={t("features.companyUnit.year")}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    )}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                )}

                <ButtonContainer>
                    <SaveBtn />
                    <CancelBtn />
                </ButtonContainer>
                </>
            :
                <p>{t("features.companyUnit.notFound")}</p>
            }
        </Form>
    );
}

export default UpdateCompany;
