import { useTranslation } from 'react-i18next';
import { Container } from 'semantic-ui-react';

const NotFound404 = () => {

    const { t } = useTranslation();

    return (
        <Container className="page systempage">
            <h1>404</h1>
            <p>{t('pages.pageNotFound')}</p>
        </Container>
    );
}

export default NotFound404;
