import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import useGetVolume from "./useGetVolume";
import useGetCompany from "Features/Company/ReadCompany/useGetCompany";
import { useParams } from "react-router-dom";
import { Header, Segment, Table, Button, Icon } from "semantic-ui-react";
import { ButtonContainer, EditBtn } from "Components/Buttons";
import { StateContext } from "Application/state/StateContext";
import { PageTitle } from "Components/Titles";
import useSurvey from "Features/Survey/useSurvey";
import useUpdateVolume from "../UpdateVolume/useUpdateVolume";
import { YesNoModal } from "Components/Modals";
import { ListCompanySurveyAttachements } from "Features/CompanyAttachement";

function ReadVolume () {

    const { t } = useTranslation();
    const { userProfile } = useContext(StateContext);
    const { companyId, surveyId } = useParams();
    const { volume, getVolumeById } = useGetVolume();
    const { company, getCompanyById } = useGetCompany();
    const [ isLoading, setIsLoading ] = useState(true);
    const { isEditingAllowed } = useSurvey();
    const { updateVolume } = useUpdateVolume();
    const [ lockSurveyModalOpen, setLockSurveyModalOpen ] = useState(false);
    const [ openSurveyModalOpen, setOpenSurveyModalOpen ] = useState(false);
    const [ isLocked, setIsLocked ] = useState(true);
    const [ isOpen, setIsOpen ] = useState(true);
    const [ isEditable, setIsEditable ] = useState(false);
    const [ deadline, setDeadline ] = useState<null | Date>(null);

    const isAdmin = userProfile && userProfile.roles && userProfile.roles.find((item:any) => item.name==="Admin") ? true : false;

    // Fetch survey data
    useEffect(() => {
        (async () => {
            if(companyId || userProfile.companyId){
                await getVolumeById(companyId || userProfile.companyId,surveyId);
            }
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userProfile.companyId]);

    // Fetch company
    useEffect(() => {
        (async () => {
            if(volume.companyId){
                await getCompanyById(volume.companyId);
                setIsEditable(isEditingAllowed(volume.isPermanentlyLocked,volume.openDate,volume.closeDate))
                setIsLocked(volume.isPermanentlyLocked);
                setIsOpen(new Date(volume.closeDate)>new Date());
                let dl = new Date(volume.closeDate);
                dl.setSeconds(dl.getSeconds()-1);
                setDeadline(dl);
                setIsLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[volume]);

    // count how many months each unit has been used during the period
    volume.categories.map((cat:any) => {
        cat.units.map((unit:any) => {
            const usageStartMonth = unit.companyUnitUsageStartMonth || 1;
            const usageStartYear = unit.companyUnitUsageStartYear || volume.period;
            const usageEndMonth = unit.companyUnitUsageEndMonth || 12;
            const usageEndYear = unit.companyUnitUsageEndYear || volume.period;
            if(usageStartYear>volume.period){
                //usage starts after the period
                unit.usageMonths=0;
            } else if(usageStartYear<volume.period){
                //usage starts before start of the period
                if(usageEndYear>volume.period){
                    //usage ends after end of the period
                    unit.usageMonths=12;
                } else {
                    //usage from start of the period until usage end month
                    unit.usageMonths=usageEndMonth;
                }
            } else {
                //usage starts after start of the period
                if(usageEndYear>volume.period){
                    //usage ends after end of the period
                    unit.usageMonths=13-usageStartMonth;
                } else {
                    //usage from start of the period until usage end month
                    unit.usageMonths=usageEndMonth + 1 - usageStartMonth;
                }
            }
            return unit;
        });
        return cat;
    });

    const lockSurvey = async (answer:boolean) => {
        setLockSurveyModalOpen(false);
        setIsLoading(true);

        const volume = {
            isPermanentlyLocked: true
        }
        let result = await updateVolume(companyId || userProfile.companyId,surveyId,volume);
        if (result && result.status === 204) {
            setIsEditable(false);
            setIsLocked(true);
        }
        setIsLoading(false);
        window.scrollTo(0,0);
    }

    const openSurvey = async (answer:boolean) => {
        setOpenSurveyModalOpen(false);
        setIsLoading(true);

        // open survey until the end of next day
        let closedate = new Date()
        closedate = new Date(closedate.getFullYear(),closedate.getMonth(),closedate.getDate()+2);
        const volume = {
            closeDate: closedate
        }
        let result = await updateVolume(companyId || userProfile.companyId,surveyId,volume);
        if (result && result.status === 204) {
            setIsOpen(true);
            closedate.setSeconds(closedate.getSeconds()-1);
            setDeadline(closedate);
        }
        
        setIsLoading(false);
        window.scrollTo(0,0);
    }

    return (
        <>
        <YesNoModal 
            type="save"
            header={t("features.survey.lockSurvey")}
            text={t("features.survey.lockSurveyConfirm")}
            method={lockSurvey}
            open={lockSurveyModalOpen}
            setOpen={setLockSurveyModalOpen}
            item=""
            noClick={false}
            handleClose={true}
            disableYesButton={false}
        />
        <YesNoModal 
            type="save"
            header={t("features.survey.openSurvey")}
            text={t("features.survey.openSurveyConfirm")}
            method={openSurvey}
            open={openSurveyModalOpen}
            setOpen={setOpenSurveyModalOpen}
            item=""
            noClick={false}
            handleClose={true}
            disableYesButton={false}
        />
        <PageTitle title={volume.name!=='' ? volume.name : t("pages.volumeDetail")} />
        <Segment className="invisible" loading={isLoading}>
            {volume.id ?
                <>
                {isAdmin &&
                    <>
                    <Header sub>{t("features.budget.deadline")}</Header>
                    <div>{deadline?.toLocaleDateString("fi-FI")}</div>
                    </>
                }
                {companyId &&
                    <>
                    <Header sub>{t("features.volume.company")}</Header>
                    <div>{company && company.name}</div>
                    </>
                }

                <div className="table-wrapper">
                    <Table>
                    {volume.categories.map((cat:any) => 
                        <Table.Body key={"cat" + cat.id}>
                        <Table.Row>
                            <Table.HeaderCell>{cat.name}</Table.HeaderCell>
                            <Table.HeaderCell>{t("features.volume.amount")}, {t("features.volume.liters")}</Table.HeaderCell>
                            <Table.HeaderCell>{t("features.volume.pcs")}</Table.HeaderCell>
                            <Table.HeaderCell>{t("features.volume.usage")}</Table.HeaderCell>
                        </Table.Row>
                        {cat.units.map((unit:any) =>
                            <Table.Row key={"unit" + unit.itemId}>
                                <Table.Cell>{unit.unitName}</Table.Cell>
                                <Table.Cell>{unit.liters}</Table.Cell>
                                <Table.Cell>{unit.amount}</Table.Cell>
                                <Table.Cell>{unit.usageMonths}</Table.Cell>
                            </Table.Row>
                        )}
                        </Table.Body>
                    )}
                    </Table>
                </div>

                <ListCompanySurveyAttachements 
                    companyId={userProfile.companyId}
                    surveyId={surveyId}
                    surveyTypeRef={"volume"}
                />

                <ButtonContainer>
                    <EditBtn to={"edit"} disabled={!isEditable} />
                    {isAdmin &&
                        <>
                        <Button
                            icon
                            className='extended-button lock-button'
                            labelPosition="left"
                            onClick={() => setLockSurveyModalOpen(true) }
                            disabled={isLocked}
                        >
                            <Icon name={'lock'} />
                            {t("features.survey.lock")}
                        </Button>
                        <Button
                            icon
                            className='extended-button date-button'
                            labelPosition="left"
                            onClick={() => setOpenSurveyModalOpen(true) }
                            disabled={isLocked || isOpen}
                        >
                            <Icon name={'calendar'} />
                            {t("features.survey.openSurvey")}
                        </Button>
                        </>
                    }
                </ButtonContainer>
                </>
            :
                <p>{t("features.survey.notFound")}</p>
            }

        </Segment>
        </>
    );
}

export default ReadVolume;
