import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import useCopyToClipboard from './hooks/useCopyToClipboard';
import ICopyToClipboard from './interfaces/ICopyToClipboard';

/** Copy value to clipboard 
 * @param {string} text - copied text
*/
const ClipboardCopy: React.FC<ICopyToClipboard> = ({ text, ...rest }: ICopyToClipboard) => {

    const { t } = useTranslation();
    const [ open, setOpen ] = useState(false);
    const { message, borderColor, onCopy } = useCopyToClipboard();

    // Empty span is needed for the trigger to show the popup.
    // Popup didnt work if trigger was CopyToClipBoard component
    return (
        <span className='no-print'>
            <Popup
                content={message}
                pinned
                onClose={() => setOpen(false)}
                hideOnScroll
                style={{borderColor: borderColor}}
                trigger={<span></span>}
                open={open}
            />
            <CopyToClipboard text={text} onCopy={(val) => {
                onCopy(val);
                setOpen(true);
            }} {...rest}>
                <Button
                    icon
                    labelPosition={'left'}
                    size='medium'
                >
                    <Icon name='copy outline'/>
                    {t('components.clipboard.title')}
                </Button>
            </CopyToClipboard>
        </span>
    )

}

export default ClipboardCopy;
