import { useState } from "react";
import { useTranslation } from 'react-i18next';
import IUseCopyToClipBoardData from '../interfaces/IUseCopyToClipBoardData';

const useCopyToClipboard = (): IUseCopyToClipBoardData => {

    const { t } = useTranslation();
    
    const [ message, setMessage ] = useState(t('components.clipboard.copied'));
    const [ borderColor, setBorderColor ] = useState('#21ba45');

    const onCopy = (value: string) => {

        // If value is returned, the cvopy was succesfull
        if (value) {
            setMessage(t('components.clipboard.copied'));
            setBorderColor('#21ba45');
        }

        // If value is null, copy was not succesfull
        if (!value) {
            setMessage(t('components.emptyValuesCantBeCopied'));
            setBorderColor('#db2828');
        }

    }

    return { message, borderColor, onCopy };

}

export default useCopyToClipboard;
