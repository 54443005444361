import { Container } from 'semantic-ui-react';
import { PageTitle } from 'Components/Titles';
import { ReadCompany } from 'Features/Company';

function ViewCompany () {
    return (
        <Container className='page'>
        
            <PageTitle title='Company details' />

            <ReadCompany />

        </Container>
    );
}

export default ViewCompany;