import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import useGetTransferFreightCost from "./useGetTransferFreightCost";
import useGetCompany from "Features/Company/ReadCompany/useGetCompany";
import { useParams } from "react-router-dom";
import { Header, Segment, Button, Icon } from "semantic-ui-react";
import { ButtonContainer, EditBtn } from "Components/Buttons";
import { StateContext } from "Application/state/StateContext";
import { PageTitle } from "Components/Titles";
import { PagedTable } from "Components/Tables";
import useSurvey from "Features/Survey/useSurvey";
import useUpdateTransferFreightCost from "../UpdateTransferFreightCost/useUpdateTransferFreightCost";
import { YesNoModal } from "Components/Modals";
import { ListCompanySurveyAttachements } from "Features/CompanyAttachement";

function ReadTransferFreightCost () {

    const { t } = useTranslation();
    const { userProfile } = useContext(StateContext);
    const { companyId, surveyId } = useParams();
    const { transferFreightCost, getTransferFreightCostById } = useGetTransferFreightCost();
    const { company, getCompanyById } = useGetCompany();
    const [ isLoading, setIsLoading ] = useState(true);
    const { isEditingAllowed } = useSurvey();
    const { updateTransferFreightCost } = useUpdateTransferFreightCost();
    const [ lockSurveyModalOpen, setLockSurveyModalOpen ] = useState(false);
    const [ openSurveyModalOpen, setOpenSurveyModalOpen ] = useState(false);
    const [ isLocked, setIsLocked ] = useState(true);
    const [ isOpen, setIsOpen ] = useState(true);
    const [ isEditable, setIsEditable ] = useState(false);
    const [ deadline, setDeadline ] = useState<null | Date>(null);

    const isAdmin = userProfile && userProfile.roles && userProfile.roles.find((item:any) => item.name==="Admin") ? true : false;

    // Fetch survey data
    useEffect(() => {
        (async () => {
            if(companyId || userProfile.companyId){
                await getTransferFreightCostById(companyId || userProfile.companyId,surveyId);
            }
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userProfile.companyId]);

    // Fetch company
    useEffect(() => {
        (async () => {
            if(transferFreightCost.companyId){
                await getCompanyById(transferFreightCost.companyId);
                setIsEditable(isEditingAllowed(transferFreightCost.isPermanentlyLocked,transferFreightCost.openDate,transferFreightCost.closeDate))
                setIsLocked(transferFreightCost.isPermanentlyLocked);
                setIsOpen(new Date(transferFreightCost.closeDate)>new Date());
                let dl = new Date(transferFreightCost.closeDate);
                dl.setSeconds(dl.getSeconds()-1);
                setDeadline(dl);
                setIsLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[transferFreightCost]);

    const lockSurvey = async (answer:boolean) => {
        setLockSurveyModalOpen(false);
        setIsLoading(true);

        const transferFreightCost = {
            isPermanentlyLocked: true
        }
        let result = await updateTransferFreightCost(companyId || userProfile.companyId,surveyId,transferFreightCost);
        if (result && result.status === 204) {
            setIsEditable(false);
            setIsLocked(true);
        }
        setIsLoading(false);
        window.scrollTo(0,0);
    }

    const openSurvey = async (answer:boolean) => {
        setOpenSurveyModalOpen(false);
        setIsLoading(true);

        // open survey until the end of next day
        let closedate = new Date()
        closedate = new Date(closedate.getFullYear(),closedate.getMonth(),closedate.getDate()+2);
        const transferFreightCost = {
            closeDate: closedate
        }
        let result = await updateTransferFreightCost(companyId || userProfile.companyId,surveyId,transferFreightCost);
        if (result && result.status === 204) {
            setIsOpen(true);
            closedate.setSeconds(closedate.getSeconds()-1);
            setDeadline(closedate);
        }
        
        setIsLoading(false);
        window.scrollTo(0,0);
    }

    return (
        <>
        <YesNoModal 
            type="save"
            header={t("features.survey.lockSurvey")}
            text={t("features.survey.lockSurveyConfirm")}
            method={lockSurvey}
            open={lockSurveyModalOpen}
            setOpen={setLockSurveyModalOpen}
            item=""
            noClick={false}
            handleClose={true}
            disableYesButton={false}
        />
        <YesNoModal 
            type="save"
            header={t("features.survey.openSurvey")}
            text={t("features.survey.openSurveyConfirm")}
            method={openSurvey}
            open={openSurveyModalOpen}
            setOpen={setOpenSurveyModalOpen}
            item=""
            noClick={false}
            handleClose={true}
            disableYesButton={false}
        />
        <PageTitle title={transferFreightCost.name!=='' ? transferFreightCost.name : t("pages.transferFreightCostDetail")} />
        <Segment className="invisible" loading={isLoading}>
            {transferFreightCost.id ?
                <>
                <Header as="h2">{t("features.transferFreightCost.costvat0")}</Header>
                {isAdmin &&
                    <>
                    <Header sub>{t("features.budget.deadline")}</Header>
                    <div>{deadline?.toLocaleDateString("fi-FI")}</div>
                    </>
                }
                {companyId &&
                    <>
                    <Header sub>{t("features.transferFreightCost.company")}</Header>
                    <div>{company && company.name}</div>
                    </>
                }

                {/*transferFreightCost.freightCosts.map(item => 
                    <>
                    <Header sub>{item.companyName} ({item.companyArea})</Header>
                    <div>{item.freightCost} {t("features.transferFreightCost.euro")}</div>
                    </>
                )*/}

                <PagedTable 
                    header={[
                        t("features.transferFreightCost.company"),
                        t("features.transferFreightCost.area"),
                        t("features.transferFreightCost.cost")
                    ]}
    
                    content={transferFreightCost.freightCosts}
    
                    contentKeys={[
                        {key: "companyName"},
                        {key: "companyArea"},
                        {key: "freightCost", type: "float", fixed: true}
                    ]}
    
                    pageSize={50}
                    resetPageOnContentChange={true}
                    collapsable={false}
                />
                
                <ListCompanySurveyAttachements 
                    companyId={userProfile.companyId}
                    surveyId={surveyId}
                    surveyTypeRef={"transferfreightcost"}
                />

                <ButtonContainer>
                    <EditBtn to={"edit"} disabled={!isEditable} />
                    {isAdmin &&
                        <>
                        <Button
                            icon
                            className='extended-button lock-button'
                            labelPosition="left"
                            onClick={() => setLockSurveyModalOpen(true) }
                            disabled={isLocked}
                        >
                            <Icon name={'lock'} />
                            {t("features.survey.lock")}
                        </Button>
                        <Button
                            icon
                            className='extended-button date-button'
                            labelPosition="left"
                            onClick={() => setOpenSurveyModalOpen(true) }
                            disabled={isLocked || isOpen}
                        >
                            <Icon name={'calendar'} />
                            {t("features.survey.openSurvey")}
                        </Button>
                        </>
                    }
                </ButtonContainer>
                </>
            :
                <p>{t("features.survey.notFound")}</p>
            }

        </Segment>
        </>
    );
}

export default ReadTransferFreightCost;
