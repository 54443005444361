import { useMsal } from '@azure/msal-react';

const useAccessTokens = () => {

    const { instance, accounts } = useMsal();

    const getToken = async (scopes: Array<string>) => {

        let accessToken = "";

        const request = {
            scopes: scopes, // Replace with your API scopes ["User.Read"]
            account: accounts[0]
        };

        // Attempt to get token silently
        await instance.acquireTokenSilent(request).then((response) => {

            accessToken = response.accessToken;

        }).catch(async (e) => {

            // If silent token acquisition fails, use acquireTokenPopup, or acquireTokenRedirect
            await instance.acquireTokenPopup(request).then((response) => {
                accessToken = response.accessToken;
            });

        });
        
        return accessToken;

    }  

    return getToken;

}

export default useAccessTokens;
