import { useState } from "react";
import { useGetItems } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import { useTranslation } from 'react-i18next';
import apiConfig from 'Application/configurations/apiConfig';
import IListUnitPoolCorrection from "./IListUnitPoolCorrections";
import { useAccessTokens } from 'Features/Authentication';

function useGetUnitPoolCorrections () {

    const { t } = useTranslation();
    const [ unitPoolCorrections, setUnitPoolCorrections ] = useState<IListUnitPoolCorrection[]>([]);
    const getItems = useGetItems();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

   /** List unit pool corrections for a unit
     * @param {string} unit - id of the unit
     * @returns {Array<IListUnitPoolCorrection>} unit pool corrections */
    const listUnitPoolCorrections = async (unit:string) => {

        const basePath = apiConfig.basePath;
        const unitEndpoint = apiConfig.endpoints.units;
        const unitCorrectionEndpoint = apiConfig.endpoints.unitpoolcorrections;
        const url = new URL(`${basePath}/${unitEndpoint}/${unit}/${unitCorrectionEndpoint}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken
        }
        
        try {

            const response = await getItems(url, options);
            const data = response?.data || [];
            setUnitPoolCorrections(data);
            return responseHandler(response, "useGetUnitPoolCorrections", { operation: "list", hide: true });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "General.RequestorNotExist") body = t('features.unitPoolCorrection.requestorNotExist');
            if (problemTitle === "UnitPoolCorrectionErrors.ListPermissionDenied") body = t('features.unitPoolCorrection.listPermissionDenied');
            if (problemTitle === "UnitErrors.NotExist") body = t('features.unit.notExist');


            return errorHandler(error, "useGetUnitPoolCorrections", { operation: "list", hide: false, messageBody: body });
   
        }

    }

    return { unitPoolCorrections, setUnitPoolCorrections, listUnitPoolCorrections };

}

export default useGetUnitPoolCorrections;