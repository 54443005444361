import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useReadUserProfile from "../hooks/useReadUserProfile";
import useGetCompany from "Features/Company/ReadCompany/useGetCompany";
import { Header, Segment } from 'semantic-ui-react';

function ReadAccount () {

    const { t } = useTranslation();
    const { userProfile, readUserProfile } = useReadUserProfile();
    const { company, getCompanyById } = useGetCompany();
    const [ isLoading, setIsLoading ] = useState(true);

    // Fetch account
    useEffect(() => {
        (async () => {
            await readUserProfile();
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // Fetch company
    useEffect(() => {
        (async () => {
            if (userProfile?.companyId) {
                await getCompanyById(userProfile.companyId);
            }            
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userProfile.companyId]);

    return (
        <Segment className="invisible" loading={isLoading}>
            {userProfile.id ?
                <>
                <Header sub>{t("features.account.company")}</Header>
                <div>{company && company.name}</div>

                <Header sub>{t("features.account.name")}</Header>
                <div>{userProfile && userProfile.name}</div>

                <Header sub>{t("features.account.email")}</Header>
                <div>{userProfile && userProfile.email}</div>

                <Header sub>{t("features.account.phoneNumber")}</Header>
                <div>{userProfile && userProfile.phoneNumber}</div>
                </>
            :
                <p>{t("features.account.notFound")}</p>
            }

        </Segment>
    );
}

export default ReadAccount;
