import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Header } from 'semantic-ui-react';
import { useDropzone } from 'react-dropzone';
import useFileDropzone from './useFileDropzone';
import IFileDropzoneProps from './IFileDropzoneProps';
import './fileDropzone.css';

/** Add file dropzone
 * @param {object} files - react state for file
 * @param {function} setFiles - react set state function to set file
 * @param {integer} [maxFiles] - maximum files allowed to be uploaded - default is not limited = 0
 * @param {string} [title] - drop files component title
 * @link https://react-dropzone.js.org/#section-accepting-specific-file-types
 * @link https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 * @link https://developer.mozilla.org/en-US/docs/Web/API/window/showOpenFilePicker
*/
const FileDropZone: React.FC<IFileDropzoneProps> = ({ setFiles, maxFiles = 0, title }: IFileDropzoneProps) => {

    const { t } = useTranslation();

    const { 
        getClassName,
        onDrop,
        nameLengthValidator,
        listAcceptedFiles,
        listRejectedFiles
    } = useFileDropzone(setFiles);

    const { 
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isFocused,
        isDragActive,
        isDragReject,
        isDragAccept
    } = useDropzone({ onDrop, maxFiles:maxFiles, validator: nameLengthValidator });

    return (
        <Container className='file-dropzone'>

            {/* Title */}
            <Header sub>{title ? title : t('components.dropzone.files')}</Header>

            {/* Dropzone */}
            <section className="container">
                <div {...getRootProps(getClassName(isFocused, isDragActive, isDragReject, isDragAccept)) }>
                    <input {...getInputProps()} />
                    {
                        isDragActive ?
                            <p>{t('components.dropzone.dragNDropFiles')}</p> :
                        <>
                            <p>{t('components.dropzone.dragNDropFiles')}</p>
                            <em>{t('components.dropzone.onlyFilesAreAccepted')}</em>
                        </>
                    }
                </div>
            </section>

            {/* List accepted files with icon */}
            {acceptedFiles && acceptedFiles.length > 0 &&
                <>
                    <h4>{t('components.dropzone.acceptedFiles')}</h4>
                    {listAcceptedFiles(acceptedFiles)}
                </>
            }
            
            {/* List rejected files with icon */}
            {fileRejections && fileRejections.length > 0 &&
                <>
                    <h4>{t('components.dropzone.rejectedFiles')}</h4>
                    {listRejectedFiles(fileRejections)}
                </>
            }

        </Container>
    );

}

export default FileDropZone;
