import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Loading } from 'Components/Loaders';
import { PagedTable } from 'Components/Tables';
import useListAnnouncements from './useListAnnouncements';

/** Announcements form is used to list all portal announcements */
const GetAllAnnoucements: React.FC = () => {

    const { t } = useTranslation();
    const [ isLoading, setIsLoading ] = useState(false);
    const { announcements, listAnnouncements } = useListAnnouncements();

    useEffect(() => {
        (async() => {
            setIsLoading(true);
            listAnnouncements({ isActive: false });
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="portal-announcement">
            
            <Loading active={isLoading} />

            <PagedTable
                header={[
                    t('features.announcements.title'),
                    t('features.announcements.startDate'),
                    t('features.announcements.endDate'),
                    t('features.announcements.type'),                    
                    t('features.announcements.actions')
                ]}
                content={announcements}
                contentKeys={[
                    {key: 'title', type: 'link', target: '/management/announcements/', targetKey: 'id'},
                    {key: 'startDate', type: 'date'},
                    {key: 'endDate', type: 'date'},
                    {key: 'type'},
                    { key: 'title', type: "crudLinks", url: "/management/announcements/", targetKey: "id" },
                ]}
            />

        </div>
    );

}

export default GetAllAnnoucements;
