import { useContext } from 'react';
import IMessages from '../interfaces/IMessageProviderProps';
import { MessageContext } from '../context/MessageContextProvider';
import './../styles/messages.css';

/** Layout message viewing container */
const Messages: React.FC<IMessages> = ({ children }: IMessages) => {

    const { getMessages } = useContext(MessageContext);

    let messageComponents = getMessages();

    return (
        //See messages.css for styling
        <div className="message-container-page">
            {messageComponents && 
                <div className={messageComponents.length ? "messages active" : "messages" }>
                    { messageComponents }
                </div>
            }
        </div>
    );
};

export default Messages;
