import { usePutItem } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import apiConfig from 'Application/configurations/apiConfig';
import IUpdateTransferFreightCost from "./interfaces/IUpdateTransferFreightCost";
import ILockTransferFreightCost from './interfaces/ILockTransferFreightCost';
import { useAccessTokens } from 'Features/Authentication';
import { useTranslation } from 'react-i18next';
import IOpenTransferFreightCost from './interfaces/IOpenTransferFreightCost';

function useUpdateTransferFreightCost () {

    const { t } = useTranslation();
    const putItem = usePutItem();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

    /** Create new transfer freight cost survey
     * @param {string} companyId - guid of the company
     * @param {string} surveyId - guid of the survey
     * @param {IUpdateTransferFreightCost} surveyData - object which describes the survey
     **/
    const updateTransferFreightCost = async (companyId: string = "", surveyId: string="", surveyData: IUpdateTransferFreightCost | ILockTransferFreightCost | IOpenTransferFreightCost) => {

        const basePath = apiConfig.basePath;
        const companyEndpoint = apiConfig.endpoints.companies
        const surveyEndpoint = apiConfig.endpoints.transferFreightCosts;
        const url = new URL(`${basePath}/${companyEndpoint}/${companyId}/${surveyEndpoint}/${surveyId}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken,
            body: surveyData
        }

        try {

            const response = await putItem(url, options);
         
            return responseHandler(response, "useUpdateTransferFreightCost", { operation: "update", messageHeader: t("features.transferFreightCost.savedSuccessfully"), hide: false });


        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "TransferFreightCost.UpdatePermissionDenied") body = t('features.survey.updatePermissionDenied');
            if (problemTitle === "Company.CompanyDataAccessDenied") body = t('features.company.companyDataAccessDenied');
            if (problemTitle === "Company.NotExist") body = t('features.company.notExist');
            if (problemTitle === "UserAccount.NotExist") body = t('features.account.userAccountNotExist');
            if (problemTitle === "General.RequestorNotExist") body = t('features.survey.requestorNotExist');
            if (problemTitle === "TransferFreightCost.NotExist") body = t('features.survey.notExist');
            if (problemTitle === "TransferFreightCost.PermanentlyLocked") body = t('features.survey.permanentlyLocked');
            if (problemTitle === "TransferFreightCost.Closed") body = t('features.survey.closed');

            return errorHandler(error, "useUpdateTransferFreightCost", { operation: "update", hide: false, messageBody: body });

        }

    }

    return { updateTransferFreightCost }

}

export default useUpdateTransferFreightCost;
