import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';

/** Use file dropzone provides, logic, data, validations and methods to be used in the component */
const useFileDropzone = (setFiles: React.Dispatch<React.SetStateAction<Array<any>>>) => {

    const { t } = useTranslation();
    const __MAX_NAME_LENGTH = 255;

    /** Get correct classname for styling */
    const getClassName = (isFocused: boolean, isDragActive: boolean, isDragReject: boolean, isDragAccept: boolean) => {
        if (isFocused) return {className: "dropzone focus"};
        if (isDragActive) return {className: "dropzone active"};
        if (isDragReject) return {className: "dropzone reject"};
        if (isDragAccept) return {className: "dropzone accept"};
        return {className: "dropzone"};
    }

    /** Handle file drop */
    const onDrop = useCallback((acceptedFiles: Array<any>) => {
        if (acceptedFiles && acceptedFiles.length) {

            // Get file extension
            let file = acceptedFiles[0];
            let path = file.path || "";
            let fileExtension = path.substring(path.lastIndexOf('.'));

            // Add file extension
            acceptedFiles[0].fileExtension = fileExtension;
            
            // Set files
            setFiles(acceptedFiles);

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /** Handle drop accepted */
    //const onDropAccepted = () => {}

    /** Handle drop rejected */
    //const onDropRejected = () => {}

    /** Get file icon */
    const getFileIcon = (extension: string) => {

        switch (extension) {

            // Word processor and text file formats
            case '.pdf':
                return <Icon name='file pdf outline' />

            case '.doc':
                return <Icon name='file word outline' />
            case '.docx':
                return <Icon name='file word outline' />

            case '.odt':
                return <Icon name='file outline' />
            case '.rtf':
                return <Icon name='file outline' />
            case '.tex':
                return <Icon name='file outline' />
            case '.wpd':
                return <Icon name='file outline' />
            case '.txt':
                return <Icon name='file outline' />

            // Spreadsheet
            case '.xls':
                return <Icon name='file excel outline' />
            case '.xlsm':
                return <Icon name='file excel outline' />
            case '.xlsx':
                return <Icon name='file excel outline' />

            // Presentation
            case '.pps':
                return <Icon name='file powerpoint outline' />
            case '.ppt':
                return <Icon name='file powerpoint outline' />
            case '.pptx':
                return <Icon name='file powerpoint outline' />
            
            // Compressed file extensions
            case '.csv':
                return <Icon name='file code outline' />
            case '.log':
                return <Icon name='file code outline' />
            case '.json':
                return <Icon name='file code outline' />
            case '.xml':
                return <Icon name='file code outline' />
             
            // Compressed files
            case '.zip':
                return <Icon name='file code outline' />
            case '.rar':
                return <Icon name='file code outline' />
            case '.7z':
                return <Icon name='file code outline' />
            case '.pkg':
                return <Icon name='file code outline' />

            // Images
            case '.ai':
                return <Icon name='file image outline' />
            case '.bmp':
                return <Icon name='file image outline' />
            case '.gif':
                return <Icon name='file image outline' />
            case '.ico':
                return <Icon name='file image outline' />
            case '.png':
                return <Icon name='file image outline' />
            case '.jpg':
                return <Icon name='file image outline' />
            case '.jpeg':
                return <Icon name='file image outline' />
            case '.ps':
                return <Icon name='file image outline' />
            case '.psd':
                return <Icon name='file image outline' />
            case '.svg':
                return <Icon name='file image outline' />
            case '.tif':
                return <Icon name='file image outline' />
            case '.tiff':
                return <Icon name='file image outline' />
            case '.webp':
                return <Icon name='file image outline' />

            // Video
            case '.avi':
                return <Icon name='file video outline' />
            case '.mov':
                return <Icon name='file video outline' />
            case '.mp4':
                return <Icon name='file video outline' />
            case '.mpg':
                return <Icon name='file video outline' />
            case '.mpeg':
                return <Icon name='file video outline' />
            case '.h264':
                return <Icon name='file video outline' />
            case '.wmv':
                return <Icon name='file video outline' />

            // Audio
            case '.aif':
                return <Icon name='file audio outline' />
            case '.cda':
                return <Icon name='file audio outline' />
            case '.mid':
                return <Icon name='file audio outline' />
            case '.mp3':
                return <Icon name='file audio outline' />
            case '.mpa':
                return <Icon name='file audio outline' />
            case '.ogg':
                return <Icon name='file audio outline' />
            case '.wav':
                return <Icon name='file audio outline' />
            case '.wma':
                return <Icon name='file audio outline' />
            case '.wpl':
                return <Icon name='file audio outline' />

            // Default
            default:
                return <Icon name='file outline' />

        }
    }

    /** Validate name length */
    const nameLengthValidator = (file: File) => {
        if (file && file.name && file.name.length > __MAX_NAME_LENGTH) {
            return {
                code: "name-too-large",
                message: t('components.dropzone.nameIsTooLarge') + " " + t('components.dropzone.maximumCharacterLength') + " " +  __MAX_NAME_LENGTH
            };
        }
        return null;
    }

    /** Get list of accepted files */
    const listAcceptedFiles = (acceptedFiles: Array<any>) => {
        return acceptedFiles.map((file, index) => (
            <p key={index}>
                {getFileIcon(file.fileExtension)}
                {file.path} - {file.size} {t('components.dropzone.bytes')}
            </p>
        ));
    }

    /** Get list of rejected files */
    const listRejectedFiles = (fileRejections: Array<any>) => {
        return fileRejections.map(({file, errors, index}) => (
            <>
                <p key={index}>
                    {getFileIcon(file.fileExtension)}
                    {file.path} - {file.size} {t('components.dropzone.bytes')}
                </p>
                <ul>
                    {errors.map((e: any ) => (
                        <li key={e.code}>{e.message}</li>
                    ))}
                </ul>
            </>
        ));
    }

    return { 
        getClassName,
        onDrop,
        //onDropAccepted,
        //onDropRejected,
        getFileIcon,
        nameLengthValidator,
        listAcceptedFiles,
        listRejectedFiles
    };

}

export default useFileDropzone;
