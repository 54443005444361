import { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import StateContextProvider from './state/StateContext';
import LoggerContextProvider from 'Features/Logging/LoggerContext';
import ErrorBoundaryWrapper from 'Features/ErrorBoundary/ErrorBoundary';
import Router from './routes/Router';
import { MsalAuthentication } from 'Features/Authentication';
import { MessageContextProvider, Messages } from 'Features/Messages';
import { PortalAnnouncement } from 'Features/Announcements';
import { Header, Content, Hamburger, BreadCrumb } from 'Components/Layout';
import IAppProps from './IAppProps';
import './styles/app.css';

function App({ msal, appInsights }: IAppProps) {  

    const [ hamburgerMenuIsOpen, setHamburgerMenuIsOpen ] = useState(false);
    
    return (
        <div className="app">
            <LoggerContextProvider appInsights={appInsights} >
                <ErrorBoundaryWrapper>
                    <MsalAuthentication msalInstance={msal} >
                        <MessageContextProvider>
                            <StateContextProvider msal={msal} >
                                <BrowserRouter>
                                    <Header setHamburgerMenuIsOpen={setHamburgerMenuIsOpen} />
                                    <PortalAnnouncement />
                                    <Hamburger hamburgerMenuIsOpen={hamburgerMenuIsOpen} setHamburgerMenuIsOpen={setHamburgerMenuIsOpen} />
                                    <Content hamburgerMenuIsOpen={hamburgerMenuIsOpen} >
                                        <Container>
                                            <BreadCrumb/>
                                            <Messages />
                                        </Container>
                                        <Router />
                                        {/*<Footer /> => See content.css */}
                                    </Content>
                                    {/*<Footer fixed /> => See content.css*/} 
                                </BrowserRouter>
                            </StateContextProvider>
                        </MessageContextProvider>
                    </MsalAuthentication>
                </ErrorBoundaryWrapper>
            </LoggerContextProvider>
        </div>
    );
}

export default App;
