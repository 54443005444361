import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import ICancelBtn from '../interfaces/ICancelBtnProps';
import './../styles/button.css';

/** Form cancel button - go back one page in history
 * @param {string} title - Button title
 * @param {boolean} hide - hide the button
 * @param {string} [labelPosition] - label position 'left' | 'right'
 * @link https://react.semantic-ui.com/elements/button/
 **/
const CancelBtn: React.FC<ICancelBtn> = (props: ICancelBtn) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const { title, hidden=false, color, labelPosition, type, ...rest } = props;

    if (hidden) return null;

    return (
        <Button
            icon
            className='extended-button cancel-button'
            color={color ? color : undefined }
            labelPosition={labelPosition ? labelPosition : 'left'}
            type={type ? type : 'reset'}
            onClick={() => navigate(-1)}
            {...rest}
        >
            <Icon name={'arrow left'} />
            {title ? title : t('components.buttons.cancel')}
        </Button>
    );

};

export default CancelBtn;
