import { Sidebar, Segment } from 'semantic-ui-react';
import IContentProps from './IContentProps';
import './content.css';
import './pages.css';

/** App layout content
 * This component add content section to the app. All pages are rendered here.
 * @param {boolean} hamburgerMenuIsOpen - Is sidebar open or closed
 **/
const Content: React.FC<IContentProps> = ({children, hamburgerMenuIsOpen}: IContentProps) => {
    return (
        <div className="app-content">
            <Sidebar.Pushable as={Segment} className='patch'>
                <Sidebar.Pusher dimmed={hamburgerMenuIsOpen}>
                    {/* This is container for pages */}
                    {children}
                </Sidebar.Pusher>
            </Sidebar.Pushable>           
        </div>
    );
};

export default Content;
