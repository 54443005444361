import { ReadClearing } from "Features/Survey";
import { Container } from "semantic-ui-react";

function ViewClearing () {

    return (
        <Container className="page">

            <ReadClearing />

        </Container>
    );
}

export default ViewClearing;