import { useContext } from "react";
import { PageTitle } from "Components/Titles";
import { Container } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { ListSurveys } from "Features/Survey";
import { StateContext } from "Application/state/StateContext";
import { ButtonContainer, NewBtn } from "Components/Buttons";
import { useLocation } from "react-router-dom";

function SurveyList () {

    const { t } = useTranslation();
    const { userProfile } = useContext(StateContext);
    const location = useLocation();

    const isAdmin = userProfile && userProfile.roles && userProfile.roles.find((role:any) => role.name==="Admin") ? true : false;

    return (
        <Container className="page">
            {isAdmin && location.pathname!=="/surveys" ? 
                <PageTitle
                    title={t("pages.surveys")}
                    button={
                        <ButtonContainer>
                            <NewBtn to={location.pathname + "/new"} />
                        </ButtonContainer>
                        
                    }
                />
            :
                <PageTitle title={t("pages.surveys")}/>
            }
            <ListSurveys showNew={isAdmin}/>
        </Container>
    );
}

export default SurveyList;