import { useState } from 'react';
import { useGetItem } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import { useTranslation } from 'react-i18next';
import apiConfig from 'Application/configurations/apiConfig';
import IReadGlassBottleReturnRate from './IReadGlassBottleReturnRate';
import { useAccessTokens } from 'Features/Authentication';

const useGetGlassBottleReturnRate = () => {

    const { t } = useTranslation();
    const getItem = useGetItem();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const [ glassBottleReturnRate, setGlassBottleReturnRate ] = useState<IReadGlassBottleReturnRate>({id: "", companyId: "", name: "", initialStockDate: new Date(), initialStock: null, procurement: null, salesToCustomers: null, purchasesFromCustomers: null, salesToCompanies: null, purchasesFromCompanies: null, scrapping: null, closingStockDate: new Date(), closingStock: null, loss: null, isPermanentlyLocked: false, isAnswered: false, openDate: new Date(), closeDate: new Date()});
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

    /** Read glass bottle return rate by guid
     * @param {string} companyId - guid of the company
     * @param {string} surveyId - guid of the survey
     * @returns {object} glassBottleReturnRate - glassBottleReturnRate object
     **/
    const getGlassBottleReturnRateById = async (companyId: any, surveyId: any) => {

        const basePath = apiConfig.basePath;
        const companyEndpoint = apiConfig.endpoints.companies
        const surveyEndpoint = apiConfig.endpoints.glassBottleReturnRates;
        const url = new URL(`${basePath}/${companyEndpoint}/${companyId}/${surveyEndpoint}/${surveyId}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken
        }

        try {

            const response = await getItem(url, options);
            const data = response?.data || [];
            setGlassBottleReturnRate(data);
            return responseHandler(response, "useGetGlassBottleReturnRate", { operation: "read", hide: true });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "GlassBottleReturnRate.ReadPermissionDenied") body = t('features.survey.readPermissionDenied');
            if (problemTitle === "Company.CompanyDataAccessDenied") body = t('features.company.companyDataAccessDenied');
            if (problemTitle === "Company.NotExist") body = t('features.company.notExist');
            if (problemTitle === "UserAccount.NotExist") body = t('features.account.userAccountNotExist');
            if (problemTitle === "General.RequestorNotExist") body = t('features.survey.requestorNotExist');
            if (problemTitle === "GlassBottleReturnRate.NotExist") body = t('features.survey.notExist');

            return errorHandler(error, "useGetGlassBottleReturnRate", { operation: "read", hide: false, messageBody: body });

        }

    }

    return { glassBottleReturnRate, setGlassBottleReturnRate, getGlassBottleReturnRateById };

}

export default useGetGlassBottleReturnRate;
