import i18n from "i18next";
import Backend from "i18next-http-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

// Default languages
const Languages = ['fi', 'en'];

i18n
    .use(Backend)
    //.use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        lng: 'fi',            // if you're using a language detector, do not define the lng option
        fallbackLng: 'fi',    // use 'fi' if detected lng is not available
        debug: false,
        whitelist: Languages,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        saveMissing: false     // send not translated keys to endpoint
    });

export default i18n;
