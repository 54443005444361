import { useState } from 'react';
import { useGetItem } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import { useTranslation } from 'react-i18next';
import apiConfig from 'Application/configurations/apiConfig';
import IReadDistributionFreight from './IReadDistributionFreight';
import { useAccessTokens } from 'Features/Authentication';

const useGetDistributionFreight = () => {

    const { t } = useTranslation();
    const getItem = useGetItem();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const [ distributionFreight, setDistributionFreight ] = useState<IReadDistributionFreight>({id: "", companyId: "", name: "", compensation: null, sold: null, isPermanentlyLocked: false, isAnswered: false, period: "", openDate: new Date(), closeDate: new Date()});
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

    /** Read glass bottle return rate by guid
     * @param {string} companyId - guid of the company
     * @param {string} surveyId - guid of the survey
     * @returns {object} distributionFreight - distributionFreight object
     **/
    const getDistributionFreightById = async (companyId: any, surveyId: any) => {

        const basePath = apiConfig.basePath;
        const companyEndpoint = apiConfig.endpoints.companies
        const surveyEndpoint = apiConfig.endpoints.distributionFreights;
        const url = new URL(`${basePath}/${companyEndpoint}/${companyId}/${surveyEndpoint}/${surveyId}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken
        }

        try {

            const response = await getItem(url, options);
            const data = response?.data || [];
            setDistributionFreight(data);
            return responseHandler(response, "useGetDistributionFreight", { operation: "read", hide: true });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "DistributionFreight.ReadPermissionDenied") body = t('features.survey.readPermissionDenied');
            if (problemTitle === "Company.CompanyDataAccessDenied") body = t('features.company.companyDataAccessDenied');
            if (problemTitle === "Company.NotExist") body = t('features.company.notExist');
            if (problemTitle === "UserAccount.NotExist") body = t('features.account.userAccountNotExist');
            if (problemTitle === "General.RequestorNotExist") body = t('features.survey.requestorNotExist');
            if (problemTitle === "DistributionFreight.NotExist") body = t('features.survey.notExist');

            return errorHandler(error, "useGetDistributionFreight", { operation: "read", hide: false, messageBody: body });

        }

    }

    return { distributionFreight, setDistributionFreight, getDistributionFreightById };

}

export default useGetDistributionFreight;
