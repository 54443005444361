import { useState } from 'react';
import { useGetItem } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import { useTranslation } from 'react-i18next';
import apiConfig from 'Application/configurations/apiConfig';
import IReadVolume from './interfaces/IReadVolume';
import { useAccessTokens } from 'Features/Authentication';

const useGetVolume = () => {

    const { t } = useTranslation();
    const getItem = useGetItem();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const [ volume, setVolume ] = useState<IReadVolume>({id: "", companyId: "", name: "", isAnswered: false, isPermanentlyLocked: false, categories: [],openDate: new Date(), closeDate: new Date(), period: 0});
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

    /** Read volume survey by guid
     * @param {string} companyId - guid of the company
     * @param {string} surveyId - guid of the survey
     * @returns {object} volume - volume object
     **/
    const getVolumeById = async (companyId: any, surveyId: any) => {

        const basePath = apiConfig.basePath;
        const companyEndpoint = apiConfig.endpoints.companies
        const surveyEndpoint = apiConfig.endpoints.volumes;
        const url = new URL(`${basePath}/${companyEndpoint}/${companyId}/${surveyEndpoint}/${surveyId}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken
        }

        try {

            const response = await getItem(url, options);
            const data = response?.data || [];
            setVolume(data);
            return responseHandler(response, "useGetVolume", { operation: "read", hide: true });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "Volume.ReadPermissionDenied") body = t('features.survey.readPermissionDenied');
            if (problemTitle === "Company.CompanyDataAccessDenied") body = t('features.company.companyDataAccessDenied');
            if (problemTitle === "Company.NotExist") body = t('features.company.notExist');
            if (problemTitle === "UserAccount.NotExist") body = t('features.account.userAccountNotExist');
            if (problemTitle === "General.RequestorNotExist") body = t('features.survey.requestorNotExist');
            if (problemTitle === "Volume.NotExist") body = t('features.survey.notExist');
            
            
            
            

            return errorHandler(error, "useGetVolume", { operation: "read", hide: false, messageBody: body });

        }

    }

    return { volume, setVolume, getVolumeById };

}

export default useGetVolume;
