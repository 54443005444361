import getRequestOptions from "../utils/getRequestOptions";
import IGetItems from "../interfaces/IGetItems";

const useGetItems = () => {

    /** Get items - return list of items
     * @param {URL} url - api endpoint url f.e. 'https://example.com/api/v1/users'
     * @param {string} [options.accessToken] - Access token is added to Authentication header if defined
     * @param {string} [options.graphToken] - Access token for graph api to be used in the api to call graph api on behalf of a user
     * @param {AbortSignal} [options.abortSignal] - signal to abort the 
     **/
    const getItems = async (url:URL, options?: IGetItems) => {
        try {

            const requestOptions = getRequestOptions({ method: "GET", ...options });

            const response = await fetch(url, requestOptions);

            let data = null;

            // Try to Parse JSON data from the response
            try {
                data = await response.json();
            } catch (err) {
                data = [];
            }

            // Return an object containing both the raw response and the parsed data
            const resObj = {
                statusText: response.statusText,
                status: response.status,
                ok: response.ok,
                url: response.url,
                data: data,
            };

            // Throw error if result is not ok
            if (!response.ok) throw resObj;

            return resObj;

        } catch (error: any) {
            if (error.name === 'AbortError') {
                return { requestAborted: true, ...error }
            } else {
                throw error;
            }
        }

    }

    return getItems;

}

export default useGetItems;
