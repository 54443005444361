import { usePostItem } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import apiConfig from 'Application/configurations/apiConfig';
import ICreateCompany from "./ICreateCompany";
import { useAccessTokens } from 'Features/Authentication';
import { useTranslation } from 'react-i18next';

function useCreateCompany () {

    const { t } = useTranslation();
    const postItem = usePostItem();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

    /** Create new company
     * @param {ICreateCompany} company - object which describes the created company
     **/
    const createCompany = async (company: ICreateCompany) => {

        const basePath = apiConfig.basePath;
        const endpoint = apiConfig.endpoints.companies;
        const url = new URL(`${basePath}/${endpoint}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken,
            body: company
        }

        try {

            const response = await postItem(url, options);
         
            return responseHandler(response, "useCreateCompany", { operation: "create", messageHeader: t("features.company.savedSuccessfully"), hide: false });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "General.RequestorNotExist") body = t('features.company.requestorNotExist');
            if (problemTitle === "Company.CreatePermissionDenied") body = t('features.company.createPermissionDenied');
            if (problemTitle === "Company.NotExist") body = t('features.company.notExist');
            if (problemTitle === "Company.AlreadyExist") body = t('features.company.alreadyExist');

            if (problemTitle === "Company.InvalidBusinessIdentityCode") body = t('features.company.invalidBusinessIdentityCode');
            if (problemTitle === "AddressType.NotExist") body = t('features.company.addressTypeNotExist');

            return errorHandler(error, "useCreateCompany", { operation: "create", hide: false, messageBody: body });
   
        }

    }

    return { createCompany }

}

export default useCreateCompany;
