import { useState } from "react";
import { useGetItems } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import { useTranslation } from 'react-i18next';
import apiConfig from 'Application/configurations/apiConfig';
import IListCompany from "./IListCompany";
import { useAccessTokens } from 'Features/Authentication';

function useGetAllCompanies () {

    const { t } = useTranslation();
    //const [ companies, setCompanies ] = useState(mockCompanies);
    const [ companies, setCompanies ] = useState<IListCompany[]>([]);
    const getItems = useGetItems();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;
    

   /** List all companies
     * @returns {Array<IListCompany>} companies */
    const listCompanies = async () => {

        const basePath = apiConfig.basePath;
        const endpoint = apiConfig.endpoints.companies;
        const url = new URL(`${basePath}/${endpoint}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken
        }
        
        try {

            const response = await getItems(url, options);
            const data = response?.data || [];
            setCompanies(data);
            return responseHandler(response, "useGetAllComapnies", { operation: "list", hide: true });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "General.RequestorNotExist") body = t('features.company.requestorNotExist');
            if (problemTitle === "Company.ListPermissionDenied") body = t('features.company.listPermissionDenied');
            if (problemTitle === "Company.CompanyDataAccessDenied") body = t('features.company.companyDataAccessDenied');

            return errorHandler(error, "useGetAllComapnies", { operation: "list", hide: false, messageBody: body });
   
        }

    }

    return { companies, setCompanies, listCompanies };

}

export default useGetAllCompanies;