import { useTranslation } from 'react-i18next';
import { Container } from 'semantic-ui-react';
import {GetAnnouncementById} from 'Features/Announcements';

const ViewAnnouncement = () => {

    const { t } = useTranslation();

    return (
        <Container className="page">

            <h1>{t('pages.viewAnnouncement')}</h1>

            <GetAnnouncementById/>

        </Container>
    );
}

export default ViewAnnouncement;
