import { useState, useEffect, useContext } from "react";
import useGetTransferFreightCost from "../ReadTransferFreightCost/useGetTransferFreightCost";
import useUpdateTransferFreightCost from "./useUpdateTransferFreightCost";
import { Form, Popup, Icon, Header } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";
import { ButtonContainer, SaveBtn, CancelBtn } from "Components/Buttons";
import IUpdateTransferFreightCost from "./interfaces/IUpdateTransferFreightCost";
import IFreightCost from "./interfaces/IUpdateFreightCost";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { InputWithUnit } from "Components/FormElements/InputWithUnit/InputWithUnit";
import { StateContext } from "Application/state/StateContext";
import { PageTitle } from "Components/Titles";
import useInputValidation from "Components/FormElements/InputWithUnit/useInputValidation";
import { MessageContext } from "Features/Messages";
import useSurvey from "Features/Survey/useSurvey";
import { UploadCompanySurveyAttachements, useUploadCompanySurveyAttachements } from 'Features/CompanyAttachement';

function UpdateTransferFreightCost () {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const detailPath = location.pathname.replace("/edit","")

    const { userProfile } = useContext(StateContext);
    const { companyId, surveyId } = useParams();
    const [ isLoading, setIsLoading ] = useState(true);
    const { isNonNegativeFloat } = useInputValidation();
    const { addMessage, setMessages } = useContext(MessageContext);
    const { updateTransferFreightCost } = useUpdateTransferFreightCost();
    const { transferFreightCost, getTransferFreightCostById } = useGetTransferFreightCost();
    const { isEditingAllowed } = useSurvey();

    const [ attachements, setAttachements ] = useState([]);
    const { uploadCompanySurveyAttachements } = useUploadCompanySurveyAttachements(); 

    const [ freightCosts, setFreightCosts ] = useState<IFreightCost[]>([]);

    // Fetch survey
    useEffect(() => {
        (async () => {
            if(companyId || userProfile.companyId) {
                await getTransferFreightCostById(companyId || userProfile.companyId,surveyId);
            }
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userProfile.companyId]);

     // set freigh costs state
     useEffect(() => {
        //if(transferFreightCost.isAnswered){
            setFreightCosts(transferFreightCost.freightCosts)
        //}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[transferFreightCost.freightCosts]);

    const setFreightCost = (value:number | null, id:number) => {
        const costs = [...freightCosts];
        const companyCost = costs.find(item => item.id === id) || {id: 0, companyId:"",companyName:"",companyArea:"",freightCost: null};
        if(value===null){
            companyCost.freightCost=null;
        } else {
            companyCost.freightCost = value;
        }
        setFreightCosts(costs);
    }

    const onSubmit = async () => {

        var uploadAttachementRequest = {
            companyId: userProfile.companyId,
            surveyId: surveyId || "",
            surveyName: transferFreightCost.name || "Siirtorahtikysely",
            surveyTypeRef: "transferfreightcost",
            attachements: attachements
        }

        let errors=false;
        freightCosts.map(item => {
            if(!isNonNegativeFloat(item.freightCost)){
                errors=true;
            }
            return item;
        });

        setMessages([]);
        if(errors){
            addMessage({header: t("features.survey.inputErrorHeader"), type: "error", body: t("features.survey.inputErrorDescription")});
            window.scrollTo(0,0);
        } else {

            setIsLoading(true);

            const transferFreightCost:IUpdateTransferFreightCost = {
                freightCosts: freightCosts
            }
            
            let result = await updateTransferFreightCost(companyId || userProfile.companyId,surveyId,transferFreightCost);

            await uploadCompanySurveyAttachements(uploadAttachementRequest);
            
            if (result && result.status === 204) return navigate(detailPath);
            
            setIsLoading(false);
        }
    }

    return (
        
        <Form loading={isLoading} onSubmit={onSubmit} className="form-inline">
            
            <PageTitle 
                title={transferFreightCost.name ? transferFreightCost.name : t("pages.editTransferFreightCost")} 
                button={
                    <Popup
                        trigger={<Icon name='info' color='blue' size='large' circular />}
                        content={t("features.transferFreightCost.infoText")}
                        position='bottom left'
                        wide="very"
                    />
                }
            />

            {transferFreightCost.id  ?
                isEditingAllowed(transferFreightCost.isPermanentlyLocked,transferFreightCost.openDate,transferFreightCost.closeDate) ?
                    <>
                    <Header as="h2">{t("features.transferFreightCost.costvat0")}</Header>
                    {freightCosts && freightCosts.map((item:any) => 
                        <InputWithUnit
                            label={item.companyName + " (" + item.companyArea + ")"}
                            id={"freightCost" + item.id}
                            value={item.freightCost}
                            validateFor="nonNegativeMoney"
                            onChangeFunc={setFreightCost}
                            onChangeFuncKey={item.id}
                            type="number"
                            unit={t("features.transferFreightCost.euro")}
                            key={item.id}
                            required
                        />
                    )}

                    <UploadCompanySurveyAttachements
                        companyId={userProfile.companyId}
                        surveyId={surveyId}
                        surveyTypeRef="transferfreightcost"
                        setAttachements={setAttachements}
                    />

                    <ButtonContainer>
                        <SaveBtn />
                        <CancelBtn />
                    </ButtonContainer>
                    </>
                :
                <p>{t("features.survey.editingNotAllowed")}</p>
            :
                <p>{t("features.survey.notFound")}</p>
            }
        </Form>
    );
}

export default UpdateTransferFreightCost;
