import { useTranslation } from 'react-i18next';
import { Modal, Header, Container } from 'semantic-ui-react';
import ApproveButton from './ApproveButton';
import IApproveModal from '../interfaces/IApproveModal';

/** Open modal and ask Yes/No question
 * @param {string} type - type of the modal [info, warning]
 * @param {string} header - Header text
 * @param {string} text - Body text
 * @param {any} open - React useState "open" -value
 * @param {function} setOpen - React useState "setOpen" -function
 * @param {boolean} disableApproveButton - Disable Yes button (f.e. some condition must be true before user can click yes)
*/
const ApproveModal: React.FC<IApproveModal> = ({ type, header, text, open, setOpen, disableApproveButton=false, children }: IApproveModal) => {

    const { t } = useTranslation();

    return (
        <Modal
            closeIcon
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
        >

            {type==='info' &&
                <Header icon='info' content={header || t('components.modals.info')} />
            }
            {type==='warning' &&
                <Header icon='warning' content={header || t('components.modals.warning')} />
            }

            <Modal.Content>
                <p>{text || t('components.modals.areYouSure')}</p>
                <Container style={{ marginTop: '20px', marginBottom: '20px' }}>
                    {children}
                </Container>
            </Modal.Content>

            <Modal.Actions>
                <ApproveButton disabled={disableApproveButton} onClick={() => {
                    setOpen(false);
                }}/>
            </Modal.Actions>

        </Modal>
    );
}

export default ApproveModal;
