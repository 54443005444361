import { useState, useEffect, useContext } from "react";
import useGetClearing from "../ReadClearing/useGetClearing";
import useUpdateClearing from "./useUpdateClearing";
import { Form, Popup, Icon, Header, Accordion, Grid, Table } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";
import { ButtonContainer, SaveBtn, CancelBtn } from "Components/Buttons";
import IUpdateClearing from "./interfaces/IUpdateClearing";
import IClearing from "./interfaces/IClearing";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { InputWithUnit } from "Components/FormElements/InputWithUnit/InputWithUnit";
import { StateContext } from "Application/state/StateContext";
import { PageTitle } from "Components/Titles";
import ICategory from "../ReadClearing/interfaces/IReadClearingCategory";
import { MessageContext } from "Features/Messages";
import useInputValidation from "Components/FormElements/InputWithUnit/useInputValidation";
import "../../../Survey/styles.css";
import configuration from "Features/Survey/Inventory/unitQuestionConfig";
import useSurvey from "Features/Survey/useSurvey";
import { UploadCompanySurveyAttachements, useUploadCompanySurveyAttachements } from 'Features/CompanyAttachement';

function UpdateClearing () {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const detailPath = location.pathname.replace("/edit","")
    
    const { userProfile } = useContext(StateContext);
    const { companyId, surveyId } = useParams();
    const [ isLoading, setIsLoading ] = useState(true);
    const { setMessages, addMessage } = useContext(MessageContext);
    const { isInteger, isFloat } = useInputValidation();
    const { updateClearing } = useUpdateClearing();
    const { isEditingAllowed } = useSurvey();
    
    const { clearing, getClearingById } = useGetClearing();
    const [ categories, setCategories ] = useState<ICategory[]>([]);

    const [ attachements, setAttachements ] = useState([]);
    const { uploadCompanySurveyAttachements } = useUploadCompanySurveyAttachements(); 

    const [ activeAccordions, setActiveAccordions ] = useState<string[]>([]);
    
    // Fetch survey
    useEffect(() => {
        (async () => {
            if(companyId || userProfile.companyId) {
                await getClearingById(companyId || userProfile.companyId,surveyId);
            }
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userProfile.companyId]);

     // set categories state
     useEffect(() => {
        setCategories(clearing.categories);
        let active:string[] = []
        clearing.categories.map(c => {
            c.units.map(u => {
                active.push(u.unitId);
                return u;
            })
            return c;
        })
        setActiveAccordions(active);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[clearing.categories]);

    const setUnit = (value:number | null, id:{category: string, unit: string, field: string}) => {
        
        const cats = [...categories];
        const category = cats.find(item => item.id===id.category) || {id:"", name:"",units:[]};
        const unit = category.units.find(item => item.unitId===id.unit) || {itemId: "", unitId: "", unitName: "", isCompanyUnit: true, clearingProcurementAmount: null, clearingProcurementRevenue: null, clearingProcurementExpense: null, clearingRepairAmount: null, clearingRepairRevenue: null, clearingRepairExpense: null, clearingScrappingAmount: null, clearingScrappingRevenue: null, clearingScrappingExpense: null, clearingSoldAmount: null, clearingSoldRevenue: null, clearingSoldExpense: null, accordionOpen: false};

        let val = null;
        if(value!==null){
            val = value;
        }
        switch(id.field){
            case "procurementAmount":
                unit.clearingProcurementAmount = val;
                break;
            case "procurementRevenue":
                unit.clearingProcurementRevenue = val;
                break;
            case "procurementExpense":
                unit.clearingProcurementExpense = val;
                break;
            case "repairAmount":
                unit.clearingRepairAmount = val;
                break;
            case "repairRevenue":
                unit.clearingRepairRevenue = val;
                break;
            case "repairExpense":
                unit.clearingRepairExpense = val;
                break;
            case "scrappingAmount":
                unit.clearingScrappingAmount = val;
                break;
            case "scrappingRevenue":
                unit.clearingScrappingRevenue = val;
                break;
            case "scrappingExpense":
                unit.clearingScrappingExpense = val;
                break;
            case "soldAmount":
                unit.clearingSoldAmount = val;
                break;
            case "soldRevenue":
                unit.clearingSoldRevenue = val;
                break;
            case "soldExpense":
                unit.clearingSoldExpense = val;
                break;
            case "accordionOpen":
                let active = [...activeAccordions];
                var index = active.indexOf(unit.unitId);
                if (index !== -1) {
                    active.splice(index, 1);
                } else {
                    active.push(unit.unitId);
                }
                setActiveAccordions(active);
        }
        setCategories(cats);
    }

    const showInput = (id:string,fieldName:string) => {
        const unit = configuration.find((unit:any) => unit.unitId.toLowerCase() === id.toLowerCase())
        switch(fieldName){
            case "procurement": return unit?.showProcurement;
            case "repair": return unit?.showRepair;
            case "scrapping": return unit?.showScrapping;
            case "sold": return unit?.showSold;
        }
        return false;
    }

    const onSubmit = async () => {

        setIsLoading(true);

        let clearings:IClearing[] = [];
        let errors=false;
        categories.map(cat =>
            cat.units.map(unit => {
                if(!isInteger(unit.clearingProcurementAmount) || !isFloat(unit.clearingProcurementRevenue,2) || !isFloat(unit.clearingProcurementExpense,2) ||
                !isInteger(unit.clearingRepairAmount) || !isFloat(unit.clearingRepairRevenue,2) || !isFloat(unit.clearingRepairExpense,2) ||
                !isInteger(unit.clearingScrappingAmount) || !isFloat(unit.clearingScrappingRevenue,2) || !isFloat(unit.clearingScrappingExpense,2) ||
                !isInteger(unit.clearingSoldAmount) || !isFloat(unit.clearingSoldRevenue,2) || !isFloat(unit.clearingSoldExpense,2)
                ){
                    errors=true;
                }
                const clearing:IClearing = {
                    id: unit.itemId,
                    procurementAmount: unit.clearingProcurementAmount,
                    procurementRevenue: unit.clearingProcurementRevenue,
                    procurementExpense: unit.clearingProcurementExpense,
                    repairAmount: unit.clearingRepairAmount,
                    repairRevenue: unit.clearingRepairRevenue,
                    repairExpense: unit.clearingRepairExpense,
                    scrappingAmount: unit.clearingScrappingAmount,
                    scrappingRevenue: unit.clearingScrappingRevenue,
                    scrappingExpense: unit.clearingScrappingExpense,
                    soldAmount: unit.clearingSoldAmount,
                    soldRevenue: unit.clearingSoldRevenue,
                    soldExpense: unit.clearingSoldExpense
                }
                clearings.push(clearing);
                return unit;
            })
        );

        var uploadAttachementRequest = {
            companyId: userProfile.companyId,
            surveyId: surveyId || "",
            surveyName: clearing.name || "Tasauskysely",
            surveyTypeRef: "clearing",
            attachements: attachements
        }

        setMessages([]);
        if(errors){
            addMessage({header: t("features.survey.inputErrorHeader"), type: "error", body: t("features.survey.inputErrorDescription")});
            window.scrollTo(0,0);
        } else {


            const clearing:IUpdateClearing = {
                clearingItems: clearings
            }

            let result = await updateClearing(companyId || userProfile.companyId,surveyId,clearing);

            await uploadCompanySurveyAttachements(uploadAttachementRequest);

            if (result && result.status === 204) return navigate(detailPath);
        }
        setIsLoading(false);
    }

    return (
        
        <Form loading={isLoading} onSubmit={onSubmit} className="form-inline">
            
            <PageTitle 
                title={clearing.name ? clearing.name : t("pages.editClearing")} 
                button={
                    <Popup
                        trigger={<Icon name='info' color='blue' size='large' circular />}
                        content={t("features.clearing.infoText")}
                        position='bottom left'
                        wide="very"
                    />
                }
            />

            {clearing.id ?
                isEditingAllowed(clearing.isPermanentlyLocked,clearing.openDate,clearing.closeDate) ?
                    <>
                    {categories && categories.map((cat:any) =>
                        <div key={"cat" + cat.id} className="clearing">
                            <Header as="h2" style={{fontSize: "1.5rem"}}>{cat.name}</Header>
                            {cat.units.map((unit:any) =>
                                <Accordion key={unit.unitId} className="unit-accordion">
                                    <Accordion.Title
                                        active={activeAccordions.find((item:any) => item === unit.unitId) ? true : false} 
                                        index={0} 
                                        onClick={() => setUnit(0,{category: cat.id, unit: unit.unitId, field: "accordionOpen"})}
                                    >
                                        <Icon name='dropdown' /> {unit.unitName}
                                    </Accordion.Title>
                                    <Accordion.Content active={activeAccordions.find((item:any) => item === unit.unitId) ? true : false} style={{paddingBottom: "5px"}}>
                                        
                                        {/* procurement */}
                                        {showInput(unit.unitId,"procurement") &&
                                            <Grid columns={2}>
                                                <Grid.Row>
                                                    <Grid.Column mobile={16} tablet={16} computer={2}><Header as="h2">{t("features.clearing.procurement")}</Header></Grid.Column>
                                                    <Grid.Column mobile={16} tablet={16} computer={14}>
                                                        <Table className="clearing">
                                                            <Table.Row>
                                                                <Table.HeaderCell></Table.HeaderCell>
                                                                <Table.HeaderCell><div className="fieldlabel">{t("features.clearing.declared")}</div></Table.HeaderCell>
                                                                <Table.HeaderCell><div className="fieldlabel">{t("features.clearing.correction")}</div></Table.HeaderCell>
                                                                <Table.HeaderCell><div className="fieldlabel">{t("features.clearing.result")}</div></Table.HeaderCell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell>
                                                                    <div className="fieldlabel required">{t("features.clearing.amount")}</div>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {unit.reportedProcurementAmount || 0} {t("features.clearing.pcs")}
                                                                </Table.Cell>
                                                                <Table.Cell className="hide-asterisk">
                                                                    <InputWithUnit
                                                                        id={"procurementAmount" + unit.itemId}
                                                                        value={unit.clearingProcurementAmount}
                                                                        validateFor="integer"
                                                                        onChangeFunc={setUnit}
                                                                        onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "procurementAmount"}}
                                                                        type="number"
                                                                        unit={t("features.clearing.pcs")}
                                                                        required
                                                                    />
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {(unit.reportedProcurementAmount || 0) + unit.clearingProcurementAmount} {t("features.clearing.pcs")}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell>
                                                                    <div className="fieldlabel required">{t("features.clearing.revenue")}</div>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {(unit.reportedProcurementRevenue || 0).toFixed(2)} {t("features.clearing.euro")}
                                                                </Table.Cell>
                                                                <Table.Cell className="hide-asterisk">
                                                                    <InputWithUnit
                                                                        id={"procurementRevenue" + unit.itemId}
                                                                        value={unit.clearingProcurementRevenue}
                                                                        validateFor="money"
                                                                        onChangeFunc={setUnit}
                                                                        onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "procurementRevenue"}}
                                                                        type="number"
                                                                        unit={t("features.clearing.euro")}
                                                                        required
                                                                    />
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {((unit.reportedProcurementRevenue || 0) + unit.clearingProcurementRevenue).toFixed(2)} {t("features.clearing.euro")}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell>
                                                                    <div className="fieldlabel required">{t("features.clearing.expense")}</div>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {(unit.reportedProcurementExpense || 0).toFixed(2)} {t("features.clearing.euro")}
                                                                </Table.Cell>
                                                                <Table.Cell className="hide-asterisk">
                                                                    <InputWithUnit
                                                                        id={"procurementExpense" + unit.itemId}
                                                                        value={unit.clearingProcurementExpense}
                                                                        validateFor="money"
                                                                        onChangeFunc={setUnit}
                                                                        onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "procurementExpense"}}
                                                                        type="number"
                                                                        unit={t("features.clearing.euro")}
                                                                        required
                                                                    />
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {((unit.reportedProcurementExpense || 0) + unit.clearingProcurementExpense).toFixed(2)} {t("features.clearing.euro")}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell>
                                                                    <div className="fieldlabel">{t("features.clearing.unitExpense")}</div>
                                                                </Table.Cell>
                                                                <Table.Cell></Table.Cell>
                                                                <Table.Cell></Table.Cell>
                                                                <Table.Cell>
                                                                    {((((unit.reportedProcurementRevenue || 0) + (unit.clearingProcurementRevenue || 0))-((unit.reportedProcurementExpense || 0) + (unit.clearingProcurementExpense || 0)))/((unit.reportedProcurementAmount || 0) + (unit.clearingProcurementAmount || 0))).toFixed(2)} {t("features.clearing.euro")}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        </Table>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        }

                                        {showInput(unit.unitId,"repair") &&
                                            <Grid columns={2}>
                                                <Grid.Row>
                                                    <Grid.Column mobile={16} tablet={16} computer={2}><Header as="h2">{t("features.clearing.repair")}</Header></Grid.Column>
                                                    <Grid.Column  mobile={16} tablet={16} computer={14}>
                                                        <Table className="clearing">
                                                            <Table.Row>
                                                                <Table.Cell></Table.Cell>
                                                                <Table.Cell><div className="fieldlabel">{t("features.clearing.declared")}</div></Table.Cell>
                                                                <Table.Cell><div className="fieldlabel">{t("features.clearing.correction")}</div></Table.Cell>
                                                                <Table.Cell><div className="fieldlabel">{t("features.clearing.result")}</div></Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell>
                                                                    <div className="fieldlabel required">{t("features.clearing.amount")}</div>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {unit.reportedRepairAmount || 0} {t("features.clearing.pcs")}
                                                                </Table.Cell>
                                                                <Table.Cell className="hide-asterisk">
                                                                    <InputWithUnit
                                                                        id={"repairAmount" + unit.itemId}
                                                                        value={unit.clearingRepairAmount}
                                                                        validateFor="integer"
                                                                        onChangeFunc={setUnit}
                                                                        onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "repairAmount"}}
                                                                        type="number"
                                                                        unit={t("features.clearing.pcs")}
                                                                        required
                                                                    />
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {(unit.reportedRepairAmount || 0) + unit.clearingRepairAmount} {t("features.clearing.pcs")}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell>
                                                                    <div className="fieldlabel required">{t("features.clearing.revenue")}</div>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {(unit.reportedRepairRevenue || 0).toFixed(2)} {t("features.clearing.euro")}
                                                                </Table.Cell>
                                                                <Table.Cell  className="hide-asterisk">
                                                                    <InputWithUnit
                                                                        id={"repairRevenue" + unit.itemId}
                                                                        value={unit.clearingRepairRevenue}
                                                                        validateFor="money"
                                                                        onChangeFunc={setUnit}
                                                                        onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "repairRevenue"}}
                                                                        type="number"
                                                                        unit={t("features.clearing.euro")}
                                                                        required
                                                                    />
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {((unit.reportedRepairRevenue || 0) + unit.clearingRepairRevenue).toFixed(2)} {t("features.clearing.euro")}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell>
                                                                    <div className="fieldlabel required">{t("features.clearing.expense")}</div>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {(unit.reportedRepairExpense || 0).toFixed(2)} {t("features.clearing.euro")}
                                                                </Table.Cell>
                                                                <Table.Cell  className="hide-asterisk">
                                                                    <InputWithUnit
                                                                        id={"repairExpense" + unit.itemId}
                                                                        value={unit.clearingRepairExpense}
                                                                        validateFor="money"
                                                                        onChangeFunc={setUnit}
                                                                        onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "repairExpense"}}
                                                                        type="number"
                                                                        unit={t("features.clearing.euro")}
                                                                        required
                                                                    />
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {((unit.reportedRepairExpense || 0) + unit.clearingRepairExpense).toFixed(2)} {t("features.clearing.euro")}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell>
                                                                    <div className="fieldlabel">{t("features.clearing.unitExpense")}</div>
                                                                </Table.Cell>
                                                                <Table.Cell></Table.Cell>
                                                                <Table.Cell></Table.Cell>
                                                                <Table.Cell>
                                                                    {((((unit.reportedRepairRevenue || 0) + (unit.clearingRepairRevenue || 0))-((unit.reportedRepairExpense || 0) + (unit.clearingRepairExpense || 0)))/((unit.reportedRepairAmount || 0) + (unit.clearingRepairAmount || 0))).toFixed(2)} {t("features.clearing.euro")}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        </Table>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        }

                                        {/* scrapping */}
                                        {showInput(unit.unitId,"scrapping") &&
                                            <Grid columns={2}>
                                                <Grid.Row>
                                                    <Grid.Column mobile={16} tablet={16} computer={2}><Header as="h2">{t("features.clearing.scrapping")}</Header></Grid.Column>
                                                    <Grid.Column mobile={16} tablet={16} computer={14}>
                                                        <Table className="clearing">
                                                            <Table.Row>
                                                                <Table.Cell></Table.Cell>
                                                                <Table.Cell><div className="fieldlabel">{t("features.clearing.declared")}</div></Table.Cell>
                                                                <Table.Cell><div className="fieldlabel">{t("features.clearing.correction")}</div></Table.Cell>
                                                                <Table.Cell><div className="fieldlabel">{t("features.clearing.result")}</div></Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell>
                                                                    <div className="fieldlabel required">{t("features.clearing.amount")}</div>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {unit.reportedScrappingAmount || 0} {t("features.clearing.pcs")}
                                                                </Table.Cell>
                                                                <Table.Cell  className="hide-asterisk">
                                                                    <InputWithUnit
                                                                        id={"scrappingAmount" + unit.itemId}
                                                                        value={unit.clearingScrappingAmount}
                                                                        validateFor="integer"
                                                                        onChangeFunc={setUnit}
                                                                        onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "scrappingAmount"}}
                                                                        type="number"
                                                                        unit={t("features.clearing.pcs")}
                                                                        required
                                                                    />
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {(unit.reportedScrappingAmount || 0) + unit.clearingScrappingAmount} {t("features.clearing.pcs")}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell>
                                                                    <div className="fieldlabel required">{t("features.clearing.revenue")}</div>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {(unit.reportedScrappingRevenue || 0).toFixed(2)} {t("features.clearing.euro")}
                                                                </Table.Cell>
                                                                <Table.Cell  className="hide-asterisk">
                                                                    <InputWithUnit
                                                                        id={"scrappingRevenue" + unit.itemId}
                                                                        value={unit.clearingScrappingRevenue}
                                                                        validateFor="money"
                                                                        onChangeFunc={setUnit}
                                                                        onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "scrappingRevenue"}}
                                                                        type="number"
                                                                        unit={t("features.clearing.euro")}
                                                                        required
                                                                    />
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {((unit.reportedScrappingRevenue || 0) + unit.clearingScrappingRevenue).toFixed(2)} {t("features.clearing.euro")}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell>
                                                                    <div className="fieldlabel required">{t("features.clearing.expense")}</div>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {(unit.reportedScrappingExpense || 0).toFixed(2)} {t("features.clearing.euro")}
                                                                </Table.Cell>
                                                                <Table.Cell  className="hide-asterisk">
                                                                    <InputWithUnit
                                                                        id={"scrappingExpense" + unit.itemId}
                                                                        value={unit.clearingScrappingExpense}
                                                                        validateFor="money"
                                                                        onChangeFunc={setUnit}
                                                                        onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "scrappingExpense"}}
                                                                        type="number"
                                                                        unit={t("features.clearing.euro")}
                                                                        required
                                                                    />
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {((unit.reportedScrappingExpense || 0) + unit.clearingScrappingExpense).toFixed(2)} {t("features.clearing.euro")}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell>
                                                                    <div className="fieldlabel">{t("features.clearing.unitExpense")}</div>
                                                                </Table.Cell>
                                                                <Table.Cell></Table.Cell>
                                                                <Table.Cell></Table.Cell>
                                                                <Table.Cell>
                                                                    {((((unit.reportedScrappingRevenue || 0) + (unit.clearingScrappingRevenue || 0))-((unit.reportedScrappingExpense || 0) + (unit.clearingScrappingExpense || 0)))/((unit.reportedScrappingAmount || 0) + (unit.clearingScrappingAmount || 0))).toFixed(2)} {t("features.clearing.euro")}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        </Table>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        }

                                        {/* sold */}
                                        {showInput(unit.unitId,"sold") &&
                                            <Grid columns={2}>
                                                <Grid.Row>
                                                    <Grid.Column mobile={16} tablet={16} computer={2}><Header as="h2">{t("features.clearing.sold")}</Header></Grid.Column>
                                                    <Grid.Column mobile={16} tablet={16} computer={14}>
                                                        <Table className="clearing">
                                                            <Table.Row>
                                                                <Table.Cell></Table.Cell>
                                                                <Table.Cell><div className="fieldlabel">{t("features.clearing.declared")}</div></Table.Cell>
                                                                <Table.Cell><div className="fieldlabel">{t("features.clearing.correction")}</div></Table.Cell>
                                                                <Table.Cell><div className="fieldlabel">{t("features.clearing.result")}</div></Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell>
                                                                    <div className="fieldlabel required">{t("features.clearing.amount")}</div>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {unit.reportedSoldAmount || 0} {t("features.clearing.pcs")}
                                                                </Table.Cell>
                                                                <Table.Cell  className="hide-asterisk">
                                                                    <InputWithUnit
                                                                        id={"soldAmount" + unit.itemId}
                                                                        value={unit.clearingSoldAmount}
                                                                        validateFor="integer"
                                                                        onChangeFunc={setUnit}
                                                                        onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "soldAmount"}}
                                                                        type="number"
                                                                        unit={t("features.clearing.pcs")}
                                                                        required
                                                                    />
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {(unit.reportedSoldAmount || 0) + unit.clearingSoldAmount} {t("features.clearing.pcs")}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell>
                                                                    <div className="fieldlabel required">{t("features.clearing.revenue")}</div>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {(unit.reportedSoldRevenue || 0).toFixed(2)} {t("features.clearing.euro")}
                                                                </Table.Cell>
                                                                <Table.Cell  className="hide-asterisk">
                                                                    <InputWithUnit
                                                                        id={"soldRevenue" + unit.itemId}
                                                                        value={unit.clearingSoldRevenue}
                                                                        validateFor="money"
                                                                        onChangeFunc={setUnit}
                                                                        onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "soldRevenue"}}
                                                                        type="number"
                                                                        unit={t("features.clearing.euro")}
                                                                        required
                                                                    />
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {((unit.reportedSoldRevenue || 0) + unit.clearingSoldRevenue).toFixed(2)} {t("features.clearing.euro")}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell>
                                                                    <div className="fieldlabel required">{t("features.clearing.expense")}</div>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {(unit.reportedSoldExpense || 0).toFixed(2)} {t("features.clearing.euro")}
                                                                </Table.Cell>
                                                                <Table.Cell  className="hide-asterisk">
                                                                    <InputWithUnit
                                                                        id={"soldExpense" + unit.itemId}
                                                                        value={unit.clearingSoldExpense}
                                                                        validateFor="money"
                                                                        onChangeFunc={setUnit}
                                                                        onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "soldExpense"}}
                                                                        type="number"
                                                                        unit={t("features.clearing.euro")}
                                                                        required
                                                                    />
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {((unit.reportedSoldExpense || 0) + unit.clearingSoldExpense).toFixed(2)} {t("features.clearing.euro")}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell>
                                                                    <div className="fieldlabel">{t("features.clearing.unitExpense")}</div>
                                                                </Table.Cell>
                                                                <Table.Cell></Table.Cell>
                                                                <Table.Cell></Table.Cell>
                                                                <Table.Cell>
                                                                    {((((unit.reportedSoldRevenue || 0) + (unit.clearingSoldRevenue || 0))-((unit.reportedSoldExpense || 0) + (unit.clearingSoldExpense || 0)))/((unit.reportedSoldAmount || 0) + (unit.clearingSoldAmount || 0))).toFixed(2)} {t("features.clearing.euro")}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        </Table>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        }
                                    </Accordion.Content>
                                </Accordion>
                            )}
                        </div>
                    )}

                    <UploadCompanySurveyAttachements
                        companyId={userProfile.companyId}
                        surveyId={surveyId}
                        surveyTypeRef="clearing"
                        setAttachements={setAttachements}
                    />

                    <ButtonContainer>
                        <SaveBtn />
                        <CancelBtn />
                    </ButtonContainer>
                    </>
                :
                    <p>{t("features.survey.editingNotAllowed")}</p>
            :
                <p>{t("features.survey.notFound")}</p>
            }
        </Form>
    );
}

export default UpdateClearing;
