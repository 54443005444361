import { useTranslation } from 'react-i18next';
import { Container } from 'semantic-ui-react';
import {CopyToClipboard} from 'Components/Clipboard';
import { PageTitle } from 'Components/Titles';
//import './../clipboards.css';

const Clipboards = () => {

    const { t } = useTranslation();

    return (
        <Container className="page">

            <PageTitle title={t('pages.clipboard')}/>

            <CopyToClipboard text='value to copy' />

        </Container>
    )

}


export default Clipboards;
