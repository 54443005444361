import { useContext } from 'react';
import  { useNavigate }  from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu, Icon, Dropdown, Image } from 'semantic-ui-react';
import useHeader from './useHeader';
import { StateContext } from 'Application/state/StateContext';
import IHeaderProps from './IHeaderProps';
import './header.css';
import CompanySelector from 'Features/Company/CompanySelector/CompanySelector';
import useChangeCompany from 'Features/Company/CompanySelector/useChangeCompany';

/** App layout header
 * This component will add fixed top menu.
 * @param {function} setHamburgerMenuIsOpen - React SetState to set sidebar open or closed
 **/
const Header: React.FC<IHeaderProps> = ({ setHamburgerMenuIsOpen }: IHeaderProps ) => {
    
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [ appName ] = useHeader();
    const { userProfile } = useContext(StateContext);
    const { changeCompany } = useChangeCompany();

    const isAdmin = userProfile && userProfile.roles && userProfile.roles.find((role:any) => role.name==="Admin") ? true : false;

    const setCompany = async (value:any) => {
        const result = await changeCompany(userProfile,value);
        if(result.status === 204){
            // update profile etc
            window.location.reload();
        }
    }

    return (
        <Menu fixed='top' size='large' className="app-header no-print">

            <Menu.Item onClick={() => navigate("/")}>
                <Image size='tiny' src="/img/logo.png" alt="logo" />
            </Menu.Item>

            <Menu.Item header className="app-name visible-md">{appName}</Menu.Item>

            

            <Menu.Menu position='right' className='top-menu'>

                {isAdmin &&
                    <Menu.Item position='right' className="company-selector visible-lg">
                        <CompanySelector selectedCompany={userProfile.companyId} setCompany={setCompany} />
                    </Menu.Item>
                }

                {/* Visible for developers only */}
                {window.location.origin === 'http://localhost:3000' && 
                    <Menu.Item as='a' className='visible-lg' onClick={() => navigate("/developer")} >
                        <Icon name='code' size="large" className='app-header' />
                    </Menu.Item>
                }

                <Dropdown
                    item
                    direction='left'
                    className="logged-user visible-lg"
                    icon={<Icon name='user' size="large" className='app-header' />}
                >
                    <Dropdown.Menu>
                        <Dropdown.Item 
                            key='profile' 
                            text={t('components.layout.header.profile')} 
                            icon='user circle'
                            onClick={() => navigate("/profile")}
                        />
                        <Dropdown.Item 
                            key='logout' 
                            text={t('components.layout.header.logout')} 
                            icon='sign out'
                            size="large"
                            onClick={() => {
                                userProfile.logoutRedirect();
                            }}
                        />
                    </Dropdown.Menu>
                </Dropdown>

                <Menu.Item as='a' className='visible-lg' onClick={() => navigate("/")} >
                    <Icon name='home' size="large" className='app-header' />
                </Menu.Item>

                <Menu.Item as='a' onClick={() => setHamburgerMenuIsOpen(true)} >
                    <Icon name='sidebar' size="large" className='app-header' />
                </Menu.Item>

            </Menu.Menu>

        </Menu>
    );
};

export default Header;
