import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'semantic-ui-react';
import { PageTitle } from 'Components/Titles';
import { FileDropzone } from 'Components/Files';

//import './../files.css';

const Files = () => {

    const { t } = useTranslation();
    const [ files, setFiles ] = useState([null]);

    console.log(files);
    
    return (
        <Container className="page">

            <PageTitle title={t('pages.files')}/>

            <FileDropzone
                setFiles={setFiles}
                maxFiles={3}
                title="Files"
            />

        </Container>
    )

}


export default Files;
