import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from 'Application/App';
import { AppLoading } from 'Components/Layout';
import { instantiateMsal } from 'Features/Authentication';
import msalConfig from "Application/configurations/msalConfig";
import appInsightsConfig from "Application/configurations/appInsightsConfig";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import './Application/configurations/i18n';
import 'semantic-ui-css/semantic.min.css';
import './index.css';

const msalInstance = instantiateMsal(msalConfig);

const appInsights = new ApplicationInsights({config: appInsightsConfig});
appInsights.loadAppInsights();

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <Suspense fallback={<AppLoading />}>
        <App msal={msalInstance} appInsights={appInsights} />
    </Suspense>
);

// <React.StrictMode></React.StrictMode> => NOTE! strict mode renders component twice on local and once on production.
