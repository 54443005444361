import { useEffect } from 'react';
import useGetAllCompanies from "../ListCompanies/useGetCompanies";
import { Form, FormSelect } from 'semantic-ui-react';

declare interface ICompanySelectorProps {
    selectedCompany: string;
    setCompany: Function;
}

const CompanySelector = ({selectedCompany, setCompany}:ICompanySelectorProps) => {

    const { companies, listCompanies } = useGetAllCompanies();

    // Fetch companies
    useEffect(() => {
        (async () => {
            await listCompanies();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const companyOptions = companies.sort((a, b) => a.name > b.name ? 1 : -1).map(comp => {
        return {key: comp.id, text: comp.name, value: comp.id}
    });

    return (
            
            <Form>
                <FormSelect
                    value={selectedCompany}
                    options={companyOptions}
                    onChange={(e,{value}) => setCompany(value)}
                />
            </Form>
            
    );
}

export default CompanySelector;