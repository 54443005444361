import { useTranslation } from 'react-i18next';
import { Container } from 'semantic-ui-react';
import { SettingsContainer, SettingsBox } from 'Components/Settings';

const Management = () => {

    const { t } = useTranslation();

    return (
        <Container className="page">

            <h1>{t('pages.management')}</h1>

            <SettingsContainer>

                {/* Announcements */}
                <SettingsBox title={t('features.management.announcements')}
                    description={t('features.management.manageAnnouncements')}
                    icon='archive'
                    items={[
                        { to: '/management/announcements', title: t('features.management.announcements') },
                        { to: '/management/announcements/new', title: t('features.management.newAnnouncement') }
                    ]}
                />

                <SettingsBox title={t('features.management.units')}
                    description={t('features.management.manageUnits')}
                    icon='inbox'
                    items={[
                        { to: '/management/units', title: t('features.management.units') }
                    ]}
                />

                <SettingsBox title={t('features.management.companies')}
                    description={t('features.management.manageCompanies')}
                    icon='industry'
                    items={[
                        { to: '/management/companies', title: t('features.management.companies') },
                        { to: '/management/companies/new', title: t('features.management.newCompany') }
                    ]}
                />

                <SettingsBox title={t('features.management.yearlyBudgets')}
                    description={t('features.management.manageYearlyBudgets')}
                    icon='money'
                    items={[
                        { to: '/management/yearlybudgets', title: t('features.management.yearlyBudgets') },
                        { to: '/management/yearlybudgets/new', title: t('features.management.newYearlyBudget') }
                    ]}
                />

            </SettingsContainer>

        </Container>
    );
}

export default Management;
