import { usePutItem } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import apiConfig from 'Application/configurations/apiConfig';
import IUpdateStorageCost from "./interfaces/IUpdateStorageCost";
import ILockStorageCost from './interfaces/ILockStorageCost';
import { useAccessTokens } from 'Features/Authentication';
import { useTranslation } from 'react-i18next';
import IOpenStorageCost from './interfaces/IOpenStorageCost';

function useUpdateStorageCost () {

    const { t } = useTranslation();
    const putItem = usePutItem();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

    /** Update glass bottle return rate
     * @param {string} companyId - guid of the company
     * @param {string} surveyId - guid of the survey
     * @param {IUpdateStorageCost} surveyData - object which describes the survey
     **/
    const updateStorageCost = async (companyId: string = "", surveyId: string="", surveyData: IUpdateStorageCost | ILockStorageCost | IOpenStorageCost) => {

        const basePath = apiConfig.basePath;
        const companyEndpoint = apiConfig.endpoints.companies
        const surveyEndpoint = apiConfig.endpoints.storageCosts;
        const url = new URL(`${basePath}/${companyEndpoint}/${companyId}/${surveyEndpoint}/${surveyId}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken,
            body: surveyData
        }

        try {

            const response = await putItem(url, options);
         
            return responseHandler(response, "useUpdateStorageCost", { operation: "update", messageHeader: t("features.storageCost.savedSuccessfully"), hide: false });


        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "StorageCost.UpdatePermissionDenied") body = t('features.survey.updatePermissionDenied');
            if (problemTitle === "Company.CompanyDataAccessDenied") body = t('features.company.companyDataAccessDenied');
            if (problemTitle === "Company.NotExist") body = t('features.company.notExist');
            if (problemTitle === "UserAccount.NotExist") body = t('features.account.userAccountNotExist');
            if (problemTitle === "General.RequestorNotExist") body = t('features.survey.requestorNotExist');
            if (problemTitle === "StorageCost.NotExist") body = t('features.survey.notExist');
            if (problemTitle === "StorageCost.PermanentlyLocked") body = t('features.survey.permanentlyLocked');
            if (problemTitle === "StorageCost.Closed") body = t('features.survey.closed');

            return errorHandler(error, "useUpdateStorageCost", { operation: "update", hide: false, messageBody: body });

        }

    }

    return { updateStorageCost }

}

export default useUpdateStorageCost;
