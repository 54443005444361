import { createContext, ErrorInfo } from 'react';
import { ICustomProperties } from '@microsoft/applicationinsights-web';
import { LogLevel } from '@azure/msal-browser';
import useLogger from './useLogger';
import IUseLogger from './interfaces/IUseLogger';
import ILoggerContextProviderProps from './interfaces/ILoggerContextProviderProps';

export const LoggerContext = createContext<IUseLogger>({
    logMSAL: (level: LogLevel, message: string, containsPii: boolean) => {},
    logErrorBoundary: (error: Error, info: ErrorInfo) =>  {},
    logException: (error: Error, properties?: ICustomProperties ) =>  {},
    trackEvent: (name: string, properties?: ICustomProperties) =>  {},
    trackTrace: (message: string, properties?: ICustomProperties) =>  {},
    trackMetric: (name: string, average: number, properties?: ICustomProperties) =>  {},
    trackPageView: (name: string, properties?: ICustomProperties) =>  {},
});

const LoggerContextProvider = ({appInsights, children}: ILoggerContextProviderProps) => {

    const logger = useLogger({appInsights});

    return (
        <LoggerContext.Provider value={logger}>
            {children}
        </LoggerContext.Provider>
    );

}

export default LoggerContextProvider;
