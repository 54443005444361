import { Container } from 'semantic-ui-react';
import { PageTitle } from 'Components/Titles';
import { ListCompanyUnits } from 'Features/CompanyUnit';

import { useTranslation } from "react-i18next";

function CompanyUnitList () {

    const { t } = useTranslation();

    return (
        <Container className='page'>
        
            <PageTitle title={t("pages.companyUnits")} />

            <ListCompanyUnits />

        </Container>
    );
}

export default CompanyUnitList;