import React, { Fragment } from 'react';
import { Table } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import ICollapsedRowProps from "../interfaces/ICollapsedRowProps";
import useCellContent from '../hooks/useCellContent';

/** Table Collapsed Row 
 * @param {object} row - Table row
 * @param {array<any>} contentKeys - Table content keys as array of objects
 * @param {boolean} collapsable - table is collapsaple?
 * @param {boolean} collapsed - table is collapsed?
 * @param {Function} setCollapsed - React Set State method to set collapsed
 * 
 * @example row (= single content row)
 * content={[
 *  { id: 1, title: "a", description:"a1" }, => ROW 0
 *  { id: 2, title: "b", description:"b2" }, => ROW 1
 *  { id: 3, title: "c", description:"c3" }, => ROW 2
 * ]
 * 
 * @example header
 *  header={[
 *      t('general.name'),
 *      t('general.firstName'),
 *      t('general.lastName'),
 * ]}
 * 
 * @example contentKeys
 *  contentKeys={[
 *      { key: 'id' },
 *      { key: 'title', type: "link" },
 *      { key: 'description' }
 * ]}
 * 
 **/
const CollapsedRow: React.FC<ICollapsedRowProps> = ({
    row = {},
    contentKeys = [],
    collapsable,
    collapsed,
    setCollapsed
}: ICollapsedRowProps) => {

    const getCellContent = useCellContent();

    return (
        <Table.Row className={collapsable && collapsed ? 'collapsable collapsed' : ''} >
            {collapsable &&
                <Table.Cell onClick={() => setCollapsed(!collapsed)}>
                    <FontAwesomeIcon icon={faPlusCircle} />
                </Table.Cell>
            }
            {contentKeys.map((cellKey: any, n: number) =>
                <Fragment key={n}> 
                    {!cellKey.hideCollapsed &&
                        <Table.Cell textAlign={cellKey.cellAlign}>
                            {getCellContent(row, cellKey)}
                        </Table.Cell>
                    }
                </Fragment>     
            )}  
        </Table.Row>
    );

}

export default CollapsedRow;
