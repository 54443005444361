import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import IDeleteIconBtnProps from '../interfaces/IDeleteIconBtnProps';

/** Form Delete Icon Button
 * @param {function} action - function which is executed on button click func(ev, data)
 * @param {boolean} [hide] - hide the button
 * @link https://react.semantic-ui.com/elements/button/
 **/
const DeleteIconBtn: React.FC<IDeleteIconBtnProps> = (props: IDeleteIconBtnProps) => {

    const { action, hidden=false, color, type, ...rest } = props;

    if (hidden) return null;

    return (
        <Button
            icon={<Icon name={'delete'} />}
            color={color ? color : undefined}
            type={type ? type : 'reset'}
            onClick={(ev, data) => action(ev, data) }
            {...rest}
        />
    );

};

export default DeleteIconBtn;
