import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from 'Application/configurations/msalConfig';
import ErrorComponent from './ErrorComponent';
import LoadingComponent from './LoadingComponent';
import IAuthentication from '../interfaces/IAuthentication';

const MsalAuthentication = ({msalInstance, children}: IAuthentication) => {

    if (!msalInstance) return null;

    // MSAL authentication template force user to authenticate before entering the app. 
    // See msal-react documentation for more options...
    return (
        <MsalProvider instance={msalInstance}>

            <MsalAuthenticationTemplate 
                interactionType={InteractionType.Redirect} 
                authenticationRequest={loginRequest} 
                errorComponent={ErrorComponent} 
                loadingComponent={LoadingComponent}
            >

                {children}

            </MsalAuthenticationTemplate>     

        </MsalProvider>
    )

}

export default MsalAuthentication;
