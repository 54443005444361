import { usePostItem } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import apiConfig from 'Application/configurations/apiConfig';
import ICreateYearlyBudget from "./ICreateYearlyBudget";
import { useAccessTokens } from 'Features/Authentication';
import { useTranslation } from 'react-i18next';

function useCreateYearlyBudget () {

    const { t } = useTranslation();
    const postItem = usePostItem();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

    /** Create new yearly bBudget
     * @param {ICreateYearlyBudget} yearly budget - object which describes the created yearly budget
     **/
    const createYearlyBudget = async (yearlyBudget: ICreateYearlyBudget) => {

        const basePath = apiConfig.basePath;
        const endpoint = apiConfig.endpoints.yearlyBudgets;
        const url = new URL(`${basePath}/${endpoint}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken,
            body: yearlyBudget
        }

        try {

            const response = await postItem(url, options);
         
            return responseHandler(response, "useCreateYearlyBudget", { operation: "create", messageHeader: t("features.yearlyBudget.savedSuccessfully"), hide: false });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "General.RequestorNotExist") body = t('features.yearlyBudget.requestorNotExist');
            if (problemTitle === "YearlyBudgetErrors.CreatePermissionDenied") body = t('features.yearlyBudget.createPermissionDenied');
            if (problemTitle === "YearlyBudgetErrors.AlreadyExist") body = t('features.yearlyBudget.alreadyExist');

            return errorHandler(error, "useCreateYearlyBudget", { operation: "create", hide: false, messageBody: body });
   
        }

    }

    return { createYearlyBudget }

}

export default useCreateYearlyBudget;
