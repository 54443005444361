import { usePutItem } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import apiConfig from 'Application/configurations/apiConfig';
import IUpdateCompany from "./IUpdateCompany";
import { useAccessTokens } from 'Features/Authentication';
import { useTranslation } from 'react-i18next';

function useUpdateCompany () {

    const { t } = useTranslation();
    const putItem = usePutItem();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

    /** Create new company
     * @param {IUpdateCompany} company - object which describes the created company
     **/
    const updateCompany = async (id: string="", company: IUpdateCompany) => {

        const basePath = apiConfig.basePath;
        const endpoint = apiConfig.endpoints.companies;
        const url = new URL(`${basePath}/${endpoint}/${id}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken,
            body: company
        }

        try {

            const response = await putItem(url, options);
         
            return responseHandler(response, "useUpdateCompany", { operation: "update", messageHeader: t("features.company.savedSuccessfully"), hide: false });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "General.RequestorNotExist") body = t('features.company.requestorNotExist');
            if (problemTitle === "Company.NotExist") body = t('features.company.notExist');
            if (problemTitle === "Company.AlreadyExist") body = t('features.company.alreadyExist');
            if (problemTitle === "Company.UpdatePermissionDenied") body = t('features.company.updatePermissionDenied');
            if (problemTitle === "Address.NotExist") body = t('features.company.addressNotExist');
            if (problemTitle === "Company.InvalidBusinessIdentityCode") body = t('features.company.invalidBusinessIdentityCode');
            if (problemTitle === "Company.CannotDeactivateDeleted") body = t('features.company.cannotDeactivateDeleted');
            if (problemTitle === "Company.CannotActivateDeleted") body = t('features.company.CannotActivateDeleted');

            return errorHandler(error, "useUpdateCompany", { operation: "update", hide: false, messageBody: body });

        }

    }

    return { updateCompany }

}

export default useUpdateCompany;
