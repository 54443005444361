import { useState, useEffect, useContext } from "react";
import useGetInventory from "../ReadInventory/useGetInventory";
import useUpdateInventory from "./useUpdateInventory";
import { Form, Popup, Icon, Header, Accordion, Grid } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";
import { ButtonContainer, SaveBtn, CancelBtn } from "Components/Buttons";
import IUpdateInventory from "./interfaces/IUpdateInventory";
import IInventory from "./interfaces/IInventory";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { InputWithUnit } from "Components/FormElements/InputWithUnit/InputWithUnit";
import { StateContext } from "Application/state/StateContext";
import { PageTitle } from "Components/Titles";
import ICategory from "../ReadInventory/interfaces/IReadInventoryCategory";
import { MessageContext } from "Features/Messages";
import useInputValidation from "Components/FormElements/InputWithUnit/useInputValidation";
import "../../../Survey/styles.css";
import configuration from "../unitQuestionConfig";
import ConfirmModal from "Components/CSV/CSVImport/ConfirmModal";
import CSVImport from "Components/CSV/CSVImport/CSVImport";
import useSurvey from "Features/Survey/useSurvey";
import { UploadCompanySurveyAttachements, useUploadCompanySurveyAttachements } from 'Features/CompanyAttachement';

function UpdateInventory () {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const detailPath = location.pathname.replace("/edit","")

    const { userProfile } = useContext(StateContext);
    const { companyId, surveyId } = useParams();
    const [ isLoading, setIsLoading ] = useState(true);
    const { setMessages, addMessage } = useContext(MessageContext);
    const { isNonNegativeInteger, isNonNegativeFloat } = useInputValidation();
    const { updateInventory } = useUpdateInventory();
    const { inventory, getInventoryById } = useGetInventory();
    const { isEditingAllowed } = useSurvey();

    const [ attachements, setAttachements ] = useState([]);
    const { uploadCompanySurveyAttachements } = useUploadCompanySurveyAttachements(); 

    const [ categories, setCategories ] = useState<ICategory[]>([]);

    // CSV import
    const [ file, setFile ] = useState(undefined);
    const [ confirmModalOpen, setConfirmModalOpen ] = useState(false);
    
    // Fetch survey
    useEffect(() => {
        (async () => {
            if(companyId || userProfile.companyId) {
                await getInventoryById(companyId || userProfile.companyId,surveyId);
            }
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userProfile.companyId]);

     // set categories state
     useEffect(() => {
        setCategories(inventory.categories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[inventory.categories]);

    const setUnit = (value:number | null, id:{category: string, unit: string, field: string}) => {
        
        const cats = [...categories];
        const category = cats.find(item => item.id===id.category) || {id:"", name:"",units:[]};
        const unit = category.units.find(item => item.unitId===id.unit) || {itemId: "", unitId: "", unitName: "", isCompanyUnit: true, emptyAmount: null, inUseFullAmount: null, forSaleAmount: null, procurementAmount: null, procurementRevenue: null, procurementExpense: null, repairAmount: null, repairRevenue: null, repairExpense: null, scrappingAmount: null, scrappingRevenue: null, scrappingExpense: null, soldAmount: null, soldRevenue: null, soldExpense: null, accordionOpen: false};

        let val = null;
        if(value!==null){
            val = value;
        }
        switch(id.field){
            case "emptyAmount":
                unit.emptyAmount = val;
                break;
            case "inUseFullAmount":
                unit.inUseFullAmount = val;
                break;
            case "forSaleAmount":
                unit.forSaleAmount = val;
                break;
            case "procurementAmount":
                unit.procurementAmount = val;
                break;
            case "procurementRevenue":
                unit.procurementRevenue = val;
                break;
            case "procurementExpense":
                unit.procurementExpense = val;
                break;
            case "repairAmount":
                unit.repairAmount = val;
                break;
            case "repairRevenue":
                unit.repairRevenue = val;
                break;
            case "repairExpense":
                unit.repairExpense = val;
                break;
            case "scrappingAmount":
                unit.scrappingAmount = val;
                break;
            case "scrappingRevenue":
                unit.scrappingRevenue = val;
                break;
            case "scrappingExpense":
                unit.scrappingExpense = val;
                break;
            case "soldAmount":
                unit.soldAmount = val;
                break;
            case "soldRevenue":
                unit.soldRevenue = val;
                break;
            case "soldExpense":
                unit.soldExpense = val;
                break;
            case "accordionOpen":
                unit.accordionOpen = !unit.accordionOpen;
        }
        setCategories(cats);
    }

    const clearUnits = () => {
        const cats = [...categories];
        cats.map((cat:any) => {
            cat.units.map((unit:any) => {
                unit.emptyAmount = null;
                unit.inUseFullAmount = null;
                unit.forSaleAmount = null;
                unit.procurementAmount = null;
                unit.procurementRevenue = null;
                unit.procurementExpense = null;
                unit.repairAmount = null;
                unit.repairRevenue = null;
                unit.repairExpense = null;
                unit.scrappingAmount = null;
                unit.scrappingRevenue = null;
                unit.scrappingExpense = null;
                unit.soldAmount = null;
                unit.soldRevenue = null;
                unit.soldExpense = null;
                return unit;
            });
            return cat;
        });
        setCategories(cats);
    }

    const onSubmit = async () => {

        setIsLoading(true);

        var uploadAttachementRequest = {
            companyId: userProfile.companyId,
            surveyId: surveyId || "",
            surveyName: inventory.name || "Inventaarikysely",
            surveyTypeRef: "inventory",
            attachements: attachements
        }

        let inventories:IInventory[] = [];
        let errors=false;
        categories.map(cat =>
            cat.units.map(unit => {
                if(!isNonNegativeInteger(unit.emptyAmount) || !isNonNegativeInteger(unit.inUseFullAmount) || !isNonNegativeInteger(unit.forSaleAmount) ||
                !isNonNegativeInteger(unit.procurementAmount) || !isNonNegativeFloat(unit.procurementRevenue,2) || !isNonNegativeFloat(unit.procurementExpense,2) ||
                !isNonNegativeInteger(unit.repairAmount) || !isNonNegativeFloat(unit.repairRevenue,2) || !isNonNegativeFloat(unit.repairExpense,2) ||
                !isNonNegativeInteger(unit.scrappingAmount) || !isNonNegativeFloat(unit.scrappingRevenue,2) || !isNonNegativeFloat(unit.scrappingExpense,2) ||
                !isNonNegativeInteger(unit.soldAmount) || !isNonNegativeFloat(unit.soldRevenue,2) || !isNonNegativeFloat(unit.soldExpense,2)
                ){
                    errors=true;
                }
                const inventory:IInventory = {
                    id: unit.itemId,
                    unitId: unit.unitId,
                    emptyAmount: unit.emptyAmount,
                    inUseFullAmount: unit.inUseFullAmount,
                    forSaleAmount: unit.forSaleAmount,
                    procurementAmount: unit.procurementAmount,
                    procurementRevenue: unit.procurementRevenue,
                    procurementExpense: unit.procurementExpense,
                    repairAmount: unit.repairAmount,
                    repairRevenue: unit.repairRevenue,
                    repairExpense: unit.repairExpense,
                    scrappingAmount: unit.scrappingAmount,
                    scrappingRevenue: unit.scrappingRevenue,
                    scrappingExpense: unit.scrappingExpense,
                    soldAmount: unit.soldAmount,
                    soldRevenue: unit.soldRevenue,
                    soldExpense: unit.soldExpense
                }
                inventories.push(inventory);
                return unit;
            })
        );

        setMessages([]);
        if(errors){
            addMessage({header: t("features.survey.inputErrorHeader"), type: "error", body: t("features.survey.inputErrorDescription")});
            window.scrollTo(0,0);
        } else {
            const inventory:IUpdateInventory = {
                inventoryItems: inventories
            }

            let result = await updateInventory(companyId || userProfile.companyId,surveyId,inventory);

            await uploadCompanySurveyAttachements(uploadAttachementRequest);

            if (result && result.status === 204) return navigate(detailPath);

        }
        setIsLoading(false);
    }

    const showInput = (id:string,fieldName:string) => {
        const unit = configuration.find((unit:any) => unit.unitId.toLowerCase() === id.toLowerCase())
        switch(fieldName){
            case "empty": return unit?.showEmpty;
            case "inUseFull": return unit?.showInUseFull;
            case "forSale": return unit?.showForSale;
            case "procurement": return unit?.showProcurement;
            case "repair": return unit?.showRepair;
            case "scrapping": return unit?.showScrapping;
            case "sold": return unit?.showSold;
        }
        return false;
    }
  
    const csvDataToFields = (csvArray:any) => {

        // empty form
        clearUnits();

        // insert values from csv array
        const cats = [...categories];
        csvArray.map((item:any) => {
            const categoryId = item.kategoriaId;
            const unitId = item.yksikkoId;
            const unit = cats.find(cat => cat.id === categoryId)?.units.find(unit => unit.unitId === unitId);
            if(unit){
                if(showInput(unitId,"empty")){
                    unit.emptyAmount = isNonNegativeInteger(parseInt(item.tyhjat)) ? parseInt(item.tyhjat) : null;
                }
                if(showInput(unitId,"inUseFull")){
                    unit.inUseFullAmount = isNonNegativeInteger(parseInt(item.taysiinSitoutuneet)) ? parseInt(item.taysiinSitoutuneet) : null;
                }
                if(showInput(unitId,"forSale")){
                    unit.forSaleAmount = isNonNegativeInteger(parseInt(item.myytavat)) ? parseInt(item.myytavat) : null;
                }
                if(unit.isCompanyUnit){
                    if(showInput(unitId,"procurement")){
                        unit.procurementAmount = isNonNegativeInteger(parseInt(item.uushankintaMaara)) ? parseInt(item.uushankintaMaara) : null;
                        unit.procurementRevenue = isNonNegativeFloat(parseFloat(item.uushankintaTuotot),2) ? parseFloat(item.uushankintaTuotot) : null;
                        unit.procurementExpense = isNonNegativeFloat(parseFloat(item.uushankintaKustannukset),2) ? parseFloat(item.uushankintaKustannukset) : null;
                    }
                    if(showInput(unitId,"repair")){
                        unit.repairAmount = isNonNegativeInteger(parseInt(item.korjatutMaara)) ? parseInt(item.korjatutMaara) : null;
                        unit.repairRevenue = isNonNegativeFloat(parseFloat(item.korjatutTuotot),2) ? parseFloat(item.korjatutTuotot) : null;
                        unit.repairExpense = isNonNegativeFloat(parseFloat(item.korjatutKustannukset),2) ? parseFloat(item.korjatutKustannukset) : null;
                    }
                    if(showInput(unitId,"scrapping")){
                        unit.scrappingAmount = isNonNegativeInteger(parseInt(item.romutetutMaara)) ? parseInt(item.romutetutMaara) : null;
                        unit.scrappingRevenue = isNonNegativeFloat(parseFloat(item.romutetutTuotot),2) ? parseFloat(item.romutetutTuotot) : null;
                        unit.scrappingExpense = isNonNegativeFloat(parseFloat(item.romutetutKustannukset),2) ? parseFloat(item.romutetutKustannukset) : null;
                    }
                    if(showInput(unitId,"sold")){
                        unit.soldAmount = isNonNegativeInteger(parseInt(item.myydytMaara)) ? parseInt(item.myydytMaara) : null;
                        unit.soldRevenue = isNonNegativeFloat(parseFloat(item.myydytTuotot),2) ? parseFloat(item.myydytTuotot) : null;
                        unit.soldExpense = isNonNegativeFloat(parseFloat(item.myydytKustannukset),2) ? parseFloat(item.myydytKustannukset) : null;
                    }
                }
            }
            return item;
        });
    };

    return (
        
        <Form loading={isLoading} onSubmit={onSubmit} className="form-inline">

            <ConfirmModal open={confirmModalOpen} setOpen={setConfirmModalOpen} file={file} setFile={setFile} csvToFieldsFunction={csvDataToFields}/>
            
            <PageTitle 
                title={inventory.name ? inventory.name : t("pages.editInventory")} 
                button={
                    <Popup
                        trigger={<Icon name='info' color='blue' size='large' circular />}
                        content={t("features.inventory.infoText")}
                        position='bottom left'
                        wide="very"
                    />
                }
            />

            {inventory.id  ?
                isEditingAllowed(inventory.isPermanentlyLocked,inventory.openDate,inventory.closeDate) ?
                    <>
                    <CSVImport file={file} setFile={setFile} setConfirmModalOpen={setConfirmModalOpen} templateFileName="inventaarimalli.csv" />
                    
                    {categories && categories.map((cat:any) =>
                        <div key={"cat" + cat.id}>
                            <Header as="h2">{cat.name}</Header>
                            {cat.units.map((unit:any) =>
                                <Accordion key={unit.unitId} className="unit-accordion">
                                    <Accordion.Title active={unit.accordionOpen || unit.isCompanyUnit} index={0} onClick={() => setUnit(0,{category: cat.id, unit: unit.unitId, field: "accordionOpen"})}>
                                        <Icon name='dropdown' /> {unit.unitName}
                                    </Accordion.Title>
                                    <Accordion.Content active={unit.accordionOpen || unit.isCompanyUnit}>
                                        <Grid columns={4} style={{clear: "both"}}>
                                            <Grid.Row>
                                                <Grid.Column></Grid.Column>
                                                <Grid.Column><div className="fieldlabel">{t("features.inventory.stockAmount")}</div></Grid.Column>
                                                <Grid.Column></Grid.Column>
                                                <Grid.Column></Grid.Column>

                                                {showInput(unit.unitId,"empty") &&
                                                    <>
                                                    <Grid.Column>
                                                        <div className={unit.isCompanyUnit ? "fieldlabel required" : "fieldlabel"}>{t("features.inventory.emptyAmount")}</div>
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        <InputWithUnit
                                                            id={"emptyAmount" + unit.itemId}
                                                            value={unit.emptyAmount}
                                                            validateFor="nonNegativeInteger"
                                                            onChangeFunc={setUnit}
                                                            onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "emptyAmount"}}
                                                            type="number"
                                                            unit={t("features.inventory.pcs")}
                                                            required={unit.isCompanyUnit}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column></Grid.Column>
                                                    <Grid.Column></Grid.Column>
                                                    </>
                                                }
                                                {showInput(unit.unitId,"inUseFull") &&
                                                    <>
                                                    <Grid.Column>
                                                        <div className={unit.isCompanyUnit ? "fieldlabel required" : "fieldlabel"}>{t("features.inventory.categories." + cat.id + ".inUseFullAmount")}</div>
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        <InputWithUnit
                                                            id={"inUseFullAmount" + unit.itemId}
                                                            value={unit.inUseFullAmount}
                                                            validateFor="nonNegativeInteger"
                                                            onChangeFunc={setUnit}
                                                            onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "inUseFullAmount"}}
                                                            type="number"
                                                            unit={t("features.inventory.pcs")}
                                                            required={unit.isCompanyUnit}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column></Grid.Column>
                                                    <Grid.Column></Grid.Column>
                                                    </>
                                                }
                                                {showInput(unit.unitId,"forSale") &&
                                                    <>
                                                    <Grid.Column>
                                                        <div className={unit.isCompanyUnit ? "fieldlabel required" : "fieldlabel"}>{t("features.inventory.forSaleAmount")}</div>
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        <InputWithUnit
                                                            id={"forSaleAmount" + unit.itemId}
                                                            value={unit.forSaleAmount}
                                                            validateFor="nonNegativeInteger"
                                                            onChangeFunc={setUnit}
                                                            onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "forSaleAmount"}}
                                                            type="number"
                                                            unit={t("features.inventory.pcs")}
                                                            required={unit.isCompanyUnit}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column></Grid.Column>
                                                    <Grid.Column></Grid.Column>
                                                    </>
                                                }
                                            </Grid.Row>

                                            {unit.isCompanyUnit &&
                                                <Grid.Row>
                                                    <Grid.Column></Grid.Column>
                                                    <Grid.Column><div className="fieldlabel">{t("features.inventory.amount")}</div></Grid.Column>
                                                    <Grid.Column><div className="fieldlabel">{t("features.inventory.revenue")}</div></Grid.Column>
                                                    <Grid.Column><div className="fieldlabel">{t("features.inventory.expense")}</div></Grid.Column>

                                                    {showInput(unit.unitId,"procurement") &&
                                                        <>
                                                        <Grid.Column>
                                                            <div className={unit.isCompanyUnit ? "fieldlabel required" : "fieldlabel"}>{t("features.inventory.procurement")}</div>
                                                        </Grid.Column>
                                                        <Grid.Column className="hide-asterisk">
                                                            <InputWithUnit
                                                                id={"procurementAmount" + unit.itemId}
                                                                value={unit.procurementAmount}
                                                                validateFor="nonNegativeInteger"
                                                                onChangeFunc={setUnit}
                                                                onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "procurementAmount"}}
                                                                type="number"
                                                                unit={t("features.inventory.pcs")}
                                                                required={unit.isCompanyUnit}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column className="hide-asterisk">
                                                            <InputWithUnit
                                                                id={"procurementRevenue" + unit.itemId}
                                                                value={unit.procurementRevenue}
                                                                validateFor="nonNegativeMoney"
                                                                onChangeFunc={setUnit}
                                                                onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "procurementRevenue"}}
                                                                type="number"
                                                                unit={t("features.inventory.euro")}
                                                                required={unit.isCompanyUnit}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column className="hide-asterisk">
                                                            <InputWithUnit
                                                                id={"procurementExpense" + unit.itemId}
                                                                value={unit.procurementExpense}
                                                                validateFor="nonNegativeMoney"
                                                                onChangeFunc={setUnit}
                                                                onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "procurementExpense"}}
                                                                type="number"
                                                                unit={t("features.inventory.euro")}
                                                                required={unit.isCompanyUnit}
                                                            />
                                                        </Grid.Column>
                                                        </>
                                                    }
                                                    {showInput(unit.unitId,"repair") &&
                                                        <>
                                                        <Grid.Column>
                                                            <div className={unit.isCompanyUnit ? "fieldlabel required" : "fieldlabel"}>{t("features.inventory.repair")}</div>
                                                        </Grid.Column>
                                                        <Grid.Column className="hide-asterisk">
                                                            <InputWithUnit
                                                                id={"repairAmount" + unit.itemId}
                                                                value={unit.repairAmount}
                                                                validateFor="nonNegativeInteger"
                                                                onChangeFunc={setUnit}
                                                                onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "repairAmount"}}
                                                                type="number"
                                                                unit={t("features.inventory.pcs")}
                                                                required={unit.isCompanyUnit}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column className="hide-asterisk">
                                                            <InputWithUnit
                                                                id={"repairRevenue" + unit.itemId}
                                                                value={unit.repairRevenue}
                                                                validateFor="nonNegativeMoney"
                                                                onChangeFunc={setUnit}
                                                                onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "repairRevenue"}}
                                                                type="number"
                                                                unit={t("features.inventory.euro")}
                                                                required={unit.isCompanyUnit}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column className="hide-asterisk">
                                                            <InputWithUnit
                                                                id={"repairExpense" + unit.itemId}
                                                                value={unit.repairExpense}
                                                                validateFor="nonNegativeMoney"
                                                                onChangeFunc={setUnit}
                                                                onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "repairExpense"}}
                                                                type="number"
                                                                unit={t("features.inventory.euro")}
                                                                required={unit.isCompanyUnit}
                                                            />
                                                        </Grid.Column>
                                                        </>
                                                    }
                                                    {showInput(unit.unitId,"scrapping") &&
                                                        <>
                                                        <Grid.Column>
                                                            <div className={unit.isCompanyUnit ? "fieldlabel required" : "fieldlabel"}>{t("features.inventory.scrapping")}</div>
                                                        </Grid.Column>
                                                        <Grid.Column className="hide-asterisk">
                                                            <InputWithUnit
                                                                id={"scrappingAmount" + unit.itemId}
                                                                value={unit.scrappingAmount}
                                                                validateFor="nonNegativeInteger"
                                                                onChangeFunc={setUnit}
                                                                onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "scrappingAmount"}}
                                                                type="number"
                                                                unit={t("features.inventory.pcs")}
                                                                required={unit.isCompanyUnit}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column className="hide-asterisk">
                                                            <InputWithUnit
                                                                id={"scrappingRevenue" + unit.itemId}
                                                                value={unit.scrappingRevenue}
                                                                validateFor="nonNegativeMoney"
                                                                onChangeFunc={setUnit}
                                                                onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "scrappingRevenue"}}
                                                                type="number"
                                                                unit={t("features.inventory.euro")}
                                                                required={unit.isCompanyUnit}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column className="hide-asterisk">
                                                            <InputWithUnit
                                                                id={"scrappingExpense" + unit.itemId}
                                                                value={unit.scrappingExpense}
                                                                validateFor="nonNegativeMoney"
                                                                onChangeFunc={setUnit}
                                                                onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "scrappingExpense"}}
                                                                type="number"
                                                                unit={t("features.inventory.euro")}
                                                                required={unit.isCompanyUnit}
                                                            />
                                                        </Grid.Column>
                                                        </>
                                                    }
                                                    {showInput(unit.unitId,"sold") &&
                                                        <>
                                                        <Grid.Column>
                                                            <div className={unit.isCompanyUnit ? "fieldlabel required" : "fieldlabel"}>{t("features.inventory.sold")}</div>
                                                        </Grid.Column>
                                                        <Grid.Column className="hide-asterisk">
                                                            <InputWithUnit
                                                                id={"soldAmount" + unit.itemId}
                                                                value={unit.soldAmount}
                                                                validateFor="nonNegativeInteger"
                                                                onChangeFunc={setUnit}
                                                                onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "soldAmount"}}
                                                                type="number"
                                                                unit={t("features.inventory.pcs")}
                                                                required={unit.isCompanyUnit}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column className="hide-asterisk">
                                                            <InputWithUnit
                                                                id={"soldRevenue" + unit.itemId}
                                                                value={unit.soldRevenue}
                                                                validateFor="nonNegativeMoney"
                                                                onChangeFunc={setUnit}
                                                                onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "soldRevenue"}}
                                                                type="number"
                                                                unit={t("features.inventory.euro")}
                                                                required={unit.isCompanyUnit}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column className="hide-asterisk">
                                                            <InputWithUnit
                                                                id={"soldExpense" + unit.itemId}
                                                                value={unit.soldExpense}
                                                                validateFor="nonNegativeMoney"
                                                                onChangeFunc={setUnit}
                                                                onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "soldExpense"}}
                                                                type="number"
                                                                unit={t("features.inventory.euro")}
                                                                required={unit.isCompanyUnit}
                                                            />
                                                        </Grid.Column>
                                                        </>
                                                    }
                                                </Grid.Row>
                                            }
                                        </Grid>
                                    </Accordion.Content>
                                </Accordion>
                            )}
                        </div>
                    )}

                    <UploadCompanySurveyAttachements
                        companyId={userProfile.companyId}
                        surveyId={surveyId}
                        surveyTypeRef="inventory"
                        setAttachements={setAttachements}
                    />
                    
                    <ButtonContainer>
                        <SaveBtn />
                        <CancelBtn />
                    </ButtonContainer>
                    </>
                :
                <p>{t("features.survey.editingNotAllowed")}</p>
            :
                <p>{t("features.survey.notFound")}</p>
            }
        </Form>
    );
}

export default UpdateInventory;
