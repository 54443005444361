import { PageTitle } from "Components/Titles";
import { Container } from "semantic-ui-react";
import { UpdateCompanySurveys } from "Features/CompanySurvey";
import { useTranslation } from "react-i18next";

function EditCompanySurveys () {

    const { t } = useTranslation();

    return (
        <Container className="page">
            <PageTitle title={t("pages.editCompanySurveys")} />
            <UpdateCompanySurveys />
        </Container>
    );
}

export default EditCompanySurveys;