import { useTranslation } from 'react-i18next';
import { usePostItem } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import apiConfig from 'Application/configurations/apiConfig';
import { useAccessTokens } from 'Features/Authentication';
import IUploadCompanySurveyAttachementsProps from './IUploadCompanySurveyAttachementsProps';

const useUploadCompanySurveyAttachements = () => {
    
    const { t } = useTranslation();

    const postItem = usePostItem();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const getToken = useAccessTokens();

    // API Scopes (see api application registration)
    const accessAsUser = apiConfig.scopes.AccessAsUser;
    const filesReadWriteAll = apiConfig.scopes.GraphApi.FilesReadWriteAll;
    const sitesReadAll = apiConfig.scopes.GraphApi.SitesReadAll;

    /** Upload all company survey attachements
     * @param {string} companyId
     * @param {string} surveyId
     * @param {string} surveyTypeRef
     * @param {array} attachements
    */
    const uploadCompanySurveyAttachements = async ({ companyId, surveyId, surveyName, surveyTypeRef, attachements }: IUploadCompanySurveyAttachementsProps) => {

        const basePath = apiConfig.basePath;
        const endpoint = apiConfig.endpoints.files;

        if (companyId == null) {
            console.log("Error: companyId is null");
        }
        if (surveyId == null) {
            console.log("Error: surveyId is null");
        }
        if (surveyTypeRef == null) {
            console.log("Error: surveyTypeRef is null");
        }

        console.log(attachements);
        console.log(attachements.length);

        // Dont try to upload if no attachements are added
        if (attachements === null || attachements.length === 0) {
            console.log("Error: attachements is null");
            return;
        }

        let url = new URL(`${basePath}/companies/${companyId}/${endpoint}`);

        const accessToken = await getToken([accessAsUser]);

        const graphApiAccessToken = await getToken([filesReadWriteAll, sitesReadAll]);

        const options = {
            accessToken: accessToken,
            graphToken: graphApiAccessToken,
            contentType: "multipart/form-data",
            body: {
                companyId: companyId,
                surveyId: surveyId,
                surveyName: surveyName,
                surveyTypeRef: surveyTypeRef,
                attachements: attachements
            }
        }
        
        try {

            const response = await postItem(url, options);

            return responseHandler(response, "useUploadCompanySurveyAttachements", { operation: "create", hide: true });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "General.RequestorNotExist") body = t('features.companyFiles.requestorNotExist');

            if (problemTitle === "CompanyFiles.CreatePermissionDenied") body = t('features.companyFiles.createPermissionDenied');

            if (problemTitle === "UserAccount.UserDataAccessDenied") body = t('features.companyFiles.userDataAccessDenied');
            if (problemTitle === "UserAccount.ReadPermissionDenied") body = t('features.companyFiles.userAccountNotFound');
            if (problemTitle === "UserAccount.NotExist") body = t('features.companyFiles.userAccountNotFound');

            if (problemTitle === "SharePointSite.NotExist") body = t('features.companyFiles.spSiteNotExist');
            if (problemTitle === "SharePointSite.PermissionDenied") body = t('features.companyFiles.spSitePermissionDenied');

            if (problemTitle === "SharePointFile.CreatePermissionDenied") body = t('features.companyFiles.spFilePermissionDenied');
            if (problemTitle === "SharePointFile.PermissionDenied") body = t('features.companyFiles.spFilePermissionDenied');
            if (problemTitle === "SharePointFile.NoDocumentsToCreate") body = t('features.companyFiles.spFileNoDocumentsToCreate');
            if (problemTitle === "SharePointFile.AlreadyExist") body = t('features.companyFiles.spFileAlreadyExist');

            if (problemTitle === "SharePointLibrary.NotExist") body = t('features.companyFiles.spLibraryNotExist');
            if (problemTitle === "SharePointLibrary.PermissionDenied") body = t('features.companyFiles.spLibraryPermissionDenied');

            if (problemTitle === "SharePointFolder.NotExist") body = t('features.companyFiles.spFolderNotExist');

            return errorHandler(error, "useUploadCompanySurveyAttachements", { operation: "create", hide: false, messageBody: body });
            
        }

    }

    return { uploadCompanySurveyAttachements };

}

export default useUploadCompanySurveyAttachements;
