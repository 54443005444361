import { useState } from 'react';
import { useGetItem } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import { useTranslation } from 'react-i18next';
import apiConfig from 'Application/configurations/apiConfig';
import IReadYearlyBudget from './IReadYearlyBudget';
import { useAccessTokens } from 'Features/Authentication';

const useGetYearlyBudget = () => {

    const { t } = useTranslation();
    const getItem = useGetItem();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const [ yearlyBudget, setYearlyBudget ] = useState<IReadYearlyBudget>({id: "", year: 0, budget: 0});
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

    /** Read yearly budget by guid
     * @param {string} id - guid of the yearly budget
     * @returns {object} yearlyBudget - yearly budget object
     **/
    const getYearlyBudgetById = async (id: string) => {

        //if (!id) throw Error("Id is mandatory when fetching YearlyBudget by id");

        const basePath = apiConfig.basePath;
        const endpoint = apiConfig.endpoints.yearlyBudgets;
        const url = new URL(`${basePath}/${endpoint}/${id}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken
        }

        try {

            const response = await getItem(url, options);
            const data = response?.data || [];
            setYearlyBudget(data);
            return responseHandler(response, "useGetYearlyBudget", { operation: "read", hide: true });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "General.RequestorNotExist") body = t('features.YearlyBudget.requestorNotExist');
            if (problemTitle === "YearlyBudgetErrors.ReadPermissionDenied") body = t('features.YearlyBudget.readPermissionDenied');
            if (problemTitle === "YearlyBudgetErrors.NotExist") body = t('features.YearlyBudget.notExist');

            return errorHandler(error, "useGetYearlyBudget", { operation: "read", hide: false, messageBody: body });

        }

    }

    return { yearlyBudget, setYearlyBudget, getYearlyBudgetById };

}

export default useGetYearlyBudget;

