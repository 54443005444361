import { usePutItem } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import apiConfig from 'Application/configurations/apiConfig';
import IUpdateAccount from "./IUpdateAccount";
import { useAccessTokens } from 'Features/Authentication';
import { useTranslation } from 'react-i18next';

function useUpdateAccount () {

    const { t } = useTranslation();
    const putItem = usePutItem();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

    /** Create new account
     * @param {IUpdateAccount} account - object which describes the created account
     **/
    const updateAccount = async (companyId: string = "", accountId: string="", account: IUpdateAccount) => {

        const basePath = apiConfig.basePath;
        const companyEndpoint = apiConfig.endpoints.companies
        const accountEndpoint = apiConfig.endpoints.accounts;
        const url = new URL(`${basePath}/${companyEndpoint}/${companyId}/${accountEndpoint}/${accountId}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken,
            body: account
        }

        try {

            const response = await putItem(url, options);
         
            return responseHandler(response, "useUpdateAccount", { operation: "update", messageHeader: t("features.account.savedSuccessfully"), hide: false });


        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "General.RequestorNotExist") body = t('features.account.requestorNotExist');
            if (problemTitle === "UserAccount.NotExist") body = t('features.account.userAccountNotExist');
            if (problemTitle === "UserAccount.UserDataAccessDenied") body = t('features.account.userDataAccessDenied');
            if (problemTitle === "UserAccount.AlreadyExist") body = t('features.account.userAccountAlreadyExist');
            if (problemTitle === "Company.NotExist") body = t('features.account.companyNotExist');
            if (problemTitle === "UserAccount.UpdatePermissionDenied") body = t('features.account.updatePermissionDenied');
            if (problemTitle === "Role.NotExist") body = t('features.account.roleNotExist');
            if (problemTitle === "Role.AlreadyExist") body = t('features.account.roleAlreadyExist');
            if (problemTitle === "UserRole.NotExist") body = t('features.account.userRoleNotExist');

            return errorHandler(error, "useUpdateAccount", { operation: "update", hide: false, messageBody: body });

        }

    }

    return { updateAccount }

}

export default useUpdateAccount;
