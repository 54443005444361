import React from 'react';
import { Pagination } from 'semantic-ui-react';
import IPagedTablePaginationProps from "../interfaces/IPagedTablePaginationProps";

/** Pagination
 * @param {number} activePage - which page is currently active?
 * @param {array<object>} content - Table content as array of objects 
 * @param {number} pageSize - how many items per page?
 * @param {number} setActivePage - React Set State method to set active page
 * @param {string} [className] - additional class */
const TablePagination: React.FC<IPagedTablePaginationProps> = ({
    content,
    pageSize,
    activePage,
    setActivePage,
    className
}: IPagedTablePaginationProps) => {

    return (
        <div className={className ? 'pagination-container' : 'pagination-container ' + className}>
            <Pagination 
                defaultActivePage={activePage + 1}
                totalPages={Math.ceil(content.length / pageSize)} 
                firstItem={null}
                lastItem={null}
                size='small'
                ellipsisItem={window.innerWidth > 767 ? undefined : null}
                onPageChange={(e, {activePage}) => {
                    let _value = activePage && typeof activePage  === 'number' ? activePage : 0;
                    setActivePage(_value - 1)
                }}
            />
        </div>
    );

}

export default TablePagination;
