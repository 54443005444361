import { useTranslation } from 'react-i18next';
import { Container, Header, Icon } from 'semantic-ui-react';
import ListNewSurveys from 'Features/Survey/ListSurveys/ListNewSurveys';
import { ListSurveys } from 'Features/Survey';
import { Link } from 'react-router-dom';

const Home = () => {

    const { t } = useTranslation();

    return (
        <Container className="page">

            <ListNewSurveys />

            <Header>{t("pages.newestSurveys")}</Header>
            <ListSurveys surveyRef="" showNew={false} limit={10} />
            <Container textAlign="right">
                <Link to="/surveys">{t("Näytä kaikki kyselyvastaukset")}</Link>
                <Icon name="angle right" color="blue"/>
            </Container>

        </Container>
    );
}

export default Home;
