const useCSVImport = () => {

    const fileReader = new FileReader();

    // reads the file, calls the function to fill in the form and clears the file
    const handleCSVImport = (answer:boolean,props:any) => { 
        const { file, setFile, func } = props;
        if (file) {
            fileReader.onload = function (event) {
                const csvOutput = event.target?.result;
                const csvArray = csvDataToArray(csvOutput);
                func(csvArray);
            };
            fileReader.readAsText(props.file);
            setFile(undefined);
        }
    }

    // takes a csv string and returns an array
    const csvDataToArray = (string:any) => {
        const csvHeader = string.slice(0, string.indexOf("\r\n")).split(";");
        const csvRows = string.slice(string.indexOf("\r\n") + 2).split("\r\n");
  
        const array = csvRows.map((i:any) => {
            const values = i.split(";");
            const obj = csvHeader.reduce((object:any, header:any, index:any) => {
                object[header] = values[index];
                return object;
            }, {});
            return obj;
        });

        return array;
    }

    return { handleCSVImport }

}

export default useCSVImport;