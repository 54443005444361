import { PageTitle } from "Components/Titles";
import { Container } from "semantic-ui-react";
import { UpdateUnitPoolCorrection } from "Features/UnitPoolCorrection";
import { useTranslation } from "react-i18next";

function EditUnitPoolCorrection () {

    const { t } = useTranslation();

    return (
        <Container className="page">
            <PageTitle title={t("pages.editUnitPoolCorrection")} />
            <UpdateUnitPoolCorrection />
        </Container>
    );
}

export default EditUnitPoolCorrection;