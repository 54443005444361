import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import useGetBudget from "./useGetBudget";
import useGetCompany from "Features/Company/ReadCompany/useGetCompany";
import { useParams } from "react-router-dom";
import { Header, Segment, Table, Button, Icon } from "semantic-ui-react";
import { ButtonContainer, EditBtn } from "Components/Buttons";
import { StateContext } from "Application/state/StateContext";
import { PageTitle } from "Components/Titles";
import useSurvey from "Features/Survey/useSurvey";
import useUpdateBudget from "../UpdateBudget/useUpdateBudget";
import { YesNoModal } from "Components/Modals";
import { ListCompanySurveyAttachements } from "Features/CompanyAttachement";

function ReadBudget () {

    const { t } = useTranslation();
    const { userProfile } = useContext(StateContext);
    const { companyId, surveyId } = useParams();
    const { budget, getBudgetById } = useGetBudget();
    const { company, getCompanyById } = useGetCompany();
    const [ isLoading, setIsLoading ] = useState(true);
    const { isEditingAllowed } = useSurvey();
    const { updateBudget } = useUpdateBudget();
    const [ lockSurveyModalOpen, setLockSurveyModalOpen ] = useState(false);
    const [ openSurveyModalOpen, setOpenSurveyModalOpen ] = useState(false);
    const [ isLocked, setIsLocked ] = useState(true);
    const [ isOpen, setIsOpen ] = useState(true);
    const [ isEditable, setIsEditable ] = useState(false);
    const [ deadline, setDeadline ] = useState<null | Date>(null);

    const isAdmin = userProfile && userProfile.roles && userProfile.roles.find((item:any) => item.name==="Admin") ? true : false;

    // Fetch survey data
    useEffect(() => {
        (async () => {
            if(companyId || userProfile.companyId){
                await getBudgetById(companyId || userProfile.companyId,surveyId);
            }
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userProfile.companyId]);

    // Fetch company
    useEffect(() => {
        (async () => {
            if(budget.companyId){
                await getCompanyById(budget.companyId);
                setIsEditable(isEditingAllowed(budget.isPermanentlyLocked,budget.openDate,budget.closeDate))
                setIsLocked(budget.isPermanentlyLocked);
                setIsOpen(new Date(budget.closeDate)>new Date());
                let dl = new Date(budget.closeDate);
                dl.setSeconds(dl.getSeconds()-1);
                setDeadline(dl);
                setIsLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[budget]);

    const lockSurvey = async (answer:boolean) => {
        setLockSurveyModalOpen(false);
        setIsLoading(true);

        const budget = {
            isPermanentlyLocked: true
        }
        let result = await updateBudget(companyId || userProfile.companyId,surveyId,budget);
        if (result && result.status === 204) {
            setIsEditable(false);
            setIsLocked(true);
        }
        setIsLoading(false);
        window.scrollTo(0,0);
    }

    const openSurvey = async (answer:boolean) => {
        setOpenSurveyModalOpen(false);
        setIsLoading(true);

        // open survey until the end of next day
        let closedate = new Date()
        closedate = new Date(closedate.getFullYear(),closedate.getMonth(),closedate.getDate()+2);
        const budget = {
            closeDate: closedate
        }
        let result = await updateBudget(companyId || userProfile.companyId,surveyId,budget);
        if (result && result.status === 204) {
            setIsOpen(true);
            closedate.setSeconds(closedate.getSeconds()-1);
            setDeadline(closedate);
        }
        
        setIsLoading(false);
        window.scrollTo(0,0);
    }

    return (
        <>
        <YesNoModal 
            type="save"
            header={t("features.survey.lockSurvey")}
            text={t("features.survey.lockSurveyConfirm")}
            method={lockSurvey}
            open={lockSurveyModalOpen}
            setOpen={setLockSurveyModalOpen}
            item=""
            noClick={false}
            handleClose={true}
            disableYesButton={false}
        />
        <YesNoModal 
            type="save"
            header={t("features.survey.openSurvey")}
            text={t("features.survey.openSurveyConfirm")}
            method={openSurvey}
            open={openSurveyModalOpen}
            setOpen={setOpenSurveyModalOpen}
            item=""
            noClick={false}
            handleClose={true}
            disableYesButton={false}
        />
        <PageTitle title={budget.name!=='' ? budget.name : t("pages.budgetDetail")} />
        <Segment className="invisible" loading={isLoading}>
            {budget.id ?
                <>
                {isAdmin &&
                    <>
                    <Header sub>{t("features.budget.deadline")}</Header>
                    <div>{deadline?.toLocaleDateString("fi-FI")}</div>
                    </>
                }
                {companyId &&
                    <>
                    <Header sub>{t("features.budget.company")}</Header>
                    <div>{company && company.name}</div>
                    </>
                }

                <div className="table-wrapper">
                <Table>
                {budget.categories.map((cat:any) => 
                    <Table.Body key={"cat" + cat.id}>
                        <Table.Row>
                            <Table.HeaderCell>{cat.name}</Table.HeaderCell>
                            <Table.HeaderCell>{t("features.budget.procurement")}, {t("features.budget.pcs")}</Table.HeaderCell>
                            {isAdmin && <Table.HeaderCell>{t("features.budget.finalBudget")}, {t("features.budget.pcs")}</Table.HeaderCell>}
                        </Table.Row>
                        {cat.units.map((unit:any) =>
                            <Table.Row key={"unit" + unit.itemId}>
                                <Table.Cell>{unit.unitName}</Table.Cell>
                                <Table.Cell>{unit.amount}</Table.Cell>
                                {isAdmin && <Table.Cell>{unit.finalBudget}</Table.Cell>}
                            </Table.Row>
                        )}
                    </Table.Body>
                )}
                </Table>
                </div>

                <ListCompanySurveyAttachements 
                    companyId={userProfile.companyId}
                    surveyId={surveyId}
                    surveyTypeRef={"budget"}
                />

                <ButtonContainer>
                    <EditBtn to={"edit"} disabled={!isEditable} />
                    {isAdmin &&
                        <>
                        <Button
                            icon
                            className='extended-button lock-button'
                            labelPosition="left"
                            onClick={() => setLockSurveyModalOpen(true) }
                            disabled={isLocked}
                        >
                            <Icon name={'lock'} />
                            {t("features.survey.lock")}
                        </Button>
                        <Button
                            icon
                            className='extended-button date-button'
                            labelPosition="left"
                            onClick={() => setOpenSurveyModalOpen(true) }
                            disabled={isLocked || isOpen}
                        >
                            <Icon name={'calendar'} />
                            {t("features.survey.openSurvey")}
                        </Button>
                        </>
                    }
                </ButtonContainer>
                </>
            :
                <p>{t("features.survey.notFound")}</p>
            }

        </Segment>
       
        </>
    );
}

export default ReadBudget;
