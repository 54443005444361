import { useTranslation } from "react-i18next";

const useInputValidation = () => {

    const { t} = useTranslation();

    const isInteger = (value:number | null) => {
        if(value===null) return true;
        const n = Math.floor(value);
        return n !== Infinity && n === value;
    }

    const isNonNegativeInteger = (value:number | null) => {
        if(value===null) return true;
        const n = Math.floor(value);
        return n !== Infinity && n === value && n >= 0;
    }

    const isFloat = (value:number | null, decimalCount?:number) => {
        if(value===null) return true;
        let n = value;
        if(decimalCount){
            n = Number(n.toFixed(decimalCount));
        }
        return n !== Infinity && n === value;
    }

    const isNonNegativeFloat = (value:number | null, decimalCount?:number) => {
        if(value===null) return true;
        let n = value;
        if(decimalCount){
            n = Number(n.toFixed(decimalCount));
        }
        return n !== Infinity && n === value && n >= 0;
    }

    const isValidMonth = (value:number | null) => {
        if(value===null) {
            return true;
        } else if(value<1 || value>12 || !isNonNegativeInteger(value)){
            return false;
        } else {
            return true;
        }
    }

    const isValidYear = (value:number | null, minYear?:number, maxYear?:number) => {
        if(value===null) {
            return true;
        } else if(value<(minYear || 1900) || value>(maxYear || 2100) || !isNonNegativeInteger(value)){
            return false;
        } else {
            return true;
        }
    }

    const validateInput = (value:number,validateFor?:string) => {
        if(validateFor==="integer" && !isInteger(value)){
            return t("components.inputWithUnit.insertIntegerValue");
        }
        if(validateFor==="nonNegativeInteger" && !isNonNegativeInteger(value)) {
            return t("components.inputWithUnit.insertNonNegativeIntegerValue");
        }
        if(validateFor==="float" && !isFloat(value)){
            return t("components.inputWithUnit.insertFloatValue");
        }
        if(validateFor==="nonNegativeFloat" && !isNonNegativeFloat(value)) {
            return t("components.inputWithUnit.insertNonNegativeFloatValue");
        }
        if(validateFor==="money" && !isFloat(value,2)){
            return t("components.inputWithUnit.insertFloatValueWithDecimalCount").replace("$prm$","2");
        }
        if(validateFor==="nonNegativeMoney" && !isNonNegativeFloat(value,2)) {
            return t("components.inputWithUnit.insertNonNegativeFloatValueWithDecimalCount").replace("$prm$","2");
        }
        return false;
    }

    return  { validateInput, isInteger, isNonNegativeInteger, isFloat, isNonNegativeFloat, isValidMonth, isValidYear };
}

export default useInputValidation;