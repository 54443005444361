import { useState } from "react";
import { useGetItems } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import { useTranslation } from 'react-i18next';
import apiConfig from 'Application/configurations/apiConfig';
import IListUnitCategory from "./IListUnitCategory";
import { useAccessTokens } from 'Features/Authentication';

function useGetUnitCategories () {

    const { t } = useTranslation();
    const [ unitCategories, setUnitCategories ] = useState<IListUnitCategory[]>([]);
    const getItems = useGetItems();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;
    

   /** List all unit categories
     * @returns {Array<IListUnit>} unit categories */
    const listUnitCategories = async () => {

        const basePath = apiConfig.basePath;
        const endpoint = apiConfig.endpoints.unitCategories;
        const url = new URL(`${basePath}/${endpoint}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken
        }
        
        try {

            const response = await getItems(url, options);
            const data = response?.data || [];
            setUnitCategories(data);
            return responseHandler(response, "useGetUnitCategories", { operation: "list", hide: true });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "General.RequestorNotExist") body = t('features.unitCategory.requestorNotExist');
            if (problemTitle === "Unit.ListPermissionDenied") body = t('features.unitCategory.listPermissionDenied');

            return errorHandler(error, "useGetUnitCategories", { operation: "list", hide: false, messageBody: body });
   
        }

    }

    return { unitCategories, setUnitCategories, listUnitCategories };

}

export default useGetUnitCategories;