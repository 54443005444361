import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageContext } from 'Features/Messages';
import { useLogMessage } from 'Features/HTTP/ResponseHandler';
import IErrorHandler from '../interfaces/IErrorHandler';
import { Message } from '../interfaces/types';
import { LoggerContext } from 'Features/Logging';

const useErrorHandler = () => {

    const { t } = useTranslation();
    const logMessage = useLogMessage();
    const { addMessage } = useContext(MessageContext);
    const {logException} = useContext(LoggerContext);

    /** Handle error responses
     * @description Handles error messages f.e Log to Console, Show error message to user
     *              Error logging to external logging system is also possible to do here 
     * @param {object} error - http response error object
     * @param {string} caller - name of the calling hook f.e. usePostUser
     * @param {Operation} [options.operation] - optional operation for default message
     * @param {boolean} [options.hide] - Optional. if true, page message is not added, default is false
     * @param {string} [options.messageHeader] - Optional message header (overwrites the default page message)
     * @param {string} [options.messageBody] - Optional message header (overwrites the default page message)
     **/
    const errorHandler = async (error: any, caller: string, options: IErrorHandler) => {

        let operation = options?.operation || null;
        let hide = options?.hide || false;
        let messageHeader = options?.messageHeader || null;
        let messageBody = options?.messageBody || null;
        let message: Message = { type:"error", header: "", body: "" };
        let status = error?.status;
        let data = error?.data;

        const messages = {

            // HTTP Errors
            badRequest400: { type:"error", header:t('features.errorHandler.error'), body:t('features.errorHandler.badRequest400') },
            unauthorized401: { type:"error", header:t('features.errorHandler.error'), body:t('features.errorHandler.unauthorized401') },
            forbidden403: { type:"error", header:t('features.errorHandler.error'), body:t('features.errorHandler.forbidden403') },
            notFound404: { type:"error", header:t('features.errorHandler.error'), body:t('features.errorHandler.notFound404') },
            internalServerError500: { type:"error", header:t('features.errorHandler.error'), body:t('features.errorHandler.internalServerError500') },

            // Database errors
            objectDoesNotExist: { type:"error", header:t('features.errorHandler.error'), body:t('features.errorHandler.itemDoesNotExist') },
            objectAlreadyExist: { type:"error", header:t('features.errorHandler.error'), body:t('features.errorHandler.itemAlreadyExist') },

            list: { type:"error", header:t('features.errorHandler.error'), body: t('features.errorHandler.list') },
            create: { type:"error", header:t('features.errorHandler.error'), body: t('features.errorHandler.create') },
            read: { type:"error", header:t('features.errorHandler.error'), body: ('features.errorHandler.read') },
            update: { type:"error", header:t('features.errorHandler.error'), body: t('features.errorHandler.update') },
            delete: { type:"error", header:t('features.errorHandler.error'), body: t('features.errorHandler.delete') }

        }
        
        // Set default message if operation is defined
        if (operation) message = messages[operation];
        
        // Set message by status - overwrites operation
        if (status) {
            switch (status) {
                case 400:
                    message = messages["badRequest400"];
                    break;
                case 401:
                    message = messages["unauthorized401"];
                    break;
                case 403:
                    message = messages["forbidden403"];
                    break;
                case 404:
                    message = messages["notFound404"];
                    break;
                case 500:
                    message = messages["internalServerError500"];
                    break;
                default:
                    // Do nothing
                    break;
            }
        }

        // Set message header if header is defined - overwrites default header
        if (messageHeader && typeof messageHeader === 'string' && messageHeader.length > 0) {
            message.header = messageHeader;
        }

        // Change message body if body is defined - overwrites default header
        if (messageBody && typeof messageBody === 'string' && messageBody.length > 0) {
            message.body = messageBody;
        }
        
        // Special cases which overwrites message
        if (data && data.errorCode) {           
            if (data.errorCode === -1) message = messages["objectDoesNotExist"];
            if (data.errorCode === -2) message = messages["objectAlreadyExist"];
        }

        // Set hide message to true when login is in progress
        if (error === "login_required") {
            hide = true;
        }

        // Show or hide message
        if (hide !== true && message.type && message.header) {
            await addMessage(message);
        }

        // Log message to console
        logMessage(error, caller, hide, messageHeader, messageBody);

        // Log to monitoring system
        if (logException) {
            logException(error, {
                apiOperation: operation,
                hideMessage: hide,
                messageHeader: messageHeader,
                messageBody: messageBody,
                messageObj: message,
                responseStatus: status,
                responsedata: data
            });
        }

        return error;

    }

    return errorHandler;

}

export default useErrorHandler;
