import { useState } from "react";
import { useGetItems } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import { useTranslation } from 'react-i18next';
import apiConfig from 'Application/configurations/apiConfig';
import IListCompanySurvey from "./IListCompanySurvey";
import { useAccessTokens } from 'Features/Authentication';

function useGetCompanySurveys () {

    const { t } = useTranslation();
    const [ companySurveys, setCompanySurveys ] = useState<IListCompanySurvey[]>([]);
    const getItems = useGetItems();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;
    

   /** List all company surveys
     * @returns {Array<IListCompanySurvey>} company surveys */
    const getCompanySurveys = async (companyId:string) => {

        const basePath = apiConfig.basePath;
        const companyEndpoint = apiConfig.endpoints.companies;
        const surveyTypeEndpoint = apiConfig.endpoints.surveytypes;
        const url = new URL(`${basePath}/${companyEndpoint}/${companyId}/${surveyTypeEndpoint}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken
        }
        
        try {

            const response = await getItems(url, options);
            const data = response?.data || [];
            setCompanySurveys(data);
            return responseHandler(response, "useGetCompanyCompanySurveys", { operation: "list", hide: true });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "General.RequestorNotExist") body = t('features.companySurveys.requestorNotExist');
            if (problemTitle === "CompanySurveys.ListPermissionDenied") body = t('features.companySurveys.listPermissionDenied');
            if (problemTitle === "CompanySurveys.CompanySurveysDataAccessDenied") body = t('features.companySurveys.companySurveyDataAccessDenied');

            return errorHandler(error, "useGetCompanyCompanySurveys", { operation: "list", hide: false, messageBody: body });
   
        }

    }

    return { companySurveys, setCompanySurveys, getCompanySurveys };

}

export default useGetCompanySurveys;