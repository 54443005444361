import { ReadGlassBottleReturnRate } from "Features/Survey";
import { Container } from "semantic-ui-react";

function ViewGlassBottleReturnRate () {

    return (
        <Container className="page">

            <ReadGlassBottleReturnRate />

        </Container>
    );
}

export default ViewGlassBottleReturnRate;