import { PageTitle } from "Components/Titles";
import { Container } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { ListUnitPoolCorrections } from "Features/UnitPoolCorrection";
import { ButtonContainer, NewBtn } from "Components/Buttons";
import { useParams } from "react-router-dom";

function UnitPoolCorrectionList () {

    const { t } = useTranslation();
    const { id } = useParams();

    return (
        <Container className="page">
            <PageTitle
                title={t("pages.unitPoolCorrections")}
                button={
                    <ButtonContainer>
                        <NewBtn to={`/management/units/${id}/unitpoolcorrections/new`} />
                    </ButtonContainer>
                }
            />
            <ListUnitPoolCorrections />
        </Container>
    );
}

export default UnitPoolCorrectionList;