import { useTranslation } from 'react-i18next';
import { Container } from 'semantic-ui-react';
import { GetAllAnnouncements } from 'Features/Announcements';
import { PageTitle } from 'Components/Titles';
import { NewBtn } from 'Components/Buttons';

const Announcements = () => {

    const { t } = useTranslation();

    return (
        <Container className="page">

            <PageTitle
                title={t('pages.announcements')}
                button={<NewBtn to="/management/announcements/new" />}
            />

            <GetAllAnnouncements/>

        </Container>
    );
}

export default Announcements;
