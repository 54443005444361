import React from 'react';
import { CSVDownload } from 'react-csv';
import IGenerateCSVDownload from './IGenerateCSVDownload';
// import './../styles/csv.css';

/** CSV Download - Download CSV from array of arrays or array of objects
 * @param {object} data - object to be converted to CSV
 * @link https://www.npmjs.com/package/react-csv
 * @link https://www.npmjs.com/package/json2csv
 **/
const GenerateCSVDownload: React.FC<IGenerateCSVDownload> = ({data}: IGenerateCSVDownload) => {

    return (
        <CSVDownload data={data} target="_blank" />
    );

};

export default GenerateCSVDownload;
