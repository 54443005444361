import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import IViewIconBtnProps from '../interfaces/IViewIconBtnProps';
import './../styles/button.css';

/** Form View Icon Button
 * @description Used to open "view item" form.
 * @param {string} to - url to the form where user want to enter
 * @param {boolean} [hide] - hide the button
 * @link https://react.semantic-ui.com/elements/button/
 **/
const ViewIconBtn: React.FC<IViewIconBtnProps> = (props: IViewIconBtnProps) => {

    const navigate = useNavigate();

    const { to, hidden=false, color, type, ...rest } = props;

    if (hidden) return null;

    return (
        <Button
            icon={<Icon name={'arrow right'} />}
            color={color ? color : undefined}
            type={type ? type : 'reset'}
            onClick={() => navigate(to) }
            {...rest}
        />
    );

};

export default ViewIconBtn;
