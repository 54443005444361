import React from 'react';
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import IGenerateCSVLink from './IGenerateCSVLink';
// import './../styles/csv.css';

/** CSV Button - Generate CSV from array of arrays or array of objects
 * @param {string} title - Button title
 * @param {object} data - object to be converted to CSV
 * @param {string} delimiter - CSV delimiter f.e. ';'
 * @param {string} filename - object to be converted to CSV
 * @link https://www.npmjs.com/package/react-csv
 * @link https://www.npmjs.com/package/json2csv
 **/
const GenerateCSVLink: React.FC<IGenerateCSVLink> = ({data, title, delimiter = ";", filename = "export.csv"}: IGenerateCSVLink) => {
    
    const { t } = useTranslation();

    // Return text if data is not defined
    if (!data || !data.length) return <b>{title || t('components.csv.generateCSV')}</b>

    return (
        <CSVLink
            data={data}
            separator={delimiter}
            filename={filename}
            target="_blank"
        >
            {title || t('components.csv.generateCSV')}
        </CSVLink>
    );

};

export default GenerateCSVLink;
