import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import useGetStorageCost from "./useGetStorageCost";
import useGetCompany from "Features/Company/ReadCompany/useGetCompany";
import { useParams } from "react-router-dom";
import { Header, Segment, Button, Icon } from "semantic-ui-react";
import { ButtonContainer, EditBtn } from "Components/Buttons";
import { StateContext } from "Application/state/StateContext";
import { PageTitle } from "Components/Titles";
import useSurvey from "Features/Survey/useSurvey";
import useUpdateStorageCost from "../UpdateStorageCost/useUpdateStorageCost";
import { YesNoModal } from "Components/Modals";
import { ListCompanySurveyAttachements } from "Features/CompanyAttachement";

function ReadStorageCost () {

    const { t } = useTranslation();
    const { userProfile } = useContext(StateContext);
    const { companyId, surveyId } = useParams();
    const { storageCost, getStorageCostById } = useGetStorageCost();
    const { company, getCompanyById } = useGetCompany();
    const [ isLoading, setIsLoading ] = useState(true);
    const { isEditingAllowed } = useSurvey();
    const { updateStorageCost } = useUpdateStorageCost();
    const [ lockSurveyModalOpen, setLockSurveyModalOpen ] = useState(false);
    const [ openSurveyModalOpen, setOpenSurveyModalOpen ] = useState(false);
    const [ isLocked, setIsLocked ] = useState(true);
    const [ isOpen, setIsOpen ] = useState(true);
    const [ isEditable, setIsEditable ] = useState(false);
    const [ deadline, setDeadline ] = useState<null | Date>(null);

    const isAdmin = userProfile && userProfile.roles && userProfile.roles.find((item:any) => item.name==="Admin") ? true : false;

    // Fetch storage cost
    useEffect(() => {
        (async () => {
            if(companyId || userProfile.companyId){
                await getStorageCostById(companyId || userProfile.companyId,surveyId);
            }
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userProfile.companyId]);

    // Fetch company
    useEffect(() => {
        (async () => {
            if(storageCost.companyId){
                await getCompanyById(storageCost.companyId);
                setIsEditable(isEditingAllowed(storageCost.isPermanentlyLocked,storageCost.openDate,storageCost.closeDate))
                setIsLocked(storageCost.isPermanentlyLocked);
                setIsOpen(new Date(storageCost.closeDate)>new Date());
                let dl = new Date(storageCost.closeDate);
                dl.setSeconds(dl.getSeconds()-1);
                setDeadline(dl);
                setIsLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[storageCost]);

    const lockSurvey = async (answer:boolean) => {
        setLockSurveyModalOpen(false);
        setIsLoading(true);

        const storageCost = {
            isPermanentlyLocked: true
        }
        let result = await updateStorageCost(companyId || userProfile.companyId,surveyId,storageCost);
        if (result && result.status === 204) {
            setIsEditable(false);
            setIsLocked(true);
        }
        setIsLoading(false);
        window.scrollTo(0,0);
    }

    const openSurvey = async (answer:boolean) => {
        setOpenSurveyModalOpen(false);
        setIsLoading(true);

        // open survey until the end of next day
        let closedate = new Date()
        closedate = new Date(closedate.getFullYear(),closedate.getMonth(),closedate.getDate()+2);
        const storageCost = {
            closeDate: closedate
        }
        let result = await updateStorageCost(companyId || userProfile.companyId,surveyId,storageCost);
        if (result && result.status === 204) {
            setIsOpen(true);
            closedate.setSeconds(closedate.getSeconds()-1);
            setDeadline(closedate);
        }
        
        setIsLoading(false);
        window.scrollTo(0,0);
    }

    return (
        <>
        <YesNoModal 
            type="save"
            header={t("features.survey.lockSurvey")}
            text={t("features.survey.lockSurveyConfirm")}
            method={lockSurvey}
            open={lockSurveyModalOpen}
            setOpen={setLockSurveyModalOpen}
            item=""
            noClick={false}
            handleClose={true}
            disableYesButton={false}
        />
        <YesNoModal 
            type="save"
            header={t("features.survey.openSurvey")}
            text={t("features.survey.openSurveyConfirm")}
            method={openSurvey}
            open={openSurveyModalOpen}
            setOpen={setOpenSurveyModalOpen}
            item=""
            noClick={false}
            handleClose={true}
            disableYesButton={false}
        />
        <PageTitle title={storageCost.name!=='' ? storageCost.name : t("pages.storageCostDetail")} />
        <Segment className="invisible" loading={isLoading}>
            {storageCost.id ?
                <>
                {isAdmin &&
                    <>
                    <Header sub>{t("features.budget.deadline")}</Header>
                    <div>{deadline?.toLocaleDateString("fi-FI")}</div>
                    </>
                }
                {companyId &&
                    <>
                    <Header sub>{t("features.storageCost.company")}</Header>
                    <div>{company && company.name}</div>
                    </>
                }

                <Header sub>{t("features.storageCost.storageCost")}</Header>
                <div>{storageCost && storageCost.storageCost?.toFixed(2)} {t("features.storageCost.eurosPerDay")}</div>

                <Header sub>{t("features.storageCost.handlingCost")}</Header>
                <div>{storageCost && storageCost.handlingCost?.toFixed(2)} {t("features.storageCost.euros")}</div>

                <ListCompanySurveyAttachements 
                    companyId={userProfile.companyId}
                    surveyId={surveyId}
                    surveyTypeRef={"storagecost"}
                />

                <ButtonContainer>
                    <EditBtn to={"edit"} disabled={!isEditable} />
                    {isAdmin &&
                        <>
                        <Button
                            icon
                            className='extended-button lock-button'
                            labelPosition="left"
                            onClick={() => setLockSurveyModalOpen(true) }
                            disabled={isLocked}
                        >
                            <Icon name={'lock'} />
                            {t("features.survey.lock")}
                        </Button>
                        <Button
                            icon
                            className='extended-button date-button'
                            labelPosition="left"
                            onClick={() => setOpenSurveyModalOpen(true) }
                            disabled={isLocked || isOpen}
                        >
                            <Icon name={'calendar'} />
                            {t("features.survey.openSurvey")}
                        </Button>
                        </>
                    }
                </ButtonContainer>
                </>
            :
                <p>{t("features.survey.notFound")}</p>
            }

        </Segment>
        </>
    );
}

export default ReadStorageCost;
