import { Container } from 'semantic-ui-react';
import { PageTitle } from 'Components/Titles';
import { ListCompanySurveys } from 'Features/CompanySurvey';

import { useTranslation } from "react-i18next";

function CompanySurveyList () {

    const { t } = useTranslation();

    return (
        <Container className='page'>
        
            <PageTitle title={t("pages.companySurveys")} />

            <ListCompanySurveys />

        </Container>
    );
}

export default CompanySurveyList;