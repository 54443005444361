import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Button } from 'semantic-ui-react';
import { PageTitle } from 'Components/Titles';
import { Loading } from 'Components/Loaders';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import apiConfig from 'Application/configurations/apiConfig';
import { useAccessTokens } from 'Features/Authentication';
import { useGetItems } from 'Features/HTTP/RequestHandlers';
import IAnnouncement from './../../Features/Announcements/GetAnnouncementById/IAnnouncement';

const ApiAccessTokens = () => {

    const { t } = useTranslation();
    const [ isLoading, setIsLoading ] = useState(false);
    const { callApiWithBothtokens, graphApiAccessToken, getGraphApiAccessToken, accessToken, getAccessToken } = useGetApiAccessToken();

    useEffect(() => {
        (async() => {
            setIsLoading(true);
            getAccessToken();
            getGraphApiAccessToken();
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    var codeString = `
        // Tee API-kutsu rajapinnalle ja lähetä molemmat tokenit otsikoissa
        fetch("https://your-api-endpoint.com/data", {
            method: "GET",
            headers: {
                "Authorization": "Bearer <apiAccessToken>",  // Omaa API:ta varten
                "GraphToken": "Bearer <graphAccessToken>"    // Microsoft Graph API:ta varten
            }
        });
    `;

    //console.log("Api application uri?");
    //console.log(process.env.REACT_APP_WEB_API_APPLICATION_ID_URI);
    //console.log();

    //console.log("Access as user api token");
    //console.log(apiConfig);
    //console.log();

    console.log("Access as user api token");
    console.log(accessToken);
    console.log();

    console.log("Graph api token with scopes");
    console.log(graphApiAccessToken);
    //console.log();

    //console.log("Announcements");
    //console.log(announcements);
    //console.log();

    return (
        <Container className="page">

            <Loading active={isLoading} />

            <PageTitle title={t('pages.apiAccessTokens')}/>

            <h4>"AccessAsUser" API access token"</h4>
            <p>{accessToken}</p>
            <p>Katso myös console!</p>
           
            <h4>Graph API access seuraavilla scopeilla "Files.ReadWrite.All" and "Sites.Read.All"</h4>
            <p>{graphApiAccessToken}</p>
            <p>Katso myös console!</p>

            <p>Huomaa että oikeudet pitää olla määäritetty rajapinnan application registrationissa</p>

            <h4>"AccessAsUser" API access token"</h4>
            <Button primary onClick={()=> { callApiWithBothtokens(); console.log("See reaquest header!!!") }}>Kutsu rajapintaa (katso reaquest header!)</Button>

            <br/><br/>

            <p>See Microsoft Authentication Library for React (msal-react) <a href="https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/README.md">documentation</a> for more information.</p>
            <p>See App registration <a href="https://learn.microsoft.com/fi-fi/entra/identity-platform/scenario-spa-app-registration">documentation</a></p>
            <p>See Graph Api <a href="https://learn.microsoft.com/en-us/graph/api/overview?view=graph-rest-1.0">documentation</a></p>
            
            <p>Tokenit pitää hakea rajapintaan erikseen! Voit liittää ne kutsuun esin näin</p>
            <pre>
                <code>
                    {codeString}
                </code>
            </pre>


        </Container>
    );
}

export default ApiAccessTokens;

const useGetApiAccessToken = () => {

    const { t } = useTranslation();
    const [ accessToken, setAccessToken ] = useState("");
    const [ graphApiAccessToken, setGraphApiAccessToken ] = useState("");
    const [ announcements, setAnnouncements ] = useState<IAnnouncement[]>([]);

    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const getToken = useAccessTokens();
    const getItems = useGetItems();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;
    const FilesReadWriteAll = apiConfig.scopes.GraphApi.FilesReadWriteAll;
    const SitesReadAll = apiConfig.scopes.GraphApi.SitesReadAll;
    
    console.log("AccessAsUser");
    console.log(AccessAsUser);

    // Get Api token
    const getAccessToken = async () => {
        const accessToken = await getToken([AccessAsUser]);
        try {

            setAccessToken(accessToken);
            return responseHandler(accessToken, "useGetApiAccessToken", { operation: "list", hide: true });
        
        } catch (error: any) {

            return errorHandler(error, "useGetApiAccessToken", { operation: "read", hide: false, messageBody: accessToken });

        }
    }

    // Get Graph api tokens
    const getGraphApiAccessToken = async () => {

        const accessToken = await getToken([FilesReadWriteAll, SitesReadAll]);

        try {

            setGraphApiAccessToken(accessToken);
            return responseHandler(accessToken, "useGetApiAccessToken", { operation: "list", hide: true });
        
        } catch (error: any) {

            return errorHandler(error, "useGetApiAccessToken", { operation: "read", hide: false, messageBody: accessToken });

        }
    }

    // Call api and include graph api token!
    const callApiWithBothtokens = async () => {

        const basePath = apiConfig.basePath;
        const endpoint = apiConfig.endpoints.announcements;

        let url = new URL(`${basePath}/${endpoint}`);

        // Get both tokens !!!
        const accessToken = await getToken([AccessAsUser]);
        const graphApiAccessToken = await getToken([FilesReadWriteAll, SitesReadAll]);

        const options = {
            accessToken: accessToken,
            graphToken: graphApiAccessToken
        }

        const response = await getItems(url, options);

        try {

            
            const data = response?.data || [];
            setAnnouncements(data);
            return responseHandler(response, "useListAnnouncements", { operation: "list", hide: true });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "General.RequestorNotExist") body = t('features.announcements.requestorNotExist');
            if (problemTitle === "Announcement.ListPermissionDenied") body = t('features.announcements.listPermissionDenied');

            return errorHandler(error, "useListAnnouncements", { operation: "list", hide: false, messageBody: body });
            
        }

    }

    return { announcements, callApiWithBothtokens, graphApiAccessToken, getGraphApiAccessToken, accessToken, getAccessToken };

}