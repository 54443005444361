import './../styles/error.css';
import config from 'Application/configurations/appConfig';

function ErrorComponent({error}: any) {

    return <div className='authentication-error'>
        <h1>An Error Occurred</h1>
        <p>Contanct System Administrator</p>

        {/* DEVELOPMENT MESSAGES */}
        {config && config.environment && (config.environment === 'development' || config.environment === "test") &&
            <>
                <h2>Error details</h2>
                <div className='error-details'>
                    
                    {/* Standard */}
                    {error.name && 
                        <>
                            <span><b>Name:</b> {error.name.toString()}</span><br/>
                        </>
                    }
                    {error.message && 
                        <>
                            <span><b>Message:</b> {error.message.toString()}</span><br/>
                        </>
                    }

                    {/* NON-Standard */}
                    {error.columnNumber && 
                        <>
                            <span><b>Column number:</b> {error.columnNumber.toString()}</span><br/>
                        </>
                    }
                    {error.displayName && 
                        <>
                            <span><b>Display name:</b> {error.displayName.toString()}</span><br/>
                        </>
                    }
                    {error.description && 
                        <>
                            <span><b>Description:</b> {error.description.toString()}</span><br/>
                        </>
                    }                
                    {error.fileName && 
                        <>
                            <span><b>File name:</b> {error.fileName.toString()}</span><br/>
                        </>
                    }                
                    {error.lineNumber && 
                        <>
                            <span><b>Line number:</b> {error.lineNumber.toString()}</span><br/>
                        </>
                    }                
                    {error.number && 
                        <>
                            <span><b>Number:</b> {error.number.toString()}</span><br/>
                        </>
                    }
                    {error.stack && 
                        <>
                            <br/>
                            <p><b>Stack trace:</b></p>
                            <p>{error.stack.toString()}</p>
                        </>
                    }
                </div>
            </>
        }

    </div>
    
}

export default ErrorComponent;
