const configuration = 
    [
        {
            "categoryId": "16B17AD4-51DC-EE11-85F7-6045BDE9B1D5",
            "categoryName": "Dollyt",
            "unitId": "498D884A-5EEB-EE11-AAF0-6045BDE9BD60",
            "unitName": "Juomadolly",
            "showEmpty": true,
            "showInUseFull" : true,
            "showForSale": true,
            "showProcurement": true,
            "showRepair": true,
            "showScrapping": true,
            "showSold": false
        },
        {
            "categoryId": "16B17AD4-51DC-EE11-85F7-6045BDE9B1D5",
            "categoryName": "Dollyt",
            "unitId": "4A8D884A-5EEB-EE11-AAF0-6045BDE9BD60",
            "unitName": "Minidolly",
            "showEmpty": true,
            "showInUseFull" : true,
            "showForSale": true,
            "showProcurement": true,
            "showRepair": true,
            "showScrapping": true,
            "showSold": false
        },
        {
            "categoryId": "17B17AD4-51DC-EE11-85F7-6045BDE9B1D5",
            "categoryName": "Lavat",
            "unitId": "4B8D884A-5EEB-EE11-AAF0-6045BDE9BD60",
            "unitName": "EUR-PAN lava",
            "showEmpty": true,
            "showInUseFull" : true,
            "showForSale": true,
            "showProcurement": true,
            "showRepair": true,
            "showScrapping": true,
            "showSold": true
        },
        {
            "categoryId": "17B17AD4-51DC-EE11-85F7-6045BDE9B1D5",
            "categoryName": "Lavat",
            "unitId": "4C8D884A-5EEB-EE11-AAF0-6045BDE9BD60",
            "unitName": "Adapterilava",
            "showEmpty": true,
            "showInUseFull" : true,
            "showForSale": true,
            "showProcurement": true,
            "showRepair": true,
            "showScrapping": true,
            "showSold": false
        },
        {
            "categoryId": "17B17AD4-51DC-EE11-85F7-6045BDE9B1D5",
            "categoryName": "Lavat",
            "unitId": "4D8D884A-5EEB-EE11-AAF0-6045BDE9BD60",
            "unitName": "PAN-lava",
            "showEmpty": true,
            "showInUseFull" : true,
            "showForSale": true,
            "showProcurement": true,
            "showRepair": true,
            "showScrapping": true,
            "showSold": false
        },
        {
            "categoryId": "17B17AD4-51DC-EE11-85F7-6045BDE9B1D5",
            "categoryName": "Lavat",
            "unitId": "4E8D884A-5EEB-EE11-AAF0-6045BDE9BD60",
            "unitName": "1/2 PAN lava",
            "showEmpty": true,
            "showInUseFull" : true,
            "showForSale": true,
            "showProcurement": true,
            "showRepair": true,
            "showScrapping": true,
            "showSold": false
        },
        {
            "categoryId": "18B17AD4-51DC-EE11-85F7-6045BDE9B1D5",
            "categoryName": "Kennolevyt",
            "unitId": "4F8D884A-5EEB-EE11-AAF0-6045BDE9BD60",
            "unitName": "0,5-litran kennolevy",
            "showEmpty": true,
            "showInUseFull" : true,
            "showForSale": true,
            "showProcurement": true,
            "showRepair": false,
            "showScrapping": true,
            "showSold": false
        },
        {
            "categoryId": "18B17AD4-51DC-EE11-85F7-6045BDE9B1D5",
            "categoryName": "Kennolevyt",
            "unitId": "508D884A-5EEB-EE11-AAF0-6045BDE9BD60",
            "unitName": "1,5-litran kennolevy",
            "showEmpty": true,
            "showInUseFull" : true,
            "showForSale": true,
            "showProcurement": true,
            "showRepair": false,
            "showScrapping": true,
            "showSold": false
        },
        {
            "categoryId": "18B17AD4-51DC-EE11-85F7-6045BDE9B1D5",
            "categoryName": "Kennolevyt",
            "unitId": "518D884A-5EEB-EE11-AAF0-6045BDE9BD60",
            "unitName": "2-litran kennolevy",
            "showEmpty": true,
            "showInUseFull" : true,
            "showForSale": true,
            "showProcurement": true,
            "showRepair": false,
            "showScrapping": true,
            "showSold": false
        },
        {
            "categoryId": "18B17AD4-51DC-EE11-85F7-6045BDE9B1D5",
            "categoryName": "Kennolevyt",
            "unitId": "528D884A-5EEB-EE11-AAF0-6045BDE9BD60",
            "unitName": "Tölkkikennolevy",
            "showEmpty": true,
            "showInUseFull" : true,
            "showForSale": true,
            "showProcurement": true,
            "showRepair": false,
            "showScrapping": true,
            "showSold": false
        },
        {
            "categoryId": "18B17AD4-51DC-EE11-85F7-6045BDE9B1D5",
            "categoryName": "Kennolevyt",
            "unitId": "538D884A-5EEB-EE11-AAF0-6045BDE9BD60",
            "unitName": "Viinikenno",
            "showEmpty": true,
            "showInUseFull" : true,
            "showForSale": true,
            "showProcurement": true,
            "showRepair": false,
            "showScrapping": true,
            "showSold": false
        },
        {
            "categoryId": "19B17AD4-51DC-EE11-85F7-6045BDE9B1D5",
            "categoryName": "Lasipulloyksiköt",
            "unitId": "548D884A-5EEB-EE11-AAF0-6045BDE9BD60",
            "unitName": "0,33-litran lasipullo (ruskea)",
            "showEmpty": true,
            "showInUseFull" : false,
            "showForSale": false,
            "showProcurement": true,
            "showRepair": false,
            "showScrapping": true,
            "showSold": false
        },
        {
            "categoryId": "19B17AD4-51DC-EE11-85F7-6045BDE9B1D5",
            "categoryName": "Lasipulloyksiköt",
            "unitId": "558D884A-5EEB-EE11-AAF0-6045BDE9BD60",
            "unitName": "Lasin laatikko",
            "showEmpty": true,
            "showInUseFull" : false,
            "showForSale": false,
            "showProcurement": true,
            "showRepair": false,
            "showScrapping": true,
            "showSold": false
        },
        {
            "categoryId": "19B17AD4-51DC-EE11-85F7-6045BDE9B1D5",
            "categoryName": "Lasipulloyksiköt",
            "unitId": "568D884A-5EEB-EE11-AAF0-6045BDE9BD60",
            "unitName": "Lasin kennolevy",
            "showEmpty": true,
            "showInUseFull" : false,
            "showForSale": false,
            "showProcurement": true,
            "showRepair": false,
            "showScrapping": true,
            "showSold": false
        }
    ];

export default configuration;