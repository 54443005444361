import { useState, useEffect } from "react";
import useCreateUnitPoolCorrection from "./useCreateUnitPoolCorrection";
import { Form } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";
import { ButtonContainer, SaveBtn, CancelBtn } from "Components/Buttons";
import ICreateUnitPoolCorrection from "./ICreateUnitPoolCorrection";
import { useNavigate, useParams } from "react-router-dom";
import { InputWithUnit } from "Components/FormElements";
import DatePicker, { registerLocale } from "react-datepicker";
import fi from "date-fns/locale/fi";
import useGetUnit from "Features/Unit/ReadUnit/useGetUnit";

function CreateUnitPoolCorrection () {

    const { t } = useTranslation();
    registerLocale("fi", fi);
    const { id } = useParams();
    const navigate = useNavigate();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ date, setDate ] = useState(new Date());
    const [ amount, setAmount ] = useState(null);
    const [ description, setDescription ] = useState("");

    const { createUnitPoolCorrection } = useCreateUnitPoolCorrection();
    const { unit, getUnitById } = useGetUnit();

    // Fetch unit
    useEffect(() => {
        (async () => {
            if (id) {
                await getUnitById(id);
                setIsLoading(false);
            }            
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const onSubmit = async () => {

        setIsLoading(true);

        let d = date;
        d.setUTCHours(0,0,0,0);

        const newUnitPoolCorrection:ICreateUnitPoolCorrection = {
            "date": d.toISOString(),
            "amount": amount,
            "description": description
        } 

        let result = await createUnitPoolCorrection(id || "", newUnitPoolCorrection);

        if (result && result.status === 201) return navigate(-1);
    

        setIsLoading(false);

    }

    return (

        <Form loading={isLoading} onSubmit={onSubmit}>

            <div className="field">
                <div style={{fontWeight: "bold"}}>{t("features.unitPoolCorrection.unit")}</div>
                <div id="unit">{id && unit.name}</div>
            </div>

            <Form.Field required>
                <div style={{fontWeight: "bold"}}>{t('features.unitPoolCorrection.date')}</div>
            </Form.Field>
            <DatePicker
                id="date"
                required
                selected={date}
                onChange={(date: Date) => setDate(date)}
                locale='fi'
                inline
            />
            <div style={{marginBottom: "20px"}}></div>
            
            <InputWithUnit
                label={t("features.unitPoolCorrection.amount")}
                id="amount"
                value={amount}
                validateFor="Integer"
                onChangeFunc={setAmount}
                type="number"
                unit={t("features.unitPoolCorrection.pcs")}
                required
            />

            <Form.TextArea
                label={t("features.unitPoolCorrection.description")}
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                autoComplete="off"
                required
            />

            <ButtonContainer>
                <SaveBtn />
                <CancelBtn />
            </ButtonContainer>
        </Form>
    );
}

export default CreateUnitPoolCorrection;
