import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetItems } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import apiConfig from 'Application/configurations/apiConfig';
import { useAccessTokens } from 'Features/Authentication';
import IListCompanySurveyAttachementProps from './IListCompanySurveyAttachementProps';
import ICompanySurveyAttachement from './ICompanySurveyAttachement';

const useListCompanySurveyAttachements = () => {
    
    const { t } = useTranslation();

    const getItems = useGetItems();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const getToken = useAccessTokens();

    const [ companyFiles, setCompanyFiles ] = useState<ICompanySurveyAttachement[]>([]);

    // API Scopes (see api application registration)
    const accessAsUser = apiConfig.scopes.AccessAsUser;
    const filesReadWriteAll = apiConfig.scopes.GraphApi.FilesReadWriteAll;
    const sitesReadAll = apiConfig.scopes.GraphApi.SitesReadAll;

    /** List all company survey attachements
     * @returns {Array<ICompanySurveyAttachement>} company survey attachements */
    const listCompanySurveyAttachements = async ({ companyId, surveyTypeRef }: IListCompanySurveyAttachementProps) => {

        const basePath = apiConfig.basePath;
        const endpoint = apiConfig.endpoints.files;

        if (companyId == null) {
            console.log("Error: CompanyId is null");
            return;
        }

        let url = new URL(`${basePath}/companies/${companyId}/${endpoint}/?surveyTypeRef=${surveyTypeRef}`);

        const accessToken = await getToken([accessAsUser]);

        const graphApiAccessToken = await getToken([filesReadWriteAll, sitesReadAll]);

        const options = {
            accessToken: accessToken,
            graphToken: graphApiAccessToken
        }
        
        try {

            const response = await getItems(url, options);
            const data = response?.data || [];
            setCompanyFiles(data);
            return responseHandler(response, "useListCompanySurveyAttachements", { operation: "list", hide: true });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "General.RequestorNotExist") body = t('features.companyFiles.requestorNotExist');
            if (problemTitle === "SharePointFile.ListPermissionDenied") body = t('features.companyFiles.listPermissionDenied');

            if (problemTitle === "UserAccount.UserDataAccessDenied") body = t('features.companyFiles.userDataAccessDenied');
            if (problemTitle === "UserAccount.NotExist") body = t('features.companyFiles.userAccountNotFound');

            if (problemTitle === "SharePointSite.NotExist") body = t('features.companyFiles.spSiteNotExist');
            if (problemTitle === "SharePointSite.PermissionDenied") body = t('features.companyFiles.spSitePermissionDenied');

            if (problemTitle === "SharePointLibrary.NotExist") body = t('features.companyFiles.spLibraryNotExist');
            if (problemTitle === "SharePointLibrary.PermissionDenied") body = t('features.companyFiles.spLibraryPermissionDenied');

            if (problemTitle === "SharePointFolder.NotExist") body = t('features.companyFiles.spFolderNotExist');

            return errorHandler(error, "useListCompanySurveyAttachements", { operation: "list", hide: false, messageBody: body });
            
        }

    }

    return { companyFiles, setCompanyFiles, listCompanySurveyAttachements };

}

export default useListCompanySurveyAttachements;
