import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import INewIconBtnProps from '../interfaces/INewIconBtnProps';
import './../styles/button.css';

/** form New Icon Button
 * @param {string} to - url to the form where user want to enter
 * @param {boolean} [hide] - hide the button
 * @link https://react.semantic-ui.com/elements/button/
 **/
const NewIconBtn: React.FC<INewIconBtnProps> = (props: INewIconBtnProps) => {

    const navigate = useNavigate();

    const { to, hidden=false, color, type, ...rest } = props;

    if (hidden) return null;

    return (
        <Button
            icon={<Icon name={'add'} />}
            color={color ? color : undefined}
            type={type ? type : 'reset'}
            onClick={() => navigate(to) }
            {...rest}
        />
    );

};

export default NewIconBtn;
