import { useEffect, useState } from 'react';
import useGetUnitPoolCorrections from './useGetUnitPoolCorrections';
import { PagedTable } from "Components/Tables";
import { useTranslation } from "react-i18next";
import { Segment } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';

function ListUnitPoolCorrections () {

    const { t } = useTranslation();
    const { id } = useParams();
    const { unitPoolCorrections, listUnitPoolCorrections } = useGetUnitPoolCorrections();
    const [ isLoading, setIsLoading ] = useState(true);


    // Fetch unit pool corrections
    useEffect(() => {
        (async () => {
            if(id){
                await listUnitPoolCorrections(id);
            }
        })();
        setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <Segment className="invisible" loading={isLoading}>
            <PagedTable
                header={[
                    t("features.unitPoolCorrection.unit"),
                    t("features.unitPoolCorrection.date"),
                    t("features.unitPoolCorrection.amount"),
                    t("features.unitPoolCorrection.description")
                ]}
            
                content={unitPoolCorrections}
            
                contentKeys={[
                    {key: "unitName", type: "link", target: `/management/units/${id}/unitpoolcorrections/`, targetKey: "id"},
                    {key: "date", type: "date"},
                    {key: "amount", type: "float"},
                    {key: "description"}
                ]}
            
                pageSize={50}
                resetPageOnContentChange={true}
                collapsable={false}
            />
        </Segment>
    );
}

export default ListUnitPoolCorrections;