import { useState } from 'react';
import { useGetItems } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import { useTranslation } from 'react-i18next';
import apiConfig from 'Application/configurations/apiConfig';
import IAnnouncement from '../GetAnnouncementById/IAnnouncement';
import { useAccessTokens } from 'Features/Authentication';
import IListAnnoucementProps from './IListAnnoucementProps';

const useListAnnouncements = () => {
    
    const { t } = useTranslation();
    const [ announcements, setAnnouncements ] = useState<IAnnouncement[]>([]);
    const getItems = useGetItems();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

    /** List all announcements
     * @returns {Array<IAnnouncement>} announcements */
    const listAnnouncements = async ({ isActive }: IListAnnoucementProps) => {

        const basePath = apiConfig.basePath;
        const endpoint = apiConfig.endpoints.announcements;
        let url = new URL(`${basePath}/${endpoint}`);
        const accessToken = await getToken([AccessAsUser]);

        // Get only active announcements?
        if (isActive) url.searchParams.append("isActive", "true");

        const options = {
            accessToken: accessToken
        }
        
        try {

            const response = await getItems(url, options);
            const data = response?.data || [];
            setAnnouncements(data);
            return responseHandler(response, "useListAnnouncements", { operation: "list", hide: true });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "General.RequestorNotExist") body = t('features.announcements.requestorNotExist');
            if (problemTitle === "Announcement.ListPermissionDenied") body = t('features.announcements.listPermissionDenied');

            return errorHandler(error, "useListAnnouncements", { operation: "list", hide: false, messageBody: body });
            
        }

    }

    return { announcements, setAnnouncements, listAnnouncements };

}

export default useListAnnouncements;
