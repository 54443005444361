import { useState, useEffect, useContext } from "react";
import useGetDistributionFreight from "../ReadDistributionFreight/useGetDistributionFreight";
import useUpdateDistributionFreight from "./useUpdateDistributionFreight";
import { Form, Header, Popup, Icon } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";
import { ButtonContainer, SaveBtn, CancelBtn } from "Components/Buttons";
import IUpdateDistributionFreight from "./interfaces/IUpdateDistributionFreight";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { InputWithUnit } from "Components/FormElements/InputWithUnit/InputWithUnit";
import { StateContext } from "Application/state/StateContext";
import { PageTitle } from "Components/Titles";
import { MessageContext } from "Features/Messages";
import useInputValidation from "Components/FormElements/InputWithUnit/useInputValidation";
import useSurvey from "Features/Survey/useSurvey";
import { UploadCompanySurveyAttachements, useUploadCompanySurveyAttachements } from 'Features/CompanyAttachement';

function UpdateDistributionFreight () {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const detailPath = location.pathname.replace("/edit","")
    
    const { userProfile } = useContext(StateContext);
    const { companyId, surveyId } = useParams();
    const [ isLoading, setIsLoading ] = useState(true);
    const { isNonNegativeInteger, isNonNegativeFloat } = useInputValidation();
    const { addMessage, setMessages } = useContext(MessageContext);
    
    const { updateDistributionFreight } = useUpdateDistributionFreight();
    const { distributionFreight, getDistributionFreightById } = useGetDistributionFreight();
    const { isEditingAllowed } = useSurvey();

    const [ attachements, setAttachements ] = useState([]);
    const { uploadCompanySurveyAttachements } = useUploadCompanySurveyAttachements(); 

    const [ compensation, setCompensation ] = useState<number | null>(null);
    const [ sold, setSold ] = useState<number | null>(null);

    // Fetch distribution freight
    useEffect(() => {
        (async () => {
            if(companyId || userProfile.companyId) {
                await getDistributionFreightById(companyId || userProfile.companyId,surveyId);
            }
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userProfile.companyId]);

    // set initial values for distribution freight
    useEffect(() => {
        setCompensation(distributionFreight.compensation);
        setSold(distributionFreight.sold);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[distributionFreight]);

    const onSubmit = async () => {

        var uploadAttachementRequest = {
            companyId: userProfile.companyId,
            surveyId: surveyId || "",
            surveyName: distributionFreight.name || "Jakelurahtikysely",
            surveyTypeRef: "distributionfreight",
            attachements: attachements
        }

        setMessages([]);
        if(
            !isNonNegativeFloat(compensation,2) || !isNonNegativeInteger(sold)
        ){

            addMessage({header: t("features.survey.inputErrorHeader"), type: "error", body: t("features.survey.inputErrorDescription")});
            window.scrollTo(0,0);
        } else {
            setIsLoading(true);

            const distributionFreight:IUpdateDistributionFreight = {
                compensation: compensation || 0,
                sold: sold || 0
            } 

            let result = await updateDistributionFreight(companyId || userProfile.companyId,surveyId,distributionFreight);

            await uploadCompanySurveyAttachements(uploadAttachementRequest);

            if (result && result.status === 204) return navigate(detailPath);
            
            setIsLoading(false);
        }
    }

    return (
        
        <Form loading={isLoading} onSubmit={onSubmit} className="form-inline">
            
            <PageTitle 
                title={distributionFreight.name ? distributionFreight.name : t("pages.editDistributionFreight")} 
                button={
                    <Popup
                        trigger={<Icon name='info' color='blue' size='large' circular />}
                        content={t("features.distributionFreight.infoText")}
                        position='bottom left'
                        wide="very"
                    />
                }
            />

            {distributionFreight.id ?
                isEditingAllowed(distributionFreight.isPermanentlyLocked,distributionFreight.openDate,distributionFreight.closeDate) ?
                    <>
                    <Header as="h2">{t("features.distributionFreight.distributionFreightPeriod")}</Header>
                    <InputWithUnit
                        label={t("features.distributionFreight.compensation")}
                        id="compensation"
                        value={compensation}
                        type="number"
                        validateFor="nonNegativeMoney"
                        onChangeFunc={setCompensation}
                        unit={t("features.distributionFreight.euros")}
                        required
                        pxwidth="100"
                    />

                    <InputWithUnit
                        label={t("features.distributionFreight.sold")}
                        id="sold"
                        value={sold}
                        type="number"
                        validateFor="nonNegativeInteger"
                        onChangeFunc={setSold}
                        unit={t("features.distributionFreight.pallets")}
                        required
                        pxwidth="100"
                    />

                    <UploadCompanySurveyAttachements
                        companyId={userProfile.companyId}
                        surveyId={surveyId}
                        surveyTypeRef="distributionfreight"
                        setAttachements={setAttachements}
                    />

                    <ButtonContainer>
                        <SaveBtn />
                        <CancelBtn />
                    </ButtonContainer>
                    </>
                :
                    <p>{t("features.survey.editingNotAllowed")}</p>
            :
                <p>{t("features.survey.notFound")}</p>
            }
        </Form>
    );
}

export default UpdateDistributionFreight;
