import { Route, Routes, Outlet } from "react-router-dom";
import { useContext } from "react";
import { StateContext } from "Application/state/StateContext";

/* Landing page */
import Home from "../../Pages/Home";

/* User Profile */
import ViewUserProfile from "../../Pages/UserProfile/ViewUserProfile";
//import EditUserProfile from "../../Pages/UserProfile/EditUserProfile";

/* Company */
import ViewCompany from 'Pages/Company/CompanyDetail';

/* Surveys */
import SurveyList from 'Pages/Survey/SurveyList';
import NewSurvey from "Pages/Survey/NewSurvey";
import GlassBottleReturnRateList from 'Pages/Survey/GlassBottleReturnRate/GlassBottleReturnRateList';
import GlassBottleReturnRateDetail from 'Pages/Survey/GlassBottleReturnRate/GlassBottleReturnRateDetail';
import EditGlassBottleReturnRate from 'Pages/Survey/GlassBottleReturnRate/EditGlassBottleReturnRate';

import TransferFreightCostList from 'Pages/Survey/TransferFreightCost/TransferFreightCostList';
import TransferFreightCostDetail from 'Pages/Survey/TransferFreightCost/TransferFreightCostDetail';
import EditTransferFreightCost from 'Pages/Survey/TransferFreightCost/EditTransferFreightCost';

import BudgetList from 'Pages/Survey/Budget/BudgetList';
import BudgetDetail from 'Pages/Survey/Budget/BudgetDetail';
import EditBudget from 'Pages/Survey/Budget/EditBudget';

import VolumeList from 'Pages/Survey/Volume/VolumeList';
import VolumeDetail from 'Pages/Survey/Volume/VolumeDetail';
import EditVolume from 'Pages/Survey/Volume/EditVolume';

import DistributionFreightList from 'Pages/Survey/DistributionFreight/DistributionFreightList';
import DistributionFreightDetail from 'Pages/Survey/DistributionFreight/DistributionFreightDetail';
import EditDistributionFreight from 'Pages/Survey/DistributionFreight/EditDistributionFreight';

import StorageCostList from 'Pages/Survey/StorageCost/StorageCostList';
import StorageCostDetail from 'Pages/Survey/StorageCost/StorageCostDetail';
import EditStorageCost from 'Pages/Survey/StorageCost/EditStorageCost';

import InventoryList from 'Pages/Survey/Inventory/InventoryList';
import InventoryDetail from 'Pages/Survey/Inventory/InventoryDetail';
import EditInventory from 'Pages/Survey/Inventory/EditInventory';

import ClearingList from 'Pages/Survey/Clearing/ClearingList';
import ClearingDetail from 'Pages/Survey/Clearing/ClearingDetail';
import EditClearing from 'Pages/Survey/Clearing/EditClearing';

/* Management */
import Management from "../../Pages/Management/Management";

/* Management / Announcements */
import Announcements from "../../Pages/Management/Announcements/Announcements";
import NewAnnouncement from "../../Pages/Management/Announcements/NewAnnouncement";
import ViewAnnouncement from "../../Pages/Management/Announcements/ViewAnnouncement";
import EditAnnouncement from "../../Pages/Management/Announcements/EditAnnouncement";

/* Management / Companies */
import CompanyList from "Pages/Management/Company/CompanyList";
import NewCompanyManagement from 'Pages/Management/Company/NewCompany';
import ViewCompanyManagement from "Pages/Management/Company/CompanyDetail";
import EditCompanyManagement from 'Pages/Management/Company/EditCompany';

/* Management / Accounts */
import AccountList from "Pages/Management/Account/AccountList";
import NewAccount from 'Pages/Management/Account/NewAccount';
import AccountDetail from "Pages/Management/Account/AccountDetail";
import EditAccount from 'Pages/Management/Account/EditAccount';

/* Management / Units */
import UnitList from "Pages/Management/Unit/UnitList";
import UnitDetail from "Pages/Management/Unit/UnitDetail";
import EditUnit from "Pages/Management/Unit/EditUnit";

/* Management / Unit pool corrections */
import UnitPoolCorrectionList from "Pages/Management/UnitPoolCorrection/UnitPoolCorrectionList";
import NewUnitPoolCorrection from "Pages/Management/UnitPoolCorrection/NewUnitPoolCorrection";
import UnitPoolCorrectionDetail from "Pages/Management/UnitPoolCorrection/UnitPoolCorrectionDetail";
import EditUnitPoolCorrection from "Pages/Management/UnitPoolCorrection/EditUnitPoolCorrection";

/* Management / Yearly budgets */
import YearlyBudgetList from "Pages/Management/YearlyBudget/YearlyBudgetList";
import NewYearlyBudget from "Pages/Management/YearlyBudget/NewYearlyBudget";
import YearlyBudgetDetail from "Pages/Management/YearlyBudget/YearlyBudgetDetail";
import EditYearlyBudget from "Pages/Management/YearlyBudget/EditYearlyBudget";

/* Management / Company units */
import CompanyUnitList from "Pages/Management/CompanyUnit/CompanyUnitList";
import EditCompanyUnits from "Pages/Management/CompanyUnit/EditCompanyUnits";

/* Management / Company surveys */
import CompanySurveyList from "Pages/Management/CompanySurvey/CompanySurveyList";
import EditCompanySurveys from "Pages/Management/CompanySurvey/EditCompanySurveys";

/* System pages */
import VersionHistory from "Pages/VersionHistory/VersionHistory";
import InternalServerError500 from "Pages/System/InternalServerError500";
import Forbidden403 from "Pages/System/Forbidden403";
import NotFound404 from "Pages/System/NotFound404";

/* Developer pages */
import Developer from "Pages/Developer/Developer";
import Clipboards from  "Pages/Developer/Clipboard";
import CSV from  "Pages/Developer/CSV";
import Files from  "Pages/Developer/Files";
import Loaders from  "Pages/Developer/Loaders";
import Modals from  "Pages/Developer/Modals";
import Tables from  "Pages/Developer/Tables";
import Search from  "Pages/Developer/Search";
import FormButtons from  "Pages/Developer/Buttons";
import Forms from  "Pages/Developer/Forms";
import ApiAccessToken from "Pages/Developer/ApiAccessToken";

const Router = () => {

    const { userProfile } = useContext(StateContext);
    const isAdmin = userProfile && userProfile.roles && userProfile.roles.find((role:any) => role.name==="Admin") ? true : false;

    return (
        <Routes>

            {/* Landing page */}
            <Route path="/" element={<Home />} errorElement={<InternalServerError500 />} />

            {/* User Profile */}
            <Route path="profile" element={<Outlet />}>
                <Route index element={<ViewUserProfile />} />
                {/*<Route path="edit" element={<EditUserProfile />} />*/}
            </Route>

            {/* Company */}
            <Route path="company" element={<ViewCompany />} />

            {/* surveys */}
            <Route path="surveys" element={<Outlet />}>
                <Route index element={<SurveyList />} />
                <Route path="new" element={<NewSurvey/>} />
                <Route path="glassbottlereturnrates" element={<Outlet />}>
                    <Route index element={<GlassBottleReturnRateList />} />
                    <Route path=":surveyId" element={<GlassBottleReturnRateDetail/>} />
                    <Route path=":surveyId/edit" element={<EditGlassBottleReturnRate/>} />
                </Route>
                <Route path="transferfreightcosts" element={<Outlet />}>
                    <Route index element={<TransferFreightCostList />} />
                    <Route path=":surveyId" element={<TransferFreightCostDetail/>} />
                    <Route path=":surveyId/edit" element={<EditTransferFreightCost/>} />
                </Route>
                <Route path="budgets" element={<Outlet />}>
                    <Route index element={<BudgetList />} />
                    <Route path=":surveyId" element={<BudgetDetail/>} />
                    <Route path=":surveyId/edit" element={<EditBudget/>} />
                </Route>
                <Route path="volumes" element={<Outlet />}>
                    <Route index element={<VolumeList />} />
                    <Route path=":surveyId" element={<VolumeDetail/>} />
                    <Route path=":surveyId/edit" element={<EditVolume/>} />
                </Route>
                <Route path="distributionfreights" element={<Outlet />}>
                    <Route index element={<DistributionFreightList />} />
                    <Route path=":surveyId" element={<DistributionFreightDetail/>} />
                    <Route path=":surveyId/edit" element={<EditDistributionFreight/>} />
                </Route>
                <Route path="storagecosts" element={<Outlet />}>
                    <Route index element={<StorageCostList />} />
                    <Route path=":surveyId" element={<StorageCostDetail/>} />
                    <Route path=":surveyId/edit" element={<EditStorageCost/>} />
                </Route>
                <Route path="inventories" element={<Outlet />}>
                    <Route index element={<InventoryList />} />
                    <Route path=":surveyId" element={<InventoryDetail/>} />
                    <Route path=":surveyId/edit" element={<EditInventory/>} />
                </Route>
                <Route path="clearings" element={<Outlet />}>
                    <Route index element={<ClearingList />} />
                    <Route path=":surveyId" element={<ClearingDetail/>} />
                    <Route path=":surveyId/edit" element={<EditClearing/>} />
                </Route>
            </Route>

            {/* Management landing */}
            {isAdmin &&
                <Route path="management" element={<Outlet />}>

                    <Route index element={<Management />} />

                    {/* Announcements */}
                    <Route path="announcements" element={<Outlet />}>
                        <Route index element={<Announcements />} />
                        <Route path="new" element={<NewAnnouncement/>} />
                        <Route path=":id" element={<ViewAnnouncement/>} />
                        <Route path=":id/edit" element={<EditAnnouncement/>} />
                    </Route>

                    {/* Units */}
                    <Route path="units" element={<Outlet />}>
                        <Route index element={<UnitList />} />
                        {/*<Route path="new" element={<NewUnit/>} />*/}
                        <Route path=":id" element={<UnitDetail/>} />
                        <Route path=":id/edit" element={<EditUnit/>} />

                        {/* Unit pool corrections */}
                        <Route path=":id/unitpoolcorrections" element={<Outlet />}>
                            <Route index element={<UnitPoolCorrectionList />} />
                            <Route path="new" element={<NewUnitPoolCorrection/>} />
                            <Route path=":unitPoolCorrectionId" element={<UnitPoolCorrectionDetail/>} />
                            <Route path=":unitPoolCorrectionId/edit" element={<EditUnitPoolCorrection/>} />
                        </Route>
                    </Route>

                     {/* Yearly budgets */}
                     <Route path="yearlybudgets" element={<Outlet />}>
                        <Route index element={<YearlyBudgetList />} />
                        <Route path="new" element={<NewYearlyBudget/>} />
                        <Route path=":id" element={<YearlyBudgetDetail/>} />
                        <Route path=":id/edit" element={<EditYearlyBudget/>} />
                    </Route>
                    
                    {/* Companies */}
                    <Route path="companies" element={<Outlet />}>
                        <Route index element={<CompanyList />} />
                        <Route path="new" element={<NewCompanyManagement/>} />
                        <Route path=":companyId" element={<ViewCompanyManagement/>} />
                        <Route path=":companyId/edit" element={<EditCompanyManagement/>} />
                        
                        {/* Accounts */}
                        <Route path=":companyId/accounts" element={<Outlet />}>
                            <Route index element={<AccountList />} />
                            <Route path="new" element={<NewAccount/>} />
                            <Route path=":accountId" element={<AccountDetail/>} />
                            <Route path=":accountId/edit" element={<EditAccount/>} />
                        </Route>

                        {/* Company units */}
                        <Route path=":companyId/units" element={<Outlet />}>
                            <Route index element={<CompanyUnitList />} />
                            {/*<Route path="new" element={<NewCompanyUnits/>} />*/}
                            <Route path="edit" element={<EditCompanyUnits/>} />
                        </Route>

                        {/* Company survey types */}
                        <Route path=":companyId/surveytypes" element={<Outlet />}>
                            <Route index element={<CompanySurveyList />} />
                            {/*<Route path="new" element={<NewCompanyUnits/>} />*/}
                            <Route path="edit" element={<EditCompanySurveys/>} />
                        </Route>

                        {/* surveys */}
                        <Route path=":companyId/surveys" element={<Outlet />}>
                            <Route index element={<SurveyList />} />
                            <Route path="new" element={<NewSurvey/>} />
                            <Route path="glassbottlereturnrates" element={<Outlet />}>
                                <Route index element={<GlassBottleReturnRateList />} />
                                <Route path=":surveyId" element={<GlassBottleReturnRateDetail/>} />
                                <Route path=":surveyId/edit" element={<EditGlassBottleReturnRate/>} />
                            </Route>
                            <Route path="transferfreightcosts" element={<Outlet />}>
                                <Route index element={<TransferFreightCostList />} />
                                <Route path=":surveyId" element={<TransferFreightCostDetail/>} />
                                <Route path=":surveyId/edit" element={<EditTransferFreightCost/>} />
                            </Route>
                            <Route path="budgets" element={<Outlet />}>
                                <Route index element={<BudgetList />} />
                                <Route path=":surveyId" element={<BudgetDetail/>} />
                                <Route path=":surveyId/edit" element={<EditBudget/>} />
                            </Route>
                            <Route path="volumes" element={<Outlet />}>
                                <Route index element={<VolumeList />} />
                                <Route path=":surveyId" element={<VolumeDetail/>} />
                                <Route path=":surveyId/edit" element={<EditVolume/>} />
                            </Route>
                            <Route path="distributionfreights" element={<Outlet />}>
                                <Route index element={<DistributionFreightList />} />
                                <Route path=":surveyId" element={<DistributionFreightDetail/>} />
                                <Route path=":surveyId/edit" element={<EditDistributionFreight/>} />
                            </Route>
                            <Route path="storagecosts" element={<Outlet />}>
                                <Route index element={<StorageCostList />} />
                                <Route path=":surveyId" element={<StorageCostDetail/>} />
                                <Route path=":surveyId/edit" element={<EditStorageCost/>} />
                            </Route>
                            <Route path="inventories" element={<Outlet />}>
                                <Route index element={<InventoryList />} />
                                <Route path=":surveyId" element={<InventoryDetail/>} />
                                <Route path=":surveyId/edit" element={<EditInventory/>} />
                            </Route>
                            <Route path="clearings" element={<Outlet />}>
                                <Route index element={<ClearingList />} />
                                <Route path=":surveyId" element={<ClearingDetail/>} />
                                <Route path=":surveyId/edit" element={<EditClearing/>} />
                            </Route>
                        </Route>
                    </Route>

                </Route>
            }

            {/* Version History */}
            <Route path="/versions" element={<VersionHistory />} />

            {/* Developer pages */}
            <Route path="developer" element={<Outlet />}>
                <Route index element={<Developer />} />
                <Route path="accesstokens" element={<ApiAccessToken/>} /> 
                <Route path="clipboard" element={<Clipboards/>} />
                <Route path="csv" element={<CSV/>} />
                <Route path="files" element={<Files/>} />
                <Route path="loaders" element={<Loaders/>} />
                <Route path="modals" element={<Modals/>} />
                <Route path="tables" element={<Tables/>} />
                <Route path="search" element={<Search/>} />
                <Route path="buttons" element={<FormButtons/>} />
                <Route path="forms" element={<Forms/>} />
            </Route>

            {/* System */}
            <Route path="/error" element={<InternalServerError500 />} />
            <Route path="/forbidden" element={<Forbidden403 />} />
            <Route path="/notfound" element={<NotFound404 />} />

            {/* Authentication response */}
            <Route path="/auth/response-oidc" element={<>Authenticating...</>} />           

            {/* 404 page not found */}
            <Route path="*" element={<NotFound404 />} />

        </Routes>
    );
}

export default Router;
