import { Configuration, LogLevel, RedirectRequest } from "@azure/msal-browser";

// Authentication configuration. See Azure AD Application registration for auth values.
const msalConfig: Configuration = {
    auth: {
        // clientName => 'Ekopullo Jäsenportaali frontend (DEV)'
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID || "/",
        authority: "https://login.microsoftonline.com/" + process.env.REACT_APP_AUTH_TENANT_ID,
        redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI,
        postLogoutRedirectUri: process.env.REACT_APP_AUTH_POST_LOGOUT_URI || "/"
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        //console.log(message);
                        return;
                    case LogLevel.Info:
                        //console.log(message);
                        return;
                    case LogLevel.Verbose:
                        //console.log(message);
                        return;
                    case LogLevel.Warning:
                        //console.log(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
    scopes: ["User.Read"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

export default msalConfig;
