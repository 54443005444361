import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';
import IApproveButtonProps from '../interfaces/IApproveButtonProps';

/** Approve Button
 * @param {string} title - Button title
 * @param {boolean} hide - hide the button
 * @link https://react.semantic-ui.com/elements/button/
 **/
const ApproveButton: React.FC<IApproveButtonProps> = (props: IApproveButtonProps) => {

    const { t } = useTranslation();
    const { title, hidden=false, color, labelPosition, type, ...rest } = props;

    if (hidden) return null;

    return (
        <Button
            icon
            className='extended-button'
            color={color ? color : undefined}
            labelPosition={labelPosition ? labelPosition : 'left'}
            type={type ? type : 'reset'}
            {...rest}
        >
            <Icon name={'check'} />
            {title ? title : t('components.buttons.approve')}
        </Button>
    );

};

export default ApproveButton;
