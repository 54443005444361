import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';
import INewBtn from '../interfaces/INewBtnProps';
import './../styles/button.css';

/** Edit Button
 * @description Used to open "edit item" form.
 * @param {string} to - url to the form where user want to enter
 * @param {string} [title] - Button title - optional
 * @param {boolean} [hide] - hide the button - optional
 * @param {string} [labelPosition] - label position 'left' | 'right'
 * @link https://react.semantic-ui.com/elements/button/
 **/
const EditBtn: React.FC<INewBtn> = (props: INewBtn) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const { to, title, hidden=false, color, labelPosition, type, ...rest } = props;

    if (hidden) return null;

    return (
        <Button
            icon
            className='extended-button edit-button'
            color={color ? color : undefined}
            labelPosition={labelPosition ? labelPosition : 'left'}
            type={type ? type : 'reset'}
            onClick={() => navigate(to) }
            {...rest}
        >
            <Icon name={'pencil'} />
            {title ? title : t('components.buttons.edit')}
        </Button>
    );

};

export default EditBtn;
