import { useState } from "react";
import { useGetItems } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import { useTranslation } from 'react-i18next';
import apiConfig from 'Application/configurations/apiConfig';
import IListYearlyBudget from "./IListYearlyBudget";
import { useAccessTokens } from 'Features/Authentication';

function useGetYearlyBudgets () {

    const { t } = useTranslation();
    const [ yearlyBudgets, setYearlyBudgets ] = useState<IListYearlyBudget[]>([]);
    const getItems = useGetItems();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;
    

   /** List all yearly budgets
     * @returns {Array<IListYearlyBudget>} yearly budgets */
    const listYearlyBudgets = async () => {

        const basePath = apiConfig.basePath;
        const endpoint = apiConfig.endpoints.yearlyBudgets;
        const url = new URL(`${basePath}/${endpoint}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken
        }
        
        try {

            const response = await getItems(url, options);
            const data = response?.data || [];
            setYearlyBudgets(data);
            return responseHandler(response, "useGetYearlyBudgets", { operation: "list", hide: true });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "General.RequestorNotExist") body = t('features.yearlyBudget.requestorNotExist');
            if (problemTitle === "YearlyBudgetErrors.ListPermissionDenied") body = t('features.yearlyBudget.listPermissionDenied');
            
            return errorHandler(error, "useGetYearlyBudgets", { operation: "list", hide: false, messageBody: body });
   
        }
    }

    return { yearlyBudgets, setYearlyBudgets, listYearlyBudgets };

}

export default useGetYearlyBudgets;