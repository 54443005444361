import { useState } from 'react';
import { Container } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from 'Components/Titles';
import { SearchBar } from 'Components/Search';
//import './../search.css';

const Search = () => {

    const { t } = useTranslation();

    const [ searchTerm, setSearchTerm ] = useState("default SearchTerm");

    // When Search bar search is clicked, this method is called and it gets the searchTerm as input.
    const searchSomething = (searchTerm: string) => {
        try {
            // Do something with the search term nd searcg items
            console.log("searchTerm: " + searchTerm);
        } catch (error) {
            // Do something with the error
        }
    }

    return (
        <Container className="page">

            <PageTitle title={t('pages.search')}/>

            <SearchBar
                title="Hae"
                placeholder="Search placeholder text here..."
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                searchItems={searchSomething}
                hidden={false}
                filters={<p>Add filter like this if needed</p>}
            >
                <p>Add more content here if needed</p>
            </SearchBar>

        </Container>
    )

}


export default Search;
