import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Container, Icon } from 'semantic-ui-react';
import useListCompanySurveyAttachements from './useListCompanySurveyAttachements';
import ICompanySurveyAttachementProps from './ICompanySurveyAttachementProps';

const ListCompanySurveyAttachements = ({ companyId, surveyId, surveyTypeRef }: ICompanySurveyAttachementProps) => {

    const { t } = useTranslation();

    const { companyFiles, listCompanySurveyAttachements } = useListCompanySurveyAttachements();

    // Get company survey attachements by survey type ref
    useEffect(() => {
        (async() => {
            await listCompanySurveyAttachements({ companyId: companyId, surveyTypeRef: surveyTypeRef });
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId]);

    return (
        <Container style={{ marginTop: '25px'}}>
            <Header sub>{t("features.companyFiles.attachements")}</Header>
            {
                companyFiles.length > 0 ?
                /* Filter by survey id !!! */
                companyFiles.filter(x => x.metadata.surveyId === surveyId)
                .map((file, index) => (
                    <>
                    <a key={index} href={file.webUrl} target="_blank" rel="noopener noreferrer" ><Icon name='file outline' /> {file.title? file.title : file.webUrl}</a>
                    <br/>
                    </>
                ))
                :
                <p>{t('features.companyFiles.noAttachements')}</p>
            }
        </Container>
    )

}

export default ListCompanySurveyAttachements;
