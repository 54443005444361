import { useState } from 'react';
import { useGetItem } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import { useTranslation } from 'react-i18next';
import apiConfig from 'Application/configurations/apiConfig';
import IAnnouncement from './IAnnouncement';
import { useAccessTokens } from 'Features/Authentication';

const useReadAnnouncement = () => {

    const { t } = useTranslation();
    const getItem = useGetItem();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const [ announcement, setAnnouncement ] = useState<IAnnouncement>({ id: 0, title: "", description: "", startDate: new Date(), endDate: new Date(), type: "", visible: true });
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

    /** Read announcement by id
     * @param {number} id - id of the announcement
     * @returns {object} announcement - announcement object
     **/
    const readAnnouncementById = async (id: any) => {

        const basePath = apiConfig.basePath;
        const endpoint = apiConfig.endpoints.announcements;
        const url = new URL(`${basePath}/${endpoint}/${id}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken
        }

        try {

            const response = await getItem(url, options);
            const data = response?.data || [];
            setAnnouncement(data);
            return responseHandler(response, "useViewAnnouncement", { operation: "read", hide: true });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let header = null;

            if (problemTitle === "General.RequestorNotExist") header = t('features.announcements.requestorNotExist');
            if (problemTitle === "Announcement.ReadPermissionDenied") header = t('features.announcements.readPermissionDenied');
            if (problemTitle === "Announcement.NotExist") header = t('features.announcements.notExist');

            return errorHandler(error, "useViewAnnouncement", { operation: "read", hide: false, messageBody: header });

        }

    }

    return { announcement, setAnnouncement, readAnnouncementById };

}

export default useReadAnnouncement;
