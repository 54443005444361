import { useTranslation } from 'react-i18next';
import { Container } from 'semantic-ui-react';
import { PageTitle } from 'Components/Titles';
import { ViewUserProfile } from 'Features/UserProfile';

const ViewProfile = () => {

    const { t } = useTranslation();

    return (
        <Container className="page">

            <PageTitle title={t('pages.viewUserProfile')} />

            <ViewUserProfile/>

        </Container>
    );

}

export default ViewProfile;