import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Grid, Icon, Input, Segment, Header, Accordion } from 'semantic-ui-react';
import SearchButton from './SearchButton';
import ISearchBarProps from '../interfaces/ISearchBarProps';

/** Search Bar
 * @param {string} searchTerm - search term.
 * @param {Function} searchItems - React setState to set search term.
 * @param {Function} searchItems - Search items function. f.e. reach hook "searchContacts"
 * 
 * @param {String} [title] - title of the search component - "Search contacts"
 * @param {String} [placeholder] - placeholder helptext - "Search contacts by firstname"
 * @param {Boolean} [hidden] - show or hide component
 * @param {Object} [filters] - additional components f.e. filter checkbox 
 * @param {Object} [children] - additional components f.e. selection list */
const SearchBar: React.FC<ISearchBarProps> = ({
    searchTerm,
    setSearchTerm,
    searchItems,
    title,
    placeholder,
    hidden=false,
    filters,
    children
}: ISearchBarProps) => {

    const { t } = useTranslation();
    const [ activeIndex, setActiveIndex ] = useState(0);

    if (hidden) return null;

    return (
        <Container className="search-bar">
            <Accordion>

                <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={() => { setActiveIndex(activeIndex === 0 ? -1 : 0); }}
                    style={{paddingBottom: 0}}
                >

                     {/* Search bar header */}
                    <Header as='h3' attached='top' className="search-items-header">
                       <Grid>
                           <Grid.Column width="8" textAlign="left">
                               <b>{title || t('components.search.title')}</b>
                           </Grid.Column>
                           <Grid.Column  width="8" textAlign="right">
                               <Icon name='dropdown' />
                           </Grid.Column>
                       </Grid>
                   </Header>

                </Accordion.Title>

                <Accordion.Content active={activeIndex === 0} style={{paddingTop: 0}}>
                <Segment attached style={{ borderRadius: "0 0 5px 5px" }}>

                    {/* Search bar input */}
                    <Input
                        fluid
                        icon='search'
                        placeholder={placeholder ? placeholder : t('components.search.placeholder')}
                        value={searchTerm}
                        onKeyPress={(ev: KeyboardEvent, data: object) => {
                            if (ev.key === 'Enter') {
                                ev.preventDefault();
                                searchItems(searchTerm);
                            }
                        }}
                        onChange={(ev, {value}) => {
                            setSearchTerm(value);
                        }}
                        className="fieldcontent"
                    />

                    {/* Additional filters */}
                    <Container>
                        {filters}
                    </Container>

                    {/* Search items button */}
                    <Container textAlign="right">
                        <SearchButton type="reset" style={{ marginTop: '20px', marginRight: 0 }} onClick={() => searchItems(searchTerm) } />
                    </Container>

                    {/* Additional content */}
                    <Container>
                        {children}
                    </Container>

                    </Segment>
                </Accordion.Content>
            </Accordion>
        </Container>
    );

};

export default SearchBar;