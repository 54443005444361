import { useEffect, useState } from 'react';
import useGetAllYearlyBudgets from "./useGetYearlyBudgets";
import { PagedTable } from "Components/Tables";
import { useTranslation } from "react-i18next";
import { Segment } from 'semantic-ui-react';

function ListYearlyBudgets () {

    const { t } = useTranslation();
    const { yearlyBudgets, listYearlyBudgets } = useGetAllYearlyBudgets();
    const [ isLoading, setIsLoading ] = useState(true);

    // Fetch yearlyBudgets
    useEffect(() => {
        (async () => {
            await listYearlyBudgets();
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <Segment className="invisible" loading={isLoading}>
            <PagedTable
                header={[
                    t("features.yearlyBudget.year"),
                    t("features.yearlyBudget.budget")
                ]}
            
                content={yearlyBudgets}
            
                contentKeys={[
                    {key: "year", type: "link",  target: "/management/yearlybudgets/", targetKey: "id"},
                    {key: "budget"}
                ]}
            
                pageSize={50}
                resetPageOnContentChange={true}
                collapsable={false}
            />
        </Segment>
    );
}

export default ListYearlyBudgets;