const apiConfig = {

    apiUrl: process.env.REACT_APP_WEB_API_BASE_URL,             // https://<your-api-url>.azurewebsites.net/api
    apiVersion: process.env.REACT_APP_WEB_API_BASE_VERSION,     // v1
    basePath: `${process.env.REACT_APP_WEB_API_BASE_URL}`,      // <your-api-url>.azurewebsites.net/api/v1
    // basePath: `${process.env.REACT_APP_WEB_API_BASE_URL}/${process.env.REACT_APP_WEB_API_BASE_VERSION}`,    // <your-api-url>.azurewebsites.net/api/v1

    scopes: {
        AccessAsUser: process.env.REACT_APP_WEB_API_APPLICATION_ID_URI || "api://00000000-0000-0000-0000-000000000000/Ekopullo.AccessAsUser",
        GraphApi: {
            FilesReadWriteAll: "https://graph.microsoft.com/Files.ReadWrite.All",
            SitesReadAll: "https://graph.microsoft.com/Sites.Read.All"
        }        
    },
    
    endpoints: {
        test: "test",
        announcements: "announcements",
        userProfile: "users/me",
        companies: "companies",
        units: "units",
        unitpoolcorrections: "unitpoolcorrections",
        surveytypes: "surveytypes",
        unitCategories: "unitcategories",
        accounts: "accounts",
        surveys: "surveys",
        glassBottleReturnRates: "surveys/glassbottlereturnrates",
        transferFreightCosts: "surveys/transferfreightcosts",
        budgets: "surveys/budgets",
        volumes: "surveys/volumes",
        distributionFreights: "surveys/distributionfreights",
        storageCosts: "surveys/storagecosts",
        inventories: "surveys/inventories",
        clearings: "surveys/clearings",
        yearlyBudgets: "yearlybudgets",
        documents: "documents",
        files: "files"
    }

};
  
export default apiConfig;
