import config from 'Application/configurations/appConfig';

const useLogToConsole = () => {
  
    const logToConsole = (response: any, caller: string, hide?: boolean, messageHeader?: any, messageBody?: any) => {
    
        const message = {
            response,
            caller,
            hide,
            messageHeader,
            messageBody
        }
    
        if (config.environment === 'development') {
            console.log("################### MESSAGE ###################");
            console.log(message);
            console.log("###############################################");
        }
    
    }

    return logToConsole;

}

export default useLogToConsole;
