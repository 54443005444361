import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';
import INoButton from '../interfaces/INoButton';

/** No Button
 * @param {string} title - Button title
 * @param {boolean} hide - hide the button
 * @link https://react.semantic-ui.com/elements/button/
 **/
const NoButton: React.FC<INoButton> = (props: INoButton) => {

    const { t } = useTranslation();
    const { title, hidden=false, color, labelPosition, type, ...rest } = props;

    if (hidden) return null;

    return (
        <Button
            icon
            className='extended-button'
            color={color ? color : undefined}
            labelPosition={labelPosition ? labelPosition : 'left'}
            type={type ? type : 'reset'}
            {...rest}
        >
            <Icon name={'ban'} />
            {title ? title : t('components.buttons.no')}
        </Button>
    );

};

export default NoButton;
