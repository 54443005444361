import { useState } from "react";
import useCreateAccount from "./useCreateAccount";
import { Form } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";
import { ButtonContainer, SaveBtn, CancelBtn } from "Components/Buttons";
import ICreateAccount from "./ICreateAccount";
import { useNavigate, useParams } from "react-router-dom";
import { EditEmail } from "Components/Email/Email";

function CreateAccount () {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { companyId } = useParams();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ lastName, setLastName ] = useState("");
    const [ firstName, setFirstName ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ phoneNumber, setPhoneNumber ] = useState("");
    const [ isActive, setIsActive ] = useState(true);
    const [ entraId, setEntraId ] = useState("");

    const { createAccount } = useCreateAccount();

    const onSubmit = async () => {

        setIsLoading(true);

        const newAccount:ICreateAccount = {
            "companyId": companyId || "",
            "username": email,
            "firstName": firstName,
            "lastName": lastName,
            "phoneNumber": phoneNumber,
            "email": email,
            "isActive": isActive,
            "id": entraId,
            "roles": ["User"]
        }

        let result = await createAccount(companyId, newAccount);

        if (result && result.status === 201) return navigate(-1);
    
        setIsLoading(false);

    }

    return (

        <Form loading={isLoading} onSubmit={onSubmit}>
            <Form.Input
                label={t("features.account.lastName")}
                id="lastname"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
            />
            <Form.Input
                label={t("features.account.firstName")}
                id="firstname"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
            />
            <EditEmail
                id="email"
                label={t("features.account.email")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />
            <Form.Input
                label={t("features.account.phoneNumber")}
                id="phonenumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
            />
            <Form.Input
                label={t("features.account.entraId")}
                id="entraId"
                value={entraId}
                onChange={(e) => setEntraId(e.target.value)}
                required
            />
            <Form.Checkbox
                label={t("features.account.accountIsActive")}
                id="isActive"
                onChange={(e,{value}) => setIsActive(!isActive)}
                checked={isActive}
            />
            <ButtonContainer>
                <SaveBtn />
                <CancelBtn />
            </ButtonContainer>
        </Form>
    );
}

export default CreateAccount;
