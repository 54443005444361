import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'semantic-ui-react';
import IPagedTableFilterProps from './IPagedTableFilterProps';
import './filterTable.css';

/** Filter Table Results
 * @param {string} filterText - react state for filter text
 * @param {function} setFilterText - react set state method to set filter text
 * @param {any} rest - any semantic ui react input prop
*/
const FilterTable: React.FC<IPagedTableFilterProps> = ({
    filterText,
    setFilterText,
    ...rest
}: IPagedTableFilterProps) => {

    const { t } = useTranslation();

    return (
        <Input
            className='paged-table-filter'
            action={{
                labelPosition: 'left',
                icon: 'delete',
                content: t('components.pagedTable.clear'),
                style: {margin: 0},
                onClick:(ev: Event) => {
                    ev.preventDefault();
                    setFilterText('');
                }
            }}
            onChange={(ev, {value} ) => {
                ev.preventDefault();
                setFilterText(value)
            }}
            fluid
            placeholder={t('components.pagedTable.filter')}
            value={filterText}
            {...rest}
        />
    );

}

export default FilterTable;
