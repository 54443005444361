import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'semantic-ui-react';
import DatePicker, { registerLocale } from "react-datepicker";
import fi from "date-fns/locale/fi";
import { ButtonContainer, CancelBtn, SaveBtn } from 'Components/Buttons';
//import typeOptions from './typeOptions';
import useViewAnnouncement from '../GetAnnouncementById/useReadAnnouncement';
import useEditAnnouncement from './useUpdateAnnouncement';
import './announcements.css';

/** Edit announcement form is used to edit single portal announcement */
const UpdateAnnouncement: React.FC = () => {

    let { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    registerLocale("fi", fi);
    const { announcement, readAnnouncementById } = useViewAnnouncement();
    const updateAnnouncementById = useEditAnnouncement();

    const [ isLoading, setIsLoading ] = useState(false);
    const [ title, setTitle ] = useState("");
    const [ description, setDescription ] = useState("");
    const [ startDate, setStartDate ] = useState(new Date());
    const [ endDate, setEndDate ] = useState(new Date());
    //const [ type, setType ] = useState("info");

    // Read announcement by id on page load
    useEffect(() => {
        (async () => {
            setIsLoading(true);
            await readAnnouncementById(id);
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Set data when reading is done
    useEffect(() => {
        (async () => {
            setTitle(announcement.title);
            setDescription(announcement.description);
            setStartDate(new Date(announcement.startDate));
            setEndDate(new Date(announcement.endDate));
            //setType(announcement.type);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [announcement]);

    const onSubmit = async () => {       

        setIsLoading(true);

        const updatedAnnouncement = {
            "title": title,
            "description": description,
            "startDate": startDate,
            "endDate": endDate,
            //"type": type
        };

        let result = await updateAnnouncementById(id, updatedAnnouncement);

        if (result && result.status === 204) return navigate(-1);

        setIsLoading(false);

    }

    return (
        <div className="portal-announcement">
            <Form loading={isLoading} onSubmit={onSubmit} >

                {/* Title */}
                <Form.Field
                    id="title"
                    required
                    control={Input}
                    label={t('features.announcements.title')}
                    value={title || ''}
                    onChange={(ev: Event, {value}: any) => setTitle(value)}
                />

                {/* Start date */}
                <div className="calendar">
                    <Form.Field required>
                        <label>{t('features.announcements.startDate')}</label>
                    </Form.Field>
                    <DatePicker
                        id="startDate"
                        required
                        selected={startDate}
                        onChange={(date: Date) => setStartDate(date)}
                        locale='fi'
                        inline
                    />
                </div>
                
                {/* End date */}
                <div className="calendar">
                    <Form.Field required>
                        <label>{t('features.announcements.endDate')}</label>
                    </Form.Field>
                    <DatePicker
                        id="endDate"
                        required
                        selected={endDate}
                        onChange={(date: Date) => setEndDate(date)}
                        locale='fi'
                        inline
                    />
                </div>

                {/* Description */}
                <Form.Field
                    id="description"
                    control={Input}
                    label={t('features.announcements.description')}
                    value={description || ''}
                    onChange={(ev: Event, {value}: any) => setDescription(value)}
                />

                {/* Message Type 
                <Form.Field required>
                    <label>{t('features.announcements.type')}</label>
                </Form.Field>
                <Dropdown
                    required
                    fluid
                    selection
                    options={typeOptions}
                    onChange={(ev: React.SyntheticEvent<HTMLElement, Event>, {value}: any) => setType(value)}
                    value={type}
                />
                <br/>*/}

                <ButtonContainer>
                    <SaveBtn />
                    <CancelBtn />
                </ButtonContainer>

            </Form>
        </div>
    );

}

export default UpdateAnnouncement;
