import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import IDisabledCSVButton from './IDisabledCSVButton';
// import './../styles/csv.css';

/** Disabled CSV Button
 * @param {string} title - Button title
 * @link https://www.npmjs.com/package/react-csv
 * @link https://www.npmjs.com/package/json2csv
 **/
const DisabledCSVButton: React.FC<IDisabledCSVButton> = ({title}: IDisabledCSVButton) => {
    
    const { t } = useTranslation();

    return <Button
            icon
            labelPosition={'left'}
            disabled
            title={title}
        >
            <Icon name={'file alternate outline'} />
            {title ? title : t('components.csv.generateCSV')}
        </Button>

};

export default DisabledCSVButton;
