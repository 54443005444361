import { usePutItem } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import apiConfig from 'Application/configurations/apiConfig';
import IUpdateVolume from "./interfaces/IUpdateVolume";
import ILockVolume from './interfaces/ILockVolume';
import { useAccessTokens } from 'Features/Authentication';
import { useTranslation } from 'react-i18next';
import IOpenVolume from './interfaces/IOpenVolume';

function useUpdateVolume () {

    const { t } = useTranslation();
    const putItem = usePutItem();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

    /** Create new volume survey
     * @param {string} companyId - guid of the company
     * @param {string} surveyId - guid of the survey
     * @param {IUpdateVolume} surveyData - object which describes the survey
     **/
    const updateVolume = async (companyId: string = "", surveyId: string="", surveyData: IUpdateVolume | ILockVolume | IOpenVolume) => {

        const basePath = apiConfig.basePath;
        const companyEndpoint = apiConfig.endpoints.companies
        const surveyEndpoint = apiConfig.endpoints.volumes;
        const url = new URL(`${basePath}/${companyEndpoint}/${companyId}/${surveyEndpoint}/${surveyId}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken,
            body: surveyData
        }

        try {

            const response = await putItem(url, options);
         
            return responseHandler(response, "useUpdateVolume", { operation: "update", messageHeader: t("features.volume.savedSuccessfully"), hide: false });


        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "Volume.UpdatePermissionDenied") body = t('features.survey.updatePermissionDenied');
            if (problemTitle === "Company.CompanyDataAccessDenied") body = t('features.company.companyDataAccessDenied');
            if (problemTitle === "Company.NotExist") body = t('features.company.notExist');
            if (problemTitle === "UserAccount.NotExist") body = t('features.account.userAccountNotExist');
            if (problemTitle === "General.RequestorNotExist") body = t('features.survey.requestorNotExist');
            if (problemTitle === "Volume.NotExist") body = t('features.survey.notExist');
            if (problemTitle === "Volume.PermanentlyLocked") body = t('features.survey.permanentlyLocked');
            if (problemTitle === "Volume.Closed") body = t('features.survey.closed');
            

            return errorHandler(error, "useUpdateVolume", { operation: "update", hide: false, messageBody: body });

        }

    }

    return { updateVolume }

}

export default useUpdateVolume;
