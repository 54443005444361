import useUpdateAccount from 'Features/Account/UpdateAccount/useUpdateAccount';
import IUpdateAccount from 'Features/Account/UpdateAccount/IUpdateAccount';


function useChangeCompany () {

    const { updateAccount } = useUpdateAccount();

    /** Change company for account
     * @param accountId - id of the account
     * @param companyId - id of the new company
     **/
    const changeCompany = async (account:IUpdateAccount, companyId: string) => {

        const currentCompany = account.companyId;
        account.companyId = companyId;

        let result = await updateAccount(currentCompany,account.id,account);

        return result;
    }

    return { changeCompany }

}

export default useChangeCompany;
