import { useState, useEffect, useContext } from "react";
import useGetVolume from "../ReadVolume/useGetVolume";
import useUpdateVolume from "./useUpdateVolume";
import { Form, Popup, Icon, Header, Grid } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";
import { ButtonContainer, SaveBtn, CancelBtn } from "Components/Buttons";
import IUpdateVolume from "./interfaces/IUpdateVolume";
import IVolume from "./interfaces/IVolume";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { InputWithUnit } from "Components/FormElements/InputWithUnit/InputWithUnit";
import { StateContext } from "Application/state/StateContext";
import { PageTitle } from "Components/Titles";
import ICategory from "../ReadVolume/interfaces/IReadVolumeCategory";
import { MessageContext } from "Features/Messages";
import useInputValidation from "Components/FormElements/InputWithUnit/useInputValidation";
import ConfirmModal from "Components/CSV/CSVImport/ConfirmModal";
import CSVImport from "Components/CSV/CSVImport/CSVImport";
import useSurvey from "Features/Survey/useSurvey";
import { UploadCompanySurveyAttachements, useUploadCompanySurveyAttachements } from 'Features/CompanyAttachement';

function UpdateVolume () {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const detailPath = location.pathname.replace("/edit","")

    const { userProfile } = useContext(StateContext);
    const { companyId, surveyId } = useParams();
    const [ isLoading, setIsLoading ] = useState(true);
    const { setMessages, addMessage } = useContext(MessageContext);
    const { isNonNegativeInteger } = useInputValidation();
    const { updateVolume } = useUpdateVolume();
    const { volume, getVolumeById } = useGetVolume();
    const { isEditingAllowed } = useSurvey();

    const [ attachements, setAttachements ] = useState([]);
    const { uploadCompanySurveyAttachements } = useUploadCompanySurveyAttachements(); 

    const [ categories, setCategories ] = useState<ICategory[]>([]);

    // CSV import
    const [ file, setFile ] = useState(undefined);
    const [ confirmModalOpen, setConfirmModalOpen ] = useState(false);

    // Fetch survey
    useEffect(() => {
        (async () => {
            if(companyId || userProfile.companyId) {
                await getVolumeById(companyId || userProfile.companyId,surveyId);
            }
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userProfile.companyId]);

     // set categories state
     useEffect(() => {
        setCategories(volume.categories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[volume.categories]);

    // count how many months each unit has been used during the period
    volume.categories.map((cat:any) => {
        cat.units.map((unit:any) => {
            const usageStartMonth = unit.companyUnitUsageStartMonth || 1;
            const usageStartYear = unit.companyUnitUsageStartYear || volume.period;
            const usageEndMonth = unit.companyUnitUsageEndMonth || 12;
            const usageEndYear = unit.companyUnitUsageEndYear || volume.period;
            if(usageStartYear>volume.period){
                //usage starts after the period
                unit.usageMonths=0;
            } else if(usageStartYear<volume.period){
                //usage starts before start of the period
                if(usageEndYear>volume.period){
                    //usage ends after end of the period
                    unit.usageMonths=12
                } else {
                    //usage from start of the period until usage end month
                    unit.usageMonths=usageEndMonth;
                }
            } else {
                //usage starts after start of the period
                if(usageEndYear>volume.period){
                    //usage ends after end of the period
                    unit.usageMonths=13-usageStartMonth;
                } else {
                    //usage from start of the period until usage end month
                    unit.usageMonths=usageEndMonth + 1 - usageStartMonth;
                }
            }
            return unit;
        });
        return cat;
    });

    const setUnit = (value:number | null, id:{category: string, unit: string, field: string}) => {
        
        const cats = [...categories];
        const category = cats.find(item => item.id===id.category) || {id:"", name:"",units:[]};
        const unit = category.units.find(item => item.unitId===id.unit) || {itemId: "", unitId: "", unitName: "", isCompanyUnit: true, amount: null, liters: null};

        if(id.field==="pcs"){
            value===null ? unit.amount = null : unit.amount = value;
        } else if(id.field==="liters"){
            value===null ? unit.liters = null : unit.liters = value;  
        }
        setCategories(cats);
    }

    const onSubmit = async () => {

        var uploadAttachementRequest = {
            companyId: userProfile.companyId,
            surveyId: surveyId || "",
            surveyName: volume.name || "Jäsenvolyymikysely",
            surveyTypeRef: "volume",
            attachements: attachements
        }

        let volumes:IVolume[] = [];
        let errors=false;
        categories.map(cat =>
            cat.units.map(unit => {
                if(!isNonNegativeInteger(unit.amount) || !isNonNegativeInteger(unit.liters)){
                    errors=true;
                }
                const volume:IVolume = {id: unit.itemId, amount: unit.amount, liters: unit.liters}
                volumes.push(volume);
                return unit;
            })
        );

        setMessages([]);
        if(errors){
            addMessage({header: t("features.survey.inputErrorHeader"), type: "error", body: t("features.survey.inputErrorDescription")});
            window.scrollTo(0,0);
        } else {
            setIsLoading(true);

            const volume:IUpdateVolume = {
                volumeItems: volumes
            }

            let result = await updateVolume(companyId || userProfile.companyId,surveyId,volume);

            await uploadCompanySurveyAttachements(uploadAttachementRequest);

            if (result && result.status === 204) return navigate(detailPath);
            
            setIsLoading(false);
        }
    }

    const clearUnits = () => {
        const cats = [...categories];
        cats.map((cat:any) => {
            cat.units.map((unit:any) => {
                unit.amount = null;
                unit.liters = null;
                return unit;
            });
            return cat;
        });
        setCategories(cats);
    }

    const csvDataToFields = (csvArray:any) => {

        // empty form
        clearUnits();

        // insert values from csv array
        const cats = [...categories];
        csvArray.map((item:any) => {
            const categoryId = item.kategoriaId;
            const unitId = item.yksikkoId;
            const unit = cats.find(cat => cat.id === categoryId)?.units.find(unit => unit.unitId === unitId);
            if(unit){
                unit.amount = isNonNegativeInteger(parseInt(item.kappaletta)) ? parseInt(item.kappaletta) : null;
                unit.liters = isNonNegativeInteger(parseInt(item.litraa)) ? parseInt(item.litraa) : null;
            }
            return item;
        });
    };

    return (
        
        <Form loading={isLoading} onSubmit={onSubmit} className="form-inline">

            <ConfirmModal open={confirmModalOpen} setOpen={setConfirmModalOpen} file={file} setFile={setFile} csvToFieldsFunction={csvDataToFields}/>
            
            <PageTitle 
                title={volume.name ? volume.name : t("pages.editVolume")} 
                button={
                    <Popup
                        trigger={<Icon name='info' color='blue' size='large' circular />}
                        content={t("features.volume.infoText")}
                        position='bottom left'
                        wide="very"
                    />
                }
            />

            {volume.id  ?
                isEditingAllowed(volume.isPermanentlyLocked,volume.openDate,volume.closeDate) ?
                    <>
                    <CSVImport file={file} setFile={setFile} setConfirmModalOpen={setConfirmModalOpen} templateFileName="volyymimalli.csv" />

                    {categories && categories.map((cat:any) =>
                        <div key={"cat" + cat.id}>
                            <Header as="h2">{cat.name}</Header>
                            <Grid>
                                {cat.units.map((unit:any) =>
                                    <Grid.Row key={unit.itemId} className="tight">
                                        <Grid.Column width={8}>
                                            <InputWithUnit
                                                label={unit.unitName + ((unit.usageMonths===12) ? "" : t("features.volume.usageMonths").replace("$prm$",unit.usageMonths))}
                                                id={"ls_unit" + unit.itemId}
                                                value={unit.liters}
                                                validateFor="nonNegativeInteger"
                                                onChangeFunc={setUnit}
                                                onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "liters"}}
                                                type="number"
                                                unit={t("features.volume.liters")}
                                                required
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={8} className="hide-asterisk">
                                            <InputWithUnit
                                                label=" "
                                                id={"pcs_unit" + unit.itemId}
                                                value={unit.amount}
                                                validateFor="nonNegativeInteger"
                                                onChangeFunc={setUnit}
                                                onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "pcs"}}
                                                type="number"
                                                unit={t("features.volume.pcs")}
                                                required
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                )}
                            </Grid>
                        </div>
                    )}
                    
                    <UploadCompanySurveyAttachements
                        companyId={userProfile.companyId}
                        surveyId={surveyId}
                        surveyTypeRef="volume"
                        setAttachements={setAttachements}
                    />

                    <ButtonContainer>
                        <SaveBtn />
                        <CancelBtn />
                    </ButtonContainer>
                    </>
                :
                    <p>{t("features.survey.editingNotAllowed")}</p>
            :
                <p>{t("features.survey.notFound")}</p>
            }
        </Form>
    );
}

export default UpdateVolume;
