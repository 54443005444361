import { PageTitle } from "Components/Titles";
import { ReadUnit } from "Features/Unit";
import { Container } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

function ViewUnit () {

    const { t } = useTranslation();

    return (
        <Container className="page">

            <PageTitle title={t("pages.unitDetail")} />
            <ReadUnit />

        </Container>
    );
}

export default ViewUnit;