import { usePutItem } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import { useTranslation } from 'react-i18next';
import apiConfig from 'Application/configurations/apiConfig';
import IAnnouncement from '../GetAnnouncementById/IAnnouncement';
import { useAccessTokens } from 'Features/Authentication';

const useUpdateAnnouncement = () => {

    const { t } = useTranslation();
    const putItem = usePutItem();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

    /** Update announcement by id
     * @param {integer} id - id of the updated item
     * @param {IAnnouncement} Announcement - object which describes the created announcement
     **/
    const updateAnnouncementById = async (id: any, announcement: IAnnouncement) => {

        const basePath = apiConfig.basePath;
        const endpoint = apiConfig.endpoints.announcements;
        const url = new URL(`${basePath}/${endpoint}/${id}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            body: announcement,
            accessToken: accessToken
        }

        try {

            const response = await putItem(url, options);
            return responseHandler(response, "useCreateAnnouncement", { operation: "update", hide: false });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "General.RequestorNotExist") body = t('features.announcements.requestorNotExist');
            if (problemTitle === "Announcement.UpdatePermissionDenied") body = t('features.announcements.updatePermissionDenied');
            if (problemTitle === "Announcement.EndDateIsBeforeStartDate") body = t('features.announcements.endDateIsBeforeStartDate');
            if (problemTitle === "Announcement.StartDateIsInThePast") body = t('features.announcements.startDateIsInThePast');
            if (problemTitle === "Announcement.NotExist") body = t('features.announcements.notExist');
            if (problemTitle === "Announcement.AlreadyExist") body = t('features.announcements.alreadyExist');

            return errorHandler(error, "useCreateAnnouncement", { operation: "update", hide: false, messageBody: body });
            
        }

    }

    return updateAnnouncementById;

}

export default useUpdateAnnouncement;
