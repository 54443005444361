import { usePostItem } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import apiConfig from 'Application/configurations/apiConfig';
import ICreateUnitPoolCorrection from "./ICreateUnitPoolCorrection";
import { useAccessTokens } from 'Features/Authentication';
import { useTranslation } from 'react-i18next';

function useCreateUnitPoolCorrection () {

    const { t } = useTranslation();
    const postItem = usePostItem();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

    /** Create new unit pool correction
     * @param {string} unitId - id of the unit
     * @param {ICreateUnitPoolCorrection} unitPoolCorrection - object which describes the created unit pool correction
     **/
    const createUnitPoolCorrection = async (unit: string, unitPoolCorrection: ICreateUnitPoolCorrection) => {

        const basePath = apiConfig.basePath;
        const unitEndpoint = apiConfig.endpoints.units;
        const correctionEndpoint = apiConfig.endpoints.unitpoolcorrections;
        const url = new URL(`${basePath}/${unitEndpoint}/${unit}/${correctionEndpoint}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken,
            body: unitPoolCorrection
        }

        try {

            const response = await postItem(url, options);
         
            return responseHandler(response, "useCreateUnitPoolCorrection", { operation: "create", messageHeader: t("features.unitPoolCorrection.savedSuccessfully"), hide: false });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "General.RequestorNotExist") body = t('features.unitPoolCorrection.requestorNotExist');
            if (problemTitle === "UnitPoolCorrection.CreatePermissionDenied") body = t('features.unitPoolCorrection.createPermissionDenied');
            if (problemTitle === "UnitErrors.NotExist") body = t('features.unit.notExist');

            return errorHandler(error, "useCreateUnitPoolCorrection", { operation: "create", hide: false, messageBody: body });
   
        }

    }

    return { createUnitPoolCorrection }

}

export default useCreateUnitPoolCorrection;
