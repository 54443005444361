import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
//import usePagedTable from './../hooks/usePagedTable';
import TableRow from './TableRow';
import IPagedTableBodyProps from '../interfaces/IPagedTableBodyProps';

/** Table Body
 * @param {array<string>} header - Table header as array of strings
 * @param {array<object>} contentKeys - Table content keys as array of objects
 * @param {array<object>} content - Table content as array of objects
 * @param {boolean} collapsable - table is collapsaple?
 * @param {number} activePage - which page is currently active?
 * @param {number} pageSize - how many items per page?
*/
const TableBody: React.FC<IPagedTableBodyProps> = ({
    header,
    contentKeys,
    content,
    collapsable = false,
    activePage,
    pageSize
}: IPagedTableBodyProps) => {

    const { t } = useTranslation();

    const start = activePage * pageSize;
    const end = start + pageSize;
    const pagedContent = content.slice(start, end);

    return (
        <Table.Body>

            {pagedContent.length === 0 &&
                <tr>
                    <td>
                        <p>{t('components.pagedTable.rowsNotFound')}</p>
                    </td>
                </tr>
            }

            {pagedContent.map((row: any, i: number) => {
                return <TableRow
                    row={row}
                    key={i}
                    header={header}
                    contentKeys={contentKeys}
                    collapsable={collapsable}
                />
            })}
        </Table.Body>
    );

}

export default TableBody;
