import React from 'react';
import { Grid } from 'semantic-ui-react';
import ISettingsContainer from './ISettingsContainer';

const SettingsContainer: React.FC<ISettingsContainer> = ({
    columns = 3,
    children = [],
    ...rest
}: ISettingsContainer) => {

    return (
        <Grid columns={columns} stackable {...rest}>
            <Grid.Row>

                {children && Array.isArray(children) ?
                    /* Map each Settings box inside grid column */
                    children.map((item:React.ReactNode, index: number) => {
                        return <Grid.Column key={index}>
                            {item}
                        </Grid.Column>
                    })
                :
                    /* show the only child */
                    <Grid.Column>
                        {children}
                    </Grid.Column>
                }

            </Grid.Row>
        </Grid>
    )
}

export default SettingsContainer;
