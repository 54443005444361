import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageContext } from 'Features/Messages';
import IResponseHandler from '../interfaces/IResponseHandler';
//import logMessage from '../utils/logMessage';
import { Message } from '../interfaces/types';

const useResponseHandler = () => {

    const { t } = useTranslation();
    const { addMessage } = useContext(MessageContext);

    /** Handle success responses
     * @description Handles success messages f.e Log to Console, Show message to user
     * @param {object} response - http response object
     * @param {string} caller - name of the calling hook f.e. usePostUser
     * @param {Operation} [options.operation] - optional operation for default message
     * @param {boolean} [options.hide] - Optional. if true, page message is not added, default is false
     * @param {string} [options.messageHeader] - Optional custom message (overwrites the default page message)
     * @param {string} [options.messageBody] - Optional custom message (overwrites the default page message)
     **/
    const responseHandler = async (response: any, caller: string, options?: IResponseHandler) => {

        let operation = options?.operation || null;
        let hide = options?.hide || false;
        let messageHeader = options?.messageHeader || null;
        let messageBody = options?.messageBody || null;
        let message: Message = { type:"success", header: null, body: "" };

        const messages = {
            list: { type:"success", header:t('features.responseHandler.list'), body: null },
            create: { type:"success", header:t('features.responseHandler.create'), body: null },
            read: { type:"success", header:t('features.responseHandler.read'), body: null },
            update: { type:"success", header:t('features.responseHandler.update'), body: null },
            delete: { type:"success", header:t('features.responseHandler.delete'), body: null }
        }

        // Set default message if operation is defined
        if (operation) message = messages[operation];

        // Set message header if header defined - overwrites default header
        if (messageHeader && typeof messageHeader === 'string' && messageHeader.length > 0) {
            message.header = messageHeader;
        }

        // Change message body if body defined - overwrites default header
        if (messageBody && typeof messageBody === 'string' && messageBody.length > 0) {
            message.body = messageBody;
        }

        // Show or hide message
        if (hide !== true && message.type && message.header) {
            await addMessage(message);
        }

        // Log message to console
        //logMessage(response, caller, hide, messageHeader, messageBody);

        return response;

    }

    return responseHandler;

}

export default useResponseHandler;
