import { useState, useEffect } from "react";
import useGetAccount from "../ReadAccount/useGetAccount";
import useUpdateAccount from "./useUpdateAccount";
import { Form } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";
import { ButtonContainer, SaveBtn, CancelBtn } from "Components/Buttons";
import IUpdateAccount from "./IUpdateAccount";
import { useNavigate, useParams } from "react-router-dom";
import { EditEmail } from "Components/Email/Email";

function UpdateAccount () {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { companyId, accountId } = useParams();
    const [ isLoading, setIsLoading ] = useState(true);
    
    const { updateAccount } = useUpdateAccount();
    const { account, getAccountById } = useGetAccount();

    const [ lastName, setLastName ] = useState("");
    const [ firstName, setFirstName ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ phoneNumber, setPhoneNumber ] = useState("");
    const [ entraId, setEntraId ] = useState("");
    const [ isActive, setIsActive ] = useState(true);

    // Fetch account
    useEffect(() => {
        (async () => {
            await getAccountById(companyId,accountId);
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // set initial values for account
    useEffect(() => {
        if (account) {
            setLastName(account.lastName);
            setFirstName(account.firstName);
            setEmail(account.email);
            setPhoneNumber(account.phoneNumber);
            setEntraId(account.id);
            setIsActive(account.isActive);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[account]);

    const onSubmit = async () => {

        setIsLoading(true);

        const account:IUpdateAccount = {
            "id": entraId,
            "firstName": firstName,
            "lastName": lastName,
            "phoneNumber": phoneNumber,
            "email": email,
            "isActive": isActive
        } 

        let result = await updateAccount(companyId,accountId,account);

        if (result && result.status === 204) return navigate(-2);
        
        setIsLoading(false);
    }

    return (
        
        <Form loading={isLoading} onSubmit={onSubmit}>
            {account.id ?
                <>
                <Form.Input
                    label={t("features.account.lastName")}
                    id="lastname"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                />
                <Form.Input
                    label={t("features.account.firstName")}
                    id="firstname"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                />
                <EditEmail
                    id="email"
                    label={t("features.account.email")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    disabled
                />
                <Form.Input
                    label={t("features.account.phoneNumber")}
                    id="phonenumber"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required
                />
                <Form.Input
                    label={t("features.account.entraId")}
                    id="entraId"
                    value={entraId}
                    onChange={(e) => setEntraId(e.target.value)}
                    required
                />
                <Form.Checkbox
                    label={t("features.account.accountIsActive")}
                    id="isActive"
                    onChange={(e,{value}) => setIsActive(!isActive)}
                    checked={isActive}
                />
                <ButtonContainer>
                    <SaveBtn />
                    <CancelBtn />
                </ButtonContainer>
                </>
            :
                <p>{t("features.account.notFound")}</p>
            }
        </Form>
    );
}

export default UpdateAccount;
