import { useEffect, useState, useContext } from "react";
import useGetCompanySurveys from "Features/Survey/ListSurveys/useGetCompanySurveys";
import { PagedTable } from "Components/Tables";
import { useTranslation } from "react-i18next";
import { Segment, Header } from "semantic-ui-react";
import { useParams, useLocation } from "react-router-dom";
import { StateContext } from "Application/state/StateContext";
import useGetCompany from "Features/Company/ReadCompany/useGetCompany";

declare interface IListSurveysProps {
    surveyRef?: string;
    showNew?: boolean;
    pageSize?: number;
    limit?: number;
}

function ListSurveys({surveyRef, showNew=true, pageSize=50, limit}:IListSurveysProps) {

    const { t } = useTranslation();
    const { userProfile }  = useContext(StateContext);
    const { companyId } = useParams();
    const { surveys, listSurveys } = useGetCompanySurveys();
    const { company, getCompanyById } = useGetCompany();
    const [ isLoading, setIsLoading ] = useState(true);
    const location = useLocation();
    
    const isAdmin = userProfile && userProfile.roles && userProfile.roles.find((role:any) => role.name==="Admin") ? true : false;

    // Fetch surveys
    useEffect(() => {
        (async () => {
            if(companyId || userProfile.companyId){
                await listSurveys(companyId || userProfile.companyId);
                await getCompanyById(companyId || userProfile.companyId);
            }
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userProfile.companyId]);

    // Init surveys for list
    useEffect(() => {
        surveys.map(item => {
            // survey closes at midnight, subtract 1 second to get the right date
            let closeDate = new Date(item.closeDate);
            closeDate.setSeconds(closeDate.getSeconds()-1);
            item.closeDate=closeDate;
            if(location.pathname==="/"){
                item.path="/surveys/"
            } else {
                item.path=location.pathname + "/"
            }
            if(!surveyRef){
                switch(item.surveyRef){
                    case "glassbottlereturnrate":
                        item.path += "glassbottlereturnrates/";
                        break;
                    case "transferfreightcost":
                        item.path += "transferfreightcosts/";
                        break;
                    case "budget":
                        item.path += "budgets/";
                        break;
                    case "volume":
                        item.path += "volumes/";
                        break;
                    case "distributionfreight":
                        item.path += "distributionfreights/";
                        break;
                    case "storagecost":
                        item.path += "storagecosts/";
                        break;
                    case "inventory":
                        item.path += "inventories/";
                        break;
                    case "clearing":
                        item.path += "clearings/";
                        break;
                }
            }
            return item;
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[surveys]);

    let filteredSurveys = surveys.filter(item => ((isAdmin || item.isAnswered) && item.surveyRef === (surveyRef || item.surveyRef)));
    if(!showNew){
        filteredSurveys = filteredSurveys.filter(item => item.isAnswered);
    }
    if(limit){
        filteredSurveys = filteredSurveys.slice(0,limit);
    }

    return (
        <Segment className="invisible" loading={isLoading}>
            {companyId &&
                <Segment className="invisible">
                <Header sub>{t("features.companyUnit.company")}</Header>
                <div>{company && company.name}</div>
                </Segment>
            }
            {isAdmin ?
                <PagedTable
                    header={[
                        t("features.survey.name"),
                        t("features.survey.closeDate"),
                        t("features.survey.isAnswered")
                    ]}

                    content={filteredSurveys}

                    contentKeys={[
                        {key: "name", type: "contentUrlLink",  urlKey: "path", targetKey: "id"},
                        {key: "closeDate", type: "date"},
                        {key: "isAnswered", type: "boolean"}
                    ]}

                    pageSize={pageSize}
                    resetPageOnContentChange={true}
                    collapsable={false}
                />
            :
                <PagedTable
                    header={[
                        t("features.survey.name"),
                        t("features.survey.closeDate")
                    ]}

                    content={filteredSurveys}

                    contentKeys={[
                        {key: "name", type: "contentUrlLink",  urlKey: "path", targetKey: "id"},
                        {key: "closeDate", type: "date"}
                    ]}

                    pageSize={pageSize}
                    resetPageOnContentChange={true}
                    collapsable={false}
                />
            }
        </Segment>
    );
}

export default ListSurveys;