import IRequestOptions from "../interfaces/IRequestOptions";

/** Create Fetch API options object
 * @description default content-type is application/json
 * @param {string} [opts.method] - Request method. Default is GET.
 * @param {any} [opts.body] - Request body object. Body is stringified
 * @param {string} [opts.contentType] - Content-type. Default is "application/json"
 * @param {string} [opts.accessToken] - access token string without Bearer keyword
 * @param {string} [opts.graphToken] - access token string without Bearer keyword for Graph API
 * @param {string} [opts.abortSignal] - Request Abort signal which allows the request to be aborted.
 * @returns {RequestInit} Fetch API Request init
 **/
const getRequestOptions = (opts: IRequestOptions): RequestInit  => {

    // Initialize request options
    const requestOptions: RequestInit = {
        "method": opts.method ? opts.method : "GET"
    }

    // Headers
    const headers = new Headers();

    // Content-type
    switch (opts.contentType) {

        case "multipart/form-data":
            // Do nothing, content type is added automatically
            break;

        case "application/json":
            headers.append("Content-Type", "application/json");
            break;

        default:
            headers.append("Content-Type", "application/json");
            break;

    }

    // Authorization headers
    if (opts.accessToken) headers.append("Authorization", `Bearer ${opts.accessToken}`);
    if (opts.graphToken) headers.append("Graph-Token", `Bearer ${opts.graphToken}`);

    // Set Request headers
    requestOptions.headers = headers;

    // Body
    const usedContentType = requestOptions.headers.get("Content-Type");
    
    if (opts.body && usedContentType === "application/json") requestOptions["body"] = JSON.stringify(opts.body);

    if (opts.body && usedContentType !== "application/json" && usedContentType !== "multipart/form-data") requestOptions["body"] = opts.body;

    if (opts.body && opts.contentType === "multipart/form-data") {

        const formData = new FormData();

        for (const [key, value] of Object.entries(opts.body)) {

            // Value is File?
            if (value instanceof File) {
                formData.append(key, value);
            }

            // Value is Array?
            if (Array.isArray(value)) {

                // Value [n] is File?
                value.forEach((item, index) => {
                    if (item instanceof File) {
                        formData.append("file", item);
                    }
                });
            }

            // Value is string?
            if (typeof value === "string") {
                formData.append(key, value);
            }
         
            // Value is string?
            if (typeof value === "number") {
                formData.append(key, value.toString());
            }

        }

        requestOptions["body"] = formData;

    }

    // Abort signal
    if (opts.abortSignal) requestOptions["signal"] = opts.abortSignal;

    return requestOptions;

}

export default getRequestOptions;
