import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from "react-router-dom";
import { Checkbox, Icon, Button } from 'semantic-ui-react';

/** UseCellContent provides, logic, data, validations and methods to be used in the Row components 
 * Used for example in the CollapsedRow and OpenRow components.
*/
const useCellContent = () => {
    
    const { t } = useTranslation();
    const navigate = useNavigate();

    /** Round to n digits.v
     * @param {number} number - number to be rounded
     * @param {number} decimals - decimals
     * @example roundNumber(3.14159265359, 2) => "3.14" 
    */
    let roundNumber = (number: number, decimals: number, fixed: boolean) => {
        if (decimals === undefined) decimals = 0;
        const factorOfTen = Math.pow(10, decimals);
        const roundedValue = Math.round(number * factorOfTen) / factorOfTen;
        return (fixed ? roundedValue.toFixed(decimals) : roundedValue);
    }

    /** See Docs/PagedTable.md for documentation!
     * @param {object} row - array item
     * @param {string} cellKey - key of the row object
     **/
    const getCellContent = (row: any, cellKey: any) => {
        switch(cellKey.type) {
            case 'actionLink':
                return <Link to='/' onClick={ (ev) => { ev.preventDefault(); cellKey.action(ev, row, cellKey); } } >{cellKey.text ? cellKey.text : row[cellKey.key]}</Link>;
            case 'actionButton':
                if(row[cellKey.disabled]){
                    return <Button disabled>{row[cellKey.key]}</Button>
                } else {
                    return <Button onClick={ (ev) => { ev.preventDefault(); cellKey.action(ev, row, cellKey); } } >{row[cellKey.key]}</Button>
                }
            case "array":
                return row[cellKey.key].map((item: any, index: number) => <div key={index}>{item}</div>)
            case 'boolean':
                    return row[cellKey.key] ? t('components.pagedTable.yes') : t('components.pagedTable.no');
            case 'contentUrlLink':
                return <Link to={`${row[cellKey.urlKey]}${row[cellKey.targetKey]}`}>{row[cellKey.key]}</Link>;
            case 'crudLinks':
                return <>
                    <Link to={`${cellKey.url}${row[cellKey.targetKey]}`}>{t("components.pagedTable.view")}</Link>
                    <span> | </span>
                    {/* Add´s '/edit' after the edit link! */}
                    <Link to={`${cellKey.url}${row[cellKey.targetKey]}/edit`}>{t("components.pagedTable.edit")}</Link>
                </>
            case 'date':
                return row[cellKey.key] ? new Date(row[cellKey.key]).toLocaleDateString("fi-FI") : "";
            case "datetime":
                return new Date(row[cellKey.key]).toLocaleDateString() + ' ' + new Date(row[cellKey.key]).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            case 'externalLink':
                return <a href={`${cellKey.target}${row[cellKey.targetKey]}`} target='_blank' rel="noopener noreferrer">{row[cellKey.key]}</a>; 
            case 'firstChars':
                    return row[cellKey.key] ? `${row[cellKey.key].substring(0,cellKey.limit)}...` : '';
            case 'float':
                return roundNumber(row[cellKey.key], (cellKey.decimal ? cellKey.decimal : 2), cellKey.fixed);
            case 'icons':
                return <>
                    <Icon name={row[cellKey.key]} className={row[cellKey.key2]} size='large'/>
                    {row[cellKey.key3] && <Icon name={row[cellKey.key]} className={row[cellKey.key3]} size='large'/>}
                </>
            case 'link':
                return <Link to={`${cellKey.target}${row[cellKey.targetKey]}`}>{cellKey.text ? cellKey.text : row[cellKey.key]}</Link>;
            case 'linkButton':
                return <Button icon className='extended-button' type={'reset'} onClick={() => navigate(`${cellKey.target}${row[cellKey.targetKey]}`)} >
                            <Icon name={'arrow right'} />
                            {t('components.buttons.view')}
                        </Button>;
            case 'linkWithTwoKeys':
                return <Link to={`${cellKey.target}${row[cellKey.targetKey]}`}>{row[cellKey.key]} {row[cellKey.key2]}</Link>;
            case 'linkWithTwoTargetsAndKeys':
                return <Link to={`${cellKey.target1}${row[cellKey.targetKey1]}${cellKey.target2}${row[cellKey.targetKey2] || ''}`}>{cellKey.text}</Link>;
            case 'select':
                return <Checkbox checked={row[cellKey.checked]} onChange={(ev) => { ev.preventDefault(); cellKey.action(ev, row, cellKey); } }/>;
            case 'textWithActionButton':
                if (row[cellKey.disabled]){
                    return <>
                        <div>{row[cellKey.key2]}</div>
                        <Button disabled>{row[cellKey.key]}</Button>
                    </>
                } else {
                    return <>
                        <div>{row[cellKey.key2]}</div>
                        <Button onClick={ (ev) => { ev.preventDefault(); cellKey.action(ev, row, cellKey); } } >
                            {row[cellKey.key]}
                        </Button>
                    </>
                }
            case 'time':
                return new Date(row[cellKey.key]).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            case 'twokeys':
                return `${row[cellKey.key]} ${row[cellKey.key2] ? row[cellKey.key2] : ''}`;
            default: 
                return row[cellKey.key];
        }
    }

    return getCellContent;

}

export default useCellContent;
