import { useState, useEffect } from "react";
import useGetCompany from "../ReadCompany/useGetCompany";
import useUpdateCompany from "./useUpdateCompany";
import { Form, Segment, Label, Grid } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";
import { ButtonContainer, SaveBtn, CancelBtn } from "Components/Buttons";
import IUpdateCompany from "./IUpdateCompany";
import { useNavigate, useParams } from "react-router-dom";
import { EditEmail } from "Components/Email/Email";

function UpdateCompany () {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { companyId } = useParams();
    const [ isLoading, setIsLoading ] = useState(true);
    
    const { updateCompany } = useUpdateCompany();
    const { company, getCompanyById } = useGetCompany();

    // Fetch company
    useEffect(() => {
        (async () => {
            if (companyId) {
                await getCompanyById(companyId);
                setIsLoading(false);
            }            
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const [ name, setName ] = useState("");
    const [ businessIdentityCode, setBusinessIdentityCode ] = useState("");
    const [ factoryAddressId, setFactoryAddressId ] = useState("");
    const [ factoryAddressLine1, setFactoryAddressLine1 ] = useState("");
    const [ factoryAddressPostalCode, setFactoryAddressPostalCode ] = useState("");
    const [ factoryAddressArea, setFactoryAddressArea ] = useState("");
    const [ billingAddressId, setBillingAddressId ] = useState("");
    const [ billingAddressLine1, setBillingAddressLine1 ] = useState("");
    const [ billingAddressPostalCode, setBillingAddressPostalCode ] = useState("");
    const [ billingAddressArea, setBillingAddressArea ] = useState("");
    const [ billingAddressEmail, setBillingAddressEmail ] = useState("");
    const [ billingAddressReference, setBillingAddressReference ] = useState("");
    const [ sharepointUrl, setSharepointUrl ] = useState("");
    const [ isActive, setIsActive ] = useState(true);

    // set initial values for company
    useEffect(() => {
        if (company) {
            setName(company.name);
            setBusinessIdentityCode(company.businessIdentityCode);
            const factoryAddress = company.addresses.find(item => item.type === 3);
            if(factoryAddress){
                setFactoryAddressId(factoryAddress.id);
                setFactoryAddressLine1(factoryAddress.addressLine1);
                setFactoryAddressPostalCode(factoryAddress.postalCode);
                setFactoryAddressArea(factoryAddress.area);
            }
            const billingAddress = company.addresses.find(item => item.type === 2);
            if(billingAddress){
                setBillingAddressId(billingAddress.id);
                setBillingAddressLine1(billingAddress.addressLine1);
                setBillingAddressPostalCode(billingAddress.postalCode);
                setBillingAddressArea(billingAddress.area);
                setBillingAddressEmail(billingAddress.email);
            }
            setBillingAddressReference(company.billing.reference);
            setSharepointUrl(company.sharePointDocumentLibrary.urlSegment)
            setIsActive(company.isActive);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[company]);

    const onSubmit = async () => {

        setIsLoading(true);

        const company:IUpdateCompany = {
            "name": name,
            "businessIdentityCode": businessIdentityCode.trim(),
            "isActive": isActive,
            "addresses": [
                {
                    "id": factoryAddressId,
                    "type": 3,
                    "addressLine1": factoryAddressLine1,
                    "area": factoryAddressArea,
                    "postalCode": factoryAddressPostalCode,
                    "email": null
                },
                {
                    "id": billingAddressId,
                    "type": 2,
                    "addressLine1": billingAddressLine1,
                    "area": billingAddressArea,
                    "postalCode": billingAddressPostalCode,
                    "email": billingAddressEmail
                }
            ],
            "billing": {
                "reference": billingAddressReference
            },
            "sharePointDocumentLibrary": {
                "urlSegment": sharepointUrl
            }
        } 

        let result = await updateCompany(companyId,company);

        if (result && result.status === 204) return navigate(-1);
        
        setIsLoading(false);
    }

    return (
        
        <Form loading={isLoading} onSubmit={onSubmit}>
            {company.name ?
                <><Grid>
                    <Grid.Row>
                        <Grid.Column width={10}>
                            <Form.Input
                                label={t("features.company.name")}
                                id="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                autoComplete="off"
                                required
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Form.Input
                                label={t("features.company.businessIdentityCode")}
                                id="businessIdentityCode"
                                value={businessIdentityCode}
                                onChange={(e) => setBusinessIdentityCode(e.target.value)}
                                required
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Segment className="form-segment">
                    <Label>{t("features.company.factoryAddress")}</Label>
                    <Form.TextArea
                        label={t("features.company.address")}
                        id="factoryAddressLine1"
                        value={factoryAddressLine1}
                        onChange={(e) => setFactoryAddressLine1(e.target.value)}
                        required
                    />
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={6}>
                            <Form.Input
                                label={t("features.company.postalCode")}
                                id="factoryAddressPostalCode"
                                value={factoryAddressPostalCode}
                                onChange={(e) => setFactoryAddressPostalCode(e.target.value)}
                                required
                            />
                            </Grid.Column>
                            <Grid.Column width={10}>
                            <Form.Input
                                label={t("features.company.area")}
                                id="factoryAddressArea"
                                value={factoryAddressArea}
                                onChange={(e) => setFactoryAddressArea(e.target.value)}
                                required
                            />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment className="form-segment">
                    <Label>{t("features.company.billingAddress")}</Label>
                    <Form.TextArea
                        label={t("features.company.address")}
                        id="billingAddressLine1"
                        value={billingAddressLine1}
                        onChange={(e) => setBillingAddressLine1(e.target.value)}
                        required
                    />
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={6}>
                            <Form.Input
                                label={t("features.company.postalCode")}
                                id="billingAddressPostalCode"
                                value={billingAddressPostalCode}
                                onChange={(e) => setBillingAddressPostalCode(e.target.value)}
                                required
                            />
                            </Grid.Column>
                            <Grid.Column width={10}>
                            <Form.Input
                                label={t("features.company.area")}
                                id="billingAddressArea"
                                value={billingAddressArea}
                                onChange={(e) => setBillingAddressArea(e.target.value)}
                                required
                            />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <EditEmail
                        id="billingAddressEmail"
                        label={t("features.company.billingEmail")}
                        value={billingAddressEmail}
                        onChange={(e) => setBillingAddressEmail(e.target.value)}
                        required 
                    />
                    <Form.Input
                        label={t("features.company.billingReference")}
                        id="billingAddressReference"
                        value={billingAddressReference}
                        onChange={(e) => setBillingAddressReference(e.target.value)}
                    />
                </Segment>
                <Form.Input
                    label={t("features.company.sharepointUrl")}
                    id="sharepointUrl"
                    value={sharepointUrl}
                    onChange={(e) => setSharepointUrl(e.target.value)}
                />
                <Form.Checkbox
                    label={t("features.company.companyIsActive")}
                    id="isActive"
                    onChange={(e,{value}) => setIsActive(!isActive)}
                    checked={isActive}
                />
                <ButtonContainer>
                    <SaveBtn />
                    <CancelBtn />
                </ButtonContainer>
                </>
            :
                <p>{t("features.company.notFound")}</p>
            }
        </Form>
    );
}

export default UpdateCompany;
