import { Fragment } from 'react';
//import { useTranslation } from 'react-i18next';
import { Breadcrumb } from "semantic-ui-react";
import { useLocation, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

/** Use BreadCrumb provides, logic, data, validations and methods to be used in the BreadCrumb component */
const useBreadCrumb = () => {

    //const { t } = useTranslation();
    const location = useLocation();

    const { t } = useTranslation();

    const isGuid = (text:string) => {
        const regExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
        return regExp.test(text);
    }

    /* If you must use traslation.json for the breadcrumb. 
       Easieast way is to create own section which contains the translation
       f.e. "url: {
            "home": "Home",
            "profile": "User profile"
       }"
    */
    const getPathSections = () => {

        const pathname = location.pathname;                         // Get location pathname '/profile/edit/'
        const replaced = pathname.replace(/^\/|\/$/g, '');          // remove '/' from start and end => 'profile/edit'        
        const pathArray = replaced.split("/");                      // convert path to array of strings => '[ "profile", "edit" ]'        

        const components: React.ReactNode[] = [];

        let lastPathName = ""

        if (pathArray && pathArray.length > 0 && pathArray[0].length > 1) {
            pathArray.forEach((path: string, index: number) => {

                const upperCasePathName = path.charAt(0).toUpperCase() + path.slice(1);     // Uppercase first character
                const pathName =                                                            
                    isGuid(path) ?                                                          // if path is a guid, it is a detail view
                        t('components.layout.breadcrumb.singular.' + lastPathName)          // -> show singular form of previous path name
                    :                                                                       // e.g. companies > company
                        t('components.layout.breadcrumb.' + upperCasePathName);
                lastPathName = pathName;
                const isLastIndex = (pathArray.length - 1) === index;                       // Check if current item last index
                const currentPathLastIndex = pathname.lastIndexOf(path);                    // get last index of current path
                const currentPath = pathname.slice(0, currentPathLastIndex) + path;         // create link path
                

                // Push path to components
                components.push(
                    <Fragment key={index}>
                        <Breadcrumb.Divider icon='right angle' />
                        <Breadcrumb.Section active={isLastIndex}>
                            { isLastIndex ?
                                <span>{pathName}</span>
                             :
                                 <Link to={currentPath}>{pathName}</Link>
                            }
                        </Breadcrumb.Section>
                    </Fragment>   
                );

            });
        }

        return components;

    }

    return { getPathSections };

}

export default useBreadCrumb;
