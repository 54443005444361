import { ReadInventory } from "Features/Survey";
import { Container } from "semantic-ui-react";

function ViewInventory () {

    return (
        <Container className="page">

            <ReadInventory />

        </Container>
    );
}

export default ViewInventory;