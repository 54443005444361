import { useTranslation } from 'react-i18next';
import { Container } from 'semantic-ui-react';
import { UpdateAnnouncement } from 'Features/Announcements';

const EditAnnouncement = () => {

    const { t } = useTranslation();

    return (
        <Container className="page">

            <h1>{t('pages.editAnnouncement')}</h1>

            <UpdateAnnouncement/>

        </Container>
    );
}

export default EditAnnouncement;
