import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Table } from 'semantic-ui-react';
import { NewBtn, ViewBtn, EditBtn, NewIconBtn, ViewIconBtn, EditIconBtn, SaveBtn, CancelBtn, DeleteBtn, DeleteIconBtn } from 'Components/Buttons';
import { PageTitle } from 'Components/Titles';

const Buttons = () => {

    const { t } = useTranslation();
    const [ open, setOpen ] = useState(false);

    console.log("Delete modal is open: " + open.toString());

    return (
        <Container className="page">


            <PageTitle title={t('pages.buttons')}/>

            <p>See Semantic UI React Buttons <a href="https://react.semantic-ui.com/elements/button/#types-basic">documentation</a> for more information.</p>

            <h1>Extended Buttons</h1>
            <ul>
                <li>Button Component is just extended Semantic UI React Button component.</li>
                <li>All buttons should accept all Semantic UI React Button props.</li>
                <li>Some props are set as defaults or constants that cant be changed using props.</li>
            </ul>

            <h4>Form button container</h4>
            <p>Components / Forms export button container which is meant to be used in forms</p>

            <h4>Form buttons</h4>
            
            <p>Components / Forms export buttons which are meant to be used in forms inside the button container.</p>

            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Button</Table.HeaderCell>
                        <Table.HeaderCell>Example 1</Table.HeaderCell>
                        <Table.HeaderCell>Example 2</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>NewBtn</Table.Cell>
                        <Table.Cell><NewBtn to="/form/address/here" /></Table.Cell>
                        <Table.Cell><NewIconBtn to="/form/address/here" /></Table.Cell>
                        <Table.Cell>Open new form</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>ViewBtn</Table.Cell>
                        <Table.Cell><ViewBtn to="/form/address/here" /></Table.Cell>
                        <Table.Cell><ViewIconBtn to="/form/address/here" /></Table.Cell>
                        <Table.Cell>Open view form</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>EditBtn</Table.Cell>
                        <Table.Cell><EditBtn to="/form/address/here" /></Table.Cell>
                        <Table.Cell><EditIconBtn to="/form/address/here" /></Table.Cell>
                        <Table.Cell>Open edit form</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>

            <h4>Form action buttons</h4>

            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Button</Table.HeaderCell>
                        <Table.HeaderCell>Example 1</Table.HeaderCell>
                        <Table.HeaderCell>Example 2</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>SaveBtn</Table.Cell>
                        <Table.Cell><SaveBtn /></Table.Cell>
                        <Table.Cell>-</Table.Cell>
                        <Table.Cell>Submit form</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>CancelBtn</Table.Cell>
                        <Table.Cell><CancelBtn /></Table.Cell>
                        <Table.Cell>-</Table.Cell>
                        <Table.Cell>Go back -1 in history</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>DeleteBtn</Table.Cell>
                        <Table.Cell><DeleteBtn setOpen={setOpen}/></Table.Cell>
                        <Table.Cell><DeleteIconBtn setOpen={setOpen}/></Table.Cell>
                        <Table.Cell>Call function which can be used to open delete modal</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>

        </Container>
    );
}

export default Buttons;