import { YesNoModal } from "Components/Modals";
import useCSVImport from "./useCSVImport";
import { useTranslation } from "react-i18next";
import IConfirmModal from "./IConfirmModal";

/** CSV import confirmation modal
 * to open or close modal
 * @param {boolean} open - React useState "open" -value
 * @param {function} setOpen - React useState "setOpen" -function
 *
 * to pass on to handleCSVImport function
 * @param {File} file - file to read in
 * @param {function} setFile - setFile function to clear the value
 * @param {function} csvToFieldsFunction - method used to fill in values to form
*/
const ConfirmModal = ({open, setOpen, file, setFile, csvToFieldsFunction}:IConfirmModal) => {

    const { t } = useTranslation();

    const { handleCSVImport } = useCSVImport();

    return (
        <YesNoModal 
            type="warning"
            header={t("components.csv.overwriteHeader")}
            text={t("components.csv.overwriteDescription")}
            method={handleCSVImport}
            noClick={false}
            open={open}
            setOpen={setOpen}
            handleClose={false}
            disableYesButton={false}
            children=""
            item={{file: file, setFile: setFile, func: csvToFieldsFunction}}
        />
    );
}

export default ConfirmModal;