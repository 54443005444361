import { useState } from "react";
import { useGetItems } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import { useTranslation } from 'react-i18next';
import apiConfig from 'Application/configurations/apiConfig';
import IListSurveys from './IListSurveys';
import { useAccessTokens } from 'Features/Authentication';

function useGetGlassBottleReturnRates () {

    const { t } = useTranslation();
    const [ surveys, setSurveys ] = useState<IListSurveys[]>([]);
    const getItems = useGetItems();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;
    

   /** List all surveys
     * @returns {Array<IListSurveys>} surveys */
    const listSurveys = async (companyId:string) => {

        const basePath = apiConfig.basePath;
        const companyEndpoint = apiConfig.endpoints.companies;
        const surveyEndpoint = apiConfig.endpoints.surveys;
        const url = new URL(`${basePath}/${companyEndpoint}/${companyId}/${surveyEndpoint}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken
        }
        
        try {

            const response = await getItems(url, options);
            const data = response?.data || [];
            setSurveys(data);
            return responseHandler(response, "useGetCompanySurveys", { operation: "list", hide: true });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "General.RequestorNotExist") body = t('features.surveys.requestorNotExist');
            if (problemTitle === "Surveys.ListPermissionDenied") body = t('features.surveys.listPermissionDenied');
            if (problemTitle === "Surveys.SurveyDataAccessDenied") body = t('features.surveys.surveyDataAccessDenied');

            return errorHandler(error, "useGetCompanySurveys", { operation: "list", hide: false, messageBody: body });
   
        }

    }

    return { surveys, setSurveys, listSurveys };

}

export default useGetGlassBottleReturnRates;