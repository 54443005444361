import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import config from 'Application/configurations/appConfig';

/** Use Header provides, logic, data, validations and methods to be used in the Header component */
const useHeader= () => {

    const { t } = useTranslation();
    const [ appName, setAppName ] = useState(t('app'));

    // Set the app name
    useEffect(() => {

        let name = t('app');
            //name +=  " ";
            //name +=  config.version;

        if (config.environment === "development") {
            name += " - DEV";
        }

        setAppName(name);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return [ appName ];

}

export default useHeader;
