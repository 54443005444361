import { useEffect } from 'react';
import { Message, Icon } from 'semantic-ui-react';
import useListAnnouncements from '../GetAllAnnouncements/useListAnnouncements';
import usePortalAnnouncement from './usePortalAnnouncement';
import IAnnouncement from '../GetAnnouncementById/IAnnouncement';
import './portalAnnouncement.css';

/** Component is used to show portal announcements to the user */
const PortalAnnouncement = () => {

    const { announcements, setAnnouncements, listAnnouncements } = useListAnnouncements();
    const { filterAnnouncements, hideAnnouncement } = usePortalAnnouncement();
    const visibleAnnouncements = filterAnnouncements(announcements);

    // Load announcements on load
    useEffect(() => {
        (async() => {
            await listAnnouncements({isActive: true});
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='portal-announcement-viewer'>
            { visibleAnnouncements.length > 0 && visibleAnnouncements.map((item: IAnnouncement, index: number) => {
                return (
                    <Message
                        key={index}
                        visible={item.visible}
                        info={item.type === 'info' ? true : false}
                        warning={item.type === 'warning' ? true : false}
                        positive={(item.type === 'positive' || item.type === 'success') ? true : false}
                        negative={(item.type === 'negative' || item.type === 'error') ? true : false}
                        onDismiss={() => {
                            const _id = item && item.id ? item.id : 0;
                            const itemHidden = hideAnnouncement(_id, announcements) || [];
                            setAnnouncements(itemHidden);
                        }}
                    >
                        <div style={{ display: 'flex' }}>
                            <div style={{ position: 'absolute', top: '50%', marginLeft: '20px', transform: 'translate(-50%, -50%)' }}>
                                {item.type === 'info' ? <Icon name='info circle' size='big'/> : null}
                                {item.type === 'warning' ? <Icon name='warning circle' size='big' /> : null}
                                {(item.type === 'positive' || item.type === 'success') ? <Icon name='check circle' size='big' /> : null}
                                {(item.type === 'negative' || item.type === 'error') ? <Icon name='times circle outline' size='big' /> : null}
                            </div>
                            <div style={{ marginLeft: '50px' }}>

                                <Message.Header style={{ display: 'inline' }}>
                                    {item.title}
                                </Message.Header>
                                {item.description && 
                                    <p>{item.description}</p>
                                }

                            </div>
                        </div>
                    </Message>
                );
            })}

        </div>
    );
}

export default PortalAnnouncement;
