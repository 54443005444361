import { Container } from 'semantic-ui-react';
import { PageTitle } from 'Components/Titles';
import { ListUnits } from 'Features/Unit';

import { useTranslation } from "react-i18next";

function UnitList () {

    const { t } = useTranslation();

    return (
        <Container className='page'>
        
            <PageTitle title={t("pages.units")} />

            <ListUnits />

        </Container>
    );
}

export default UnitList;