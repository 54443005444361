import { useEffect, useState } from 'react';
import useGetUnits from "./useGetUnits";
import { PagedTable } from "Components/Tables";
import { useTranslation } from "react-i18next";
import { Segment, Form } from 'semantic-ui-react';

function ListUnits () {

    const { t } = useTranslation();
    const { units, listUnits } = useGetUnits();
    const [ isLoading, setIsLoading ] = useState(true);
    const [ onlyActive, setOnlyActive ] = useState(true);

    // Fetch units
    useEffect(() => {
        (async () => {
            await listUnits();
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // Init units
    useEffect(() => {
       units && units.map((unit:any) => {
            unit.categoryName=unit.category.name;
            return unit;
       });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[units]);

    return (
        <Segment className="invisible" loading={isLoading}>
            <Form>
                <Form.Checkbox
                    label={t("features.unit.showOnlyActiveUnits")}
                    id="showOnlyActive"
                    onChange={(e,{value}) => setOnlyActive(!onlyActive)}
                    checked={onlyActive}
                />
            </Form>
            <PagedTable
                header={[
                    t("features.unit.name"),
                    t("features.unit.category"),
                    t("features.unit.active")
                ]}
            
                content={onlyActive ? units.filter(item => item.isActive) : units}
            
                contentKeys={[
                    {key: "name", type: "link",  target: "/management/units/", targetKey: "id"},
                    {key: "categoryName"},
                    {key: "isActive", type: "boolean"}
                ]}
            
                pageSize={50}
                resetPageOnContentChange={true}
                collapsable={false}
            />
        </Segment>
    );
}

export default ListUnits;