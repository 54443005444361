//import { useState } from 'react';

const useValidateEmail = () => {

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    /** Validate single email 
    * @param {string} email single email
    * @returns return true if email is valid, null, emty, 0 or undefined else false.*/
    const isValidEmail = (email:string) => {
        
        if (!email) return true;

        if (!emailRegex.test(email)) {
            return false;
        } else {
            return true;
        };

    };

    /** Validate array of emails 
    * @param {array} emails array of emails 
    * @returns return true if all emails are valid or array is empty, null, 0 or undefined else false.*/
    const isValidArrayOfEmails = (emails:string[]) => {

        if (!emails || emails.length === 0) return true;

        let isValid = true;

        // All emails must be valid
        emails.forEach(email => {
            if (!isValidEmail(email)) isValid = false;
        });

        return isValid;

    };

    return { isValidEmail, isValidArrayOfEmails };

};

export default useValidateEmail;
