import { useDeleteItem } from 'Features/HTTP/RequestHandlers';
import { useTranslation } from 'react-i18next';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import apiConfig from 'Application/configurations/apiConfig';
import { useAccessTokens } from 'Features/Authentication';

const useDeleteAnnouncement = () => {

    const { t } = useTranslation();
    const deleteItem = useDeleteItem();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

    /** Delete announcement by id
     * @param {number} id - id of the deleted announcement
     **/
    const deleteAnnouncementById = async (id: any) => {

        const basePath = apiConfig.basePath;
        const endpoint = apiConfig.endpoints.announcements;
        const url = new URL(`${basePath}/${endpoint}/${id}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken
        }

        try {

            const response = await deleteItem(url, options);

            return responseHandler(response, "useDeleteAnnouncement", { operation: "delete", hide: false });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "General.RequestorNotExist") body = t('features.announcements.requestorNotExist');
            if (problemTitle === "Announcement.DeletePermissionDenied") body = t('features.announcements.deletePermissionDenied');
            if (problemTitle === "Announcement.NotExist") body = t('features.announcements.notExist');

            return errorHandler(error, "useDeleteAnnouncement", { operation: "delete", hide: false, messageBody: body });

        }

    }

    return deleteAnnouncementById;

}

export default useDeleteAnnouncement;
