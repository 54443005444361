import { useTranslation } from 'react-i18next';
import { Container } from 'semantic-ui-react';
import IUploadCompanySurveyAttachementProps from './IUploadCompanySurveyAttachementProps';

import { FileDropzone } from 'Components/Files';

const UploadCompanySurveyAttachements = ({ companyId, surveyId, surveyTypeRef, setAttachements }: IUploadCompanySurveyAttachementProps) => {

    const { t } = useTranslation();

    return (
        <Container style={{display: 'flex'}}>
            <FileDropzone
                setFiles={setAttachements}
                maxFiles={3}
                title={t('features.companyFiles.attachements')}
            />
        </Container>
    )

}

export default UploadCompanySurveyAttachements;
