import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Segment, Header } from 'semantic-ui-react';
import { ButtonContainer, CancelBtn, EditBtn, DeleteBtn } from 'Components/Buttons';
import useViewAnnouncement from 'Features/Announcements/GetAnnouncementById/useReadAnnouncement'
import DeleteAnnouncementModal from '../DeleteAnnouncement/DeleteAnnouncementModal';
import 'react-datepicker/dist/react-datepicker.css';

/** View announcement form is used to view single portal announcement details */
const GetAnnouncementById: React.FC = () => {

    const { t } = useTranslation();
    let { id } = useParams();
    const { announcement, readAnnouncementById } = useViewAnnouncement();
    const [ modalIsOpen, setModalIsOpen ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    
    // Read announcement by id on page load
    useEffect(() => {
        (async () => {
            setIsLoading(true);
            await readAnnouncementById(id);
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Segment className="invisible" loading={isLoading} >

            {/* Delete announcement modal */}
            <DeleteAnnouncementModal
                id={id}
                isOpen={modalIsOpen}
                setIsOpen={setModalIsOpen}
                setIsLoading={setIsLoading}
            />

            {/* Title */}
            <Header sub>{t('features.announcements.title')}</Header>
            <div>{announcement.title}</div>

            {/* Description */}
            <Header sub>{t('features.announcements.description')}</Header>
            <div>{announcement.description}</div>

            {/* Start date */}
            <Header sub>{t('features.announcements.startDate')}</Header>
            <div>{new Date(announcement.startDate).toLocaleDateString("fi-FI")}</div>

            {/* End date */}
            <Header sub>{t('features.announcements.endDate')}</Header>
            <div>{new Date(announcement.endDate).toLocaleDateString("fi-FI")}</div>

            {/* Message Type 
            <Header sub>{t('features.announcements.type')}</Header>
            <div>{announcement.type}</div>
            */}

            {/* Visible 
            <Header sub>{t('features.announcements.visible')}</Header>
            */}

            <ButtonContainer>
                <EditBtn to={"/management/announcements/" + id + "/edit"} />
                <DeleteBtn setOpen={setModalIsOpen} />
                <CancelBtn />
            </ButtonContainer>

        </Segment>
    );

}

export default GetAnnouncementById;
