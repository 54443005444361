import { useTranslation } from 'react-i18next';
import { Dimmer, Loader, Image } from 'semantic-ui-react';
import './loading.css';

/** App loading component 
 * This is used when app is not yet started in the index.js
 * @param {string} [text] - Loading text
 * @param {string} [path] - Image path
 */
const AppLoading: React.FC = () => {

    const { t } = useTranslation();

    return (
        <div id='loader'>
            <Dimmer active>
                <Loader size='massive'>{t('components.layout.appLoading.loading')}</Loader>
                <div className='loader-content'>
                    <Image src="/img/logo.png" width={60} /> <span>{t('components.layout.appLoading.company')}</span>
                </div>
            </Dimmer>
        </div>
    );

}

export default AppLoading;
