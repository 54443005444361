import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import useGetDistributionFreight from "./useGetDistributionFreight";
import useGetCompany from "Features/Company/ReadCompany/useGetCompany";
import { useParams } from "react-router-dom";
import { Header, Segment, Button, Icon } from "semantic-ui-react";
import { ButtonContainer, EditBtn } from "Components/Buttons";
import { StateContext } from "Application/state/StateContext";
import { PageTitle } from "Components/Titles";
import useSurvey from "Features/Survey/useSurvey";
import useUpdateDistributionFreight from "../UpdateDistributionFreight/useUpdateDistributionFreight";
import { YesNoModal } from "Components/Modals";
import { ListCompanySurveyAttachements } from "Features/CompanyAttachement";

function ReadDistributionFreight () {

    const { t } = useTranslation();
    const { userProfile } = useContext(StateContext);
    const { companyId, surveyId } = useParams();
    const { distributionFreight, getDistributionFreightById } = useGetDistributionFreight();
    const { company, getCompanyById } = useGetCompany();
    const [ isLoading, setIsLoading ] = useState(true);
    const { isEditingAllowed } = useSurvey();
    const { updateDistributionFreight } = useUpdateDistributionFreight();
    const [ lockSurveyModalOpen, setLockSurveyModalOpen ] = useState(false);
    const [ openSurveyModalOpen, setOpenSurveyModalOpen ] = useState(false);
    const [ isLocked, setIsLocked ] = useState(true);
    const [ isOpen, setIsOpen ] = useState(true);
    const [ isEditable, setIsEditable ] = useState(false);
    const [ deadline, setDeadline ] = useState<null | Date>(null);

    const isAdmin = userProfile && userProfile.roles && userProfile.roles.find((item:any) => item.name==="Admin") ? true : false;

    // Fetch ditribution freight data
    useEffect(() => {
        (async () => {
            if(companyId || userProfile.companyId){
                await getDistributionFreightById(companyId || userProfile.companyId,surveyId);
            }
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userProfile.companyId]);

    // Fetch company
    useEffect(() => {
        (async () => {
            if(distributionFreight.companyId){
                await getCompanyById(distributionFreight.companyId);
                setIsEditable(isEditingAllowed(distributionFreight.isPermanentlyLocked,distributionFreight.openDate,distributionFreight.closeDate))
                setIsLocked(distributionFreight.isPermanentlyLocked);
                setIsOpen(new Date(distributionFreight.closeDate)>new Date());
                let dl = new Date(distributionFreight.closeDate);
                dl.setSeconds(dl.getSeconds()-1);
                setDeadline(dl);
                setIsLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[distributionFreight]);

    const lockSurvey = async (answer:boolean) => {
        setLockSurveyModalOpen(false);
        setIsLoading(true);

        const distributionFreight = {
            isPermanentlyLocked: true
        }
        let result = await updateDistributionFreight(companyId || userProfile.companyId,surveyId,distributionFreight);
        if (result && result.status === 204) {
            setIsLocked(true);
            setIsEditable(false);
        }
        setIsLoading(false);
        window.scrollTo(0,0);
    }

    const openSurvey = async (answer:boolean) => {
        setOpenSurveyModalOpen(false);
        setIsLoading(true);

        // open survey until the end of next day
        let closedate = new Date()
        closedate = new Date(closedate.getFullYear(),closedate.getMonth(),closedate.getDate()+2);
        const distributionFreight = {
            closeDate: closedate
        }
        let result = await updateDistributionFreight(companyId || userProfile.companyId,surveyId,distributionFreight);
        if (result && result.status === 204) {
            setIsOpen(true);
            closedate.setSeconds(closedate.getSeconds()-1);
            setDeadline(closedate);
        }
        
        setIsLoading(false);
        window.scrollTo(0,0);
    }

    return (
        <>
        <YesNoModal 
            type="save"
            header={t("features.survey.lockSurvey")}
            text={t("features.survey.lockSurveyConfirm")}
            method={lockSurvey}
            open={lockSurveyModalOpen}
            setOpen={setLockSurveyModalOpen}
            item=""
            noClick={false}
            handleClose={true}
            disableYesButton={false}
        />
        <YesNoModal 
            type="save"
            header={t("features.survey.openSurvey")}
            text={t("features.survey.openSurveyConfirm")}
            method={openSurvey}
            open={openSurveyModalOpen}
            setOpen={setOpenSurveyModalOpen}
            item=""
            noClick={false}
            handleClose={true}
            disableYesButton={false}
        />
        <PageTitle title={distributionFreight.name!=='' ? distributionFreight.name : t("pages.distributionFreightDetail")} />
        <Segment className="invisible" loading={isLoading}>
            {distributionFreight.id ?
                <>
                <Header as="h2">{t("features.distributionFreight.distributionFreightPeriod")}</Header>
                {isAdmin &&
                    <>
                    <Header sub>{t("features.distributionFreight.deadline")}</Header>
                    <div>{deadline?.toLocaleDateString("fi-FI")}</div>
                    </>
                }
                {companyId &&
                    <>
                    <Header sub>{t("features.distributionFreight.company")}</Header>
                    <div>{company && company.name}</div>
                    </>
                }

                <Header sub>{t("features.distributionFreight.compensation")}</Header>
                <div>{distributionFreight && distributionFreight.compensation?.toFixed(2)} {t("features.distributionFreight.euros")}</div>

                <Header sub>{t("features.distributionFreight.sold")}</Header>
                <div>{distributionFreight && distributionFreight.sold} {t("features.distributionFreight.pallets")}</div>

                <ListCompanySurveyAttachements 
                    companyId={userProfile.companyId}
                    surveyId={surveyId}
                    surveyTypeRef={"distributionfreight"}
                />

                <ButtonContainer>
                        <EditBtn to={"edit"} disabled={!isEditable} />
                        {isAdmin &&
                        <>
                        <Button
                            icon
                            className='extended-button lock-button'
                            labelPosition="left"
                            onClick={() => setLockSurveyModalOpen(true) }
                            disabled={isLocked}
                        >
                            <Icon name={'lock'} />
                            {t("features.survey.lock")}
                        </Button>
                        <Button
                            icon
                            className='extended-button date-button'
                            labelPosition="left"
                            onClick={() => setOpenSurveyModalOpen(true) }
                            disabled={isLocked || isOpen}
                        >
                            <Icon name={'calendar'} />
                            {t("features.survey.openSurvey")}
                        </Button>
                        </>
                    }
                </ButtonContainer>
                </>
            :
                <p>{t("features.survey.notFound")}</p>
            }

        </Segment>
        </>
    );
}

export default ReadDistributionFreight;
