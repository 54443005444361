// MSAL imports
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult, Configuration } from "@azure/msal-browser";

const instantiateMsal = (msalConfig: Configuration) => {

    // This must be imported in the index.ts file. Do not set it in the App component!
    // MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders!
    const msalInstance = new PublicClientApplication(msalConfig);

    // Default to using first account if no account is active on page load.
    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
            msalInstance.setActiveAccount(accounts[0]);
        }
    }

    // Listen for sign-in and set active account after login
    msalInstance.addEventCallback((event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload as AuthenticationResult;
            const account = payload.account;
            msalInstance.setActiveAccount(account);
        }
    });

    return msalInstance;

}

// Use this instance in the index.js!
export default instantiateMsal;
