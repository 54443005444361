//import sortBy from '../types/sortBy';

/** useSortContent provides sorting methods */
const useSortContent = () => {

    /* Local private helper methods for sorting */
    function sortArrayByDate (array: Array<any>, param: string, asc = true) {
        if (!Array.isArray(array)) return;
        // Typescript does not like if dates are subtracted without valueOf!
        return array?.slice().sort((a, b) => {
            if (asc) {
                return new Date(a[param]).valueOf() - new Date(b[param]).valueOf();
            }
            return new Date(b[param]).valueOf() - new Date(a[param]).valueOf();
        });
    }
    function sortArrayByProperty (array: Array<any>, param: any, asc = true) {
        if (!Array.isArray(array)) return;
        const sliced = array.slice();
        const sorted = sliced.sort((a, b) => {

            // Set defaults is value is missing
            if(!a[param]) a[param] = '';
            if(!b[param]) b[param] = '';

            if (asc) {
                if (a[param] > b[param]) return 1;
                if (b[param] > a[param]) return -1;
                return 0;
            }

            if (a[param] > b[param]) return -1;
            if (b[param] > a[param]) return 1;
            return 0;

        });

        return sorted;

    }
    function sortArrayByPropertyAsInt (array: Array<any>, param: any, asc = true) {

        if (!Array.isArray(array)) return;

        const numbers = array.slice();
        const filtered = numbers.filter(item => !isNaN(parseInt(item[param])));

        const sorted = filtered.sort((a, b) => {

            // Set defaults is value is missing
            if(!a[param]) a[param] = '';
            if(!b[param]) b[param] = '';

            if (asc) {
                if (parseInt(a[param]) > parseInt(b[param])) return 1;
                if (parseInt(b[param]) > parseInt(a[param])) return -1;
                return 0;
            }

            if (parseInt(a[param]) > parseInt(b[param])) return -1;
            if (parseInt(b[param]) > parseInt(a[param])) return 1;
            return 0;

        });

        const filteredArray = array.filter(item => isNaN(parseInt(item[param])));
        const strings = sortArrayByProperty(filteredArray, param, asc) || [];

        if (asc) return numbers.concat(strings);
        return strings.concat(sorted);

    }

    /** Sort content data
     * @param {sortBy} sortBy
     * @param {Array<any>} content
     */
    const sortContent = (sortBy: any, content: Array<any>) => {
        switch(sortBy.type) {
            case 'int':
                return sortArrayByPropertyAsInt(content, sortBy.key, sortBy.asc) || [];
            case 'date':
                return sortArrayByDate(content, sortBy.key, sortBy.asc)  || [];
            default:
                return sortArrayByProperty(content, sortBy.key, sortBy.asc)  || [];
        }    
    }

    return { sortContent };

}

export default useSortContent;