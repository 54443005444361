import './../styles/loading.css';

function LoadingComponent() {
    return <div className='authentication-loading'>
        <img src='./img/logo.png' width={60} alt='logo.png' />
        <p>Authentication in progress...</p>
    </div>
}

export default LoadingComponent;
