import { useEffect } from 'react';
import { useReadUserProfile } from 'Features/UserProfile';
import { PublicClientApplication } from "@azure/msal-browser";

interface IUseStateContextProps {
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>; // Type for setState
    msal?: PublicClientApplication;
}

const useStateContext = ({ setIsLoading, msal }: IUseStateContextProps) => {

    const { userProfile, readUserProfile } = useReadUserProfile();

    const logoutRedirect = () => {
        if (msal) {
            msal.logoutRedirect({
                postLogoutRedirectUri: "/"
            });
        }
    }

    const logoutPopup = () => {
        if (msal) {
            msal.logoutPopup({
                postLogoutRedirectUri: "/"
            });
        }
    }

    // Create global user profile object with additional methods
    const profile = {
        ...userProfile,
        activeAccount: msal ? msal.getActiveAccount() : null,
        logoutRedirect,
        logoutPopup
    }

    // Get data which are fetch only once but used across the app (f.e. userprofile)
    useEffect(() => {
        (async () => {
            setIsLoading(true);    
            await readUserProfile();
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return profile;

}

export default useStateContext;
