import { ReadDistributionFreight } from "Features/Survey";
import { Container } from "semantic-ui-react";

function ViewDistributionFreight () {

    return (
        <Container className="page">

            <ReadDistributionFreight />

        </Container>
    );
}

export default ViewDistributionFreight;