import { Container } from "semantic-ui-react";
import { UpdateGlassBottleReturnRate } from "Features/Survey";

function EditGlassBottleReturnRate () {

    return (
        <Container className="page">
            <UpdateGlassBottleReturnRate />
        </Container>
    );
}

export default EditGlassBottleReturnRate;