import { Container } from "semantic-ui-react";
import { UpdateInventory } from "Features/Survey";

function EditInventory () {

    return (
        <Container className="page">
            <UpdateInventory />
        </Container>
    );
}

export default EditInventory;