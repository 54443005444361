import { useEffect, useState } from 'react';
import useGetCompany from 'Features/Company/ReadCompany/useGetCompany';
import useGetCompanyUnits from "Features/CompanyUnit/ListCompanyUnits/useGetCompanyUnits";
import { useTranslation } from "react-i18next";
import { Segment, Header, Table } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { ButtonContainer, EditBtn } from 'Components/Buttons';
import IListCompanyUnit from "../ListCompanyUnits/IListCompanyUnit";

function ListCompanyUnits () {

    const { t } = useTranslation();
    const { companyId } = useParams();
    const { company, getCompanyById } = useGetCompany();
    const { companyUnits, getCompanyUnits } = useGetCompanyUnits();
    const [ categories, setCategories ] = useState<IListCompanyUnit[]>([]);
    const [ isLoading, setIsLoading ] = useState(true);
    
    // Fetch company and company units
    useEffect(() => {
        (async () => {
            if (companyId) {
                await getCompanyById(companyId);
                await getCompanyUnits(companyId);
                setIsLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // set initial values for company
    useEffect(() => {
        if (companyUnits.length>0) {
            const cats:any[] = [];
            setCategories([]);
            companyUnits.map(unit => {
                if(!cats.find(cat => cat.name === unit.category.name)){
                    cats.push({id: unit.category.id, name: unit.category.name, units: []})
                }
                const usageStart = unit.usageStartYear ? unit.usageStartMonth + "/" + unit.usageStartYear : "";
                const usageEnd = unit.usageEndYear ? unit.usageEndMonth + "/" + unit.usageEndYear : "";
                unit.usagePeriod=(usageStart || usageEnd) ? usageStart + "-" + usageEnd : "";
                cats.find(cat => cat.name === unit.category.name).units.push(unit);
                return unit;
            });
            setCategories(cats);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[companyUnits]);

    return (
        <Segment className="invisible" loading={isLoading}>
            {companyId &&
                <>
                <Header sub>{t("features.companyUnit.company")}</Header>
                <div>{company && company.name}</div>
                </>
            }
            <div className="table-wrapper">
                <Table>
                    {categories.length>0 && categories.map((cat:any) =>
                        <Table.Body key={"cat" + cat.id}>
                            <Table.Row>
                                <Table.HeaderCell>{cat.name}</Table.HeaderCell>
                                <Table.HeaderCell>{t("features.companyUnit.active")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("features.companyUnit.usagePeriod")}</Table.HeaderCell>
                            </Table.Row>
                            {cat.units.length>0 && cat.units.map((unit:any) =>
                                <Table.Row key={"unit" + unit.unitId}>
                                    <Table.Cell>
                                        {unit.unitName}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {unit.isCompanyUnit ? t("features.companyUnit.yes") : t("features.companyUnit.no")}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {unit.usagePeriod}
                                    </Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    )}
                </Table>
            </div>
            
            <ButtonContainer><EditBtn to={"/management/companies/" + companyId + "/units/edit"} /></ButtonContainer>

        </Segment>
    );
}

export default ListCompanyUnits;