import { ReadBudget } from "Features/Survey";
import { Container } from "semantic-ui-react";

function ViewBudget () {

    return (
        <Container className="page">

            <ReadBudget />

        </Container>
    );
}

export default ViewBudget;