import { Container } from "semantic-ui-react";
import { UpdateDistributionFreight } from "Features/Survey";

function EditDistributionFreight () {

    return (
        <Container className="page">
            <UpdateDistributionFreight />
        </Container>
    );
}

export default EditDistributionFreight;