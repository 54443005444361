import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Dropdown, Container } from 'semantic-ui-react';
import IPagedTablePageSizeProps from './IPagedTablePageSizeProps';
import './pageSize.css';

const defaultOptions = [
    { key: 0, text: 5, value: 5 },
    { key: 1, text: 10, value: 10 },
    { key: 2, text: 25, value: 25 },
    { key: 3, text: 50, value: 50 }
];

/** Table page size
 * @param {string} itemsPerPage - react state for items per page selection
 * @param {function} setItemsPerPage - react set state method to set selected items per page
 * @param {SemanticTEXTALIGNMENTS} textAlign - Semantic UI React text alignment 'left' | 'right'
 * @param {array<Option>} [options] - Array of options 
 * @param {any} rest - any semantic ui react input prop
*/
const TablePageSize: React.FC<IPagedTablePageSizeProps> = ({
    itemsPerPage,
    setItemsPerPage,
    textAlign = 'right',
    options = defaultOptions,
    ...rest
}: IPagedTablePageSizeProps) => {

    const { t } = useTranslation();

    return (
        <Container textAlign={textAlign} className='paged-table-page-size'>
            <Form.Field>
                <b>{t('components.pagedTable.pageSize')}</b>
            </Form.Field>
            <Dropdown
                {...rest}
                selection
                value={itemsPerPage}
                options={options}
                onChange={(ev, {value}) => {
                    let _value: number = value && typeof value === 'number' ? value : 25;
                    setItemsPerPage(_value);
                } }
            />
        </Container>
    );

}

export default TablePageSize;