import { Form } from "semantic-ui-react";
import { InputProps  } from 'semantic-ui-react';
import useValidateEmail from "./UseValidateEmail";
import { useTranslation } from "react-i18next";

const EditEmail = (props: InputProps) => {

    const { t } = useTranslation();
    const { isValidEmail } = useValidateEmail();
    const { ...rest } = props;

    return (
        <Form.Input
            {...rest}
            error={
                !isValidEmail(props.value) && t("components.email.formatIncorrect")
            }
        />
    )

}

export { EditEmail };