import getRequestOptions from "../utils/getRequestOptions";
import IDeleteItem from "../interfaces/IDeleteItem";

const useDeleteItem = () => {

    /** Delete item - delete single item by id
     * @param {URL} url - api endpoint url f.e. 'https://example.com/api/v1/users({id}'
     * @param {string} [options.accessToken] - Access token is added to Authentication header if defined
     * @param {AbortSignal} [options.abortSignal] - signal to abort the 
     **/
    const deleteItem = async (url: URL, options?: IDeleteItem) => {
        try {

            const requestOptions = getRequestOptions({ method: "DELETE", ...options });
            
            const response = await fetch(url, requestOptions);

            let data = null;

            // Try to Parse JSON data from the response
            try {
                data = await response.json();
            } catch (err) {
                data = [];
            }

            // Return an object containing both the raw response and the parsed data
            const resObj = {
                statusText: response.statusText,
                status: response.status,
                ok: response.ok,
                url: response.url,
                data: data,
            };

            // Throw error if result is not ok
            if (!response.ok) throw resObj;

            return resObj;

        } catch (error: any) {
            if (error.name === 'AbortError') {
                return { requestAborted: true, ...error }
            } else {
                throw error;
            }
        }

    }

    return deleteItem;

}

export default useDeleteItem;
