import { useTranslation } from 'react-i18next';
import { Container } from 'semantic-ui-react';

declare interface InternalServerError500Props {
    error?: Error
}

const InternalServerError500 = ({ error }: InternalServerError500Props) => {

    const { t } = useTranslation();

    return (
        <Container className="page systempage">
            <h1>500</h1>
            <p>{t('pages.internalServerError')}</p>
            <pre>{error && error.message ? error.message : ""}</pre>
        </Container>
    );
}

export default InternalServerError500;