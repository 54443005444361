import { createContext } from 'react';
import useMessages from '../hooks/useMessages';
import IMessageProviderProps from '../interfaces/IMessageProviderProps';
import MessageContextType from '../types/MessageContextType';

/* Why this? > TypeScript does not allow null value to createContext! */
const defaultValue = {
    messages: [],
    setMessages: () => {},
    addMessage: () => {},
    addMessages: () => {},
    removeMessage: () => {},
    getMessages: () => {}
}

/** Message provider for the application - This is used to show page messages 
 * for example when item is created, updated or deleted.
 **/
export const MessageContext = createContext<MessageContextType>(defaultValue);

const MessageContextProvider = ({children}: IMessageProviderProps) => {

    const {
        messages, 
        setMessages, 
        addMessage, 
        addMessages, 
        removeMessage, 
        getMessages 
    } = useMessages();

    return (
        /** Message Context Provider
         * @param {object} messages - Raw messages
         * @param {function} setMessages - React set state method to set messages state
         * @param {function} addMessage - Function to add single message
         * @param {function} addMessages - Function to add array messages
         * @param {function} removeMessage - Function to remove single message
         * @param {function} getMessages - Function to get message components
         */
        <MessageContext.Provider value={{ messages, setMessages, addMessage, addMessages, removeMessage, getMessages }} >
            {children}
        </MessageContext.Provider>
    )
}

export default MessageContextProvider;
