import { useState, useEffect, useContext } from "react";
import useGetYearlyBudget from "../ReadYearlyBudget/useGetYearlyBudget";
import useUpdateYearlyBudget from "./useUpdateYearlyBudget";
import { Form } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";
import { ButtonContainer, SaveBtn, CancelBtn } from "Components/Buttons";
import IUpdateYearlyBudget from "./IUpdateYearlyBudget";
import { useNavigate, useParams } from "react-router-dom";
import { InputWithUnit } from "Components/FormElements";
import useInputValidation from "Components/FormElements/InputWithUnit/useInputValidation";
import { MessageContext } from "Features/Messages";

function UpdateYearlyBudget () {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [ isLoading, setIsLoading ] = useState(true);
    
    const { updateYearlyBudget } = useUpdateYearlyBudget();
    const { yearlyBudget, getYearlyBudgetById } = useGetYearlyBudget();
    const { setMessages, addMessage } = useContext(MessageContext);

    const { isValidYear, isNonNegativeInteger } = useInputValidation();

    // Fetch yearlyBudget
    useEffect(() => {
        (async () => {
            if (id) {
                await getYearlyBudgetById(id);
                setIsLoading(false);
            }            
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const [ year, setYear ] = useState<number | null>(null);
    const [ budget, setBudget ] = useState<number | null>(null);

    // set initial values for yearly budget
    useEffect(() => {
        if (yearlyBudget) {
            setYear(yearlyBudget.year);
            setBudget(yearlyBudget.budget);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[yearlyBudget]);

    const onSubmit = async () => {

        setIsLoading(true);

        setMessages([]);
        if(!isValidYear(year) || !isNonNegativeInteger(budget)){
            addMessage({header: t("features.yearlyBudget.inputErrorHeader"), type: "error", body: t("features.yearlyBudget.inputErrorDescription")});
            window.scrollTo(0,0);
        } else {
            const yearlyBudget:IUpdateYearlyBudget = {
                "year": year,
                "budget": budget
            } 

            let result = await updateYearlyBudget(id,yearlyBudget);

            if (result && result.status === 204) return navigate(-1);
        }
        
        setIsLoading(false);
    }

    return (
        
        <Form loading={isLoading} onSubmit={onSubmit}>
            {yearlyBudget.id ?
                <>
                <Form.Input
                    label={t("features.yearlyBudget.year")}
                    id="year"
                    type="number"
                    value={year}
                    onChange={(e) => setYear(parseInt(e.target.value))}
                    autoComplete="off"
                    required
                />
                <InputWithUnit
                    label={t("features.yearlyBudget.budget")}
                    id="budget"
                    value={budget}
                    type="number"
                    validateFor="nonNegativeInteger"
                    onChangeFunc={setBudget}
                    unit={t("features.yearlyBudget.euro")}
                    required
                />

                <ButtonContainer>
                    <SaveBtn />
                    <CancelBtn />
                </ButtonContainer>
                </>
            :
                <p>{t("features.yearlyBudget.notFound")}</p>
            }
        </Form>
    );
}

export default UpdateYearlyBudget;
