import { useState, useEffect } from "react";
import useGetCompany from "Features/Company/ReadCompany/useGetCompany";
import useGetSurveyTypes from "Features/SurveyType/ListSurveyTypes/useGetSurveyTypes";
import useGetCompanySurveys from "../ListCompanySurveys/useGetCompanySurveys";
import useUpdateCompanySurveys from "./useUpdateCompanySurveys";
import { Form, Header, Segment }  from 'semantic-ui-react';
import { useTranslation } from "react-i18next";
import { ButtonContainer, SaveBtn, CancelBtn } from "Components/Buttons";
import { useNavigate, useParams } from "react-router-dom";

function UpdateCompanySurveys () {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { companyId } = useParams();
    const [ isLoading, setIsLoading ] = useState(true);
    
    const { updateCompanySurveys } = useUpdateCompanySurveys();
    const { company, getCompanyById } = useGetCompany();
    const { surveyTypes, listSurveyTypes } = useGetSurveyTypes();
    const { companySurveys, getCompanySurveys } = useGetCompanySurveys();
    const [ surveys, setSurveys ] = useState<any>([]);

    // Fetch company, company surveys and survey types
    useEffect(() => {
        (async () => {
            await listSurveyTypes();
            if (companyId) {
                await getCompanyById(companyId);
                await getCompanySurveys(companyId);
                setIsLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // set initial values for company
    useEffect(() => {
        const surveys:any[] = [...surveyTypes];
        surveys.map(survey => {
            survey.uiName = t("features.surveyType." + survey.name);
            if(companySurveys.find(s => s.surveyTypeId === survey.id)){
                survey.isCompanySurvey = true;
            } else {
                survey.isCompanySurvey = false;
            }
            return survey;
        })
        setSurveys(surveys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[companySurveys,surveyTypes]);

    const setIsCompanySurvey = (surveyId:string) => {
        let newSurveyList = [...surveys]
        const survey = newSurveyList.find(s => s.id === surveyId);
        survey.isCompanySurvey = !survey.isCompanySurvey;
        setSurveys(newSurveyList);
    }

    const onSubmit = async () => {

        setIsLoading(true);

        const data:any = [];
        surveys.map((survey:any) => {
            if(survey.isCompanySurvey){
                data.push({id: survey.id});
            }
            return survey;
        });

        let result = await updateCompanySurveys(companyId,data);

        if (result && result.status === 204) return navigate(-1);
        
        setIsLoading(false);
    }

    return (
        
        <Form loading={isLoading} onSubmit={onSubmit}>
            <>
            {companyId &&
                <Segment className="invisible">
                <Header sub>{t("features.companySurvey.company")}</Header>
                <div>{company && company.name}</div>
                </Segment>
            }

            <Header sub style={{marginBottom: "10px"}}>{t("features.companySurvey.selectCompanySurveys")}</Header>
            {surveys.map((survey:any) =>
                <Form.Checkbox
                    key={"survey" + survey.id}
                    label={survey.uiName}
                    id={"active" + survey.id}
                    checked={survey.isCompanySurvey}
                    onChange={(e,{value}) => setIsCompanySurvey(survey.id)}
                />
            )}

            <ButtonContainer>
                <SaveBtn />
                <CancelBtn />
            </ButtonContainer>
            </>

        </Form>
    );
}

export default UpdateCompanySurveys;
