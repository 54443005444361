import { PageTitle } from "Components/Titles";
import { Container } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { ListSurveys } from "Features/Survey";

function DistributionFreightList () {

    const { t } = useTranslation();

    return (
        <Container className="page">
            <PageTitle
                title={t("pages.distributionFreights")}
            />
            <ListSurveys surveyRef="distributionfreight" />
        </Container>
    );
}

export default DistributionFreightList;