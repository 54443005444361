import { useTranslation } from 'react-i18next';
import { Container } from 'semantic-ui-react';
import {CreateAnnouncement} from 'Features/Announcements';

const NewAnnouncement = () => {

    const { t } = useTranslation();

    return (
        <Container className="page">

            <h1>{t('pages.newAnnouncement')}</h1>

            <CreateAnnouncement/>

        </Container>
    );
}

export default NewAnnouncement;
