import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dimmer, Loader } from 'semantic-ui-react';
import { ILoadingProps } from './ILoadingProps';
import './loading.css';

/** Page content loading overlay with loader icon 
 *  This loader can be used in any page when something is loading.
 * @param {boolean} active - Is the loader visible or hidden
*/
const Loading: React.FC<ILoadingProps> = ({ active }: ILoadingProps) => {
    const { t } = useTranslation();
    return (
        <Dimmer active={active} id={active ? 'overlay': ''} >
            <Loader size='massive'>{t('components.loaders.loading')}</Loader>
        </Dimmer>
    );
}

export default Loading;
