import { PageTitle } from "Components/Titles";
import { Container } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { ListAccounts } from "Features/Account";
import { ButtonContainer, NewBtn } from "Components/Buttons";
import { useParams } from "react-router-dom";

function AccountList () {

    const { t } = useTranslation();
    const { companyId } = useParams();

    return (
        <Container className="page">
            <PageTitle
                title={t("pages.accounts")}
                button={
                    <ButtonContainer>
                        <NewBtn to={"/management/companies/" + companyId + "/accounts/new"} />
                    </ButtonContainer>
                }
            />
            <ListAccounts />
        </Container>
    );
}

export default AccountList;