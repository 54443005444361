import { usePutItem } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import apiConfig from 'Application/configurations/apiConfig';
import IListCompanySurvey from "../ListCompanySurveys/IListCompanySurvey";
import { useAccessTokens } from 'Features/Authentication';
import { useTranslation } from 'react-i18next';

function useUpdateCompanySurveys () {

    const { t } = useTranslation();
    const putItem = usePutItem();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

    /** Create company surveys
     * @paramUpdate {IUpdateCompanySurvey} companySurveys - object which describes company surveys
     **/
    const updateCompanySurveys = async (id: string="", companySurveys: IListCompanySurvey[]) => {

        const basePath = apiConfig.basePath;
        const companyEndpoint = apiConfig.endpoints.companies;
        const surveyTypeEndpoint = apiConfig.endpoints.surveytypes;
        const url = new URL(`${basePath}/${companyEndpoint}/${id}/${surveyTypeEndpoint}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken,
            body: companySurveys
        }

        try {

            const response = await putItem(url, options);
         
            return responseHandler(response, "useUpdateCompanySurveys", { operation: "update", messageHeader: t("features.companySurvey.savedSuccessfully"), hide: false });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "General.RequestorNotExist") body = t('features.companySurvey.requestorNotExist');
            if (problemTitle === "Company.NotExist") body = t('features.company.notExist');
            if (problemTitle === "Company.CompanyDataAccessDenied") body = t('features.company.companyDataAccessDenied');
            if (problemTitle === "CompanySurvey.UpdatePermissionDenied") body = t('features.companySurvey.updatePermissionDenied');

            return errorHandler(error, "useUpdateCompanySurveys", { operation: "update", hide: false, messageBody: body });

        }

    }

    return { updateCompanySurveys }

}

export default useUpdateCompanySurveys;
