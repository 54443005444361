import { useState } from "react";
import { useGetItems } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import { useTranslation } from 'react-i18next';
import apiConfig from 'Application/configurations/apiConfig';
import IListUnit from "./IListUnit";
import { useAccessTokens } from 'Features/Authentication';

function useGetUnits () {

    const { t } = useTranslation();
    const [ units, setUnits ] = useState<IListUnit[]>([]);
    const getItems = useGetItems();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;
    

   /** List all units
     * @returns {Array<IListUnit>} units */
    const listUnits = async () => {

        const basePath = apiConfig.basePath;
        const endpoint = apiConfig.endpoints.units;
        const url = new URL(`${basePath}/${endpoint}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken
        }
        
        try {

            const response = await getItems(url, options);
            const data = response?.data || [];
            setUnits(data);
            return responseHandler(response, "useGetUnits", { operation: "list", hide: true });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "General.RequestorNotExist") body = t('features.unit.requestorNotExist');
            if (problemTitle === "Unit.ListPermissionDenied") body = t('features.unit.listPermissionDenied');

            return errorHandler(error, "useGetUnits", { operation: "list", hide: false, messageBody: body });
   
        }

    }

    return { units, setUnits, listUnits };

}

export default useGetUnits;