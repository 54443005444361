import { useState, useEffect, useContext } from "react";
import useGetGlassBottleReturnRate from "../ReadGlassBottleReturnRate/useGetGlassBottleReturnRate";
import useUpdateGlassBottleReturnRate from "./useUpdateGlassBottleReturnRate";
import { Form, Header, Popup, Icon } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";
import { ButtonContainer, SaveBtn, CancelBtn } from "Components/Buttons";
import IUpdateGlassBottleReturnRate from "./interfaces/IUpdateGlassBottleReturnRate";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { InputWithUnit } from "Components/FormElements/InputWithUnit/InputWithUnit";
import { StateContext } from "Application/state/StateContext";
import { PageTitle } from "Components/Titles";
import { MessageContext } from "Features/Messages";
import useInputValidation from "Components/FormElements/InputWithUnit/useInputValidation";
import useSurvey from "Features/Survey/useSurvey";
import { UploadCompanySurveyAttachements, useUploadCompanySurveyAttachements } from 'Features/CompanyAttachement';

function UpdateGlassBottleReturnRate () {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const detailPath = location.pathname.replace("/edit","")
    
    const { userProfile } = useContext(StateContext);
    const { companyId, surveyId } = useParams();
    const [ isLoading, setIsLoading ] = useState(true);
    const { isNonNegativeInteger } = useInputValidation();
    const { addMessage, setMessages } = useContext(MessageContext);
    
    const { updateGlassBottleReturnRate } = useUpdateGlassBottleReturnRate();
    const { glassBottleReturnRate, getGlassBottleReturnRateById } = useGetGlassBottleReturnRate();
    const { isEditingAllowed } = useSurvey();

    const [ attachements, setAttachements ] = useState([]);
    const { uploadCompanySurveyAttachements } = useUploadCompanySurveyAttachements(); 

    const [ initialStock, setInitialStock ] = useState<number | null>(null);
    const [ procurement, setProcurement ] = useState<number | null>(null);
    const [ salesToCustomers, setSalesToCustomers ] = useState<number | null>(null);
    const [ purchasesFromCustomers, setPurchasesFromCustomers ] = useState<number | null>(null);
    const [ salesToCompanies, setSalesToCompanies ] = useState<number | null>(null);
    const [ purchasesFromCompanies, setPurchasesFromCompanies ] = useState<number | null>(null);
    const [ scrapping, setScrapping ] = useState<number | null>(null);
    const [ closingStock, setClosingStock ] = useState<number | null>(null);

    // Fetch glass bottle return rate
    useEffect(() => {
        (async () => {
            if(companyId || userProfile.companyId) {
                await getGlassBottleReturnRateById(companyId || userProfile.companyId,surveyId);
            }
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userProfile.companyId]);

    // set initial values for glass bottle return rate
    useEffect(() => {
        if (glassBottleReturnRate.isAnswered) {
            setInitialStock(glassBottleReturnRate.initialStock);
            setProcurement(glassBottleReturnRate.procurement);
            setSalesToCustomers(glassBottleReturnRate.salesToCustomers);
            setPurchasesFromCustomers(glassBottleReturnRate.purchasesFromCustomers);
            setSalesToCompanies(glassBottleReturnRate.salesToCompanies);
            setPurchasesFromCompanies(glassBottleReturnRate.purchasesFromCompanies);
            setScrapping(glassBottleReturnRate.scrapping);
            setClosingStock(glassBottleReturnRate.closingStock);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[glassBottleReturnRate]);

    const onSubmit = async () => {

        var uploadAttachementRequest = {
            companyId: userProfile.companyId,
            surveyId: surveyId || "",
            surveyName: glassBottleReturnRate.name || "Lasin palautuskysely",
            surveyTypeRef: "glassbottlereturnrate",
            attachements: attachements
        }

        setMessages([]);
        if(
            !isNonNegativeInteger(initialStock) || !isNonNegativeInteger(procurement) || !isNonNegativeInteger(salesToCustomers) ||
            !isNonNegativeInteger(purchasesFromCustomers) || !isNonNegativeInteger(salesToCompanies) || !isNonNegativeInteger(purchasesFromCompanies) ||
            !isNonNegativeInteger(scrapping) || !isNonNegativeInteger(closingStock)
        ){
            addMessage({header: t("features.survey.inputErrorHeader"), type: "error", body: t("features.survey.inputErrorDescription")});
            window.scrollTo(0,0);
        } else {
            setIsLoading(true);

            const glassBottleReturnRate:IUpdateGlassBottleReturnRate = {
                initialStock: initialStock || 0,
                procurement: procurement || 0,
                salesToCustomers: salesToCustomers || 0,
                purchasesFromCustomers: purchasesFromCustomers || 0,
                salesToCompanies: salesToCompanies || 0,
                purchasesFromCompanies: purchasesFromCompanies || 0,
                scrapping: scrapping || 0,
                closingStock: closingStock || 0
            } 

            let result = await updateGlassBottleReturnRate(companyId || userProfile.companyId,surveyId,glassBottleReturnRate);

            await uploadCompanySurveyAttachements(uploadAttachementRequest);

            if (result && result.status === 204) return navigate(detailPath);
            
            setIsLoading(false);
        }
    }

    return (
        
        <Form loading={isLoading} onSubmit={onSubmit} className="form-inline">
            
            <PageTitle 
                title={glassBottleReturnRate.name ? glassBottleReturnRate.name : t("pages.editGlassBottleReturnRate")} 
                button={
                    <Popup
                        trigger={<Icon name='info' color='blue' size='large' circular />}
                        content={t("features.glassBottleReturnRate.infoText")}
                        position='bottom left'
                        wide="very"
                    />
                }
            />

            {glassBottleReturnRate.id ?
                isEditingAllowed(glassBottleReturnRate.isPermanentlyLocked,glassBottleReturnRate.openDate,glassBottleReturnRate.closeDate) ?
                    <>
                    <Header as="h2">{t("features.glassBottleReturnRate.glassBottles")}</Header>

                    <InputWithUnit
                        label={t("features.glassBottleReturnRate.initialStock") + " " + new Date(glassBottleReturnRate.initialStockDate).toLocaleDateString("fi-FI")}
                        id="initialStock"
                        value={initialStock}
                        type="number"
                        validateFor="nonNegativeInteger"
                        onChangeFunc={setInitialStock}
                        unit={t("features.glassBottleReturnRate.pcs")}
                        required
                    />

                    <InputWithUnit
                        label={t("features.glassBottleReturnRate.procurement")}
                        id="procurement"
                        value={procurement}
                        type="number"
                        validateFor="nonNegativeInteger"
                        onChangeFunc={setProcurement}
                        unit={t("features.glassBottleReturnRate.pcs")}
                        required
                    />

                    <InputWithUnit
                        label={t("features.glassBottleReturnRate.salesToCustomers")}
                        id="salesToCustomers"
                        value={salesToCustomers}
                        type="number"
                        validateFor="nonNegativeInteger"
                        onChangeFunc={setSalesToCustomers}
                        unit={t("features.glassBottleReturnRate.pcs")}
                        required
                    />

                    <InputWithUnit
                        label={t("features.glassBottleReturnRate.purchasesFromCustomers")}
                        id="purchasesFromCustomers"
                        value={purchasesFromCustomers}
                        type="number"
                        validateFor="nonNegativeInteger"
                        onChangeFunc={setPurchasesFromCustomers}
                        unit={t("features.glassBottleReturnRate.pcs")}
                        required
                    />

                    <InputWithUnit
                        label={t("features.glassBottleReturnRate.salesToCompanies")}
                        id="salesToCompanies"
                        value={salesToCompanies}
                        type="number"
                        validateFor="nonNegativeInteger"
                        onChangeFunc={setSalesToCompanies}
                        unit={t("features.glassBottleReturnRate.pcs")}
                        required
                    />

                    <InputWithUnit
                        label={t("features.glassBottleReturnRate.purchasesFromCompanies")}
                        id="purchasesFromCompanies"
                        value={purchasesFromCompanies}
                        type="number"
                        validateFor="nonNegativeInteger"
                        onChangeFunc={setPurchasesFromCompanies}
                        unit={t("features.glassBottleReturnRate.pcs")}
                        required
                    />

                    <InputWithUnit
                        label={t("features.glassBottleReturnRate.scrapping")}
                        id="scrapping"
                        value={scrapping}
                        type="number"
                        validateFor="nonNegativeInteger"
                        onChangeFunc={setScrapping}
                        unit={t("features.glassBottleReturnRate.pcs")}
                        required
                    />

                    <InputWithUnit
                        label={t("features.glassBottleReturnRate.closingStock") + " " + new Date(glassBottleReturnRate.closingStockDate).toLocaleDateString("fi-FI")}
                        id="closingStock"
                        value={closingStock}
                        type="number"
                        validateFor="nonNegativeInteger"
                        onChangeFunc={setClosingStock}
                        unit={t("features.glassBottleReturnRate.pcs")}
                        required
                    />
                    
                    <UploadCompanySurveyAttachements
                        companyId={userProfile.companyId}
                        surveyId={surveyId}
                        surveyTypeRef="glassbottlereturnrate"
                        setAttachements={setAttachements}
                    />       

                    <ButtonContainer>
                        <SaveBtn />
                        <CancelBtn />
                    </ButtonContainer>

                    </>
                :
                    <p>{t("features.survey.editingNotAllowed")}</p>
            :
                <p>{t("features.survey.notFound")}</p>
            }
        </Form>
    );
}

export default UpdateGlassBottleReturnRate;
