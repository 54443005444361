import { useState, useEffect, useContext } from "react";
import useGetStorageCost from "../ReadStorageCost/useGetStorageCost";
import useUpdateStorageCost from "./useUpdateStorageCost";
import { Form, Popup, Icon } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";
import { ButtonContainer, SaveBtn, CancelBtn } from "Components/Buttons";
import IUpdateStorageCost from "./interfaces/IUpdateStorageCost";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { InputWithUnit } from "Components/FormElements/InputWithUnit/InputWithUnit";
import { StateContext } from "Application/state/StateContext";
import { PageTitle } from "Components/Titles";
import { MessageContext } from "Features/Messages";
import useInputValidation from "Components/FormElements/InputWithUnit/useInputValidation";
import useSurvey from "Features/Survey/useSurvey";
import { UploadCompanySurveyAttachements, useUploadCompanySurveyAttachements } from 'Features/CompanyAttachement';

function UpdateStorageCost () {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const detailPath = location.pathname.replace("/edit","")

    const { userProfile } = useContext(StateContext);
    const { companyId, surveyId } = useParams();
    const [ isLoading, setIsLoading ] = useState(true);
    const { isNonNegativeFloat } = useInputValidation();
    const { addMessage, setMessages } = useContext(MessageContext);
    
    const { updateStorageCost } = useUpdateStorageCost();
    const { storageCost, getStorageCostById } = useGetStorageCost();
    const { isEditingAllowed } = useSurvey();

    const [ attachements, setAttachements ] = useState([]);
    const { uploadCompanySurveyAttachements } = useUploadCompanySurveyAttachements(); 

    const [ strgCost, setStrgCost ] = useState<number | null>(null);
    const [ handlingCost, setHandlingCost ] = useState<number | null>(null);

    // Fetch storage cost
    useEffect(() => {
        (async () => {
            if(companyId || userProfile.companyId) {
                await getStorageCostById(companyId || userProfile.companyId,surveyId);
            }
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userProfile.companyId]);

    // set initial values for storage cost
    useEffect(() => {
        setStrgCost(storageCost.storageCost);
        setHandlingCost(storageCost.handlingCost);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[storageCost]);

    const onSubmit = async () => {

        var uploadAttachementRequest = {
            companyId: userProfile.companyId,
            surveyId: surveyId || "",
            surveyName: storageCost.name || "Kausivarastoinnin kustannuskysely",
            surveyTypeRef: "storagecost",
            attachements: attachements
        }

        setMessages([]);
        if(
            !isNonNegativeFloat(strgCost,2) || !isNonNegativeFloat(handlingCost,2)
        ){
            addMessage({header: t("features.survey.inputErrorHeader"), type: "error", body: t("features.survey.inputErrorDescription")});
            window.scrollTo(0,0);
        } else {
            setIsLoading(true);

            const storageCost:IUpdateStorageCost = {
                storageCost: strgCost || 0,
                handlingCost: handlingCost || 0
            } 

            let result = await updateStorageCost(companyId || userProfile.companyId,surveyId,storageCost);

            await uploadCompanySurveyAttachements(uploadAttachementRequest);

            if (result && result.status === 204) return navigate(detailPath);
            
            setIsLoading(false);
        }
    }

    return (
        
        <Form loading={isLoading} onSubmit={onSubmit} className="form-inline">
            
            <PageTitle 
                title={storageCost.name ? storageCost.name : t("pages.editStorageCost")} 
                button={
                    <Popup
                        trigger={<Icon name='info' color='blue' size='large' circular />}
                        content={t("features.storageCost.infoText")}
                        position='bottom left'
                        wide="very"
                    />
                }
            />

            {storageCost.id ?
                isEditingAllowed(storageCost.isPermanentlyLocked,storageCost.openDate,storageCost.closeDate) ?
                    <>
                    <InputWithUnit
                        label={t("features.storageCost.storageCost")}
                        id="strgCost"
                        value={strgCost}
                        type="number"
                        validateFor="nonNegativeMoney"
                        onChangeFunc={setStrgCost}
                        unit={t("features.storageCost.eurosPerDay")}
                        required
                        pxwidth="100"
                    />

                    <InputWithUnit
                        label={t("features.storageCost.handlingCost")}
                        id="handlingCost"
                        value={handlingCost}
                        type="number"
                        validateFor="nonNegativeMoney"
                        onChangeFunc={setHandlingCost}
                        unit={t("features.storageCost.euros")}
                        required
                        pxwidth="100"
                    />

                    <UploadCompanySurveyAttachements
                        companyId={userProfile.companyId}
                        surveyId={surveyId}
                        surveyTypeRef="storagecost"
                        setAttachements={setAttachements}
                    />

                    <ButtonContainer>
                        <SaveBtn />
                        <CancelBtn />
                    </ButtonContainer>
                    </>
                :
                <p>{t("features.survey.editingNotAllowed")}</p>
            :
                <p>{t("features.survey.notFound")}</p>
            }
        </Form>
    );
}

export default UpdateStorageCost;
