import { useContext } from "react";
import { StateContext } from "Application/state/StateContext";

/** Use survey provides logic, data, validations and methods to be used in the survey components */
const useSurvey = () => {

    const { userProfile } = useContext(StateContext);
    const isAdmin = userProfile && userProfile.roles && userProfile.roles.find((role:any) => role.name==="Admin");

    const isEditingAllowed = (isPermanentlyLocked:boolean, openDate:Date, closeDate:Date) => {
        if(isPermanentlyLocked){
            return false;
        } else if(isAdmin){
            return true;
        } else if(new Date(openDate)<new Date() && new Date(closeDate)>new Date()) {
            return true;
        } else {
            return false;
        }
    }

    return { isEditingAllowed };

}

export default useSurvey;
