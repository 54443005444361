import { useState } from 'react';
import { useGetItem } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import { useTranslation } from 'react-i18next';
import apiConfig from 'Application/configurations/apiConfig';
import IReadCompany from './IReadCompany';
import { useAccessTokens } from 'Features/Authentication';

const useGetCompany = () => {

    const { t } = useTranslation();
    const getItem = useGetItem();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const [ company, setCompany ] = useState<IReadCompany>({name: "", businessIdentityCode: "", isActive: true, addresses: [], billing: {reference: ""}, sharePointDocumentLibrary: {urlSegment: ""}, userAccounts: []});
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

    /** Read company by guid
     * @param {string} id - guid of the company
     * @returns {object} company - company object
     **/
    const getCompanyById = async (id: string) => {

        //if (!id) throw Error("Id is mandatory when fetching company by id");

        const basePath = apiConfig.basePath;
        const endpoint = apiConfig.endpoints.companies;
        const url = new URL(`${basePath}/${endpoint}/${id}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken
        }

        try {

            const response = await getItem(url, options);
            const data = response?.data || [];
            setCompany(data);
            return responseHandler(response, "useGetCompany", { operation: "read", hide: true });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "General.RequestorNotExist") body = t('features.company.requestorNotExist');
            if (problemTitle === "Company.ReadPermissionDenied") body = t('features.company.readPermissionDenied');
            if (problemTitle === "Company.CompanyDataAccessDenied") body = t('features.company.companyDataAccessDenied');
            if (problemTitle === "Company.NotExist") body = t('features.company.notExist');
            if (problemTitle === "UserAccount.NotExist") body = t('features.company.userAccountNotExist');
            if (problemTitle === "Address.NotExist") body = t('features.company.addressNotExist');

            return errorHandler(error, "useGetCompany", { operation: "read", hide: false, messageBody: body });

        }

    }

    return { company, setCompany, getCompanyById };

}

export default useGetCompany;

