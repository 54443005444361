import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useGetAccount from "./useGetAccount";
import useGetCompany from "Features/Company/ReadCompany/useGetCompany";
import { useParams } from 'react-router-dom';
import { Header, Segment } from 'semantic-ui-react';
import { ButtonContainer, EditBtn } from 'Components/Buttons';

function ReadAccount () {

    const { t } = useTranslation();
    const { companyId, accountId } = useParams();
    const { account, getAccountById } = useGetAccount();
    const { company, getCompanyById } = useGetCompany();
    const [ isLoading, setIsLoading ] = useState(true);

    // Fetch account
    useEffect(() => {
        (async () => {
            await getAccountById(companyId,accountId);
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // Fetch company
    useEffect(() => {
        (async () => {
            await getCompanyById(account.companyId);
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[account]);

    return (
        <Segment className="invisible" loading={isLoading}>
            {account.id ?
                <>
                <Header sub>{t("features.account.company")}</Header>
                <div>{company && company.name}</div>

                <Header sub>{t("features.account.name")}</Header>
                <div>{account && account.name}</div>

                <Header sub>{t("features.account.email")}</Header>
                <div>{account && account.email}</div>

                <Header sub>{t("features.account.phoneNumber")}</Header>
                <div>{account && account.phoneNumber}</div>

                <Header sub>{t("features.account.entraId")}</Header>
                <div>{account && account.id}</div>

                <Header sub>{account.isActive ? t("features.account.accountIsActive") : t("features.account.accountIsNotActive")}</Header>

                <ButtonContainer><EditBtn to={"/management/companies/" + companyId + "/accounts/" + accountId + "/edit"} /></ButtonContainer>
                </>
            :
                <p>{t("features.account.notFound")}</p>
            }

        </Segment>
    );
}

export default ReadAccount;
