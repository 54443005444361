import { useTranslation } from 'react-i18next';
import { Container, List } from 'semantic-ui-react';
import { PageTitle } from 'Components/Titles';

const Developer = () => {

    const { t } = useTranslation();

    return (
        <Container className="page">

            <PageTitle title={t('pages.developer')}/>

            <p>Developer page show examples of components and features.</p>
            <p>See README.md, docs and project documentation for more information about technologies, development and project structure..</p>
            
            <h4>Get API Token</h4>
            <a href='/developer/accesstokens'>Get Api Access tokens</a>

            <h4>System pages</h4>

            <List as='ul'>
                <List.Item as='li'><a href='/error'>500 internal server error</a></List.Item>
                <List.Item as='li'><a href='/forbidden'>403 forbidden</a></List.Item>
                <List.Item as='li'><a href='/notfound'>404 Not found</a></List.Item>
                <List.Item as='li'><a href='/versions'>Version history</a></List.Item>
            </List>

            <h4>Out Of The Box Features</h4>
            <List as='ul'>
                <List.Item as='li'>Announcements</List.Item>
                <List.Item as='li'>Authentication</List.Item>
                <List.Item as='li'>HTTP</List.Item>
                <List.Item as='li'>Logging</List.Item>
                <List.Item as='li'>Messages</List.Item>
                <List.Item as='li'>UserProfile</List.Item>
            </List>

            <h4>Components</h4>

            <List as='ul'>
                <List.Item as='li'>Layout</List.Item>
                <List.Item as='li'><a href='/developer/clipboard'>Clipboards</a></List.Item>
                <List.Item as='li'><a href='/developer/csv'>CSV</a></List.Item>
                <List.Item as='li'><a href='/developer/files'>Files</a></List.Item>
                <List.Item as='li'><a href='/developer/loaders'>Loaders/Spinners</a></List.Item>
                <List.Item as='li'><a href='/developer/modals'>Modals</a></List.Item>
                <List.Item as='li'><a href='/developer/tables'>Tables</a></List.Item>
                <List.Item as='li'><a href='/developer/search'>Search</a></List.Item>
                <List.Item as='li'><a href='/developer/buttons'>Form buttons</a></List.Item>
                <List.Item as='li'><a href='/developer/forms'>Forms</a></List.Item>
            </List>

            <h4>Quick links to documentation</h4>

            <List as='ul'>
                <List.Item as='li'><a href='https://create-react-app.dev/docs/adding-typescript/'>Create react app typescript</a></List.Item>
                <List.Item as='li'><a href='https://reactrouter.com/en/main'>React Router</a></List.Item>
                <List.Item as='li'><a href='https://react.semantic-ui.com'>Semantic UI React</a></List.Item>
                <List.Item as='li'><a href='https://react.i18next.com'>I18Next</a></List.Item>
                <List.Item as='li'><a href='https://fontawesome.com/docs/web/setup/packages'>Font Awesome</a></List.Item>
                <List.Item as='li'><a href='https://origin.fontawesome.com/v6/docs/web/dig-deeper/styles'>Font Awesome icons</a></List.Item>
                <List.Item as='li'><a href='https://www.npmjs.com/package/react-copy-to-clipboard'>Copy to Clipboard</a></List.Item>
                <List.Item as='li'><a href='https://www.npmjs.com/package/react-csv'>React CSV</a></List.Item>
                <List.Item as='li'><a href='https://www.npmjs.com/package/react-dropzone'>React Dropzone</a></List.Item>
                <List.Item as='li'><a href='https://www.npmjs.com/package/@azure/msal-react '>MSAL Authentication</a></List.Item>
                <List.Item as='li'><a href='https://www.npmjs.com/package/react-dropzone'>React Dropzone</a></List.Item>
            </List>

        </Container>
    );
}

export default Developer;