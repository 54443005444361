import { useTranslation } from 'react-i18next';
import { Container } from 'semantic-ui-react';
import { GenerateCSVButton, GenerateCSVLink, GenerateCSVDownload } from 'Components/CSV';
import { PageTitle } from 'Components/Titles';
//import './../csv.css';

const exampleCSV1 = [
    ["firstname", "lastname", "email"],
    ["Ahmed", "Tomi", "ah@smthing.co.com"],
    ["Raed", "Labes", "rl@smthing.co.com"],
    ["Yezzi", "Min l3b", "ymin@cocococo.com"]
];

const exampleCSV2 = [
    { firstname: "Ahmed", lastname: "Tomi", email: "ah@smthing.co.com" },
    { firstname: "Raed", lastname: "Labes", email: "rl@smthing.co.com" },
    { firstname: "Yezzi", lastname: "Min l3b", email: "ymin@cocococo.com" }
];

const CSV = () => {

    const { t } = useTranslation();

    return (
        <Container className="page">

            <PageTitle title={t('pages.csv')}/>

            <h4>Download CSV</h4>
            <p>Automatic download fires on mounting the dlownload component</p>
            <GenerateCSVDownload data={exampleCSV1} />

            <h4>Generate CSV Button</h4>
            <GenerateCSVButton title='CSV 1' data={exampleCSV1} delimiter=';' filename='csv-export-1.csv' />

            <h4>Generate CSV Link</h4>
            <GenerateCSVLink title='CSV 1' data={exampleCSV2} delimiter=';' filename='csv-export-2.csv' />

        </Container>
    )

}


export default CSV;
