import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import useGetClearing from "./useGetClearing";
import useGetCompany from "Features/Company/ReadCompany/useGetCompany";
import { useParams } from "react-router-dom";
import { Header, Segment, Table, Button, Icon } from "semantic-ui-react";
import { ButtonContainer, EditBtn } from "Components/Buttons";
import { StateContext } from "Application/state/StateContext";
import { PageTitle } from "Components/Titles";
import useSurvey from "Features/Survey/useSurvey";
import configuration from "Features/Survey/Inventory/unitQuestionConfig";
import useUpdateClearing from "../UpdateClearing/useUpdateClearing";
import { YesNoModal } from "Components/Modals";
import { ListCompanySurveyAttachements } from "Features/CompanyAttachement";

function ReadClearing () {

    const { t } = useTranslation();
    const { userProfile } = useContext(StateContext);
    const { companyId, surveyId } = useParams();
    const { clearing, getClearingById } = useGetClearing();
    const { company, getCompanyById } = useGetCompany();
    const [ isLoading, setIsLoading ] = useState(true);
    const { isEditingAllowed } = useSurvey();
    const { updateClearing } = useUpdateClearing();
    const [ lockSurveyModalOpen, setLockSurveyModalOpen ] = useState(false);
    const [ openSurveyModalOpen, setOpenSurveyModalOpen ] = useState(false);
    const [ isLocked, setIsLocked ] = useState(true);
    const [ isOpen, setIsOpen ] = useState(true);
    const [ isEditable, setIsEditable ] = useState(false);
    const [ deadline, setDeadline ] = useState<null | Date>(null);

    const isAdmin = userProfile && userProfile.roles && userProfile.roles.find((item:any) => item.name==="Admin") ? true : false;

    // Fetch survey data
    useEffect(() => {
        (async () => {
            if(companyId || userProfile.companyId){
                await getClearingById(companyId || userProfile.companyId,surveyId);
            }
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userProfile.companyId]);

    // Fetch company
    useEffect(() => {
        (async () => {
            if(clearing.companyId){
                await getCompanyById(clearing.companyId);
                setIsLocked(clearing.isPermanentlyLocked);
                setIsOpen(new Date(clearing.closeDate)>new Date());
                setIsEditable(isEditingAllowed(clearing.isPermanentlyLocked,clearing.openDate,clearing.closeDate));
                let dl = new Date(clearing.closeDate);
                dl.setSeconds(dl.getSeconds()-1);
                setDeadline(dl);
                setIsLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[clearing]);

    const showInput = (id:string,fieldName:string) => {
        const unit = configuration.find((unit:any) => unit.unitId.toLowerCase() === id.toLowerCase())
        switch(fieldName){
            case "procurement": return unit?.showProcurement;
            case "repair": return unit?.showRepair;
            case "scrapping": return unit?.showScrapping;
            case "sold": return unit?.showSold;
        }
        return false;
    }
    
    const lockSurvey = async (answer:boolean) => {
        setLockSurveyModalOpen(false);
        setIsLoading(true);

        const clearing = {
            isPermanentlyLocked: true
        }
        let result = await updateClearing(companyId || userProfile.companyId,surveyId,clearing);
        if (result && result.status === 204) {
            setIsLocked(true);
            setIsEditable(false);
        }
        setIsLoading(false);
        window.scrollTo(0,0)
    }

    const openSurvey = async (answer:boolean) => {
        setOpenSurveyModalOpen(false);
        setIsLoading(true);

        // open survey until the end of next day
        let closedate = new Date()
        closedate = new Date(closedate.getFullYear(),closedate.getMonth(),closedate.getDate()+2);
        const clearing = {
            closeDate: closedate
        }
        let result = await updateClearing(companyId || userProfile.companyId,surveyId,clearing);
        if (result && result.status === 204) {
            setIsOpen(true);
            closedate.setSeconds(closedate.getSeconds()-1);
            setDeadline(closedate);
        }
        
        setIsLoading(false);
        window.scrollTo(0,0)
    }

    //console.log("closedate: " + clearing.closeDate);
    //console.log("deadline: " + deadline);

    return (
        <>
        <YesNoModal 
            type="save"
            header={t("features.survey.lockSurvey")}
            text={t("features.survey.lockSurveyConfirm")}
            method={lockSurvey}
            open={lockSurveyModalOpen}
            setOpen={setLockSurveyModalOpen}
            item=""
            noClick={false}
            handleClose={true}
            disableYesButton={false}
        />
        <YesNoModal 
            type="save"
            header={t("features.survey.openSurvey")}
            text={t("features.survey.openSurveyConfirm")}
            method={openSurvey}
            open={openSurveyModalOpen}
            setOpen={setOpenSurveyModalOpen}
            item=""
            noClick={false}
            handleClose={true}
            disableYesButton={false}
        />
        <PageTitle title={clearing.name!=='' ? clearing.name : t("pages.clearingDetail")} />
        <Segment className="invisible" loading={isLoading}>
            {clearing.id ?
                <>
                {isAdmin &&
                    <>
                    <Header sub>{t("features.clearing.deadline")}</Header>
                    <div>{deadline?.toLocaleDateString("fi-FI")}</div>
                    </>
                }
                {companyId &&
                    <>
                    <Header sub>{t("features.clearing.company")}</Header>
                    <div>{company && company.name}</div>
                    </>
                }

                <div className="table-wrapper">
                <Table className="clearing">
                {clearing.categories.map((cat:any) => 
                    <Table.Body key={"cat" + cat.id}>
                        <Table.Row>
                            <Table.HeaderCell colSpan="2">{cat.name}</Table.HeaderCell>
                        </Table.Row>
                        {cat.units.map((unit:any) =>
                            <Table.Row key={"unit" + unit.itemId}>
                                <Table.Cell width={4} className="header"><div>{unit.unitName}</div></Table.Cell>
                                <Table.Cell>
                                    {/* procurement */}
                                    {showInput(unit.unitId,"procurement") &&
                                        <Table style={{border: "none"}}>
                                            <Table.Body>
                                                <Table.Row className="header">
                                                    <Table.Cell width={4} className="header">{t("features.clearing.procurement")}</Table.Cell>
                                                    <Table.Cell width={4} textAlign="right">{t("features.clearing.declared")}</Table.Cell>
                                                    <Table.Cell width={4} textAlign="right">{t("features.clearing.correction")}</Table.Cell>
                                                    <Table.Cell width={4} textAlign="right">{t("features.clearing.result")}</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>{t("features.clearing.amount")} {t("features.clearing.pcs")}</Table.Cell>
                                                    <Table.Cell textAlign="right">{unit.reportedProcurementAmount}</Table.Cell>
                                                    <Table.Cell textAlign="right">{unit.clearingProcurementAmount}</Table.Cell>
                                                    <Table.Cell textAlign="right">{unit.summedProcurementAmount}</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>{t("features.clearing.revenue")} {t("features.clearing.euro")}</Table.Cell>
                                                    <Table.Cell textAlign="right">{unit.reportedProcurementRevenue?.toFixed(2)}</Table.Cell>
                                                    <Table.Cell textAlign="right">{unit.clearingProcurementRevenue?.toFixed(2)}</Table.Cell>
                                                    <Table.Cell textAlign="right">{unit.summedProcurementRevenue?.toFixed(2)}</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>{t("features.clearing.expense")} {t("features.clearing.euro")}</Table.Cell>
                                                    <Table.Cell textAlign="right">{unit.reportedProcurementExpense?.toFixed(2)}</Table.Cell>
                                                    <Table.Cell textAlign="right">{unit.clearingProcurementExpense?.toFixed(2)}</Table.Cell>
                                                    <Table.Cell textAlign="right">{unit.summedProcurementExpense?.toFixed(2)}</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>{t("features.clearing.unitExpense")} {t("features.clearing.euro")}</Table.Cell>
                                                    <Table.Cell></Table.Cell>
                                                    <Table.Cell></Table.Cell>
                                                    <Table.Cell textAlign="right">{(((unit.summedProcurementRevenue || 0) - (unit.summedProcurementExpense || 0))/(unit.summedProcurementAmount)).toFixed(2)}</Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                    }
                                    {/* repair */}
                                    {showInput(unit.unitId,"repair") &&
                                        <Table style={{border: "none"}}>
                                            <Table.Body>
                                            <Table.Row className="header">
                                                <Table.Cell width={4} className="header">{t("features.clearing.repair")}</Table.Cell>
                                                <Table.Cell width={4} textAlign="right">{t("features.clearing.declared")}</Table.Cell>
                                                <Table.Cell width={4} textAlign="right">{t("features.clearing.correction")}</Table.Cell>
                                                <Table.Cell width={4} textAlign="right">{t("features.clearing.result")}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{t("features.clearing.amount")} {t("features.clearing.pcs")}</Table.Cell>
                                                <Table.Cell textAlign="right">{unit.reportedRepairAmount}</Table.Cell>
                                                <Table.Cell textAlign="right">{unit.clearingRepairAmount}</Table.Cell>
                                                <Table.Cell textAlign="right">{unit.summedRepairAmount}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{t("features.clearing.revenue")} {t("features.clearing.euro")}</Table.Cell>
                                                <Table.Cell textAlign="right">{unit.reportedRepairRevenue?.toFixed(2)}</Table.Cell>
                                                <Table.Cell textAlign="right">{unit.clearingRepairRevenue?.toFixed(2)}</Table.Cell>
                                                <Table.Cell textAlign="right">{unit.summedRepairRevenue?.toFixed(2)}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{t("features.clearing.expense")} {t("features.clearing.euro")}</Table.Cell>
                                                <Table.Cell textAlign="right">{unit.reportedRepairExpense?.toFixed(2)}</Table.Cell>
                                                <Table.Cell textAlign="right">{unit.clearingRepairExpense?.toFixed(2)}</Table.Cell>
                                                <Table.Cell textAlign="right">{unit.summedRepairExpense?.toFixed(2)}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{t("features.clearing.unitExpense")} {t("features.clearing.euro")}</Table.Cell>
                                                <Table.Cell></Table.Cell>
                                                <Table.Cell></Table.Cell>
                                                <Table.Cell textAlign="right">{(((unit.summedRepairRevenue || 0) - (unit.summedRepairExpense || 0))/(unit.summedRepairAmount)).toFixed(2)}</Table.Cell>
                                            </Table.Row>
                                            </Table.Body>
                                        </Table>
                                    }
                                    {/* scrapping */}
                                    {showInput(unit.unitId,"scrapping") &&
                                        <Table style={{border: "none"}}>
                                            <Table.Body>
                                            <Table.Row className="header">
                                                <Table.Cell width={4} className="header">{t("features.clearing.scrapping")}</Table.Cell>
                                                <Table.Cell width={4} textAlign="right">{t("features.clearing.declared")}</Table.Cell>
                                                <Table.Cell width={4} textAlign="right">{t("features.clearing.correction")}</Table.Cell>
                                                <Table.Cell width={4} textAlign="right">{t("features.clearing.result")}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{t("features.clearing.amount")} {t("features.clearing.pcs")}</Table.Cell>
                                                <Table.Cell textAlign="right">{unit.reportedScrappingAmount}</Table.Cell>
                                                <Table.Cell textAlign="right">{unit.clearingScrappingAmount}</Table.Cell>
                                                <Table.Cell textAlign="right">{unit.summedScrappingAmount}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{t("features.clearing.revenue")} {t("features.clearing.euro")}</Table.Cell>
                                                <Table.Cell textAlign="right">{unit.reportedScrappingRevenue?.toFixed(2)}</Table.Cell>
                                                <Table.Cell textAlign="right">{unit.clearingScrappingRevenue?.toFixed(2)}</Table.Cell>
                                                <Table.Cell textAlign="right">{unit.summedScrappingRevenue?.toFixed(2)}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{t("features.clearing.expense")} {t("features.clearing.euro")}</Table.Cell>
                                                <Table.Cell textAlign="right">{unit.reportedScrappingExpense?.toFixed(2)}</Table.Cell>
                                                <Table.Cell textAlign="right">{unit.clearingScrappingExpense?.toFixed(2)}</Table.Cell>
                                                <Table.Cell textAlign="right">{unit.summedScrappingExpense?.toFixed(2)}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{t("features.clearing.unitExpense")} {t("features.clearing.euro")}</Table.Cell>
                                                <Table.Cell></Table.Cell>
                                                <Table.Cell></Table.Cell>
                                                <Table.Cell textAlign="right">{(((unit.summedScrappingRevenue || 0) - (unit.summedScrappingExpense || 0))/(unit.summedScrappingAmount)).toFixed(2)}</Table.Cell>
                                            </Table.Row>
                                            </Table.Body>
                                        </Table>
                                    }
                                    {/* sold */}
                                    {showInput(unit.unitId,"sold") &&
                                        <Table style={{border: "none"}}>
                                            <Table.Body>
                                            <Table.Row className="header">
                                                <Table.Cell width={4} className="header">{t("features.clearing.sold")}</Table.Cell>
                                                <Table.Cell width={4} textAlign="right">{t("features.clearing.declared")}</Table.Cell>
                                                <Table.Cell width={4} textAlign="right">{t("features.clearing.correction")}</Table.Cell>
                                                <Table.Cell width={4} textAlign="right">{t("features.clearing.result")}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{t("features.clearing.amount")} {t("features.clearing.pcs")}</Table.Cell>
                                                <Table.Cell textAlign="right">{unit.reportedSoldAmount}</Table.Cell>
                                                <Table.Cell textAlign="right">{unit.clearingSoldAmount}</Table.Cell>
                                                <Table.Cell textAlign="right">{unit.summedSoldAmount}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{t("features.clearing.revenue")} {t("features.clearing.euro")}</Table.Cell>
                                                <Table.Cell textAlign="right">{unit.reportedSoldRevenue?.toFixed(2)}</Table.Cell>
                                                <Table.Cell textAlign="right">{unit.clearingSoldRevenue?.toFixed(2)}</Table.Cell>
                                                <Table.Cell textAlign="right">{unit.summedSoldRevenue?.toFixed(2)}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{t("features.clearing.expense")} {t("features.clearing.euro")}</Table.Cell>
                                                <Table.Cell textAlign="right">{unit.reportedSoldExpense?.toFixed(2)}</Table.Cell>
                                                <Table.Cell textAlign="right">{unit.clearingSoldExpense?.toFixed(2)}</Table.Cell>
                                                <Table.Cell textAlign="right">{unit.summedSoldExpense?.toFixed(2)}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{t("features.clearing.unitExpense")} {t("features.clearing.euro")}</Table.Cell>
                                                <Table.Cell></Table.Cell>
                                                <Table.Cell></Table.Cell>
                                                <Table.Cell textAlign="right">{(((unit.summedSoldRevenue || 0) - (unit.summedSoldExpense || 0))/(unit.summedSoldAmount)).toFixed(2)}</Table.Cell>
                                            </Table.Row>
                                            </Table.Body>
                                        </Table>
                                    }
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                )}
                </Table>
                </div>

                <ListCompanySurveyAttachements 
                    companyId={userProfile.companyId}
                    surveyId={surveyId}
                    surveyTypeRef={"clearing"}
                />

                <ButtonContainer>
                    <EditBtn to={"edit"} disabled={!isEditable} />
                    {isAdmin &&
                        <>
                        <Button
                            icon
                            className='extended-button lock-button'
                            labelPosition="left"
                            onClick={() => setLockSurveyModalOpen(true) }
                            disabled={isLocked}
                        >
                            <Icon name={'lock'} />
                            {t("features.survey.lock")}
                        </Button>
                        <Button
                            icon
                            className='extended-button date-button'
                            labelPosition="left"
                            onClick={() => setOpenSurveyModalOpen(true) }
                            disabled={isLocked || isOpen}
                        >
                            <Icon name={'calendar'} />
                            {t("features.survey.openSurvey")}
                        </Button>
                        </>
                    }
                </ButtonContainer>
                </>
            :
                <p>{t("features.survey.notFound")}</p>
            }

        </Segment>
        </>
    );
}

export default ReadClearing;
