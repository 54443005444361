import React from 'react';
import { Container } from 'semantic-ui-react';
import IButtonContainerProps from '../interfaces/IButtonContainerProps';
//import './../styles/buttonContainer.css';

/** Form button container
 * @param {string} title - Button title
 * @param {boolean} hide - hide the button
 * @link https://react.semantic-ui.com/elements/button/
 **/
const ButtonContainer: React.FC<IButtonContainerProps> = ({children, ...rest}: IButtonContainerProps) => {

    return (
        <Container textAlign='right' className='button-container' {...rest}>
            {children}
        </Container>
    );

};

export default ButtonContainer;


