import { useState } from 'react';
import { useGetItem } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import { useTranslation } from 'react-i18next';
import apiConfig from 'Application/configurations/apiConfig';
import IUserProfile from '../interfaces/IUserProfile';
import { useAccessTokens } from 'Features/Authentication';

const useReadUserProfile = () => {

    const getItem = useGetItem();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const { t } = useTranslation();
    const [ userProfile, setUserProfile ] = useState<IUserProfile>({ id: "", companyId: "", name: "", username: "", firstName: "", lastName: "", phoneNumber: "", email: ""});
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

    /** Read User Profile
     * @returns {object} UserProfile - UserProfile object **/
    const readUserProfile = async () => {

        const basePath = apiConfig.basePath;
        const endpoint = apiConfig.endpoints.userProfile;
        const url = new URL(`${basePath}/${endpoint}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken
        }

        try {

            const response = await getItem(url, options);
            const data = response?.data || [];
            setUserProfile(data);
            return responseHandler(response, "useViewUserProfile", { operation: "read", hide: true });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "General.RequestorNotExist") body = t('features.userProfile.requestorNotExist');
            if (problemTitle === "UserProfile.ReadPermissionDenied") body = t('features.userProfile.requestorNotExist');
            if (problemTitle === "UserProfile.NotExist") body = t('features.userProfile.requestorNotExist');

            return errorHandler(error, "useViewUserProfile", { operation: "read", hide: false, messageBody: body });

        }

    }

    return { userProfile, readUserProfile };

}

export default useReadUserProfile;
