import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import useGetInventory from "./useGetInventory";
import useGetCompany from "Features/Company/ReadCompany/useGetCompany";
import { useParams } from "react-router-dom";
import { Header, Segment, Table, Button, Icon } from "semantic-ui-react";
import { ButtonContainer, EditBtn } from "Components/Buttons";
import { StateContext } from "Application/state/StateContext";
import { PageTitle } from "Components/Titles";
import useSurvey from "Features/Survey/useSurvey";
import useUpdateInventory from "../UpdateInventory/useUpdateInventory";
import { YesNoModal } from "Components/Modals";
import { ListCompanySurveyAttachements } from "Features/CompanyAttachement";

function ReadInventory () {

    const { t } = useTranslation();
    const { userProfile } = useContext(StateContext);
    const { companyId, surveyId } = useParams();
    const { inventory, getInventoryById } = useGetInventory();
    const { company, getCompanyById } = useGetCompany();
    const [ isLoading, setIsLoading ] = useState(true);
    const { isEditingAllowed } = useSurvey();
    const { updateInventory } = useUpdateInventory();
    const [ lockSurveyModalOpen, setLockSurveyModalOpen ] = useState(false);
    const [ openSurveyModalOpen, setOpenSurveyModalOpen ] = useState(false);
    const [ isLocked, setIsLocked ] = useState(true);
    const [ isOpen, setIsOpen ] = useState(true);
    const [ isEditable, setIsEditable ] = useState(false);
    const [ deadline, setDeadline ] = useState<null | Date>(null);

    const isAdmin = userProfile && userProfile.roles && userProfile.roles.find((item:any) => item.name==="Admin") ? true : false;

    // Fetch survey data
    useEffect(() => {
        (async () => {
            if(companyId || userProfile.companyId){
                await getInventoryById(companyId || userProfile.companyId,surveyId);
            }
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userProfile.companyId]);

    // Fetch company
    useEffect(() => {
        (async () => {
            if(inventory.companyId){
                await getCompanyById(inventory.companyId);
                setIsEditable(isEditingAllowed(inventory.isPermanentlyLocked,inventory.openDate,inventory.closeDate))
                setIsLocked(inventory.isPermanentlyLocked);
                setIsOpen(new Date(inventory.closeDate)>new Date());
                let dl = new Date(inventory.closeDate);
                dl.setSeconds(dl.getSeconds()-1);
                setDeadline(dl);
                setIsLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[inventory]);

    const isAnswered = (unit:any) => {
        if(unit.emptyAmount || unit.inUseFullAmount || unit.forSaleAmount ||
            unit.procurementAmount || unit.procurementRevenue || unit.procurementExpense ||
            unit.repairAmount || unit.repairRevenue || unit.repairExpense ||
            unit.scrappingAmount || unit.scrappingRevenue || unit.scrappingExpense ||
            unit.soldAmount || unit.soldRevenue || unit.soldExpense){
                return true;
        }
        return false;
    }

    const lockSurvey = async (answer:boolean) => {
        setLockSurveyModalOpen(false);
        setIsLoading(true);

        const inventory = {
            isPermanentlyLocked: true
        }
        let result = await updateInventory(companyId || userProfile.companyId,surveyId,inventory);
        if (result && result.status === 204) {
            setIsEditable(false);
            setIsLocked(true);
        }
        setIsLoading(false);
        window.scrollTo(0,0);
    }

    const openSurvey = async (answer:boolean) => {
        setOpenSurveyModalOpen(false);
        setIsLoading(true);

        // open survey until the end of next day
        let closedate = new Date()
        closedate = new Date(closedate.getFullYear(),closedate.getMonth(),closedate.getDate()+2);
        const inventory = {
            closeDate: closedate
        }
        let result = await updateInventory(companyId || userProfile.companyId,surveyId,inventory);
        if (result && result.status === 204) {
            setIsOpen(true);
            closedate.setSeconds(closedate.getSeconds()-1);
            setDeadline(closedate);
        }
        
        setIsLoading(false);
        window.scrollTo(0,0);
    }

    return (
        <>
        <YesNoModal 
            type="save"
            header={t("features.survey.lockSurvey")}
            text={t("features.survey.lockSurveyConfirm")}
            method={lockSurvey}
            open={lockSurveyModalOpen}
            setOpen={setLockSurveyModalOpen}
            item=""
            noClick={false}
            handleClose={true}
            disableYesButton={false}
        />
        <YesNoModal 
            type="save"
            header={t("features.survey.openSurvey")}
            text={t("features.survey.openSurveyConfirm")}
            method={openSurvey}
            open={openSurveyModalOpen}
            setOpen={setOpenSurveyModalOpen}
            item=""
            noClick={false}
            handleClose={true}
            disableYesButton={false}
        />
        <PageTitle title={inventory.name!=='' ? inventory.name : t("pages.inventoryDetail")} />
        <Segment className="invisible" loading={isLoading}>
            {inventory.id ?
                <>
                {isAdmin &&
                    <>
                    <Header sub>{t("features.budget.deadline")}</Header>
                    <div>{deadline?.toLocaleDateString("fi-FI")}</div>
                    </>
                }
                {companyId &&
                    <>
                    <Header sub>{t("features.inventory.company")}</Header>
                    <div>{company && company.name}</div>
                    </>
                }

                <div className="table-wrapper">
                <Table className="inventory">
                {inventory.categories.map((cat:any) => 
                    <Table.Body key={"cat" + cat.id}>
                        {cat.units.filter((unit:any) => isAnswered(unit)).length>0 &&
                            <Table.Row>
                                <Table.HeaderCell>{cat.name}</Table.HeaderCell>
                            </Table.Row>
                        }
                        {cat.units.filter((unit:any) => isAnswered(unit)).map((unit:any) =>
                            <Table.Row key={"unit" + unit.itemId}>
                                <Table.Cell>
                                    <Table>
                                        <Table.Body>
                                            <Table.Row className="header">
                                                <Table.Cell width={7}><div style={{fontWeight: "bold"}}>{unit.unitName}</div></Table.Cell>
                                                <Table.Cell width={3} textAlign="right">{t("features.inventory.amount")}</Table.Cell>
                                                <Table.Cell width={3} textAlign="right">{t("features.inventory.revenue")}</Table.Cell>
                                                <Table.Cell width={3} textAlign="right">{t("features.inventory.expense")}</Table.Cell>
                                            </Table.Row>
                                            {(unit.emptyAmount || 0)>0 &&
                                                <Table.Row>
                                                    <Table.Cell>{t("features.inventory.emptyAmount")}</Table.Cell>
                                                    <Table.Cell textAlign="right">{unit.emptyAmount}</Table.Cell>
                                                    <Table.Cell></Table.Cell>
                                                    <Table.Cell></Table.Cell>
                                                </Table.Row>
                                            }
                                            {(unit.inUseFullAmount || 0)>0 &&
                                                <Table.Row>
                                                    <Table.Cell>{t("features.inventory.categories." + cat.id + ".inUseFullAmount")}</Table.Cell>
                                                    <Table.Cell textAlign="right">{unit.inUseFullAmount}</Table.Cell>
                                                    <Table.Cell></Table.Cell>
                                                    <Table.Cell></Table.Cell>
                                                </Table.Row>
                                            }
                                            {(unit.forSaleAmount || 0)>0  &&
                                                <Table.Row>
                                                    <Table.Cell>{t("features.inventory.forSaleAmount")}</Table.Cell>
                                                    <Table.Cell textAlign="right">{unit.forSaleAmount}</Table.Cell>
                                                    <Table.Cell></Table.Cell>
                                                    <Table.Cell></Table.Cell>
                                                </Table.Row>
                                            }
                                            {((unit.procurementAmount || 0)>0 || (unit.procurementRevenue || 0)>0 || (unit.procurementExpense || 0)>0) &&
                                                <Table.Row>
                                                    <Table.Cell>{t("features.inventory.procurement")}</Table.Cell>
                                                    <Table.Cell textAlign="right">{unit.procurementAmount}</Table.Cell>
                                                    <Table.Cell textAlign="right">{unit.procurementRevenue?.toFixed(2)}</Table.Cell>
                                                    <Table.Cell textAlign="right">{unit.procurementExpense?.toFixed(2)}</Table.Cell>
                                                </Table.Row>
                                            }
                                            {((unit.repairAmount || 0)>0 || (unit.repairRevenue || 0)>0 || (unit.repairExpense || 0)>0) &&
                                                <Table.Row>
                                                    <Table.Cell>{t("features.inventory.repair")}</Table.Cell>
                                                    <Table.Cell textAlign="right">{unit.repairAmount}</Table.Cell>
                                                    <Table.Cell textAlign="right">{unit.repairRevenue?.toFixed(2)}</Table.Cell>
                                                    <Table.Cell textAlign="right">{unit.repairExpense?.toFixed(2)}</Table.Cell>
                                                </Table.Row>
                                            }
                                            {((unit.scrappingAmount || 0)>0 || (unit.scrappingRevenue || 0)>0 || (unit.scrappingExpense || 0)>0) &&
                                                <Table.Row>
                                                    <Table.Cell>{t("features.inventory.scrapping")}</Table.Cell>
                                                    <Table.Cell textAlign="right">{unit.scrappingAmount}</Table.Cell>
                                                    <Table.Cell textAlign="right">{unit.scrappingRevenue?.toFixed(2)}</Table.Cell>
                                                    <Table.Cell textAlign="right">{unit.scrappingExpense?.toFixed(2)}</Table.Cell>
                                                </Table.Row>
                                            }
                                            {((unit.soldAmount || 0)>0 || (unit.soldRevenue || 0)>0 || (unit.soldExpense || 0)>0) &&
                                                <Table.Row>
                                                    <Table.Cell>{t("features.inventory.sold")}</Table.Cell>
                                                    <Table.Cell textAlign="right">{unit.soldAmount}</Table.Cell>
                                                    <Table.Cell textAlign="right">{unit.soldRevenue?.toFixed(2)}</Table.Cell>
                                                    <Table.Cell textAlign="right">{unit.soldExpense?.toFixed(2)}</Table.Cell>
                                                </Table.Row>
                                            }
                                        </Table.Body>
                                    </Table>

                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                )}
                </Table>
                </div>

                <ListCompanySurveyAttachements 
                    companyId={userProfile.companyId}
                    surveyId={surveyId}
                    surveyTypeRef={"inventory"}
                />

                <ButtonContainer>
                    <EditBtn to={"edit"} disabled={!isEditable} />
                    {isAdmin &&
                        <>
                        <Button
                            icon
                            className='extended-button lock-button'
                            labelPosition="left"
                            onClick={() => setLockSurveyModalOpen(true) }
                            disabled={isLocked}
                        >
                            <Icon name={'lock'} />
                            {t("features.survey.lock")}
                        </Button>
                        <Button
                            icon
                            className='extended-button date-button'
                            labelPosition="left"
                            onClick={() => setOpenSurveyModalOpen(true) }
                            disabled={isLocked || isOpen}
                        >
                            <Icon name={'calendar'} />
                            {t("features.survey.openSurvey")}
                        </Button>
                        </>
                    }
                </ButtonContainer>
                </>
            :
                <p>{t("features.survey.notFound")}</p>
            }

        </Segment>
        </>
    );
}

export default ReadInventory;
