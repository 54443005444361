import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useGetYearlyBudget from "./useGetYearlyBudget";
import { useParams } from 'react-router-dom';
import { Header, Segment } from 'semantic-ui-react';
import { ButtonContainer, EditBtn } from 'Components/Buttons';

function ReadYearlyBudget () {

    const { t } = useTranslation();
    const { id } = useParams();
    const { yearlyBudget, getYearlyBudgetById } = useGetYearlyBudget();
    const [ isLoading, setIsLoading ] = useState(true);

    // Fetch YearlyBudget
    useEffect(() => {
        (async () => {
            if (id) {
                await getYearlyBudgetById(id);
                setIsLoading(false);
            }            
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    
    return (
        <Segment className="invisible" loading={isLoading}>
        {yearlyBudget.id ?
            <>
            <Header sub>{t("features.yearlyBudget.year")}</Header>
            <div>{yearlyBudget && yearlyBudget.year}</div>

            <Header sub>{t("features.yearlyBudget.budget")}</Header>
            <div>{yearlyBudget && yearlyBudget.budget}</div>

            <ButtonContainer>
                <EditBtn to={"/management/yearlybudgets/" + id + "/edit"} />
            </ButtonContainer>
            </>
        :
            <p>{t("features.YearlyBudget.notFound")}</p>
        }

        </Segment>
    );

}

export default ReadYearlyBudget;
