import React from 'react';
import { Message, Icon } from 'semantic-ui-react';
import IMessageProps from '../interfaces/IPageMessageProps';

const PageMessage: React.FC<IMessageProps> = ({ type, dismiss, header, content, option }: IMessageProps) => {

    const onDismiss = () => {
        if (dismiss) dismiss();
    }

    return (
        <Message
            size='tiny'
            icon
            negative={type && type === 'negative' ? true : false}
            warning={type && type === 'warning' ? true : false}
            positive={type && (type === 'fetching'|| type === 'positive') ? true : false}
            info={type && (type === 'info' || type === 'saving') ? true : false}
            onDismiss={() => onDismiss()}
        >
            <Message.Content>
                <Message.Header>

                    {type && type === 'negative' && <Icon name='exclamation triangle' />  }
                    {type && type === 'warning' && <Icon name='exclamation' />  }
                    {type && type === 'fetching' && <Icon name='circle notched' loading />  }
                    {type && (type === 'info' || type === 'positive') && <Icon name='circle notched' />  }
                    {type && type === 'saving' && <Icon name='check' />  }

                    {header}

                </Message.Header>
                {content}
                <br/>
                {option}
            </Message.Content>
        </Message>
    )
}

export default PageMessage;
