import { useState } from 'react';
import { useGetItem } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import { useTranslation } from 'react-i18next';
import apiConfig from 'Application/configurations/apiConfig';
import IReadInventory from './interfaces/IReadInventory';
import { useAccessTokens } from 'Features/Authentication';

const useGetInventory = () => {

    const { t } = useTranslation();
    const getItem = useGetItem();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const [ inventory, setInventory ] = useState<IReadInventory>({id: "", companyId: "", name: "", isAnswered: true, isPermanentlyLocked: true, categories: [], openDate: new Date(), closeDate: new Date()});
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

    /** Read inventory by guid
     * @param {string} companyId - guid of the company
     * @param {string} surveyId - guid of the survey
     * @returns {object} inventory - inventory object
     **/
    const getInventoryById = async (companyId: any, surveyId: any) => {

        const basePath = apiConfig.basePath;
        const companyEndpoint = apiConfig.endpoints.companies
        const surveyEndpoint = apiConfig.endpoints.inventories;
        const url = new URL(`${basePath}/${companyEndpoint}/${companyId}/${surveyEndpoint}/${surveyId}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken
        }

        try {

            const response = await getItem(url, options);
            const data = response?.data || [];
            setInventory(data);
            return responseHandler(response, "useGetInventory", { operation: "read", hide: true });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "Inventory.ReadPermissionDenied") body = t('features.survey.readPermissionDenied');
            if (problemTitle === "Company.CompanyDataAccessDenied") body = t('features.company.companyDataAccessDenied');
            if (problemTitle === "Company.NotExist") body = t('features.company.notExist');
            if (problemTitle === "UserAccount.NotExist") body = t('features.account.userAccountNotExist');
            if (problemTitle === "General.RequestorNotExist") body = t('features.survey.requestorNotExist');
            if (problemTitle === "Inventory.NotExist") body = t('features.survey.notExist');

            return errorHandler(error, "useGetInventory", { operation: "read", hide: false, messageBody: body });

        }

    }

    return { inventory, setInventory, getInventoryById };

}

export default useGetInventory;
