import { Breadcrumb } from "semantic-ui-react";
import { Link } from "react-router-dom";
import useBreadCrumb from './useBreadCrumb';
import './breadcrumb.css';
import { useTranslation } from 'react-i18next';

const Bread = () => {

    const { getPathSections } = useBreadCrumb();
    const { t } = useTranslation();
   
    return (
        <Breadcrumb style={{ marginTop: '10px' }}>
            <Breadcrumb.Section><Link to="/">{t('components.layout.breadcrumb.Home')}</Link></Breadcrumb.Section>
            {getPathSections()}
        </Breadcrumb>
    );
}

export default Bread;