import { PageTitle } from "Components/Titles";
import { Container } from "semantic-ui-react";
import { CreateCompany } from "Features/Company";
import { useTranslation } from "react-i18next";

function NewCompany () {

    const { t } = useTranslation();

    return (
        <Container className="page">
            <PageTitle title={t("pages.newCompany")} />
            <CreateCompany />
        </Container>
    );
}

export default NewCompany;