import React, { useState, useEffect } from 'react';
import { Table } from 'semantic-ui-react';
import TablePagination from '../PagedTable/components/TablePagination';
import TableHeader from '../PagedTable/components/TableHeader';
import TableBody from '../PagedTable/components/TableBody';
import useSortContent from '../PagedTable/hooks/useSortContent';
import IPagedTableProps from "../PagedTable/interfaces/IPagedTableProps";
import './styles/pagedtable.css';

/** Combination of Semantic UI Table and Pagination
 * @param {array} header - Translated strings of header cells
 * @param {array} content - Table content as array of objects
 * @param {array} contentKeys - Array of objects containing keys, actions and type of content fields rendered to table
 * 
 * @param {number} pageSize - How many items per page? default is 10.
 * @param {any} resetPage - reset the page to page 1 when this value changes.
 * @param {boolean} collapsable - Is the rows collapsable?
 * @param {boolean} resetPageOnContentChange - Reset the page back to 1 when content has changed?
 * 
 * @param {string} tableprops - Semantic UI table properties passed to component
 * 
 * @example
    <PagedTable unstackable celled striped compact='very' pageSize={20}
       header={[
           t('general.name'),
           t('general.firstName'),
           t('general.lastName'),
           t('general.admin'),
           t('general.previousLogin'),
           t('general.action'),
           t('general.select')
       ]}
       content={[
          { id: 1, name: "Teppo Mahönen", firstName:"Teppo", lastName:"Mahönen", isAdminUser:true, previousLogin:"2023-11-06T10:46:07.912Z" },
       ]}
       contentKeys={[
           {key: 'firstName', key2: 'lastName', target: '/users/', type: 'linkWithTwoKeys', targetKey: 'id'},
           {key: 'firstName' },
           {key: 'lastName', target: '/users', type: 'link', targetKey: 'id'},
           {key: 'isAdminUser', type: 'boolean' },
           {key: 'previousLogin', type: 'date'},
           {key: 'id', type: 'action', text: t('general.acknowledge'), action: (ev, row, cellKey) => { 
              setAckNotificationId(row.id);
              setModalIsOpen(true);
           }},
           {key: 'Title', type: 'linkWithTwoTargetsAndKeys', target1: '/projects/', targetKey1: 'Id', target2: '/activities/new', targetKey2:'',  text: t('activity.newActivity')}           
           {key: 'Select', checked:'CheckThisItem' type: 'select', action: (ev, row, cellKey) => { 
              console.log(row);
           }},
       ]}
    />
 */
const PagedTable: React.FC<IPagedTableProps> = ({
    header,
    content,
    contentKeys,
    pageSize = 10,
    resetPage,
    collapsable = false,
    resetPageOnContentChange = false,
    ...tableprops
}: IPagedTableProps) => {

    const [ activePage, setActivePage ] = useState(0);
    const [ sortedContent, setSortedContent ] = useState(content);
    const [ sortBy, setSortBy ] = useState({ key: '', asc: true, type: 'string' });  
    const { sortContent } = useSortContent();

    useEffect(() => {
        let value = sortBy && sortBy.key ? sortContent(sortBy, content) : content;
        setSortedContent(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortBy, content]);

    useEffect(() => {
        setActivePage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetPage]);

    useEffect(() => {
        if (resetPageOnContentChange) setActivePage(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content]);

    return (
        <>

            {/* Paged table */}
            <div className='table-wrapper'>
                <Table {...tableprops}>

                    <TableHeader
                        header={header}
                        content={content}
                        contentKeys={contentKeys}
                        collapsable={collapsable}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                    />

                    <TableBody
                        header={header}
                        content={sortedContent}
                        contentKeys={contentKeys}
                        collapsable={collapsable}
                        activePage={activePage}
                        pageSize={pageSize}
                    />

                </Table>
            </div>

            {/* Show pagination if there are more items that can fit in one page */}
            {content.length > pageSize && 
                <TablePagination
                    content={content}
                    pageSize={pageSize}
                    activePage={activePage}
                    setActivePage={setActivePage}
                /> 
            }

        </>
    );
}

export default PagedTable;