import { usePutItem } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import apiConfig from 'Application/configurations/apiConfig';
import IUpdateUnit from "./IUpdateUnit";
import { useAccessTokens } from 'Features/Authentication';
import { useTranslation } from 'react-i18next';

function useUpdateUnit () {

    const { t } = useTranslation();
    const putItem = usePutItem();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

    /** update unit
     * @param {IUpdateUnit} unit - object which describes the modified unit
     **/
    const updateUnit = async (id: string="", unit: IUpdateUnit) => {

        const basePath = apiConfig.basePath;
        const endpoint = apiConfig.endpoints.units;
        const url = new URL(`${basePath}/${endpoint}/${id}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken,
            body: unit
        }

        try {

            const response = await putItem(url, options);
         
            return responseHandler(response, "useUpdateUnit", { operation: "update", messageHeader: t("features.unit.savedSuccessfully"), hide: false });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "General.RequestorNotExist") body = t('features.unit.requestorNotExist');
            if (problemTitle === "Unit.NotExist") body = t('features.unit.notExist');
            if (problemTitle === "Unit.AlreadyExist") body = t('features.unit.alreadyExist');
            if (problemTitle === "Unit.UpdatePermissionDenied") body = t('features.unit.updatePermissionDenied');
            if (problemTitle === "UnitCategory.NotExist") body = t('features.unit.categotyNotExist');

            return errorHandler(error, "useUpdateUnit", { operation: "update", hide: false, messageBody: body });

        }

    }

    return { updateUnit }

}

export default useUpdateUnit;
