import { Container } from "semantic-ui-react";
import { UpdateStorageCost } from "Features/Survey";

function EditStorageCost () {

    return (
        <Container className="page">
            <UpdateStorageCost />
        </Container>
    );
}

export default EditStorageCost;