import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useGetCompany from "./useGetCompany";
import { useParams } from 'react-router-dom';
import { Header, Segment } from 'semantic-ui-react';
import { ButtonContainer, EditBtn } from 'Components/Buttons';
import { SettingsContainer, SettingsBox } from "Components/Settings";

function ReadCompany () {

    const { t } = useTranslation();
    const { companyId } = useParams();
    const { company, getCompanyById } = useGetCompany();
    const [ isLoading, setIsLoading ] = useState(true);

    // Fetch company
    useEffect(() => {
        (async () => {
            if (companyId) {
                await getCompanyById(companyId);
                setIsLoading(false);
            }            
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const factoryAddress = company ? company.addresses.find(item => item.type === 3) : undefined;
    const billingAddress = company ? company.addresses.find(item => item.type === 2) : undefined;
    
    return (
        <Segment className="invisible" loading={isLoading}>
            {company.name ?
                <>
                <Segment loading={isLoading}>
                    <Header sub>{t("features.company.name")}</Header>
                    <div className="title"><strong></strong></div>
                    <div>{company && company.name}</div>

                    <Header sub>{t("features.company.businessIdentityCode")}</Header>
                    <div>{company && company.businessIdentityCode}</div>

                    <Header sub>{t("features.company.factoryAddress")}</Header>
                    {factoryAddress && 
                        <>
                            <div className="wrap-newline">{factoryAddress.addressLine1}</div>
                            <div>{factoryAddress.postalCode} {factoryAddress.area}</div>
                        </>
                    }
                    
                    <Header sub>{t("features.company.billingAddress")}</Header>
                    {billingAddress && 
                        <>
                            <div className="wrap-newline">{billingAddress.addressLine1}</div>
                            <div>{billingAddress.postalCode} {billingAddress.area}</div>
                            <div>{billingAddress.email}</div>
                        </>
                    }
                    <div>{t('features.company.billingReference')}: {company && company.billing.reference}</div>

                    <Header sub>{t('features.company.sharepointUrl')}</Header>
                    <div>{company && company.sharePointDocumentLibrary.urlSegment}</div>

                    <Header sub>{company.isActive ? t("features.company.companyIsActive") : t("features.company.companyIsNotActive")}</Header>

                    <ButtonContainer><EditBtn to={"/management/companies/" + companyId + "/edit"} /></ButtonContainer>
                </Segment>
                <SettingsContainer>

                    {/* Accounts */}
                    <SettingsBox title={t("features.company.accounts")}
                        description={t("features.company.manageAccounts")}
                        icon="user"
                        items={[
                            { to: "/management/companies/" + companyId + "/accounts", title: t('features.company.accounts') }
                        ]}
                    />

                    {/* Surveys */}
                    <SettingsBox title={t("features.company.surveys")}
                        description={t("features.company.manageSurveys")}
                        icon="question"
                        items={[
                            { to: "/management/companies/" + companyId + "/surveytypes", title: t('features.company.surveyTypes') },
                            { to: "/management/companies/" + companyId + "/surveys", title: t('features.company.surveyAnswers') }
                        ]}
                    />

                    {/* Units */}
                    <SettingsBox title={t("features.company.units")}
                        description={t("features.company.manageUnits")}
                        icon="inbox"
                        items={[
                            { to: "/management/companies/" + companyId + "/units", title: t('features.company.units') }
                        ]}
                    />

                </SettingsContainer>
            </>
        :
            <p>{t("features.company.notFound")}</p>
        }

        </Segment>
    );

}

export default ReadCompany;
