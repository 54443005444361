import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PageMessage from '../components/PageMessage';
import IMessage from '../interfaces/IMessage';
//import { mockMessages, mockMessage } from './mockdata';;

/** Use Messages provides, logic, data, validations and methods to be used in the Messages component */
const useMessages = () => {

    const [ messages, setMessages ] = useState([]); // mockMessages || [mockMessage]

    // Remove message from the messages array (dont remove errors automatically!)
    const removeMessageAfterTimeout = (id: number) => {
        return setTimeout(() => {
            setMessages(messages => {
                return messages.filter((msg: IMessage) => {
                    if (msg.type === 'error') return true;
                    if (msg.type === 'portal') return true; // Portal message is visible until removed by user
                    if (msg.id === id) return false;
                    return true;
                });
            });
        }, 5000);
    }

    const removeErrorMessageAfterTimeout = (id: number) => {
        return setTimeout(() => {
            setMessages(messages => {
                return messages.filter((msg: IMessage) => {
                    if (msg.type === 'error') return false;
                    if (msg.type === 'portal') return false; // Portal message is visible until removed by user
                    if (msg.id === id) return true;
                    return true;
                });
            });
        }, 10000);
    }

    /** Function adds array of messages to the container
     * @description type, header and body are all mandatory!!!
     * @param {array} msg - Message object or array of objects
     * @example Array of messages
     *    Array:  [{type:"", header:"", body:""},{type:"", header:"", body:""}]
     **/
    const addMessages = (msgs: Array<IMessage>) => {

        return setMessages(messages => {

            const clone: any = [...messages];

            msgs.forEach(item => {

                item.id = uuidv4();
                clone.push(item);

                if (item.type === 'error') removeErrorMessageAfterTimeout(item.id || 0);
                if (item.type !== 'error') removeMessageAfterTimeout(item.id || 0);

            });

            return clone;

        });

    }

    /** Function adds single message to the container
     * @description type, header and body are all mandatory!!!
     * @param {object} msg - Message object
     * @example message object
     *    Object: {type:"", header:"", body:""} 
     **/
    const addMessage = (msg: IMessage) => {

        msg.id = uuidv4();

        setMessages(messages => {

            const clone: any = [...messages];
            clone.push(msg);

            if (msg.type === 'error') removeErrorMessageAfterTimeout(msg.id || 0);
            if (msg.type !== 'error') removeMessageAfterTimeout(msg.id || 0);

            return clone;
        
        });

        return msg.id;

    }

    /* Function removes message from the container */
    const removeMessage = (id: number) => {
        setMessages(messages => {
            return messages.filter((item: any) => {
                return item.id !== id; 
            });
        });
    }

    // Get all messages - return Array of Semantic UI react Message componentes
    const getMessages = () => {

        const msg = messages ? messages.map((msg: IMessage) => {

            let selectedType = null;
            
            switch (msg.type) {
                case "success":
                    selectedType = <PageMessage type="positive" key={msg.id} header={msg.header} content={msg.body} option={msg.option} dismiss={() => removeMessage(msg.id)} />
                    break;
                case "warning":
                    selectedType = <PageMessage type="warning" key={msg.id} header={msg.header} content={msg.body} option={msg.option} dismiss={() => removeMessage(msg.id)} />
                    break;
                case "error":
                    selectedType = <PageMessage type="negative" key={msg.id} header={msg.header} content={msg.body} option={msg.option} dismiss={() => removeMessage(msg.id)} />
                    break;
                case "saving":
                    selectedType = <PageMessage type="saving" key={msg.id} header={msg.header} content={msg.body} option={msg.option} dismiss={() => removeMessage(msg.id)} />
                    break;
                case "loading":
                    selectedType = <PageMessage type="fetching" key={msg.id} header={msg.header} content={msg.body} option={msg.option} dismiss={() => removeMessage(msg.id)} />
                    break;
                case "portal":
                    selectedType = <PageMessage type="warning" key={msg.id} header={msg.header} content={msg.body} option={msg.option} dismiss={() => removeMessage(msg.id)} />
                    break;
                case "info":
                    selectedType = <PageMessage type="info" key={msg.id} header={msg.header} content={msg.body} option={msg.option} dismiss={() => removeMessage(msg.id)} />
                    break;
                default:
                    selectedType = <PageMessage type="info" key={msg.id} header={msg.header} content={msg.body} option={msg.option} dismiss={() => removeMessage(msg.id)} />
                    break;
            }

            return selectedType;

        }) : [];

        return msg;
    }

    return { messages, setMessages, addMessage, addMessages, removeMessage, getMessages };

}

export default useMessages;
