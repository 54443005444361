import { usePutItem } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import apiConfig from 'Application/configurations/apiConfig';
import IUpdateYearlyBudget from "./IUpdateYearlyBudget";
import { useAccessTokens } from 'Features/Authentication';
import { useTranslation } from 'react-i18next';

function useUpdateYearlyBudget () {

    const { t } = useTranslation();
    const putItem = usePutItem();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

    /** Update yearly budget
     * @param {IUpdateYearlyBudget} yearlyBudget - object which describes the yearly budget
     **/
    const updateYearlyBudget = async (id: string="", yearlyBudget: IUpdateYearlyBudget) => {

        const basePath = apiConfig.basePath;
        const endpoint = apiConfig.endpoints.yearlyBudgets;
        const url = new URL(`${basePath}/${endpoint}/${id}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken,
            body: yearlyBudget
        }

        try {

            const response = await putItem(url, options);
         
            return responseHandler(response, "useUpdateYearlyBudget", { operation: "update", messageHeader: t("features.yearlyBudget.savedSuccessfully"), hide: false });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "General.RequestorNotExist") body = t('features.yearlyBudget.requestorNotExist');
            if (problemTitle === "YearlyBudgetErrors.NotExist") body = t('features.yearlyBudget.notExist');
            if (problemTitle === "YearlyBudgetErrors.AlreadyExist") body = t('features.yearlyBudget.alreadyExist');
            if (problemTitle === "YearlyBudgetErrors.UpdatePermissionDenied") body = t('features.yearlyBudget.updatePermissionDenied');

            return errorHandler(error, "useUpdateYearlyBudget", { operation: "update", hide: false, messageBody: body });

        }

    }

    return { updateYearlyBudget }

}

export default useUpdateYearlyBudget;
