import { Container } from "semantic-ui-react";
import { UpdateTransferFreightCost } from "Features/Survey";

function EditTransferFreightCost () {

    return (
        <Container className="page">
            <UpdateTransferFreightCost />
        </Container>
    );
}

export default EditTransferFreightCost;