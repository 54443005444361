import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';
import IDeleteBtnProps from '../interfaces/IDeleteBtnProps';
import './../styles/button.css';

/** Form Delete Button - call delete method
 * @param {function} action - function which is executed on button click func(ev, data)
 * @param {string} title - Button title
 * @param {boolean} hide - hide the button
 * @param {string} [labelPosition] - label position 'left' | 'right'
 * @link https://react.semantic-ui.com/elements/button/
 **/
const DeleteBtn: React.FC<IDeleteBtnProps> = (props: IDeleteBtnProps) => {

    const { t } = useTranslation();
    const { setOpen, title, hidden=false, color, labelPosition, type, ...rest } = props;

    if (hidden) return null;

    return (
        <Button
            icon
            className='extended-button delete-button'
            color={color ? color : undefined}
            labelPosition={labelPosition ? labelPosition : 'left'}
            type={type ? type : 'reset'}
            onClick={(ev, data) => setOpen(true) }
            {...rest}
        >
            <Icon name={'delete'} />
            {title ? title : t('components.buttons.delete')}
        </Button>
    );

};

export default DeleteBtn;
