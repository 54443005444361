import { usePutItem } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import apiConfig from 'Application/configurations/apiConfig';
import IUpdateGlassBottleReturnRate from "./interfaces/IUpdateGlassBottleReturnRate";
import ILockGlassBottleReturnRate from './interfaces/ILockGlassBottleReturnRate';
import { useAccessTokens } from 'Features/Authentication';
import { useTranslation } from 'react-i18next';
import IOpenGlassBottleReturnRate from './interfaces/IOpenGlassBottleReturnrate';

function useUpdateGlassBottleReturnRate () {

    const { t } = useTranslation();
    const putItem = usePutItem();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

    /** Update glass bottle return rate
     * @param {string} companyId - guid of the company
     * @param {string} surveyId - guid of the survey
     * @param {IUpdateGlassBottleReturnRate} surveyData - object which describes the survey
     **/
    const updateGlassBottleReturnRate = async (companyId: string = "", surveyId: string="", surveyData: IUpdateGlassBottleReturnRate | ILockGlassBottleReturnRate | IOpenGlassBottleReturnRate) => {

        const basePath = apiConfig.basePath;
        const companyEndpoint = apiConfig.endpoints.companies
        const surveyEndpoint = apiConfig.endpoints.glassBottleReturnRates;
        const url = new URL(`${basePath}/${companyEndpoint}/${companyId}/${surveyEndpoint}/${surveyId}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken,
            body: surveyData
        }

        try {

            const response = await putItem(url, options);
         
            return responseHandler(response, "useUpdateGlassBottleReturnRate", { operation: "update", messageHeader: t("features.glassBottleReturnRate.savedSuccessfully"), hide: false });


        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "GlassBottleReturnRate.UpdatePermissionDenied") body = t('features.survey.updatePermissionDenied');
            if (problemTitle === "Company.CompanyDataAccessDenied") body = t('features.company.companyDataAccessDenied');
            if (problemTitle === "Company.NotExist") body = t('features.company.notExist');
            if (problemTitle === "UserAccount.NotExist") body = t('features.account.userAccountNotExist');
            if (problemTitle === "General.RequestorNotExist") body = t('features.survey.requestorNotExist');
            if (problemTitle === "GlassBottleReturnRate.NotExist") body = t('features.survey.notExist');
            if (problemTitle === "GlassBottleReturnRate.PermanentlyLocked") body = t('features.survey.permanentlyLocked');
            if (problemTitle === "GlassBottleReturnRate.Closed") body = t('features.survey.closed');

            return errorHandler(error, "useUpdateGlassBottleReturnRate", { operation: "update", hide: false, messageBody: body });

        }

    }

    return { updateGlassBottleReturnRate }

}

export default useUpdateGlassBottleReturnRate;
