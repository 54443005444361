import { useTranslation } from 'react-i18next';
import { Container } from 'semantic-ui-react';
import { PageTitle } from 'Components/Titles';
import { Loading } from 'Components/Loaders'
//import './../loaders.css';

const Loaders = () => {

    const { t } = useTranslation();

    return (
        <Container className="page">

            <PageTitle title={t('pages.loaders')}/>

            <Loading active={true} />

        </Container>
    )

}


export default Loaders;
