import { useState } from 'react';
import { useGetItem } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import { useTranslation } from 'react-i18next';
import apiConfig from 'Application/configurations/apiConfig';
import IReadUnit from './IReadUnit';
import { useAccessTokens } from 'Features/Authentication';

const useGetUnit = () => {

    const { t } = useTranslation();
    const getItem = useGetItem();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const [ unit, setUnit ] = useState<IReadUnit>({name: "", width: 0, height: 0, depth: 0, mass: 0, stackAmount: 0, initialPool: 0, isActive: true, isDeleted: false, category: {id: "", name: ""}});
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

    /** Read unit by guid
     * @param {string} id - guid of the unit
     * @returns {object} unit - unit object
     **/
    const getUnitById = async (id: any) => {

        const basePath = apiConfig.basePath;
        const endpoint = apiConfig.endpoints.units;
        const url = new URL(`${basePath}/${endpoint}/${id}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken
        }

        try {

            const response = await getItem(url, options);
            const data = response?.data || [];
            setUnit(data);
            return responseHandler(response, "useGetUnit", { operation: "read", hide: true });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "General.RequestorNotExist") body = t('features.unit.requestorNotExist');
            if (problemTitle === "Unit.ReadPermissionDenied") body = t('features.unit.readPermissionDenied');
            if (problemTitle === "Unit.NotExist") body = t('features.unit.notExist');

            return errorHandler(error, "useGetUnit", { operation: "read", hide: false, messageBody: body });

        }

    }

    return { unit, setUnit, getUnitById };

}

export default useGetUnit;

