import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'semantic-ui-react';
import { ApproveModal, YesNoModal, OpenModalBtn } from 'Components/Modals';
import { PageTitle } from 'Components/Titles';
//import './../modals.css';

const Modals = () => {

    const { t } = useTranslation();
    const [ openApprove, setOpenApprove ] = useState(false);
    const [ openYesNo, setOpenYesNo ] = useState(false);

    return (
        <Container className="page">

            <PageTitle title={t('pages.modals')}/>

            <ApproveModal
                type="info"
                header="header"
                text="text"
                open={openApprove}
                setOpen={setOpenApprove}
                disableApproveButton={true}
            />

            <OpenModalBtn onClick={() => setOpenApprove(true)} title='Open Approve Modal' />

            <YesNoModal
                type="delete"
                header="header"
                text="text"
                method={() => {
                    return console.log("method clicked");
                }}
                item={34}
                noClick={true}
                open={openYesNo}
                setOpen={setOpenYesNo}
                handleClose={true}
                disableYesButton={false}
            />
            <OpenModalBtn onClick={() => setOpenYesNo(true)} title='Open Yes No Modal' />

        </Container>
    )

}


export default Modals;
