import React from 'react';
import { useNavigate } from 'react-router-dom';
import { YesNoModal } from 'Components/Modals';
import useDeleteAnnouncement from 'Features/Announcements/DeleteAnnouncement/useDeleteAnnouncement';
import IDeleteModalProps from './IDeleteModalProps';

/** Delete announcement modal is used to soft delete single portal announcement */
const DeleteAnnouncementModal: React.FC<IDeleteModalProps> = ({ id, isOpen, setIsOpen, setIsLoading }: IDeleteModalProps) => {

    const navigate = useNavigate();
    const deleteAnnouncementById = useDeleteAnnouncement();

    return (
        <div className="portal-announcement delete">
            <YesNoModal
                type='delete'
                header=''
                text=''
                method={async (answer: boolean) => {
                    setIsLoading(true);
                    if (answer) {
                        await deleteAnnouncementById(id);
                        return navigate(-1);
                    }
                    setIsLoading(false);
                }}
                item={id}
                noClick={false}
                open={isOpen}
                setOpen={setIsOpen}
                handleClose={true}
                disableYesButton={false}
            />
        </div>
    );

}

export default DeleteAnnouncementModal;
