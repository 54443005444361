import { Container } from "semantic-ui-react";
import { UpdateClearing } from "Features/Survey";

function EditClearing () {

    return (
        <Container className="page">
            <UpdateClearing />
        </Container>
    );
}

export default EditClearing;