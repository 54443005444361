import { useTranslation } from 'react-i18next';
import { Container, Form, Grid } from 'semantic-ui-react';
import { PageTitle } from 'Components/Titles';
import { Release } from 'Components/VersionHistory';

const VersionHistory = () => {

    const { t } = useTranslation();

    return (
        <Container className="page versions">
            <Form>

                <PageTitle title={t('pages.versionHistory')} />

                <Grid stackable>
                   
                    {/* < ADD NEW VERSION ALWAYS ON THE TOP BUT BELOW THIS LINE > */}

                    <Release version="3.2.2"
                        date="11.4.2023"
                        changes={[
                            "Added filter by product name to search project products view"
                        ]}
                        features={[]}
                        bugs={[]}
                    />

                    <Release version="3.2.1"
                        date="30.3.2023"
                        changes={[
                            "Added vertical scrollbar to simple search components"
                        ]}
                        features={[]}
                        bugs={[]}
                    />

                    <Release version="3.2.0"
                        date="25.1.2023"
                        changes={[
                            "Notifications: Removed 'Name' field"
                        ]}
                        features={[
                            "Top bar: Added version number"
                        ]}
                        bugs={[
                            "Create activity: Fixed wrong notification body"
                        ]}
                    />

                    {/* < OLD VERSIONS BELOW THIS LINE > */}

                </Grid>
            </Form>
        </Container>
    );

}

export default VersionHistory;
