import { PageTitle } from "Components/Titles";
import { ReadYearlyBudget } from "Features/YearlyBudget";
import { Container } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

function YearlyBudgetDetail () {

    const { t } = useTranslation();

    return (
        <Container className="page">

            <PageTitle title={t("pages.yearlyBudgetDetail")} />
            <ReadYearlyBudget />

        </Container>
    );
}

export default YearlyBudgetDetail;