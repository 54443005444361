import { useState, createContext } from 'react';
import { Loading } from 'Components/Loaders';
import IStateContextProviderProps from './IStateContextProviderProps';
import useStateContext from './useStateContext';
//import useTestApi from 'Features/HTTP/TestApi/useTestApi';

export const StateContext = createContext<any>(null);

const StateContextProvider = ({children, msal}: IStateContextProviderProps) => {

    const [ isLoading, setIsLoading ] = useState(false);
    const profile = useStateContext({ setIsLoading, msal });
    //const { callTestApi } = useTestApi();
    //callTestApi();

    return (
        <StateContext.Provider value={{
            // Add all public properties and method here which should be accessible to all components and pages.
            userProfile: profile
        }}>
            <Loading active={ isLoading } />
            
            {/* Remove this and uncomment the section below when user profile is available! */}
            {children}

        {/*
            {profile && profile.username &&
                <>{children}</>
            }
            {profile && !profile.username &&
                <CustomerAccountNotFound title="Jäsentiliä tai käyttäjää ei löytynyt!" text="Ota yhteys Ekopulloon"/>
            }
        */}
        </StateContext.Provider>
    );

}

export default StateContextProvider;
