import { usePostItem } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import { useTranslation } from 'react-i18next';
import apiConfig from 'Application/configurations/apiConfig';
import IAnnouncement from '../GetAnnouncementById/IAnnouncement';
import { useAccessTokens } from 'Features/Authentication';

const useCreateAnnouncement = () => {

    const { t } = useTranslation();
    const postItem = usePostItem();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

    /** Create new announcement
     * @param {IAnnouncement} Announcement - object which describes the created announcement
     **/
    const createAnnouncement = async (announcement: IAnnouncement) => {
        
        const basePath = apiConfig.basePath;
        const endpoint = apiConfig.endpoints.announcements;
        const url = new URL(`${basePath}/${endpoint}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken,
            body: announcement
        }

        try {

            const response = await postItem(url, options);
         
            return responseHandler(response, "useCreateAnnouncement", { operation: "create", hide: false });

        } catch (error: any) {
            
            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "General.RequestorNotExist") body = t('features.announcements.requestorNotExist');
            if (problemTitle === "Announcement.CreatePermissionDenied") body = t('features.announcements.createPermissionDenied');
            if (problemTitle === "Announcement.EndDateIsBeforeStartDate") body = t('features.announcements.endDateIsBeforeStartDate');
            if (problemTitle === "Announcement.StartDateIsInThePast") body = t('features.announcements.startDateIsInThePast');
            if (problemTitle === "Announcement.AlreadyExist") body = t('features.announcements.alreadyExist');

            return errorHandler(error, "useCreateAnnouncement", { operation: "create", hide: false, messageBody: body });

        }

    }

    return createAnnouncement;

}

export default useCreateAnnouncement;
