import sortBy from '../types/sortBy';

/** UseSortTable provides sorting methods */
const useSortTableData = () => {

    /** Sort table data
     * @param {sortBy} sortBy
     * @param {contentKey} contentKey
     * @param {Array<any>} content
     * @returns sortBy { key: string, asc: boolean, type: string }
     */
    function sortTableData(sortBy: sortBy, contentKey: any, content: Array<any>): sortBy {
        if (content.length > 1) {
            return {
                key: contentKey?.key, 
                asc: sortBy.key === contentKey?.key ? !sortBy.asc : true,
                type: contentKey?.sortType || (contentKey?.type === 'date' && 'date') || 'string'
            }
        }

        return sortBy;
        
    }

    return { sortTableData };

}

export default useSortTableData;