import { useState, useEffect, useContext } from "react";
import useGetUnit from "../ReadUnit/useGetUnit";
import useGetUnitCategories from "../../UnitCategory/ListUnitCategories/useGetUnitCategories";
import useUpdateUnit from "./useUpdateUnit";
import { Form, Segment, Label, Grid } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";
import { ButtonContainer, SaveBtn, CancelBtn } from "Components/Buttons";
import IUpdateUnit from "./IUpdateUnit";
import { useNavigate, useParams } from "react-router-dom";
import { InputWithUnit } from "Components/FormElements";
import { MessageContext } from "Features/Messages";
import useInputValidation from "Components/FormElements/InputWithUnit/useInputValidation";

function UpdateUnit () {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [ isLoading, setIsLoading ] = useState(true);
    const { setMessages, addMessage } = useContext(MessageContext);
    const { isNonNegativeInteger, isNonNegativeFloat } = useInputValidation();
    
    const { updateUnit } = useUpdateUnit();
    const { unit, getUnitById } = useGetUnit();
    const { unitCategories, listUnitCategories } = useGetUnitCategories();
    const [ name, setName ] = useState("");
    const [ category, setCategory ] = useState("");
    const [ width, setWidth ] = useState<number | null>(null);
    const [ height, setHeight ] = useState<number | null>(null);
    const [ depth, setDepth ] = useState<number | null>(null);
    const [ mass, setMass ] = useState<number | null>(null);
    const [ stackAmount, setStackAmount ] = useState<number | null>(null);
    const [ initialPool, setInitialPool ] = useState<number | null>(null);
    const [ isActive, setIsActive ] = useState(true);

    // Fetch unit categories
    useEffect(() => {
        (async () => {
            await listUnitCategories();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // Init unit category options
    const categoryOptions = unitCategories.map((cat:any) => {
        return {
            key: cat.id,
            text: cat.name,
            value: cat.id
        }
    });

    // Fetch unit
    useEffect(() => {
        (async () => {
            await getUnitById(id);
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // set initial values for unit
    useEffect(() => {
        if (unit) {
            setName(unit.name);
            setWidth(unit.width);
            setHeight(unit.height);
            setDepth(unit.depth);
            setMass(unit.mass);
            setStackAmount(unit.stackAmount);
            setInitialPool(unit.initialPool);
            setCategory(unit.category.id);
            setIsActive(unit.isActive);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[unit]);

    const onSubmit = async () => {

        setIsLoading(true);

        setMessages([]);
        if(!isNonNegativeInteger(width) || !isNonNegativeInteger(height) || !isNonNegativeInteger(depth) || !isNonNegativeFloat(mass) || !isNonNegativeInteger(stackAmount) || !isNonNegativeInteger(initialPool)){
            
            addMessage({header: t("features.unit.inputErrorHeader"), type: "error", body: t("features.unit.inputErrorDescription")});
            window.scrollTo(0,0);
        
        } else {

            const unit:IUpdateUnit = {
                "name": name,
                "categoryId": category,
                "width": width,
                "height": height,
                "depth": depth,
                "mass": mass,
                "stackAmount": stackAmount,
                "initialPool": initialPool,
                "isActive": isActive
            
            } 

            let result = await updateUnit(id,unit);

            if (result && result.status === 204) return navigate(-1);
        }
        
        setIsLoading(false);
    }

    return (
        
        <Form loading={isLoading} onSubmit={onSubmit}>
            {unit.name ?
                <>
                <Form.Input
                    label={t("features.unit.name")}
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoComplete="off"
                    required
                />
                <Form.Select
                    label={t("features.unit.category")}
                    options={categoryOptions}
                    value={category}
                    onChange={(e,{value}) => setCategory(String(value))}
                />
                <Segment className="form-segment">
                    <Label>{t("features.unit.measurements")}</Label>
                    <Grid columns={3}>
                        <Grid.Row>
                            <Grid.Column>
                                <InputWithUnit
                                    label={t("features.unit.width")}
                                    id="width"
                                    type="number"
                                    validateFor="nonNegativeInteger"
                                    value={width}
                                    onChangeFunc={setWidth}
                                    unit={t("features.unit.mm")}
                                    required
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <InputWithUnit
                                    label={t("features.unit.height")}
                                    id="height"
                                    type="number"
                                    validateFor="nonNegativeInteger"
                                    value={height}
                                    onChangeFunc={setHeight}
                                    unit={t("features.unit.mm")}
                                    required
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <InputWithUnit
                                    label={t("features.unit.depth")}
                                    id="depth"
                                    type="number"
                                    validateFor="nonNegativeInteger"
                                    value={depth}
                                    onChangeFunc={setDepth}
                                    unit={t("features.unit.mm")}
                                    required
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <InputWithUnit
                    label={t("features.unit.mass")}
                    id="mass"
                    type="number"
                    validateFor="nonNegativeFloat"
                    value={mass}
                    onChangeFunc={setMass}
                    unit={t("features.unit.kg")}
                    required
                />
                <InputWithUnit
                    label={t("features.unit.stackAmount")}
                    id="stackAmount"
                    type="number"
                    validateFor="nonNegativeInteger"
                    value={stackAmount}
                    onChangeFunc={setStackAmount}
                    unit={t("features.unit.pcs")}
                    required
                />
                <InputWithUnit
                    disabled
                    label={t("features.unit.initialPool")}
                    id="initialPool"
                    type="number"
                    validateFor="nonNegativeInteger"
                    value={initialPool}
                    onChangeFunc={setInitialPool}
                    unit={t("features.unit.pcs")}
                    required
                />
                <Form.Checkbox
                    label={t("features.unit.unitIsActive")}
                    id="isActive"
                    onChange={(e,{value}) => setIsActive(!isActive)}
                    checked={isActive}
                />
                <ButtonContainer>
                    <SaveBtn />
                    <CancelBtn />
                </ButtonContainer>
                </>
            :
                <p>{t("features.unit.notFound")}</p>
            }
        </Form>
    );
}

export default UpdateUnit;
