import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';
import ISaveBtn from '../interfaces/ISaveBtnProps';
import './../styles/button.css';

/** Save Button - Submit the form
 * @description Remember to add submit method to the form component when using this button
 * @param {string} title - Button title
 * @param {boolean} hide - hide the button
 * @param {string} [labelPosition] - label position 'left' | 'right'
 * @link https://react.semantic-ui.com/elements/button/
 **/
const SaveBtn: React.FC<ISaveBtn> = (props: ISaveBtn) => {

    const { t } = useTranslation();
    const { title, hidden=false, color, labelPosition, type, ...rest } = props;

    if (hidden) return null;

    return (
        <Button
            icon
            className='extended-button save-button'
            color={color ? color : undefined}
            labelPosition={labelPosition ? labelPosition : 'left'}
            type={type ? type : 'submit'}
            {...rest}
        >
            <Icon name={'save'} />
            {title ? title : t('components.buttons.save')}
        </Button>
    );

};

export default SaveBtn;
