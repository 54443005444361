import { useState } from "react";
import { useGetItems } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import { useTranslation } from 'react-i18next';
import apiConfig from 'Application/configurations/apiConfig';
import IListCompanyUnit from "./IListCompanyUnit";
import { useAccessTokens } from 'Features/Authentication';

function useGetCompanyUnits () {

    const { t } = useTranslation();
    const [ companyUnits, setCompanyUnits ] = useState<IListCompanyUnit[]>([]);
    const getItems = useGetItems();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;
    

   /** List all company units
     * @returns {Array<IListCompanyUnit>} company units */
    const getCompanyUnits = async (companyId:string) => {

        const basePath = apiConfig.basePath;
        const companyEndpoint = apiConfig.endpoints.companies;
        const unitEndpoint = apiConfig.endpoints.units;
        const url = new URL(`${basePath}/${companyEndpoint}/${companyId}/${unitEndpoint}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken
        }
        
        try {

            const response = await getItems(url, options);
            const data = response?.data || [];
            setCompanyUnits(data);
            return responseHandler(response, "useGetCompanyCompanyUnits", { operation: "list", hide: true });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "General.RequestorNotExist") body = t('features.companyUnits.requestorNotExist');
            if (problemTitle === "CompanyUnits.ListPermissionDenied") body = t('features.companyUnits.listPermissionDenied');
            if (problemTitle === "CompanyUnits.CompanyUnitsDataAccessDenied") body = t('features.companyUnits.companyUnitDataAccessDenied');

            return errorHandler(error, "useGetCompanyCompanyUnits", { operation: "list", hide: false, messageBody: body });
   
        }

    }

    return { companyUnits, setCompanyUnits, getCompanyUnits };

}

export default useGetCompanyUnits;