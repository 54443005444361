import { ReadVolume } from "Features/Survey";
import { Container } from "semantic-ui-react";

function ViewVolume () {

    return (
        <Container className="page">

            <ReadVolume />

        </Container>
    );
}

export default ViewVolume;