import { PageTitle } from "Components/Titles";
import { Container } from "semantic-ui-react";
import { CreateSurvey } from "Features/Survey";
import { useTranslation } from "react-i18next";

function NewSurvey () {

    const { t } = useTranslation();

    return (
        <Container className="page">
            <PageTitle title={t("pages.newSurvey")} />
            <CreateSurvey />
        </Container>
    );
}

export default NewSurvey;