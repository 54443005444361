import React, { Fragment } from 'react';
import { Table, Icon } from 'semantic-ui-react';
import IPagedTableHeaderProps from "../interfaces/IPagedTableHeaderProps";
import useSortTableData from '../hooks/useSortTableData';

/** Header
 * @param {array<any>} header - Table header as array of strings
 * @param {array<any>} contentKeys - Table content keys as array of objects
 * @param {boolean} collapsable - table is collapsaple?
*/
const TableHeader: React.FC<IPagedTableHeaderProps> = ({
    header = [],
    contentKeys = [],
    collapsable = true,
    content = [],
    sortBy,
    setSortBy
}: IPagedTableHeaderProps) => {

    const { sortTableData } = useSortTableData();

    return (
        <Table.Header>
            <Table.Row>
                {collapsable &&
                    <Table.HeaderCell />
                }
                { header && header.map((item: any, i: number) =>
                    <Fragment key={i}>
                        {!contentKeys[i]?.hideCollapsed &&
                            <Table.HeaderCell 
                                className='sortable-table'
                                width={contentKeys[i].cellWidth}
                                onClick={() => {
                                    const sortByObj = sortTableData(sortBy, contentKeys[i], content);
                                    setSortBy(sortByObj);
                                }}
                            >
                                {item}
                                {sortBy.key === contentKeys[i].key && sortBy.asc && <Icon name='caret up' />}
                                {sortBy.key === contentKeys[i].key && !sortBy.asc && <Icon name='caret down' />}
                            </Table.HeaderCell>
                        }
                    </Fragment>
                )} 
            </Table.Row>
        </Table.Header>
    );

}

export default TableHeader;
