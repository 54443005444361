import React from 'react';
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import DisabledCSVButton from '../DisabledCSVButton/DisabledCSVButton';
import IGenerateCSVButton from './IGenerateCSVButton';
// import './../styles/csv.css';

/** CSV Button - Generate CSV from array of arrays or array of objects
 * @param {string} title - Button title
 * @param {object} data - object to be converted to CSV
 * @param {string} delimiter - CSV delimiter f.e. ';'
 * @param {string} filename - object to be converted to CSV
 * @link https://www.npmjs.com/package/react-csv
 * @link https://www.npmjs.com/package/json2csv
 **/
const GenerateCSVButton: React.FC<IGenerateCSVButton> = ({title, data, delimiter = ";", filename = "export.csv"}: IGenerateCSVButton) => {
    
    const { t } = useTranslation();

    // Return disabled button if data is not defined
    if (!data || !data.length) return <DisabledCSVButton title={title || t('components.csv.generateCSV')} />

    return (
        <CSVLink 
            className="ui green small icon left labeled button"
            data={data}
            separator={delimiter}
            filename={filename}
        >
            <i aria-hidden="true" className="file outline icon"></i>
            {title || t('components.csv.generateCSV')}
        </CSVLink>
    );

};

export default GenerateCSVButton;
