import { Container } from "semantic-ui-react";
import { UpdateBudget } from "Features/Survey";

function EditBudget () {

    return (
        <Container className="page">
            <UpdateBudget />
        </Container>
    );
}

export default EditBudget;