import { PageTitle } from "Components/Titles";
import { Container } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { ListCompanies } from "Features/Company";
import { ButtonContainer, NewBtn } from "Components/Buttons";

function CompanyList () {

    const { t } = useTranslation();

    return (
        <Container className="page">
            <PageTitle
                title={t("pages.companies")}
                button={
                    <ButtonContainer>
                        <NewBtn to="/management/companies/new" />
                    </ButtonContainer>
                }
            />
            <ListCompanies />
        </Container>
    );
}

export default CompanyList;