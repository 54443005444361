import { usePostItem } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import apiConfig from 'Application/configurations/apiConfig';
import ICreateSurvey from "./ICreateSurvey";
import { useAccessTokens } from 'Features/Authentication';
import { useTranslation } from 'react-i18next';

function useCreateSurvey () {

    const { t } = useTranslation();
    const postItem = usePostItem();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

    /** Create new survey
     * @param {ICreateSurvey} survey - object which describes the created survey
     **/
    const createSurvey = async (company: string, type: string, survey: ICreateSurvey) => {

        const basePath = apiConfig.basePath;
        const companyEndpoint = apiConfig.endpoints.companies;
        const surveyEndpoint = apiConfig.endpoints.surveys;
        const url = new URL(`${basePath}/${companyEndpoint}/${company}/${surveyEndpoint}/${type}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken,
            body: survey
        }

        try {

            const response = await postItem(url, options);
         
            return responseHandler(response, "useCreateSurvey", { operation: "create", messageHeader: t("features.survey.savedSuccessfully"), hide: false });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "General.RequestorNotExist") body = t('features.survey.requestorNotExist');
            if (problemTitle === "Company.NotExist") body = t('features.company.notExist');
            if (problemTitle === "UserAccount.NotExist") body = t('features.account.notExist');
            if (problemTitle === "Company.CompanyDataAccessDenied") body = t('features.company.companyDataAccessDenied');
            
            if (problemTitle === "GlassBottleReturnRateSurvey.AlreadyExist" || 
                problemTitle === "TransferFreightCostSurvey.AlreadyExist" ||
                problemTitle === "BudgetSurvey.AlreadyExist" ||
                problemTitle === "VolumeSurvey.AlreadyExist" ||
                problemTitle === "InventorySurvey.AlreadyExist" ||
                problemTitle === "StorageCostSurvey.AlreadyExist" ||
                problemTitle === "DistributionFreightSurvey.AlreadyExist" ||
                problemTitle === "ClearingSurvey.AlreadyExist") {
                    body = t('features.survey.alreadyExist');
            }

            if (problemTitle === "GlassBottleReturnRateSurvey.CreatePermissionDenied" || 
                problemTitle === "TransferFreightCostSurvey.CreatePermissionDenied" ||
                problemTitle === "BudgetSurvey.CreatePermissionDenied" ||
                problemTitle === "VolumeSurvey.CreatePermissionDenied" ||
                problemTitle === "InventorySurvey.CreatePermissionDenied" ||
                problemTitle === "StorageCostSurvey.CreatePermissionDenied" ||
                problemTitle === "DistributionFreightSurvey.CreatePermissionDenied" ||
                problemTitle === "ClearingSurvey.CreatePermissionDenied") {
                    body = t('features.survey.createPermissionDenied');
            }


            return errorHandler(error, "useCreateCompany", { operation: "create", hide: false, messageBody: body });
   
        }

    }

    return { createSurvey }

}

export default useCreateSurvey;
