import { PageTitle } from "Components/Titles";
import { Container } from "semantic-ui-react";
import { UpdateYearlyBudget } from "Features/YearlyBudget";
import { useTranslation } from "react-i18next";

function EditYearlyBudget () {

    const { t } = useTranslation();

    return (
        <Container className="page">
            <PageTitle title={t("pages.editYearlyBudget")} />
            <UpdateYearlyBudget />
        </Container>
    );
}

export default EditYearlyBudget;