import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Form, FormField, Message } from 'semantic-ui-react';
import { PageTitle } from 'Components/Titles';
import DatePicker from "react-datepicker";
import { ButtonContainer, CancelBtn, SaveBtn } from 'Components/Buttons';
import "react-datepicker/dist/react-datepicker.css";

const Forms = () => {

    const { t } = useTranslation();

    const [ isLoading, setIsLoading ] = useState(false);
    const [ firstName, setFirstName ] = useState("");
    const [ age, setAge ] = useState("30");
    const [ email, setEmail ] = useState("");
    const [ emailError, setEmailError ] = useState("");
    const [ telephone, setTelephone ] = useState("");
    const [ telephoneError, setTelephoneError ] = useState("");
    const [ url, setUrl ] = useState("");
    const [ choice, setChoice ] = useState("");
    const [ startDate, setStartDate ] = useState(new Date());
    
    const onSubmit = () => {
        alert("Do something with the submitted form!");
    }

    const options = [
        { key: 'm', text: 'Male', value: 'male' },
        { key: 'f', text: 'Female', value: 'female' },
        { key: 'o', text: 'Cow', value: 'cow' },
    ]

    useEffect(() => {
        setIsLoading(false);
    },[]);

    return (
        <Container className="page">

            <PageTitle title={t('pages.forms')}/>

            <Form loading={isLoading} onSubmit={onSubmit}>

                {/* Different inputs */}
                <Form.Group widths='equal'>
                    <Form.Input
                        required
                        fluid
                        label='Text (First name)'
                        value={firstName}
                        onChange={(e, { value }) => setFirstName(value) }
                        placeholder='Enter text'
                    />
                    <Form.Input
                        required
                        fluid
                        label='Number (Age)'
                        value={age}
                        onChange={(e, { value }) => setAge(value) }
                        placeholder='Enter number'
                        type='number'
                    />
                    <Form.Input
                        required
                        fluid
                        label='Email'
                        value={email}
                        onChange={(e, { value }) => {
                            setEmail(value);
                            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                            setEmailError(emailRegex.test(value) ? "" : 'Please enter a valid email address');
                        } }
                        placeholder='Enter email'
                        type='email'
                        error={emailError ? emailError : null}
                    />
                </Form.Group>

                {/* Different inputs */}
                <Form.Group widths='equal'>
                    <Form.Input
                        required
                        fluid
                        label='Range'
                        value={age}
                        onChange={(e, { value }) => setAge(value) }
                        type='range'
                        min={20}
                        max={65}
                    />
                    <Form.Input
                        required
                        fluid
                        label='Telephone number'
                        value={telephone}
                        onChange={(e, { value }) => {
                            setTelephone(value);
                            const phoneRegex = /^\d{10}$/; // Assuming a simple 10-digit phone number
                            setTelephoneError(phoneRegex.test(value) ? "" : 'Please enter a valid telephone number');
                        } }

                        placeholder='Enter tel. 123-456-7890'
                        type='tel'
                        error={telephoneError ? telephoneError : null}
                    />
                    <Form.Input
                        required
                        fluid
                        label='url'
                        value={url}
                        onChange={(e, { value }) => setUrl(value)}
                        placeholder='enter url'
                        type='url'
                    />
                </Form.Group>

                {/* Date and time pickers */}
                <Form.Group widths='equal'>
                    <FormField
                        required
                        label='Date'
                        control={DatePicker}
                        selected={startDate}
                        onChange={(date: Date) => setStartDate(date)}
                    />
                    <FormField
                        label='Date and time'
                        control={DatePicker}
                        selected={startDate}
                        onChange={(date: Date) => setStartDate(date)}
                        showTimeSelect
                        dateFormat="Pp"
                    />
                </Form.Group>

                {/* Text area and dropdown*/}
                <Form.Group widths='equal'>
                    <Form.Select label="Select gender" options={options} placeholder='Gender' />
                    <Form.TextArea
                        label='About'
                        placeholder='Tell us more about you...'
                    />
                </Form.Group>

                {/* Dropdown, Disabled & Readonly */}    
                <Form.Group widths='equal'>
                    <Form.Input fluid label='Disabled field' placeholder='Disabled' disabled />
                    <Form.Input fluid label='Read only field' placeholder='Read only' value="4000€" readOnly />
                </Form.Group>

                {/* Checkboxes */}
                <Form.Field label="Checkboxes" />
                <Form.Checkbox
                    required
                    label='I agree to the Terms and Conditions'
                />
                <Form.Checkbox
                    required
                    label='I agree to the other Conditions'
                />

                {/* Radios */}
                <Form.Field label="Radios" />
                <Form.Radio
                    label='Small'
                    value='sm'
                    checked={choice === 'sm'}
                    onChange={(e, { value }: any) => setChoice(value)}
                />
                <Form.Radio
                    label='Medium'
                    value='md'
                    checked={choice === 'md'}
                    onChange={(e, { value }: any) => setChoice(value)}
                />
                <Form.Radio
                    label='Large'
                    value='lg'
                    checked={choice === 'lg'}
                    onChange={(e, { value }: any) => setChoice(value)}
                />

                <Form.Field label="MESSAGES" />
                <Message
                    visible
                    success
                    header='Form Completed'
                    content="You're all signed up for the newsletter"
                />
                <Message
                    visible
                    error
                    header='Action Forbidden'
                    content='You can only sign up for an account once with a given e-mail address.'
                />
                <Message
                    visible
                    warning
                    header='Could you check something!'
                    list={[
                        'That e-mail has been subscribed, but you have not yet clicked the verification link in your e-mail.',
                    ]}
                />

                {/* Submit form */}
                <ButtonContainer>
                    <CancelBtn />
                    <SaveBtn />
                </ButtonContainer>

            </Form>

        </Container>
    )

}


export default Forms;
