import { PageTitle } from "Components/Titles";
import { Container } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { ListYearlyBudgets } from "Features/YearlyBudget";
import { ButtonContainer, NewBtn } from "Components/Buttons";

function YearlyBudgetList () {

    const { t } = useTranslation();

    return (
        <Container className="page">
            <PageTitle
                title={t("pages.yearlyBudgets")}
                button={
                    <ButtonContainer>
                        <NewBtn to={"/management/yearlybudgets/new"} />
                    </ButtonContainer>
                }
            />
            <ListYearlyBudgets />
        </Container>
    );
}

export default YearlyBudgetList;