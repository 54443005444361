import { useEffect, useState } from 'react';
import useGetCompany from 'Features/Company/ReadCompany/useGetCompany';
import useGetCompanySurveys from "Features/CompanySurvey/ListCompanySurveys/useGetCompanySurveys";
import useGetSurveyTypes from "Features/SurveyType/ListSurveyTypes/useGetSurveyTypes";
import { useTranslation } from "react-i18next";
import { Segment, Header } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { ButtonContainer, EditBtn } from 'Components/Buttons';
import IListCompanySurvey from "../ListCompanySurveys/IListCompanySurvey";
import { PagedTable } from 'Components/Tables';

function ListCompanySurveys () {

    const { t } = useTranslation();
    const { companyId } = useParams();
    const { company, getCompanyById } = useGetCompany();
    const { companySurveys, getCompanySurveys } = useGetCompanySurveys();
    const { surveyTypes, listSurveyTypes } = useGetSurveyTypes();
    const [ surveys, setSurveys ] = useState<IListCompanySurvey[]>([]);
    const [ isLoading, setIsLoading ] = useState(true);
    
    // Fetch company, company surveys and survey types
    useEffect(() => {
        (async () => {
            await listSurveyTypes();
            if (companyId) {
                await getCompanyById(companyId);
                await getCompanySurveys(companyId);
                setIsLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // set initial values for company
    useEffect(() => {
        const surveys:any[] = [...surveyTypes];
        surveys.map(survey => {
            survey.uiName = t("features.surveyType." + survey.name);
            if(companySurveys.find(s => s.surveyTypeId === survey.id)){
                survey.isCompanySurvey = true;
            } else {
                survey.isCompanySurvey = false;
            }
            return survey;
        })
        setSurveys(surveys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[companySurveys,surveyTypes]);

    return (
        <Segment className="invisible" loading={isLoading}>
            {companyId &&
                <>
                <Header sub>{t("features.companySurvey.company")}</Header>
                <div>{company && company.name}</div>
                </>
            }
            
            <PagedTable
                header={[
                    t("features.companySurvey.survey"),
                    t("features.companySurvey.isCompanySurvey"),
                ]}
            
                content={surveys}
            
                contentKeys={[
                    {key: "uiName"},
                    {key: "isCompanySurvey", type: "boolean"}
                ]}
            
                pageSize={50}
                resetPageOnContentChange={true}
                collapsable={false}
            />

            <ButtonContainer><EditBtn to={"/management/companies/" + companyId + "/surveytypes/edit"} /></ButtonContainer>

        </Segment>
    );
}

export default ListCompanySurveys;