import { useState } from 'react';
import { useGetItem } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import { useTranslation } from 'react-i18next';
import apiConfig from 'Application/configurations/apiConfig';
import IReadStorageCost from './IReadStorageCost';
import { useAccessTokens } from 'Features/Authentication';

const useGetStorageCost = () => {

    const { t } = useTranslation();
    const getItem = useGetItem();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const [ storageCost, setStorageCost ] = useState<IReadStorageCost>({id: "", companyId: "", name: "", storageCost: null, handlingCost: null, isPermanentlyLocked: false, isAnswered: false, openDate: new Date(), closeDate: new Date()});
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

    /** Read glass bottle return rate by guid
     * @param {string} companyId - guid of the company
     * @param {string} surveyId - guid of the survey
     * @returns {object} storageCost - storageCost object
     **/
    const getStorageCostById = async (companyId: any, surveyId: any) => {

        const basePath = apiConfig.basePath;
        const companyEndpoint = apiConfig.endpoints.companies
        const surveyEndpoint = apiConfig.endpoints.storageCosts;
        const url = new URL(`${basePath}/${companyEndpoint}/${companyId}/${surveyEndpoint}/${surveyId}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken
        }

        try {

            const response = await getItem(url, options);
            const data = response?.data || [];
            setStorageCost(data);
            return responseHandler(response, "useGetStorageCost", { operation: "read", hide: true });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "StorageCost.ReadPermissionDenied") body = t('features.survey.readPermissionDenied');
            if (problemTitle === "Company.CompanyDataAccessDenied") body = t('features.company.companyDataAccessDenied');
            if (problemTitle === "Company.NotExist") body = t('features.company.notExist');
            if (problemTitle === "UserAccount.NotExist") body = t('features.account.userAccountNotExist');
            if (problemTitle === "General.RequestorNotExist") body = t('features.survey.requestorNotExist');
            if (problemTitle === "StorageCost.NotExist") body = t('features.survey.notExist');

            return errorHandler(error, "useGetStorageCost", { operation: "read", hide: false, messageBody: body });

        }

    }

    return { storageCost, setStorageCost, getStorageCostById };

}

export default useGetStorageCost;
