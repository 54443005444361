import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from 'semantic-ui-react';
import IPageTitleProps from './IPageTitleProps';
import './pageTitle.css';

/** Page Title
 * @param {string} title - Page title (left side)
 * @param {boolean} active - hr color is changed when active
 * @param {object} button - Button component (right side) */
const PageTitle: React.FC<IPageTitleProps> = ({ title, active, button }: IPageTitleProps) => {

    const { t } = useTranslation();

    return (
        <div className="page-title">
            
                {button ?
                    <Grid columns="2">                    
                        <Grid.Column width={12} textAlign="left"><h1>{title || t('components.titles.pageTitle')}</h1></Grid.Column>
                        <Grid.Column width={4} textAlign="right">{button}</Grid.Column>
                    </Grid>
                :
                    <h1>{title || t('components.titles.pageTitle')}</h1>
                }
            {/*<hr className={active ? "blue" : ""} />*/}
        </div>
    );

}

export default PageTitle;
