import IAnnouncement from '../GetAnnouncementById/IAnnouncement';
import { compareAsc } from 'date-fns';

const usePortalAnnouncement = () => {

    /** Filter future items and hidden items */
    const filterAnnouncements = (announcements: Array<IAnnouncement>) => {

        /* If Start date is in the future, filter it away from the list */
        let filtered = announcements.filter((item: IAnnouncement) => {
            return compareAsc(new Date(item.startDate), new Date()) !== 1;
        });

        /* Show only visibler announcements */
        const visibleAnnouncements = filtered.map((item: IAnnouncement) => {
            if (!item.visible) item.visible = true;
            return item;
        });

        return visibleAnnouncements;

    }

    /** filter item if use hide it */
    const hideAnnouncement = (id: number, announcements: Array<IAnnouncement>) => {
        let filtered = announcements.filter((item: any) => item.id !== id);
        return filtered;
    }


    return { filterAnnouncements, hideAnnouncement };

}

export default usePortalAnnouncement;
