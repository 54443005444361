import { Grid, Button, Input, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import ICSVImport from "./ICSVImport";

const CSVImport = ({file, setFile, setConfirmModalOpen, templateFileName}:ICSVImport) => {

    const { t } =useTranslation();
    return (

        <Grid>
            <Grid.Column mobile={12} tablet={10} computer={10}>
                <div className="field" style={{marginBottom: "5px"}}>
                    <label htmlFor="csvFileInput">{t("components.csv.uploadCsvFile")}</label>
                </div>
                <div style={{float: "left", marginRight: "20px", marginBottom: "10px"}}>
                    <Input
                        type={"file"}
                        id={"csvFileInput"}
                        accept={".csv"}
                        onChange={(e:any) => setFile(e.target.files[0])}
                    />
                </div>
                <div style={{float: "left", marginBottom: "30px"}}>
                    <Button
                        icon
                        className='extended-button view-button'
                        color={undefined}
                        labelPosition="left"
                        type="reset"
                        onClick={(e) => {
                            setConfirmModalOpen(true);
                        }}
                        disabled={file ? false : true}
                    >
                        <Icon name={'arrow right'} />
                        {t("components.csv.fillInForm")}
                    </Button>
                </div>
            </Grid.Column>
            <Grid.Column  mobile={12} tablet={6} computer={6}>
                <div className="fieldlabel" style={{marginBottom: "5px"}}>{t("components.csv.uploadTemplate")}</div>
                <div><a href={process.env.PUBLIC_URL + "/csv/" + templateFileName} download={templateFileName}>{templateFileName}</a></div>
            </Grid.Column>
        </Grid>
    );
}

export default CSVImport;