import { useEffect, useState } from 'react';
import useGetCompany from "Features/Company/ReadCompany/useGetCompany";
import { PagedTable } from "Components/Tables";
import { useTranslation } from "react-i18next";
import { Segment, Form, Header } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';

function ListAccounts () {

    const { t } = useTranslation();
    const { companyId } = useParams();
    const { company, getCompanyById } = useGetCompany();
    const [ isLoading, setIsLoading ] = useState(true);
    const [ onlyActive, setOnlyActive ] = useState(true);
    
    // Fetch company
    useEffect(() => {
        (async () => {
            if (companyId) {
                await getCompanyById(companyId);
                setIsLoading(false);
            }           
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <Segment className="invisible" loading={isLoading}>
            {companyId &&
                <Segment className="invisible">
                <Header sub>{t("features.companyUnit.company")}</Header>
                <div>{company && company.name}</div>
                </Segment>
            }
            <Form>
                <Form.Checkbox
                    label={t("features.account.showOnlyActiveAccounts")}
                    id="showOnlyActive"
                    onChange={(e,{value}) => setOnlyActive(!onlyActive)}
                    checked={onlyActive}
                />
            </Form>
            <PagedTable
                header={[
                    t("features.account.name"),
                    t("features.account.active")
                ]}

                content={onlyActive ? company.userAccounts.filter(item => item.isActive) : company.userAccounts}

                contentKeys={[
                    {key: "name", type: "link",  target: "/management/companies/" + companyId + "/accounts/", targetKey: "id"},
                    {key: "isActive", type: "boolean"}
                ]}

                pageSize={50}
                resetPageOnContentChange={true}
                collapsable={false}
            />
        </Segment>
    );
}

export default ListAccounts;