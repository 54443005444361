import { useState, useContext } from "react";
import useCreateSurvey from "./useCreateSurvey";
import { Form, Grid, Segment, Label, FormSelect } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";
import { ButtonContainer, SaveBtn, CancelBtn } from "Components/Buttons";
import ICreateSurvey from "./ICreateSurvey";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import fi from "date-fns/locale/fi";
import { MessageContext } from "Features/Messages";
import { StateContext } from "Application/state/StateContext";

function CreateSurvey () {

    const { t } = useTranslation();
    registerLocale("fi", fi);

    const navigate = useNavigate();
    const { companyId } = useParams();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ type, setType ] = useState("");
    const [ periodYear, setPeriodYear ] = useState<number | null>(null);
    const [ periodMonth, setPeriodMonth ] = useState<number | null>(null);
    const [ openDate, setOpenDate ] = useState<Date | null>(null);
    const [ closeDate, setCloseDate ] = useState<Date | null>(null);
    const { addMessage } = useContext(MessageContext);
    const { createSurvey } = useCreateSurvey();
    const { userProfile } = useContext(StateContext);

    const surveyTypeOptions = [
        { key: "glassbottlereturnrate", text: t("features.survey.glassBottleReturnRateSurvey"), value: "glassbottlereturnrates" },
        { key: "transferfreightcost", text: t("features.survey.transferFreightCostSurvey"), value: "transferfreightcosts" },
        { key: "budget", text: t("features.survey.budgetSurvey"), value: "budgets" },
        { key: "volume", text: t("features.survey.volumeSurvey"), value: "volumes" },
        { key: "distributionfreight", text: t("features.survey.distributionFreightSurvey"), value: "distributionfreights" },
        { key: "storagecost", text: t("features.survey.storagecostSurvey"), value: "storagecosts" },
        { key: "inventory", text: t("features.survey.inventorySurvey"), value: "inventories" },
        { key: "clearing", text: t("features.survey.clearingSurvey"), value: "clearings" }
    ]

    let monthOptions = [];
    for(let i = 1; i<13; i++){
        monthOptions.push({key: i, text: String(i), value: i});
    }

    let yearOptions = [];
    for(let i = new Date().getFullYear()-3; i<new Date().getFullYear()+3; i++){
        yearOptions.push({key: i, text: String(i), value: i});
    }

    const onSubmit = async () => {

        setIsLoading(true);

        if(!type || !periodYear || (type === "inventories" && !periodMonth) || !openDate || !closeDate){
            addMessage({header: t("features.survey.inputErrorHeader"), type: "error", body: t("features.survey.inputMissingDescription")});
            window.scrollTo(0,0);
        } else {
            let startDate = openDate || new Date();
            startDate = new Date(startDate.getFullYear(),startDate.getMonth(),startDate.getDate());

            let endDate = closeDate || new Date();
            endDate = new Date(endDate.getFullYear(),endDate.getMonth(),endDate.getDate()+1);

            const newSurvey:ICreateSurvey = {
                "period": periodYear,
                "periodYear": periodYear,
                "periodMonth": periodMonth,
                "openDate": startDate.toISOString(),
                "closeDate": endDate.toISOString()
            } 

            let result = await createSurvey(companyId || userProfile.companyId,type,newSurvey);

            if (result && result.status === 201) return navigate(-1);
        }
    
        setIsLoading(false);
    }

    
    return (

        <Form loading={isLoading} onSubmit={onSubmit}>

            <FormSelect
                label={t("features.survey.type")}
                selection
                value={type}
                options={surveyTypeOptions}
                onChange={(ev, {value}) => { setType(String(value)); }}
                required
            />

            <Segment className="form-segment">
                <Label>{t("features.survey.surveyPeriod")}</Label>
                <Grid>
                    {type === "inventories" &&
                        <Grid.Column mobile={16} tablet={8} computer={4}>
                            <FormSelect
                                label={t("features.survey.periodMonth")}
                                id="periodMonth"
                                selection
                                value={periodMonth || ""}
                                options={monthOptions}
                                onChange={(ev, {value}) => { setPeriodMonth(parseInt(String(value))); }}
                                required
                            />
                        </Grid.Column>
                    }
                    <Grid.Column mobile={16} tablet={8} computer={4}>
                        <FormSelect
                            label={t("features.survey.periodYear")}
                            id="periodYear"
                            selection
                            value={periodYear || ""}
                            options={yearOptions}
                            onChange={(ev, {value}) => { setPeriodYear(parseInt(String(value))); }}
                            required
                        />
                    </Grid.Column>
                </Grid>
            </Segment>

            <Segment className="form-segment">
                <Label>{t("features.survey.answerPeriod")}</Label>
                <Grid style={{marginBottom: "20px"}}>
                    <Grid.Column mobile={16} tablet={8} computer={4}>
                        <Form.Field required>
                            <label>{t('features.survey.openDate')}</label>
                        </Form.Field>
                        <DatePicker
                            id="openDate"
                            required
                            selected={openDate || new Date()}
                            onChange={(date: Date) => setOpenDate(date)}
                            locale='fi'
                            inline
                        />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={4}>
                        <Form.Field required>
                            <label>{t('features.survey.closeDate')}</label>
                        </Form.Field>
                        <DatePicker
                            id="closeDate"
                            required
                            selected={closeDate}
                            onChange={(date: Date) => setCloseDate(date)}
                            locale='fi'
                            inline
                        />
                    </Grid.Column>
                </Grid>
            </Segment>

            <ButtonContainer>
                <SaveBtn />
                <CancelBtn />
            </ButtonContainer>
        </Form>
    );
}

export default CreateSurvey;
