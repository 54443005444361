import { usePutItem } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import apiConfig from 'Application/configurations/apiConfig';
import IUpdateUnitPoolCorrection from "./IUpdateUnitPoolCorrection";
import { useAccessTokens } from 'Features/Authentication';
import { useTranslation } from 'react-i18next';

function useUpdateUnitPoolCorrection () {

    const { t } = useTranslation();
    const putItem = usePutItem();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

    /** Edit unit pool Correction
     * @param {IUpdateUnitPoolCorrection} unitPoolCorrection - object which describes the modified unit pool correction
     **/
    const updateUnitPoolCorrection = async (unit: string, id: string="", unitPoolCorrection: IUpdateUnitPoolCorrection) => {

        const basePath = apiConfig.basePath;
        const unitEndpoint = apiConfig.endpoints.units;
        const unitPoolCorrectionEndpoint = apiConfig.endpoints.unitpoolcorrections;
        const url = new URL(`${basePath}/${unitEndpoint}/${unit}/${unitPoolCorrectionEndpoint}/${id}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken,
            body: unitPoolCorrection
        }

        try {

            const response = await putItem(url, options);
         
            return responseHandler(response, "useUpdateUnitPoolCorrection", { operation: "update", messageHeader: t("features.unitPoolCorrection.savedSuccessfully"), hide: false });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "General.RequestorNotExist") body = t('features.unitPoolCorrection.requestorNotExist');
            if (problemTitle === "UnitPoolCorrectionErrors.NotExist") body = t('features.unitPoolCorrection.notExist');
            if (problemTitle === "UnitPoolCorrectionErrors.UpdatePermissionDenied") body = t('features.unitPoolCorrection.updatePermissionDenied');

            return errorHandler(error, "useUpdateUnitPoolCorrection", { operation: "update", hide: false, messageBody: body });

        }

    }

    return { updateUnitPoolCorrection }

}

export default useUpdateUnitPoolCorrection;
