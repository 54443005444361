import { useEffect, useState } from 'react';
import useGetAllCompanies from "./useGetCompanies";
import { PagedTable } from "Components/Tables";
import { useTranslation } from "react-i18next";
import { Segment, Form } from 'semantic-ui-react';

function ListCompanies () {

    const { t } = useTranslation();
    const { companies, listCompanies } = useGetAllCompanies();
    const [ isLoading, setIsLoading ] = useState(true);
    const [ onlyActive, setOnlyActive ] = useState(true);

    // Fetch companies
    useEffect(() => {
        (async () => {
            await listCompanies();
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <Segment className="invisible" loading={isLoading}>
            <Form>
                <Form.Checkbox
                    label={t("features.company.showOnlyActiveCompanies")}
                    id="showOnlyActive"
                    onChange={(e,{value}) => setOnlyActive(!onlyActive)}
                    checked={onlyActive}
                />
            </Form>
            <PagedTable
                header={[
                    t("features.company.name"),
                    t("features.company.businessIdentityCode"),
                    t("features.company.active")
                ]}
            
                content={onlyActive ? companies.filter(item => item.isActive) : companies}
            
                contentKeys={[
                    {key: "name", type: "link",  target: "/management/companies/", targetKey: "id"},
                    {key: "businessIdentityCode"},
                    {key: "isActive", type: "boolean"}
                ]}
            
                pageSize={50}
                resetPageOnContentChange={true}
                collapsable={false}
            />
        </Segment>
    );
}

export default ListCompanies;