import { usePutItem } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import apiConfig from 'Application/configurations/apiConfig';
import IUpdateBudget from "./interfaces/IUpdateBudget";
import ILockBudget from './interfaces/ILockBudget';
import { useAccessTokens } from 'Features/Authentication';
import { useTranslation } from 'react-i18next';
import IOpenBudget from './interfaces/IOpenBudget';

function useUpdateBudget () {

    const { t } = useTranslation();
    const putItem = usePutItem();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

    /** Create new budget survey
     * @param {string} companyId - guid of the company
     * @param {string} surveyId - guid of the survey
     * @param {IUpdateBudget} surveyData - object which describes the survey
     **/
    const updateBudget = async (companyId: string = "", surveyId: string="", surveyData: IUpdateBudget | ILockBudget | IOpenBudget) => {

        const basePath = apiConfig.basePath;
        const companyEndpoint = apiConfig.endpoints.companies
        const surveyEndpoint = apiConfig.endpoints.budgets;
        const url = new URL(`${basePath}/${companyEndpoint}/${companyId}/${surveyEndpoint}/${surveyId}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken,
            body: surveyData
        }

        try {

            const response = await putItem(url, options);
         
            return responseHandler(response, "useUpdateBudget", { operation: "update", messageHeader: t("features.budget.savedSuccessfully"), hide: false });


        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "Budget.UpdatePermissionDenied") body = t('features.survey.updatePermissionDenied');
            if (problemTitle === "Company.CompanyDataAccessDenied") body = t('features.company.companyDataAccessDenied');
            if (problemTitle === "Company.NotExist") body = t('features.company.notExist');
            if (problemTitle === "UserAccount.NotExist") body = t('features.account.userAccountNotExist');
            if (problemTitle === "General.RequestorNotExist") body = t('features.survey.requestorNotExist');
            if (problemTitle === "Budget.NotExist") body = t('features.survey.notExist');
            if (problemTitle === "Budget.PermanentlyLocked") body = t('features.survey.permanentlyLocked');
            if (problemTitle === "Budget.Closed") body = t('features.survey.closed');

            return errorHandler(error, "useUpdateBudget", { operation: "update", hide: false, messageBody: body });

        }

    }

    return { updateBudget }

}

export default useUpdateBudget;
