import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useGetUnit from "./useGetUnit";
import { useParams } from 'react-router-dom';
import { Header, Segment } from 'semantic-ui-react';
import { ButtonContainer, EditBtn } from 'Components/Buttons';
import { SettingsContainer, SettingsBox } from "Components/Settings";


function ReadUnit () {

    const { t } = useTranslation();
    const { id } = useParams();
    const { unit, getUnitById } = useGetUnit();
    const [ isLoading, setIsLoading ] = useState(true);

    // Fetch unit
    useEffect(() => {
        (async () => {
            await getUnitById(id);
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <Segment className="invisible" loading={isLoading}>
            {unit.name ?
                <>
                <Segment loading={isLoading}>
                    <Header sub>{t("features.unit.name")}</Header>
                    <div>{unit && unit.name}</div>

                    <Header sub>{t("features.unit.category")}</Header>
                    <div>{unit && unit.category.name}</div>

                    <Header sub>{t("features.unit.width")}</Header>
                    <div>{unit && unit.width} {t("features.unit.mm")}</div>

                    <Header sub>{t("features.unit.height")}</Header>
                    <div>{unit && unit.height} {t("features.unit.mm")}</div>

                    <Header sub>{t("features.unit.depth")}</Header>
                    <div>{unit && unit.depth} {t("features.unit.mm")}</div>

                    <Header sub>{t("features.unit.mass")}</Header>
                    <div>{unit && unit.mass} {t("features.unit.kg")}</div>

                    <Header sub>{t("features.unit.stackAmount")}</Header>
                    <div>{unit && unit.stackAmount} {t("features.unit.pcs")}</div>

                    <Header sub>{t("features.unit.initialPool")}</Header>
                    <div>{unit && unit.initialPool} {t("features.unit.pcs")}</div>

                    <Header sub>{unit.isActive ? t("features.unit.unitIsActive") : t("features.unit.unitIsNotActive")}</Header>

                    <ButtonContainer><EditBtn to={"/management/units/" + id + "/edit"} /></ButtonContainer>
                </Segment>
                
                <SettingsContainer>

                    {/* Accounts */}
                    <SettingsBox title={t("features.unit.unitPoolCorrections")}
                        description={t("features.unit.manageUnitPoolCorrections")}
                        icon="user"
                        items={[
                            { to: "/management/units/" + id + "/unitpoolcorrections", title: t('features.unit.unitPoolCorrections') }
                        ]}
                    />
                </SettingsContainer>
            </>
        :
            <p>{t("features.unit.notFound")}</p>
        }

        </Segment>
    );

}

export default ReadUnit;
