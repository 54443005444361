import { ErrorInfo } from 'react';
import { ICustomProperties } from '@microsoft/applicationinsights-web';
import { LogLevel } from "@azure/msal-browser";
import IUseLoggerProps from './interfaces/IUseLoggerProps';
import IUseLogger from './interfaces/IUseLogger'

/** Used to log events */
const useLogger = ({appInsights}: IUseLoggerProps): IUseLogger => {
          
    /** Log MSAL Authentication events 
     * @param {LogLevel} level - 
     * @param {string} message - 
     * @param {boolean} containsPii - 
     **/
    const logMSAL = (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
            return;
        }
        switch (level) {
            case LogLevel.Error:
                //console.log(message);
                appInsights.trackEvent({ name: 'MsalAuthenticationException', properties: { type: "Error", message: message } });
                return;
            case LogLevel.Info:
                //console.log(message);
                //appInsights.trackEvent({ name: 'MsalAuthenticationInfo', properties: { type: "Info", message: message } });
                return;
            case LogLevel.Verbose:
                //console.log(message);
                //appInsights.trackEvent({ name: 'MsalAuthenticationVerbose', properties: { type: "Verbose", message: message } });
                return;
            case LogLevel.Warning:
                //console.log(message);
                //appInsights.trackEvent({ name: 'MsalAuthenticationWarning', properties: { type: "Warning", message: message } });
                return;
            default:
                return;
        }
    }

    /** Log react-error-boundary event
     * @param {Error} error - error object
     * @param {ErrorInfo} info.componentStack - A string that represents the component stack trace at the time the error was thrown.
     **/
    const logErrorBoundary = (error: Error, info: ErrorInfo) => {

        const properties = {
            type: "Error",
            error: error,
            name: error.name || "",
            message: error.message || "",
            stack: error.stack || "",
            componentStack: info.componentStack || ""
        }

        appInsights.trackEvent({ name: 'ErrorBoundaryException', properties: properties });

    }

    /** Track exception */
    const logException = (error: Error, properties?: ICustomProperties) => {
        appInsights.trackException({ exception: error, properties: properties })
    }

    /** Track event */
    const trackEvent = (name: string, properties?: ICustomProperties) => {
        //appInsights.trackEvent({ name: name, properties: properties })
    }

    /** Track trace */
    const trackTrace = (message: string, properties?: ICustomProperties) => {
        //appInsights.trackTrace({ message: message, properties: properties })
    }

    /** Track track metric */
    const trackMetric = (name: string, average: number, properties?: ICustomProperties) => {
        //appInsights.trackMetric({ name: name, average: average, properties: properties })
    }

    /** Track page view */
    const trackPageView = (name: string, properties?: ICustomProperties) => {
        //appInsights.trackPageView({ name: name, properties: properties })
    }

    const logger = { 
        logMSAL,
        logErrorBoundary,
        logException,
        trackEvent,
        trackTrace,
        trackMetric,
        trackPageView
    };

    return logger;

}

export default useLogger;
