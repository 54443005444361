import { useState } from 'react';
import { useGetItem } from 'Features/HTTP/RequestHandlers';
import { useResponseHandler, useErrorHandler } from 'Features/HTTP/ResponseHandler';
import { useTranslation } from 'react-i18next';
import apiConfig from 'Application/configurations/apiConfig';
import IReadUnitPoolCorrection from './IReadUnitPoolCorrection';
import { useAccessTokens } from 'Features/Authentication';

const useGetUnitPoolCorrection = () => {

    const { t } = useTranslation();
    const getItem = useGetItem();
    const responseHandler = useResponseHandler();
    const errorHandler = useErrorHandler();
    const [ unitPoolCorrection, setUnitPoolCorrection ] = useState<IReadUnitPoolCorrection>({id: "", date: new Date(), amount: 0, description: "", unitId: "", unitName: ""});
    const getToken = useAccessTokens();
    const AccessAsUser = apiConfig.scopes.AccessAsUser;

    /** Read unit pool correction by guid
     * @param {string} id - guid of the unit pool correction
     * @returns {object} unitPoolCorrection - unit pool correction object
     **/
    const getUnitPoolCorrectionById = async (unit: string, id: string) => {

        //if (!id) throw Error("Id is mandatory when fetching unit pool correction by id");

        const basePath = apiConfig.basePath;
        const unitEndpoint = apiConfig.endpoints.units;
        const unitPoolCorrectionEndpoint = apiConfig.endpoints.unitpoolcorrections;
        const url = new URL(`${basePath}/${unitEndpoint}/${unit}/${unitPoolCorrectionEndpoint}/${id}`);
        const accessToken = await getToken([AccessAsUser]);

        const options = {
            accessToken: accessToken
        }

        try {

            const response = await getItem(url, options);
            const data = response?.data || [];
            setUnitPoolCorrection(data);
            return responseHandler(response, "useGetUnitPoolCorrection", { operation: "read", hide: true });

        } catch (error: any) {

            let problemTitle = error && error.data && error.data.title ? error.data.title : "";
            let body = null;

            if (problemTitle === "General.RequestorNotExist") body = t('features.unitPoolCorrection.requestorNotExist');
            if (problemTitle === "UnitPoolCorrectionErrors.ReadPermissionDenied") body = t('features.unitPoolCorrection.readPermissionDenied');
            if (problemTitle === "UnitPoolCorrectionErrors.NotExist") body = t('features.unitPoolCorrection.notExist');

            return errorHandler(error, "useGetUnitPoolCorrection", { operation: "read", hide: false, messageBody: body });

        }

    }

    return { unitPoolCorrection, setUnitPoolCorrection, getUnitPoolCorrectionById };

}

export default useGetUnitPoolCorrection;

