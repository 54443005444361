import { useState, useEffect, useContext } from "react";
import useGetBudget from "../ReadBudget/useGetBudget";
import useUpdateBudget from "./useUpdateBudget";
import { Form, Popup, Icon, Header, Grid } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";
import { ButtonContainer, SaveBtn, CancelBtn } from "Components/Buttons";
import IUpdateBudget from "./interfaces/IUpdateBudget";
import IBudget from "./interfaces/IBudget";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { InputWithUnit } from "Components/FormElements/InputWithUnit/InputWithUnit";
import { StateContext } from "Application/state/StateContext";
import { PageTitle } from "Components/Titles";
import ICategory from "../ReadBudget/interfaces/IReadBudgetCategory";
import { MessageContext } from "Features/Messages";
import useInputValidation from "Components/FormElements/InputWithUnit/useInputValidation";
import useSurvey from "Features/Survey/useSurvey";
import { UploadCompanySurveyAttachements, useUploadCompanySurveyAttachements } from 'Features/CompanyAttachement';

function UpdateBudget () {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const detailPath = location.pathname.replace("/edit","")
    
    const { userProfile } = useContext(StateContext);
    const { companyId, surveyId } = useParams();
    const [ isLoading, setIsLoading ] = useState(true);
    const { setMessages, addMessage } = useContext(MessageContext);
    const { isNonNegativeInteger } = useInputValidation();
    const { updateBudget } = useUpdateBudget();
    const { budget, getBudgetById } = useGetBudget();
    const { isEditingAllowed } = useSurvey();

    const [ attachements, setAttachements ] = useState([]);
    const { uploadCompanySurveyAttachements } = useUploadCompanySurveyAttachements(); 

    const [ categories, setCategories ] = useState<ICategory[]>([]);

    const isAdmin = userProfile && userProfile.roles && userProfile.roles.find((item:any) => item.name==="Admin") ? true : false;

    // Fetch survey
    useEffect(() => {
        (async () => {
            if(companyId || userProfile.companyId) {
                await getBudgetById(companyId || userProfile.companyId,surveyId);
            }
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userProfile.companyId]);

     // set categories state
     useEffect(() => {
        setCategories(budget.categories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[budget.categories]);

    const setUnit = (value:number | null, id:{category: string, unit: string, field: string}) => {
        
        const cats = [...categories];
        const category = cats.find(item => item.id===id.category) || {id:"", name:"",units:[]};
        const unit = category.units.find(item => item.unitId===id.unit) || {itemId: "", unitId: "", unitName: "", isCompanyUnit: true, amount: null, finalBudget: null};

        switch(id.field){
            case "amount":
                value === null ? unit.amount = null : unit.amount = value;
                break;
            case "finalBudget":
                value === null ? unit.finalBudget = null : unit.finalBudget = value;
                break;
        }
        setCategories(cats);
    }

    const onSubmit = async () => {

        let budgets:IBudget[] = [];
        let errors=false;
        categories.map(cat =>
            cat.units.map(unit => {
                if(!isNonNegativeInteger(unit.amount)){
                    errors=true;
                }
                const budget:IBudget = isAdmin ? {id: unit.itemId, amount: unit.amount, finalBudget: unit.finalBudget} : {id: unit.itemId, amount: unit.amount};
                budgets.push(budget);
                return unit;
            })
        );

        var uploadAttachementRequest = {
            companyId: userProfile.companyId,
            surveyId: surveyId || "",
            surveyName: budget.name || "Budjettikysely",
            surveyTypeRef: "budget",
            attachements: attachements
        }

        setMessages([]);
        if(errors){
            addMessage({header: t("features.survey.inputErrorHeader"), type: "error", body: t("features.survey.inputErrorDescription")});
            window.scrollTo(0,0);
        } else {
            setIsLoading(true);

            const budget:IUpdateBudget = {
                budgets: budgets
            }

            let result = await updateBudget(companyId || userProfile.companyId,surveyId,budget);

            await uploadCompanySurveyAttachements(uploadAttachementRequest);

            if (result && result.status === 204) return navigate(detailPath);
            
            setIsLoading(false);
        }
    }

    return (
        
        <Form loading={isLoading} onSubmit={onSubmit} className="form-inline">
            
            <PageTitle 
                title={budget.name ? budget.name : t("pages.editBudget")} 
                button={
                    <Popup
                        trigger={<Icon name='info' color='blue' size='large' circular />}
                        content={t("features.budget.infoText")}
                        position='bottom left'
                        wide="very"
                    />
                }
            />

            {budget.id ?
                isEditingAllowed(budget.isPermanentlyLocked,budget.openDate,budget.closeDate) ?
                    <>
                    {categories && categories.map((cat:any) =>
                        <div key={"cat" + cat.id}>
                            <Header as="h2">{cat.name}</Header>
                            <Grid key={cat.id}>
                                
                                    {cat.units.map((unit:any) =>
                                        <Grid.Row key={unit.unitId}>
                                            
                                            <Grid.Column width={isAdmin ? 8 : 16}>
                                                <InputWithUnit
                                                    label={unit.unitName}
                                                    id={"amount" + unit.itemId}
                                                    value={unit.amount}
                                                    validateFor="nonNegativeInteger"
                                                    onChangeFunc={setUnit}
                                                    onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "amount"}}
                                                    type="number"
                                                    unit={t("features.budget.pcs")}
                                                    required
                                                />
                                            </Grid.Column>
                                            {isAdmin &&
                                                <Grid.Column width={8}>
                                                    <InputWithUnit
                                                        label={t("features.budget.finalBudget")}
                                                        id={"final" + unit.itemId}
                                                        value={unit.finalBudget}
                                                        validateFor="nonNegativeInteger"
                                                        onChangeFunc={setUnit}
                                                        onChangeFuncKey={{category: cat.id, unit: unit.unitId, field: "finalBudget"}}
                                                        type="number"
                                                        unit={t("features.budget.pcs")}
                                                    />
                                                </Grid.Column>
                                            }
                                        </Grid.Row>
                                    )}
                                
                            </Grid>
                        </div>
                    )}

                    <UploadCompanySurveyAttachements
                        companyId={userProfile.companyId}
                        surveyId={surveyId}
                        surveyTypeRef="budget"
                        setAttachements={setAttachements}
                    />

                    <ButtonContainer>
                        <SaveBtn />
                        <CancelBtn />
                    </ButtonContainer>
                    </>
                :
                    <p>{t("features.survey.editingNotAllowed")}</p>
            :
                <p>{t("features.survey.notFound")}</p>
            }
        </Form>
    );
}

export default UpdateBudget;
