import React, { useState, Fragment } from 'react';
import OpenRow from './OpenRow';
import CollapsedRow from './CollapsedRow';
import IPagedTableRowProps from '../interfaces/IPagedTableRowProps';

/** Table Row
 * @param {object} row - row object
 * @param {array<string>} header - Table header as array of strings
 * @param {array<object>} contentKeys - Table content keys as array of objects
 * @param {boolean} collapsable - table is collapsable?
*/
const TableRow: React.FC<IPagedTableRowProps> = ({
    row,
    header,
    contentKeys,
    collapsable = false
}: IPagedTableRowProps) => {

    const [ collapsed, setCollapsed ] = useState(collapsable ? true : false);

    return (
        <Fragment>
            {(!collapsable || collapsed) ?

                    <CollapsedRow 
                        row={row}
                        contentKeys={contentKeys}
                        collapsable={collapsable}                                
                        collapsed={collapsed}
                        setCollapsed={setCollapsed}
                    />
                :
                    <OpenRow
                        header={header} 
                        row={row}
                        contentKeys={contentKeys}
                        collapsable={collapsable}
                        collapsed={collapsed}
                        setCollapsed={setCollapsed}
                    />
            }
        </Fragment>
    );

}

export default TableRow;
