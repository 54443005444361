import { useTranslation } from 'react-i18next';
import { Modal, Header, Container } from 'semantic-ui-react';
import YesButton from './YesButton';
import NoButton from './NoButton';

import IYesNoModal from '../interfaces/IYesNoModal';

/** Open modal and ask Yes/No question
 * @param {string} type - type of the modal [delete, save, cancel, warning, ready]
 * @param {string} header - Header text
 * @param {string} text - Body text
 * @param {function} method - method which is called when user clicks "YES".
 *                     (if call is needed also on "NO" click, set noClick to true)
 *                     Called method must accept two params method(answer <boolean>, item <any>);
 * @param {any} item - OPTIONAL item which is passed as method parameter
 * @param {boolean} noClick - Set to true if method should be called when user clicks "NO" 
 * @param {any} open - React useState "open" -value
 * @param {function} setOpen - React useState "setOpen" -function
 * @param {boolean} handleClose - Close modal
 * @param {boolean} disableYesButton - Disable Yes button (f.e. some condition must be true before user can click yes)
*/
const YesNoModal: React.FC<IYesNoModal> = ({ type, header, text, method, item, noClick, open, setOpen, handleClose=false, disableYesButton=false, children }: IYesNoModal) => {

    const { t } = useTranslation();

    return (
        <Modal
            closeIcon
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
        >

            {type==='delete' &&
                <Header icon='delete' content={header || t('components.modals.delete')} />
            }
            {type==='save' &&
                <Header icon='save' content={header || t('components.modals.save')} />
            }
            {type==='cancel' &&
                <Header icon='cancel' content={header || t('components.modals.cancel')} />
            }
            {type==='warning' &&
                <Header icon='warning' content={header || t('components.modals.warning')} />
            }
            {type==='ready' &&
                <Header icon='question' content={header || t('components.modals.ready')} />
            }

            <Modal.Content>
                <Container style={{ marginTop: '20px', marginBottom: '20px' }}>
                    {children}
                </Container>
                {text || t('components.modals.areYouSure')}
            </Modal.Content>

            <Modal.Actions>

                <NoButton color="red" onClick={() => {
                    setOpen(false);
                    if (method && noClick) item ? method(false, item) : method(false);
                    
                }}/>

                <YesButton disabled={disableYesButton} color="green" onClick={() => {
                    if (!handleClose) setOpen(false);
                    if (method) item ? method(true, item) : method(true);
                }}/>

            </Modal.Actions>

        </Modal>
    );

}

export default YesNoModal;
