
/** Use Page Filter provides, logic, data, validations and methods to be used in the Paged table filter component */
const usePageFilter = () => {

    /** Compare if a and b are same text 
     * @param {string} a - string for example "title"
     * @param {string} b - string for example "title"
     * @returns {boolean} true | false
     **/
    const matchString = ((a: string, b: string ): Boolean => {
        let areEqual = a ? a.toLowerCase().indexOf(b.toLowerCase()) !== -1 : false;
        return areEqual;
    });

    /** Compare if a and b are same number
     * b is text because it comes from text field input
     * @param {number} a - number for example 123
     * @param {string} b - number as text for example "123"
     * @returns {boolean} true | false
     **/
    const matchInteger = ((a: number, b: string ): Boolean => {
        let areEqual = a ? a.toString().toLowerCase().indexOf(b.toLowerCase()) !== -1 : false;
        return areEqual;
    });

    /** Compare if a and b are same date 
     * @param {string} a - Date string f.e. 2023-11-08T08:48:15.045Z
     * @param {string} b - Date string f.e. 1.8.2023
     * @returns {boolean} true | false
     **/
    const matchDate = ((a: string, b: string ): Boolean => {
        let areEqual = a ? new Date(a).toLocaleDateString("fi-FI").indexOf(b.toLowerCase()) !== -1 : false;
        return areEqual;
    });

    /** Compare if a and b are same boolean value 
     * @param {boolean} a - Boolean value for example true
     * @param {string} b - Boolean value as string f.e. "true"
     * @returns {boolean} true | false
     **/
    const matchBool = ((a: boolean, b: string ): Boolean => {
        let areTrue = a === true && b.toLowerCase() === 'true' ? true : false;
        let areFalse = a === false && b.toLowerCase() === 'false' ? true : false;
        let areEqual = areTrue || areFalse;
        return areEqual;
    });

    return {
        matchString,
        matchInteger,
        matchDate,
        matchBool    
    };

}

export default usePageFilter;
