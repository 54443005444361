import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'semantic-ui-react';
import { EventType } from '@testing-library/react';
import { PageTitle } from 'Components/Titles';
import { PagedTable, FilterTable, PageSize, usePageFilter } from 'Components/Tables';
//import './../tables.css';

const Tables = () => {

    const { t } = useTranslation();
    const [ filterText, setFilterText ] = useState("");
    const [ itemsPerPage, setItemsPerPage ] = useState(25);
    const { matchString, matchInteger, matchDate, matchBool } = usePageFilter();

    const itemsToBeFiltered = [
        { id: 1, title: "a", description:"a1", date: '2021-11-05T08:48:15.045Z', isAdmin: true, project: 123456 },
        { id: 2, title: "b", description:"b2", date: '2022-12-08T08:48:15.045Z', isAdmin: false, project: 123457 },
        { id: 3, title: "c", description:"c3", date: '2022-13-09T08:48:15.045Z', isAdmin: false, project: 123458 },
        { id: 4, title: "d", description:"d4", date: '2023-14-10T08:48:15.045Z', isAdmin: true, project: 123459 },
        { id: 5, title: "e", description:"e5", date: '2023-15-11T08:48:15.045Z', isAdmin: false, project: 123460 },
        { id: 6, title: "f", description:"f6", date: '2024-16-12T08:48:15.045Z', isAdmin: false, project: 123461 }
    ]

    /* Create filtering method, match fields that you want to include to the filter  */
    const filteredData = itemsToBeFiltered.filter(item => {
        let titleMatch = matchString(item.title, filterText);
        let dateMatch = matchDate(item.date, filterText);
        let boolMatch = matchBool(item.isAdmin, filterText);
        let intMatch = matchInteger(item.project, filterText);   
        if (titleMatch || dateMatch || boolMatch || intMatch) return true;
        return false;
    });

    return (
        <Container className="page">

            <PageTitle title={t('pages.tables')}/>

            <h2>Paged Table examples</h2><hr/>

            <h4>Basic Paged Table</h4>
            <PagedTable
                header={[
                    "id",
                    "title"
                ]}
                content={[
                    { id: 1, title: "a", description:"a1" },
                    { id: 2, title: "b", description:"b2" },
                    { id: 3, title: "c", description:"c3" }
                ]}
                contentKeys={[
                    { key: 'id' },
                    { key: 'title' }
                ]}
                pageSize={2}
                resetPage={1} // You can change any value here to reset the table
                resetPageOnContentChange={true}
                
            />
            <br/>

            <h4>Paged Table filter and Items per page</h4>

            <PageSize itemsPerPage={itemsPerPage}  setItemsPerPage={setItemsPerPage} />

            <FilterTable filterText={filterText} setFilterText={setFilterText} />

            <PagedTable
                header={[
                    "id",
                    "title",
                    "date",
                    "project",
                    "isAdmin"
                ]}
                content={filteredData}
                contentKeys={[
                    { key: 'id' },
                    { key: 'title' },
                    { key: 'date' },
                    { key: 'project' },
                    { key: 'isAdmin', type: "boolean" }
                ]}
                pageSize={itemsPerPage}
                resetPage={1} // You can change any value here to reset the table
                resetPageOnContentChange={true}
                
            />
            <br/>

            <h4>Paged Table - Collapsable with paging</h4>
            <PagedTable
                header={[
                    "id",
                    "title",
                    "description"
                ]}
                content={[
                    { id: 1, title: "a", description:"a1" },
                    { id: 2, title: "b", description:"b2" },
                    { id: 3, title: "c", description:"c3" },
                    { id: 4, title: "d", description:"d3" },
                    { id: 5, title: "e", description:"e3" },
                    { id: 6, title: "f", description:"f3" },
                    { id: 7, title: "g", description:"g3" },
                    { id: 8, title: "h", description:"h3" },
                    { id: 9, title: "i", description:"i3" },
                    { id: 10, title: "j", description:"j3" },
                    { id: 11, title: "k", description:"k3" }
                ]}
                contentKeys={[
                    { key: 'id' },
                    { key: 'title', type: "link" },
                    { key: 'description' }
                ]}
                collapsable
                resetPageOnContentChange={true}
            />
            <br/>


            <h2>Paged Table Content Keys</h2><hr/>

            <h4>Paged Table - Align cell content to left, center or right</h4>
            <PagedTable
                header={[
                    "id",
                    "title",
                    "description"
                ]}
                content={[
                    { id: 1, title: "a", description:"a1" },
                    { id: 2, title: "b", description:"b2" },
                    { id: 3, title: "c", description:"c3" },
                    { id: 4, title: "d", description:"d3" },
                    { id: 5, title: "e", description:"e3" },
                    { id: 6, title: "f", description:"f3" },
                    { id: 7, title: "g", description:"g3" },
                    { id: 8, title: "h", description:"h3" },
                    { id: 9, title: "i", description:"i3" },
                    { id: 10, title: "j", description:"j3" },
                    { id: 11, title: "k", description:"k3" }
                ]}
                contentKeys={[
                    { key: 'id' }, // Align cell content to left, center or right
                    { key: 'title', type: "link" },
                    { key: 'description', cellAlign: 'right' }
                ]}
                collapsable
                resetPageOnContentChange={true}
            />
            <br/>

            <h4>Paged Table - Hide column when items are collapsed but show when opened!</h4>
            <PagedTable
                header={[
                    "id",
                    "title",
                    "description"
                ]}
                content={[
                    { id: 1, title: "a", description:"a1" },
                    { id: 2, title: "b", description:"b2" },
                    { id: 3, title: "c", description:"c3" },
                    { id: 4, title: "d", description:"d3" },
                    { id: 5, title: "e", description:"e3" },
                    { id: 6, title: "f", description:"f3" },
                    { id: 7, title: "g", description:"g3" },
                    { id: 8, title: "h", description:"h3" },
                    { id: 9, title: "i", description:"i3" },
                    { id: 10, title: "j", description:"j3" },
                    { id: 11, title: "k", description:"k3" }
                ]}
                contentKeys={[
                    { key: 'id', cellAlign: 'left' }, // Align cell content to left, center or right
                    { key: 'title', type: "link", hideCollapsed: true },
                    { key: 'description', hideCollapsed: true }
                ]}
                collapsable
                resetPageOnContentChange={true}
            />
            <br/>


            <h4>Paged Table - Change Cell width</h4>

            <p>Allowed cellWidth values: </p>
            <ul>
                <li>"1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16"</li>
                <li>1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16</li>
                <li>"one","two","three","four","five","six","seven","eight","nine","ten","eleven","twelve","thirteen","fourteen","fifteen","sixteen"</li>
            </ul>
            <PagedTable
                header={[
                    "id",
                    "title",
                    "description"
                ]}
                content={[
                    { id: 1, title: "a", description:"a1" },
                    { id: 2, title: "b", description:"b2" },
                    { id: 3, title: "c", description:"c3" },
                    { id: 4, title: "d", description:"d3" },
                    { id: 5, title: "e", description:"e3" },
                    { id: 6, title: "f", description:"f3" },
                    { id: 7, title: "g", description:"g3" },
                    { id: 8, title: "h", description:"h3" },
                    { id: 9, title: "i", description:"i3" },
                    { id: 10, title: "j", description:"j3" },
                    { id: 11, title: "k", description:"k3" }
                ]}
                contentKeys={[
                    { key: 'id' }, // Align cell content to left, center or right
                    { key: 'title', type: "link" },
                    { key: 'description', cellWidth: "12" }
                ]}
                collapsable
                resetPageOnContentChange={true}
            />
            <br/>

            <h4>Action Link - Call given function using link</h4>
            <PagedTable
                header={[
                    "id",
                    "title",
                ]}
                content={[
                    { id: 1, title: "action Link" },
                ]}
                contentKeys={[
                    { key: 'id' },
                    { key: 'title', type: "actionLink", text: "Additional text", action: (ev: EventType, row: any, cellKey: any) => { alert ("action") } },
                ]}
            />
            <br/>

            <h4>Action Button - Call given function using button, button can be disabled</h4>
            <PagedTable
                header={[
                    "id",
                    "title",
                ]}
                content={[
                    { id: 1, title: "actionButton 1", disabled: true },
                    { id: 2, title: "actionButton 2", disabled: false }
                ]}
                contentKeys={[
                    { key: 'id' },
                    { key: 'title', type: "actionButton", disabled: "disabled", action: (ev: EventType, row: any, cellKey: any) => { console.log(row) } },
                ]}
            />
            <br/>

            <h4>Array - Show list of array items in a cell</h4>
            <PagedTable
                header={[
                    "id",
                    "title",
                    "items"
                ]}
                content={[
                    { id: 1, title: "array of items", items: ["item1", "item2", "item3"] },
                ]}
                contentKeys={[
                    { key: 'id' },
                    { key: 'title' },
                    { key: 'items', type: "array" },
                ]}
            />
            <br/>

            <h4>Boolean - Show boolean as Yes or No</h4>
            <PagedTable
                header={[
                    "id",
                    "isAdmin"
                ]}
                content={[
                    { id: 1, isAdmin: true },
                    { id: 2, isAdmin: false }
                ]}
                contentKeys={[
                    { key: 'id' },
                    { key: 'isAdmin', type: "boolean" }
                ]}
            />
            <br/>

            <h4>Content Url Link - Link to another page when url is defined in the content</h4>
            <PagedTable
                header={[
                    "id",
                    "title",
                    "url"
                ]}
                content={[
                    { id: 1, title: "link 1", url: "http://localhost:3000/" },
                    { id: 2, title: "link 2", url: "http://localhost:3000/" }
                ]}
                contentKeys={[
                    { key: 'id' },
                    { key: 'title', type: "contentUrlLink", urlKey: "url", targetKey: "id" },
                    { key: 'url' }
                ]}
            />
            <br/>           

            <h4>Crud Links - one to three links</h4>
            <p>Note! Add '/Edit' after the editUrl!!!</p>
            <PagedTable
                header={[
                    "id",
                    "title",
                    "url"
                ]}
                content={[
                    { id: 1, title: "link 1", url: "http://localhost:3000/" },
                    { id: 2, title: "link 2", url: "http://localhost:3000/" }
                ]}
                contentKeys={[
                    { key: 'id' },
                    { key: 'title', type: "crudLinks", url: "/management/announcements/", targetKey: "id" },
                    { key: 'url' }
                ]}
            />
            <br/>

            <h4>Date field - show date as locate date string</h4>
            <PagedTable
                header={[
                    "id",
                    "date"
                ]}
                content={[
                    { id: 1, date: "2023-11-06T12:20:59.192Z" }
                ]}
                contentKeys={[
                    { key: 'id' },
                    { key: 'date', type: "date" }
                ]}
            />
            <br/>

            <h4>Datetime - show date as locate date string and locale time string</h4>
            <PagedTable
                header={[
                    "id",
                    "date"
                ]}
                content={[
                    { id: 1, date: "2023-11-06T12:20:59.192Z" }
                ]}
                contentKeys={[
                    { key: 'id' },
                    { key: 'date', type: "datetime" }
                ]}
            />
            <br/>

            <h4>External Link - Link to external url or another page</h4>
            <PagedTable
                header={[
                    "id",
                    "title"
                ]}
                content={[
                    { id: 1, title: "link 1" },
                    { id: 2, title: "link 2" }
                ]}
                contentKeys={[
                    { key: 'id' },
                    { key: 'title', type: "externalLink", target: "/projects/view/", targetKey: "id" }
                ]}
            />
            <br/>

            <h4>First chars - Show N characters and three dots ... </h4>
            <PagedTable
                header={[
                    "id",
                    "title"
                ]}
                content={[
                    { id: 1, title: "some text" },
                    { id: 2, title: "other text" }
                ]}
                contentKeys={[
                    { key: 'id' },
                    { key: 'title', type: "firstChars", limit:3 }
                ]}
            />
            <br/>

            <h4>Float numbers - Clean float number </h4>
            <PagedTable
                header={[
                    "id",
                    "title"
                ]}
                content={[
                    { id: 1, title: "link 1", someNumber: 12.346437 },
                    { id: 2, title: "link 2", someNumber: 34.357356  }
                ]}
                contentKeys={[
                    { key: 'id' },
                    { key: 'someNumber', type: "float", decimal:2 }
                ]}
            />
            <br/>

            <h4>Icons -  show same icon one or two times with different classes</h4>
            <PagedTable
                header={[
                    "id",
                    "title"
                ]}
                content={[
                    { id: 1, title: "link 1", icon: "blind", className1: "link1", className2: "link2" },
                    { id: 2, title: "link 2", icon: "blind", className1: "link1", className2: "link2" }
                ]}
                contentKeys={[
                    { key: 'id' },
                    { key: 'icon', key2: "className1", key3: "className2", type: "icons" }
                ]}
            />
            <br/>

            <h4>Link - Link to another page </h4>
            <PagedTable
                header={[
                    "id",
                    "title"
                ]}
                content={[
                    { id: 1, title: "link 1", someNumber: 12.346437 },
                    { id: 2, title: "link 2", someNumber: 34.357356  }
                ]}
                contentKeys={[
                    { key: 'id' },
                    { key: 'title', type: "link", target: "/projects/view/", targetKey: "id", text: "optional text"  }
                ]}
            />
            <br/>

            <h4>Link Button - Link to another page </h4>
            <PagedTable
                header={[
                    "id",
                    "title"
                ]}
                content={[
                    { id: 1, title: "link 1", someNumber: 12.346437 },
                    { id: 2, title: "link 2", someNumber: 34.357356  }
                ]}
                contentKeys={[
                    { key: 'id' },
                    { key: 'title', type: "linkButton", target: "/projects/view/", targetKey: "id" }
                ]}
            />
            <br/>

            <h4>Link with two keys - Link to another page, title contains two keys</h4>
            <PagedTable
                header={[
                    "id",
                    "title",
                    "xxx"
                ]}
                content={[
                    { id: 1, title: "link 1", xxx: "some text" },
                    { id: 2, title: "link 2", xxx: "other text" }
                ]}
                contentKeys={[
                    { key: 'id' },
                    { key: 'title', key2: 'xxx', type: "linkWithTwoKeys", target: "/projects/view/", targetKey: "id" },
                    { key: 'xxx' }
                ]}
            />
            <br/>

            <h4>Link With Two Targets And Keys - Link to another page, title contains two keys and link contains to keys</h4>
            <PagedTable
                header={[
                    "id",
                    "title",
                    "id2"
                ]}
                content={[
                    { id: 1, title: "link 1", id2: 21 },
                    { id: 2, title: "link 2", id2: 23 }
                ]}
                contentKeys={[
                    { key: 'id' },
                    { key: 'title', key2: 'id2', type: "linkWithTwoTargetsAndKeys", target1: "/projects/", targetKey1: "id", target2: "/contacts/view/", targetKey2: "id2", text: "optional text" },
                    { key: 'id2' }
                ]}
            />
            <br/>

            <h4>Select - Checkbox</h4>
            <PagedTable
                header={[
                    "id",
                    "title",
                    "checked"
                ]}
                content={[
                    { id: 1, title: "link 1", checked: true },
                    { id: 2, title: "link 2", checked: false }
                ]}
                contentKeys={[
                    { key: 'id' },
                    { key: 'title', type: "select", action: (ev: EventType, row: any, cellKey: any) => { alert("checkbox checked: " + row.id); console.log(row) } },
                    { key: 'url' }
                ]}
            />
            <br/>

            <h4>Key With Action Button - Show text and button with title underneath. Button can be disabled.  </h4>
            <PagedTable
                header={[
                    "id",
                    "title"
                ]}
                content={[
                    { id: 1, title: "title 1a", text: "some text a", disabled: true },
                    { id: 2, title: "title 1b", text: "some text b", disabled: false }
                ]}
                contentKeys={[
                    { key: 'id' },
                    { key: 'title', disabled:"disabled", key2: "text", type: "textWithActionButton", action: (ev: EventType, row: any, cellKey: any) => { console.log(row); } }
                ]}
            />
            <br/>

            <h4>Time - Convert ISO date string to Locale Time String</h4>
            <PagedTable
                header={[
                    "id",
                    "title",
                    "date"
                ]}
                content={[
                    { id: 1, title: "some date 1", date: "2023-11-06T12:20:59.192Z" },
                    { id: 2, title: "some date 2", date: "2023-12-06T12:20:59.192Z" }
                ]}
                contentKeys={[
                    { key: 'id' },
                    { key: 'date', type: "time" },
                    { key: 'date' }
                ]}
            />
            <br/>

            <h4>Two keys - Show two keys on title</h4>
            <PagedTable
                header={[
                    "id",
                    "title",
                    "checked"
                ]}
                content={[
                    { id: 1, title: "link 1", checked: true },
                    { id: 2, title: "link 2", checked: false }
                ]}
                contentKeys={[
                    { key: 'id' },
                    { key: 'title', type: "twokeys", key2: "title" },
                    { key: 'url' }
                ]}
            />
            <br/>

        </Container>
    )

}

export default Tables;
