import { useState, useEffect } from "react";
import useGetUnitPoolCorrection from "../ReadUnitPoolCorrection/useGetUnitPoolCorrection";
import useUpdateUnitPoolCorrection from "./useUpdateUnitPoolCorrection";
import { Form } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";
import { ButtonContainer, SaveBtn, CancelBtn } from "Components/Buttons";
import IUpdateUnitPoolCorrection from "./IUpdateUnitPoolCorrection";
import { useNavigate, useParams } from "react-router-dom";
import { InputWithUnit } from "Components/FormElements";
import DatePicker, { registerLocale } from "react-datepicker";
import fi from "date-fns/locale/fi";
import useGetUnit from "Features/Unit/ReadUnit/useGetUnit";

function UpdateUnitPoolCorrection () {

    const { t } = useTranslation();
    registerLocale("fi", fi);
    const navigate = useNavigate();
    const { id,unitPoolCorrectionId } = useParams();
    const [ isLoading, setIsLoading ] = useState(true);
    
    const { updateUnitPoolCorrection } = useUpdateUnitPoolCorrection();
    const { unitPoolCorrection, getUnitPoolCorrectionById } = useGetUnitPoolCorrection();

    const [ date, setDate ] = useState(new Date());
    const [ amount, setAmount ] = useState<number | null>(null);
    const [ description, setDescription ] = useState("");

    const { unit, getUnitById } = useGetUnit();

    // Fetch unit pool correction
    useEffect(() => {
        (async () => {
            if (unitPoolCorrectionId && id) {
                await getUnitPoolCorrectionById(id,unitPoolCorrectionId);
                await getUnitById(id);
                setIsLoading(false);
            }            
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    // set initial values for unitPoolCorrection
    useEffect(() => {
        if (unitPoolCorrection) {
            setDate(new Date(unitPoolCorrection.date));
            setAmount(unitPoolCorrection.amount);
            setDescription(unitPoolCorrection.description);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[unitPoolCorrection]);

    const onSubmit = async () => {

        setIsLoading(true);

        const d = date;
        d.setDate(d.getDate() + 1);
        d.setUTCHours(0,0,0,0);

        const unitPoolCorrection:IUpdateUnitPoolCorrection = {
            date: d.toISOString(),
            amount: amount || 0,
            description: description
        } 

        let result = await updateUnitPoolCorrection(id || "",unitPoolCorrectionId,unitPoolCorrection);

        if (result && result.status === 204) return navigate(-1);
        
        setIsLoading(false);
    }

    return (
        
        <Form loading={isLoading} onSubmit={onSubmit}>
            {unitPoolCorrection.date ?
                <>
                <div className="field">
                    <div style={{fontWeight: "bold"}}>{t("features.unitPoolCorrection.unit")}</div>
                    <div id="unit">{id && unit.name}</div>
                </div>

                <Form.Field required>
                    <div style={{fontWeight: "bold"}}>{t('features.unitPoolCorrection.date')}</div>
                </Form.Field>
                <DatePicker
                    id="date"
                    required
                    selected={date}
                    onChange={(date: Date) => setDate(date)}
                    locale='fi'
                    inline
                />
                <div style={{marginBottom: "20px"}}></div>

                <InputWithUnit
                    label={t("features.unitPoolCorrection.amount")}
                    id="amount"
                    value={amount}
                    validateFor="Integer"
                    onChangeFunc={setAmount}
                    type="number"
                    unit={t("features.unitPoolCorrection.pcs")}
                    required
                />

                <Form.TextArea
                    label={t("features.unitPoolCorrection.description")}
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    autoComplete="off"
                    required
                />
                <ButtonContainer>
                    <SaveBtn />
                    <CancelBtn />
                </ButtonContainer>
                </>
            :
                <p>{t("features.unitPoolCorrection.notFound")}</p>
            }
        </Form>
    );
}

export default UpdateUnitPoolCorrection;
