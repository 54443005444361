import { useState } from "react";
import useCreateYearlyBudget from "./useCreateYearlyBudget";
import { Form } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";
import { ButtonContainer, SaveBtn, CancelBtn } from "Components/Buttons";
import ICreateYearlyBudget from "./ICreateYearlyBudget";
import { useNavigate } from "react-router-dom";
import { InputWithUnit } from "Components/FormElements";

function CreateYearlyBudget () {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ year, setYear ] = useState<number | null>(null);
    const [ budget, setBudget ] = useState<number | null>(null);

    const { createYearlyBudget } = useCreateYearlyBudget();

    const onSubmit = async () => {

        setIsLoading(true);

        const newYearlyBudget:ICreateYearlyBudget = {
            year: year,
            budget: budget
        } 

        let result = await createYearlyBudget(newYearlyBudget);

        if (result && result.status === 201) return navigate(-1);
    
        setIsLoading(false);

    }

    return (

        <Form loading={isLoading} onSubmit={onSubmit}>
            <Form.Input
                label={t("features.yearlyBudget.year")}
                id="year"
                type="number"
                value={year}
                onChange={(e) => setYear(parseInt(e.target.value))}
                autoComplete="off"
                required
            />
            <InputWithUnit
                label={t("features.yearlyBudget.budget")}
                id="budget"
                value={budget}
                onChangeFunc={setBudget}
                unit={t("features.yearlyBudget.euro")}
                required
            />

            <ButtonContainer>
                <SaveBtn />
                <CancelBtn />
            </ButtonContainer>
        </Form>
    );
}

export default CreateYearlyBudget;
