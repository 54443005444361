import { useEffect, useState, useContext } from "react";
import useGetCompanySurveys from "Features/Survey/ListSurveys/useGetCompanySurveys";
import { useTranslation } from "react-i18next";
import { Segment, Header, Icon } from "semantic-ui-react";
import { StateContext } from "Application/state/StateContext";
import IListSurvey from "./IListSurveys";
import { Link } from 'react-router-dom';
import '../styles.css';


function ListNewSurveys () {

    const { t } = useTranslation();
    const { userProfile }  = useContext(StateContext);
    const { surveys, listSurveys } = useGetCompanySurveys();
    const [ isLoading, setIsLoading ] = useState(true);

    const newSurveys = surveys.filter(item =>
        !item.isPermanentlyLocked && 
        !item.isAnswered && 
        new Date(item.openDate)<=new Date() && 
        new Date(item.closeDate)>=new Date()
    )

    // Fetch surveys
    useEffect(() => {
        (async () => {
            if(userProfile.companyId){
                await listSurveys(userProfile.companyId);
            }
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userProfile.companyId]);

    return (
        <Segment className="invisible" loading={isLoading}>
            <Header>{t("features.survey.newSurveys")}</Header>
            {newSurveys.map((item:IListSurvey,index) => {
                let path = "/surveys/"
                switch (item.surveyRef || "") {
                    case "glassbottlereturnrate": 
                        path += "glassbottlereturnrates";
                        break;
                    case "transferfreightcost":
                        path += "transferfreightcosts";
                        break;
                    case "budget":
                        path += "budgets";
                        break;
                    case "volume":
                        path += "volumes";
                        break;
                    case "distributionfreight":
                        path += "distributionfreights";
                        break;
                    case "storagecost":
                        path += "storagecosts";
                        break;
                    case "inventory":
                        path += "inventories";
                        break;
                    case "clearing":
                        path += "clearings";
                        break;
                }
                // survey closes at midnight, subtract 1 second to get the right date
                let closeDate = new Date(item.closeDate);
                closeDate.setSeconds(closeDate.getSeconds()-1);
                item.closeDate=closeDate;
                
                return (
                    <div key={index}>
                        <Icon name="angle right" color="blue" />
                        <Link className="survey-link" key={item.id} to={path + "/" + item.id + "/edit"} >{item.name}</Link>
                        <div className="survey-deadline">- {t("features.survey.answerBy").replace("$prm$", closeDate.toLocaleDateString("fi-FI"))}</div>
                    </div>
                )
            })}
            {newSurveys.length===0 &&
                <p>{t("features.survey.noNewSurveys")}</p>
            }
        </Segment>
    );
}

export default ListNewSurveys;