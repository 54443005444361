import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useGetUnitPoolCorrection from "./useGetUnitPoolCorrection";
import { useParams } from 'react-router-dom';
import { Header, Segment } from 'semantic-ui-react';
import { ButtonContainer, EditBtn } from 'Components/Buttons';

function ReadUnitPoolCorrection () {

    const { t } = useTranslation();
    const { id,unitPoolCorrectionId } = useParams();
    const { unitPoolCorrection, getUnitPoolCorrectionById } = useGetUnitPoolCorrection();
    const [ isLoading, setIsLoading ] = useState(true);

    // Fetch unit pool correction
    useEffect(() => {
        (async () => {
            if (id && unitPoolCorrectionId) {
                await getUnitPoolCorrectionById(id,unitPoolCorrectionId);
                setIsLoading(false);
            }            
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <Segment className="invisible" loading={isLoading}>
            {unitPoolCorrection.date ?
                <>
                <Header sub>{t("features.unitPoolCorrection.unit")}</Header>
                <div>{unitPoolCorrection && unitPoolCorrection.unitName}</div>

                <Header sub>{t("features.unitPoolCorrection.date")}</Header>
                <div>{unitPoolCorrection && new Date(unitPoolCorrection.date).toLocaleDateString("fi-FI")}</div>

                <Header sub>{t("features.unitPoolCorrection.amount")}</Header>
                <div>{unitPoolCorrection && unitPoolCorrection.amount}</div>

                <Header sub>{t("features.unitPoolCorrection.description")}</Header>
                <div>{unitPoolCorrection && unitPoolCorrection.description}</div>

                <ButtonContainer><EditBtn to={`/management/units/${id}/unitpoolcorrections/${unitPoolCorrectionId}/edit`} /></ButtonContainer>
                </>
            :
                <p>{t("features.unitPoolCorrection.notFound")}</p>
            }

        </Segment>
    );

}

export default ReadUnitPoolCorrection;
