import { ErrorBoundary } from "react-error-boundary";
import InternalServerError500 from 'Pages/System/InternalServerError500';
import { LoggerContext } from "Features/Logging/LoggerContext";
import { useContext } from "react";
import IErrorBoundaryWrapper from "./IErrorBoundaryProps";

function ErrorBoundaryWrapper({children}: IErrorBoundaryWrapper) {

    const { logErrorBoundary } = useContext(LoggerContext);

    return(
        <ErrorBoundary fallback={<InternalServerError500/>} onError={logErrorBoundary} >
            {children}
        </ErrorBoundary>
    );

}

export default ErrorBoundaryWrapper;