import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'semantic-ui-react';
import IReleaseProps from './IReleaseProps';

/** New release
 * @param {string} version release version f.e. 3.2.1
 * @param {string} [description] release description <optional>
 * @param {string} [date] release date <optional>
 * @param {array} [changes] array of strings <optional>
 * @param {array} [features] array of strings <optional>
 * @param {array} [bugs] array of strings  <optional> */
const Release: React.FC<IReleaseProps> = ({version, description, date, changes, features, bugs}: IReleaseProps) => {

    const { t } = useTranslation();

    return (
        <Container className="release">

            <h3>{t('components.versions.title') + " " + version}</h3>

            <Container style={{ paddingLeft: "20px", marginBottom: "20px" }}>
                <b>{t('components.versions.info')}</b>
                <ul>
                    {date && <li>{t('components.versions.date') + ": " + date}</li>}
                    {description && <li>{t('components.versions.description') + ": " + description}</li>}
                </ul>
            </Container>

            {changes && changes.length > 0 && 
                <Container style={{ paddingLeft: "20px" }}>
                    <b>{t('components.versions.changes')}</b>
                    <ul>
                        {changes.map((item, index) => <li key={index}>{item}</li>)}
                    </ul>
                </Container>
            }

            {features && features.length > 0 && 
                <Container style={{ paddingLeft: "20px" }}>
                    <b>{t('components.versions.features')}</b>
                    <ul>
                        {features.map((item, index) => <li key={index}>{item}</li>)}
                    </ul>
                </Container>
            }

            {bugs && bugs.length > 0 && 
                <Container style={{ paddingLeft: "20px" }}>
                    <b>{t('components.versions.bugs')}</b>
                    <ul>
                        {bugs.map((item, index) => <li key={index}>{item}</li>)}
                    </ul>
                </Container>
            }

            <hr/>
        </Container>
    );
}

export default Release;
