import React, { Fragment } from 'react';
import { Table } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/pro-solid-svg-icons';
import IOpenRowProps from "../interfaces/IOpenRowProps";
import useCellContent from '../hooks/useCellContent';

/** Table Open Row 
 * @param {object} row - Table row
 * @param {array<string>} header - Table header as array of strings
 * @param {array<any>} contentKeys - Table content keys as array of objects
 * @param {boolean} collapsable - table is collapsaple?
 * @param {boolean} collapsed - table is collapsed?
 * @param {Function} setCollapsed - React Set State method to set collapsed
 * 
 * @example row (= single content row)
 * content={[
 *  { id: 1, title: "a", description:"a1" }, => ROW 0
 *  { id: 2, title: "b", description:"b2" }, => ROW 1
 *  { id: 3, title: "c", description:"c3" }, => ROW 2
 * ]
 * 
 * @example header
 *  header={[
 *      t('general.name'),
 *      t('general.firstName'),
 *      t('general.lastName'),
 * ]}
 * 
 * @example contentKeys
 *  contentKeys={[
 *      { key: 'id' },
 *      { key: 'title', type: "link" },
 *      { key: 'description' }
 * ]}
 * 
 **/
const OpenRow: React.FC<IOpenRowProps> = ({
    row = {},
    header = [],
    contentKeys = [],
    collapsable,
    collapsed,
    setCollapsed
}: IOpenRowProps) => {

    const getCellContent = useCellContent();

    return (
        <Table.Row className={collapsable && !collapsed ? 'collapsable open' : ''} >
            {collapsable &&
                <Table.Cell onClick={() => setCollapsed(!collapsed)}>
                    <FontAwesomeIcon icon={faMinusCircle} />
                </Table.Cell>
            }
            <Table.Cell colSpan={contentKeys.length}>
                {contentKeys.map((cellKey, i) =>
                    <Fragment key={i}>
                        <div>
                            <span className='header'>
                                {`${header[i]}: `}
                            </span>
                            {getCellContent(row, cellKey)}
                        </div>
                    </Fragment>     
                )}
            </Table.Cell>

        </Table.Row>
    );

}

export default OpenRow;
